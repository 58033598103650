import { useEffect, useMemo, useCallback, useState } from 'react';
import { throttle } from 'lodash';
import { useLazyQuery, useMutation } from '@apollo/client';
import { client as httpClient } from '../http';
import { backendContext } from '../graphql/client';
import { GET_ACCOUNT_CARTS, VALIDATE_ACCOUNT_CARTS } from '../graphql/queries';
import {
  ADD_CART_ITEM,
  ADD_CART_ITEMS,
  UPDATE_CART_ITEM,
  REMOVE_CART_ITEM,
  CLEAR_CARTS,
  UPDATE_CART,
  CHECKOUT,
} from '../graphql/mutations';
import {
  transformCart,
  consolidateCartItems,
  calculateOrderSummary,
} from '../utils';
import useAccount from './useAccount';

const INIT_COUPON_VALIDATION = {
  isValid: null,
  error: null,
  message: null,
};

const useCart = ({ validate = false } = {}) => {
  const { accountId } = useAccount();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [loading, setLoading] = useState(false); // checkout loading state
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState(null);

  // TODO Coupons was once close to shipping, hence intertwined in various places; will need some time to safely refactor
  const [couponValidation] = useState(INIT_COUPON_VALIDATION);
  const { coupon } = couponValidation || {};
  //   const [validatingCoupon, setValidatingCoupon] = useState(false);
  //   const [couponCode, setCouponCode] = useState(null);
  //   const [isDirtyCoupon, setIsDirtyCoupon] = useState(false);
  //   const initCartCoupon = useRef(false);

  const handleReportError = async (report) => {
    const LOG_API = '/logs/report';
    await httpClient.post({
      url: LOG_API,
      body: report,
    });
  };

  const [getCart, { data: cartData, refetch: refetchCart }] = useLazyQuery(
    GET_ACCOUNT_CARTS,
    backendContext,
  );
  const [
    validateCart,
    { data: cartValidationData = null, refetch: refetchValidateCart },
  ] = useLazyQuery(VALIDATE_ACCOUNT_CARTS, backendContext);

  const [addOneCartItem, { loading: addOneCartLoading }] = useMutation(
    ADD_CART_ITEM,
    backendContext,
  );
  const [addCartItems, { loading: addCartLoading }] = useMutation(
    ADD_CART_ITEMS,
    backendContext,
  );
  const [updateCartItem, { loading: updateCartLoading }] = useMutation(
    UPDATE_CART_ITEM,
    backendContext,
  );
  const [removeCartItem, { loading: removeCartLoading }] = useMutation(
    REMOVE_CART_ITEM,
    backendContext,
  );
  const [clearCart, { loading: clearCartLoading }] = useMutation(
    CLEAR_CARTS,
    backendContext,
  );
  // Legacy — diffs whole array of items
  const [updateCartFn, { loading: updateFnCartLoading }] = useMutation(
    UPDATE_CART,
    backendContext,
  );

  const cartLoading =
    addCartLoading ||
    addOneCartLoading ||
    updateCartLoading ||
    removeCartLoading ||
    clearCartLoading ||
    updateFnCartLoading;

  const updateCart = async (params) => {
    if (validate) {
      return updateCartFn({
        ...params,
        refetchQueries: [GET_MY_CART, VALIDATE_CART],
      });
    } else {
      return updateCartFn({ ...params, refetchQueries: [GET_MY_CART] });
    }
  };
  const [checkout] = useMutation(CHECKOUT, backendContext);

  useEffect(() => {
    getCart();
  }, []);

  const cart = useMemo(() => {
    const c = [];
    cartData?.accountCarts?.carts?.forEach(({ cartItems }) => {
      cartItems.forEach((item) => {
        c.push(item);
      });
    });
    return c;
  }, [cartData?.accountCarts?.carts]);

  useEffect(() => {
    if (validate) validateCart();
  }, [cart, validate]);

  const cartValidation = useMemo(() => {
    const v = {
      isValid: true,
      unavailableItems: [],
    };
    cartValidationData?.accountCarts?.carts?.forEach(({ validation }) => {
      if (validation?.isValid === false) {
        v.isValid = false;
        validation.unavailableItems.forEach((item) => {
          v.unavailableItems.push(item);
        });
      }
    });
    return v?.isValid === false ? v : null;
  }, [cartValidationData?.accountCarts?.carts]);

  useEffect(() => {
    if (!cartValidation) return;
    const { isValid, unavailableItems } = cartValidation;
    if (isValid === false) {
      setErrors({ unavailableItems });
    } else {
      setErrors(null);
    }
  }, [cartValidation]);

  const [transformedCart, quantity, summary] = useMemo(() => {
    const tc = transformCart(cart, couponValidation);
    return [
      tc.transformedCartItems,
      tc.quantity,
      calculateOrderSummary(cartData?.accountCarts),
    ];
  }, [cartData, cart, couponValidation, errors]);

  const handleCheckout = useCallback(async () => {
    if (errors) {
      setErrors(null);
    }
    setLoading(true);
    const variables = {
      paymentMethodId: selectedPaymentMethod,
      accountId,
    };
    let hasError = false;
    const checkoutResult = await checkout({ variables }).catch((e) => {
      hasError = true;
      return { data: {}, errors: [e] };
    });
    const {
      data: { checkout: res = null },
      errors: resErr = [],
    } = checkoutResult || { data: {}, errors: [] };
    if (
      hasError ||
      !res ||
      res?.isValid === false ||
      res?.errors?.length > 0 ||
      resErr?.length > 0
    ) {
      // TODO Recheck cart; working to optimize backend since requests can timeout
      const cartRes = await refetchCart();
      if (cartRes?.accountCarts?.carts?.length === 0) {
        setSuccess(true);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        return;
      }
      // TODO Recheck cart

      hasError = res?.errors?.length > 0 || resErr.length > 0;
      const errorMessage = hasError
        ? 'There was an error processing your order. Please try again or contact support.'
        : 'There was an error validating your cart items. Please remove noted items above to complete checkout process.';
      setErrors({
        message: errorMessage,
        unavailableItems: res?.unavailableItems || [],
        cartItems: res?.cartItems || [],
      });
      await Promise.all([
        handleReportError({ variables, checkoutResult }),
        refetchValidateCart(),
      ]);
      const scrollEl = document.getElementById('checkout-error');
      if (scrollEl) scrollEl.scrollIntoView({ behavior: 'smooth' });
    } else {
      await refetchCart();
      setSuccess(true);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    setLoading(false);
  }, [
    accountId,
    selectedPaymentMethod,
    errors,
    refetchCart,
    refetchValidateCart,
    checkout,
  ]);

  const handleCheckoutThrottled = useCallback(throttle(handleCheckout, 1000), [
    handleCheckout,
  ]);

  const handleUpdateCart = useCallback(
    async (contents = { items: [], coupons: [] }) => {
      if (errors) setErrors(null);
      if (!contents.coupons) {
        contents.coupons = cart.coupons || [];
      }
      if (!contents.items) {
        contents.items = cart || [];
      }
      await updateCart({
        variables: {
          contents,
        },
      });
    },
    [cart, errors],
  );

  const handleClearCart = useCallback(async () => {
    await clearCart();
    await refetchCart();
  }, []);

  const handleUpdateCartItem = useCallback(
    async ({ id, quantity }, shouldRefetch = true) => {
      if (id) {
        const cartItem = cart.find(({ id: cId }) => cId === id);
        const userId = cartItem?.user?.id;
        if (!cartItem || !userId) {
          console.error(
            'Cart item id or user id not found in handleUpdateCartItem()',
            { userId, itemId: id, quantity, accountId },
          );
          return;
        }
        if (quantity >= 0) {
          await updateCartItem({
            variables: { cartId: userId, itemId: id, quantity },
          });
          if (shouldRefetch) await refetchCart();
          return;
        }
      }
      if (shouldRefetch) await refetchCart();
    },
    [cart],
  );

  const handleAddCartItem = useCallback(
    async (params) => {
      const {
        item: { selectedDates, selectedUsers, quantity = 1, options = {} },
        menuProductId,
      } = params;
      const addItems = [];
      selectedDates.forEach((date) => {
        selectedUsers.forEach((userId) => {
          addItems.push({
            menuProductId,
            quantity,
            userId,
            date,
            options,
          });
        });
      });
      const updatedCartData = {
        items: consolidateCartItems([...cart, ...addItems]),
      };

      // Diff add/update opertions
      const aItems = updatedCartData.items
        .filter(({ id }) => !id)
        .map(({ date, quantity, userId }) => ({
          menuProductId,
          date,
          quantity,
          userId,
        }));
      const uItems = updatedCartData.items
        .filter(({ id, quantity }) => {
          const cItem = cart.find(({ id: cId }) => cId === id);
          if (!cItem) return false;
          return cItem?.quantity !== quantity;
        })
        .map(({ id, quantity, userId }) => ({ id, quantity, userId }));

      const ops = [];
      if (aItems.length === 1) {
        ops.push(() =>
          addOneCartItem({
            variables: { item: aItems[0] },
          }),
        );
      } else if (aItems.length > 1) {
        ops.push(() =>
          addCartItems({
            variables: { items: aItems },
          }),
        );
      }
      if (uItems.length > 0) {
        uItems.forEach((item) => {
          ops.push(() =>
            handleUpdateCartItem(
              {
                id: item.id,
                quantity: item.quantity,
              },
              false,
            ),
          );
        });
      }
      if (ops.length > 0) {
        await Promise.all(ops.map((fn) => fn()));
        await refetchCart();
      }
      return updatedCartData;
    },
    [cart, handleUpdateCartItem],
  );

  const handleRemoveCartItem = useCallback(
    async ({ id }) => {
      if (id) {
        const cartItem = cart.find(({ id: cId }) => cId === id);
        const userId = cartItem?.user?.id;
        if (!cartItem) {
          console.error(
            'Cart item id or user id not found in handleRemoveCartItem()',
            { userId, itemId: id, accountId },
          );
        }
        await removeCartItem({ variables: { cartId: userId, itemId: id } });
        await refetchCart();
        return;
      }
      await refetchCart();
    },
    [cart],
  );

  return {
    cart,
    transformedCart,
    summary,
    quantity,
    cartLoading,
    refetchCart,
    cartValidation,
    onUpdateCart: handleUpdateCart,
    onUpdateCartItem: handleUpdateCartItem,
    onRemoveCartItem: handleRemoveCartItem,
    onClearCart: handleClearCart,
    onAddCartItem: handleAddCartItem,
    selectedPaymentMethod,
    onSetSelectedPaymentMethod: setSelectedPaymentMethod,
    onCheckout: handleCheckoutThrottled,
    checkoutLoading: loading,
    success,
    errors,
    coupon,
  };
};

export default useCart;
