import { useState, useEffect } from 'react';

const useVersionCheck = () => {
  const [hasAppUpdate, setHasAppUpdate] = useState(null);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data) {
        switch (event.data.type) {
        case 'NEW_VERSION_AVAILABLE':
          setHasAppUpdate(true);
          break;
        case 'VERSION_CURRENT':
          setHasAppUpdate(false);
          break;
        default:
          break;
        }
      }
    };

    navigator?.serviceWorker?.addEventListener('message', handleMessage);

    return () => navigator?.serviceWorker?.removeEventListener('message', handleMessage);
  }, []);

  return { hasAppUpdate };
};

export default useVersionCheck;
