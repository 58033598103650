import { useState, useMemo, useCallback } from 'react';
import styled from '@emotion/styled';
import useSubscriptions from '../../hooks/useSubscriptions';
import { getNow, formatDateTime } from '../../utils';
import { SUBSCRIPTION_STATUSES } from '../../utils/Consts';
import { ModalAdvanced, ToggleButton, Heading } from '../../common';

const Wrapper = styled.div`
  justify-content: flex-start;
  align-items: stretch;
`;

const PauseSubscriptionForm = ({ userId, userDisplayName, onClose }) => {
  const { isSaving, onUpsertUserSubscription } = useSubscriptions();
  const [pauseUntilDate, setPauseUntilDate] = useState(null);

  const handleSubmitPauseSubscription = useCallback(async () => {
    if (!pauseUntilDate) return;
    await onUpsertUserSubscription(userId, null, {
      status: SUBSCRIPTION_STATUSES.PAUSED,
      pauseUntil: pauseUntilDate,
    });
    if (onClose) onClose();
  }, [pauseUntilDate, userId]);

  const options = useMemo(() => {
    const now = getNow().startOf('week');
    const o = [1, 2, 3, 4].map((v) => {
      const d = now.clone().add(v, 'week');
      const labelDate = formatDateTime({ dateTime: d, format: 'MMM D' });
      return {
        label: `Skip ${v} ${v === 1 ? 'week' : 'weeks'}. Resume ${labelDate}`,
        date: formatDateTime({ dateTime: d, format: 'YYYY-MM-DD' }),
      };
    });
    return o;
  }, []);

  const renderHeading = (title) => {
    return (
      <Heading variant="h6" sx={{ marginBottom: '1rem' }}>
        {title}
      </Heading>
    );
  };

  const renderPauseOption = ({ label, date }) => {
    const isSelected = pauseUntilDate === date;
    const buttonSx = { marginBottom: '0.75rem' };
    return (
      <ToggleButton
        key={date}
        onClick={() => setPauseUntilDate(date)}
        variant="outlined"
        sx={buttonSx}
        loading={isSaving}
        isSelected={isSelected}
        fullWidth
      >
        {label}
      </ToggleButton>
    );
  };

  const actions = useMemo(() => {
    const a = [
      {
        label: 'Pause Subscription',
        onClick: handleSubmitPauseSubscription,
        disabled: !pauseUntilDate || isSaving,
        loading: isSaving,
      },
    ];
    return a;
  }, [isSaving, pauseUntilDate]);

  const renderContent = () => {
    return (
      <>
        {renderHeading(userDisplayName)}
        {options.map(renderPauseOption)}
      </>
    );
  };

  return (
    <ModalAdvanced
      title="Skip Orders"
      wrapper={Wrapper}
      onClose={onClose}
      allowOutsideClickToClose={false}
      actions={actions}
    >
      {renderContent()}
    </ModalAdvanced>
  );
};

export default PauseSubscriptionForm;
