import { ImageList } from '@mui/material';
import styled from '@emotion/styled';
import { styleConstructor } from '../../styles/Utils';

const Image = styled.img`
    ${({ sx }) => styleConstructor(sx)}
`;

Image.List = ImageList;

export default Image;
