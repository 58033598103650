import useSubscriptions from '../../hooks/useSubscriptions';
import useProductReactions from '../../hooks/useProductReactions';
import { PageContainer, UserProductReactionsCatalog } from '../../components';
import { Heading } from '../../common';

const UserProfiles = () => {
  const { userIds, users } = useSubscriptions();
  const {
    products,
    userProducts,
    onUpdate: onUpdateProductReaction,
    loading,
    isSaving,
  } = useProductReactions();

  const renderUserProductReactionsCatalog = () => {
    return (
      <UserProductReactionsCatalog
        userIds={userIds}
        users={users}
        products={products}
        userProducts={userProducts}
        onSubmit={onUpdateProductReaction}
        isSaving={isSaving}
      />
    );
  };

  return (
    <PageContainer
      pageTitle="Product Catalog"
      showPageTitle={false}
      loading={loading}
    >
      <Heading variant="h4">Help us understand you a little bit more</Heading>
      {renderUserProductReactionsCatalog()}
    </PageContainer>
  );
};

export default UserProfiles;
