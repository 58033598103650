import { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_ROSTERS } from '../graphql/queries';
import { client as httpClient } from '../http';
import { getNow, formatDateTime, sort, sortUsaStates, sortGradesComparator, constructQueryString } from '../utils';
import { ROSTER_GROUPS, ROSTER_GROUP_LABELS } from '../utils/Consts';
import USA_STATE_LIST from '../utils/usaStates.json';

const { GRADE, HOMEROOM, MEAL_PERIOD, LOCATION } = ROSTER_GROUPS;
const USA_STATES = USA_STATE_LIST
  .map(({ name: label, abbreviation: value }) => ({ label, value }));

const ACCESS_KEY = process?.env?.CLOUD_ACCESS_KEY;

const notListed = {
  label: '—School Not Listed—',
  value: 'NOT_LISTED',
  locationId: null,
};

const useRosterSelection = (
  initState,
  {
    form,
    isPublic = false,
  },
) => {
  const { values, handleChange, setFieldValue, setFieldTouched } = form;

  const [usaState, setUsaState] = useState(initState?.usaState || '');
  const [selectedLocationId, setSelectedLocationId] = useState(initState?.locationId || null);
  const [rosterIds] = useState(initState?.rosterIds || []);

  const [locations, setLocations] = useState(usaState ? { [usaState]: [notListed] } : {});

  const [publicRosters, setPublicRosters] = useState(null);
  const [publicRostersLoading, setPublicRostersLoading] = useState(false);

  const [getRosters, { data: rData, loading: rLoading }] = useLazyQuery(GET_ROSTERS, {
    fetchPolicy: 'network-only',
  });

  const rostersData = isPublic ? publicRosters : rData;
  const rostersLoading = isPublic ? publicRostersLoading : rLoading;

  const { current: dateContext } = useRef(initState?.date
      ? formatDateTime({ dateTime: initState?.date, asString: false }).endOf('day').format()
      : getNow().endOf('day').format()
  );

  const initStateRef = useRef(initState);
  const valuesRef = useRef(form?.values);

  const resetRosterValues = () => {
    setFieldValue(`rosterId:${MEAL_PERIOD}`, null);
    setFieldValue(`rosterId:${GRADE}`, null);
    setFieldValue(`rosterId:${HOMEROOM}`, null);
    setFieldValue(`rosterId:${LOCATION}`, null);
  };

  useEffect(() => {
    valuesRef.current = values;
  }, [values]);

  useEffect(() => {
    setFieldValue('usaState', usaState);
  }, [usaState]);

  useEffect(() => {
    if (
      rosterIds.includes(values[`rosterId:${GRADE}`])
      && rosterIds.includes(values[`rosterId:${HOMEROOM}`])
    ) return;

    const rId = values[`rosterId:${GRADE}`]
      ? values[`rosterId:${HOMEROOM}`]
      : null;
    setFieldValue(values[`rosterId:${HOMEROOM}}`], rId);
  }, [values[`rosterId:${GRADE}`]]);

  useEffect(() => {
    if (!usaState) return;
    if (!values.school && !initStateRef.current.locationId) {
      setSelectedLocationId(null);
      setFieldValue('locationId', null);
      resetRosterValues();
      return;
    }
    if (locations[usaState]) {
      const foundSchool = locations[usaState]?.find(({ value, locationId }) => {
        if (!values.school && initStateRef.current.locationId) {
          // Select school from init state — this case is edit mode with location set on au
          return locationId === initStateRef.current.locationId;
        }
        return value === values.school;
      });
      if (foundSchool?.locationId) {
        // TODO Because we have to init a state when a locationId is passed (*) for school list query, we need to set the found usaState here
        const isTempUsaState = usaState === '*'
          && initStateRef.current.usaState === '*';
        const isLocMatch = usaState === foundSchool.stateProvince;
        setSelectedLocationId(foundSchool.locationId);
        setFieldValue('locationId', foundSchool.locationId);
        if (!values.school && isLocMatch) {
          setFieldValue('school', foundSchool.value);
        }
        if (isTempUsaState && !isLocMatch) {
          setUsaState(foundSchool.stateProvince);
        }
      }
      const lId = foundSchool?.locationId || null;
      setSelectedLocationId(lId);
      setFieldValue('locationId', lId);
      if (!lId) resetRosterValues();
    }
  }, [usaState, values.school, locations]);

  useEffect(() => {
    if (values.locationId) {
      if (!isPublic) {
        getRosters({
          variables: {
            locationIds: [values.locationId],
            dateTime: dateContext,
          }
        });
      } else {
        (async () => {
          setPublicRostersLoading(true);
          const termIds = initStateRef.current?.termIds || [];
          const qs = constructQueryString({ date: dateContext, termIds });
          await httpClient.get({
            url: `/locations/${values.locationId}/rosters${qs}`,
            headers: { _accessKey: ACCESS_KEY },
          })
            .then(({ data }) => {
              setPublicRosters({ rosters: data });
            }
            ).catch((err) => {
              console.error(err);
            }
            ).finally(() => {
              setPublicRostersLoading(false);
            });
        })();
      }
    }
  }, [values.locationId]);

  const getRosterOptions = useCallback((rosters, customSortComparator = null, showDescription = true) => {
    const sortedRosters = customSortComparator
      ? rosters?.sort(customSortComparator)
      : sort(rosters, ['sortOrder', 'name', 'description', 'id']);
    const r = sortedRosters?.map((r) => {
      return {
        value: r.id,
        label: (showDescription && r.description)
          ? `${r.name} (${r.description})`
          : r.name,
      };
    });
    return r;
  }, []);

  const { rosterFields, hasUnsetRosters, terms } = useMemo(() => {
    const f = [];
    if ((rostersData?.rosters?.length === 0) || !values.locationId || !values.school) {
      return ({ rosterFields: f, hasUnsetRosters: false });
    }

    const getHasRosterToInit = (rId) => {
      return f.some(({ options }) => {
        return options?.some(({ value }) => {
          return value === rId;
        });
      });
    };

    const getInitTermRoster = () => ({
      [GRADE]: [], [HOMEROOM]: [], [MEAL_PERIOD]: [], [LOCATION]: [],
    });

    const termIds = [];
    const termObjs = {};
    const rosterIdsByGroup = {};
    const rostersByGroup = {};

    rostersData?.rosters.forEach((r) => {
      const termId = r.term?.id;
      const hasTerm = termIds.includes(termId);
      if (!hasTerm && termId) {
        termIds.push(termId);
        termObjs[termId] = r.term;
        }
        if (!rosterIdsByGroup[termId]) {
          rosterIdsByGroup[termId] = getInitTermRoster();
          rostersByGroup[termId] = getInitTermRoster();
          }
          if (rosterIdsByGroup[termId][r.group]) {
            rosterIdsByGroup[termId][r.group].push(r.id);
            rostersByGroup[termId][r.group].push(r);
            }
            });

    termIds.forEach((termId) => {
      let filteredHomeRosters = rostersByGroup[termId][HOMEROOM];
      if (rosterIdsByGroup[termId][MEAL_PERIOD].length > 1) {
        f.push({
          id: `rosterId:${MEAL_PERIOD}:${termId}`,
          label: `${ROSTER_GROUP_LABELS[MEAL_PERIOD]} (${termObjs[termId].name})`,
          type: 'autocomplete',
          options: getRosterOptions(rostersByGroup[termId][MEAL_PERIOD]),
        });
      }

      if (rosterIdsByGroup[termId][GRADE].length > 1) {
        f.push({
          id: `rosterId:${GRADE}:${termId}`,
          label: `${ROSTER_GROUP_LABELS[GRADE]} (${termObjs[termId].name})`,
          type: 'autocomplete',
          options: rostersByGroup[termId][GRADE].every(({ sortOrder }) => !sortOrder)
            ? getRosterOptions(rostersByGroup[termId][GRADE], sortGradesComparator, false)
            : getRosterOptions(rostersByGroup[termId][GRADE], null, false),
        });
      }

      if (rosterIdsByGroup[termId][HOMEROOM].length > 0) {
        const shouldFilterHomeroomRosters = true; // TODO Enabling for all
        // || (rosterIdsByGroup[termId][GRADE].length === 1)
        // || (values[`rosterId:${GRADE}:${termId}`] && (rosterIdsByGroup[termId][GRADE].length > 1));
        if (shouldFilterHomeroomRosters) {
          // const foundGradeRoster = (rosterIdsByGroup[termId][GRADE].length === 1)
          //   ? rosterIdsByGroup[termId][GRADE][0]
          //   : rostersByGroup[termId][GRADE].find((r) => {
          //     return r.id === values[`rosterId:${GRADE}:${termId}`];
          //   });
          // if (foundGradeRoster?.description) {
          //   filteredHomeRosters = rostersByGroup[termId][HOMEROOM].filter((r) => {
          //     return r.description
          //       ? r.description === foundGradeRoster.description
          //       : true;
          //   });
          // }
          if (filteredHomeRosters?.length > 1) {
            f.push({
              id: `rosterId:${HOMEROOM}:${termId}`,
              label: `${ROSTER_GROUP_LABELS[HOMEROOM]} (${termObjs[termId].name})`,
              type: 'autocomplete',
              options: getRosterOptions(filteredHomeRosters),
            });
          }
        }
      }

      if (rosterIdsByGroup[termId][MEAL_PERIOD].length === 0) {
        setFieldValue(`rosterId:${MEAL_PERIOD}:${termId}`, null);
      }
      if (rosterIdsByGroup[termId][GRADE].length === 0) {
        setFieldValue(`rosterId:${GRADE}:${termId}`, null);
      }
      if (filteredHomeRosters.length === 0) {
        setFieldValue(`rosterId:${HOMEROOM}:${termId}`, null);
      }

      if (rosterIdsByGroup[termId][MEAL_PERIOD].length === 1) {
        setFieldValue(`rosterId:${MEAL_PERIOD}:${termId}`, rosterIdsByGroup[termId][MEAL_PERIOD][0]);
      }
      if (rosterIdsByGroup[termId][GRADE].length === 1) {
        setFieldValue(`rosterId:${GRADE}:${termId}`, rosterIdsByGroup[termId][GRADE][0]);
      }
      if (filteredHomeRosters.length === 1) {
        setFieldValue(`rosterId:${HOMEROOM}:${termId}`, rosterIdsByGroup[termId][HOMEROOM][0]);
      }

      if (
        rosterIdsByGroup[termId][MEAL_PERIOD].length > 1
        && !values[`rosterId:${MEAL_PERIOD}:${termId}`]
        && initStateRef.current[`rosterId:${MEAL_PERIOD}:${termId}`]
        && getHasRosterToInit(initStateRef.current[`rosterId:${MEAL_PERIOD}:${termId}`])
      ) {
        setFieldValue(`rosterId:${MEAL_PERIOD}:${termId}`, initStateRef.current[`rosterId:${MEAL_PERIOD}:${termId}`]);
      }
      if (
        rosterIdsByGroup[termId][GRADE].length > 1
        && !values[`rosterId:${GRADE}:${termId}`]
        && initStateRef.current[`rosterId:${GRADE}:${termId}`]
        && getHasRosterToInit(initStateRef.current[`rosterId:${GRADE}:${termId}`])
      ) {
        setFieldValue(`rosterId:${GRADE}:${termId}`, initStateRef.current[`rosterId:${GRADE}:${termId}`]);
      }
      if (
        filteredHomeRosters.length > 1
        && !values[`rosterId:${HOMEROOM}:${termId}`]
        && initStateRef.current[`rosterId:${HOMEROOM}:${termId}`]
        && getHasRosterToInit(initStateRef.current[`rosterId:${HOMEROOM}:${termId}`])
      ) {
        setFieldValue(`rosterId:${HOMEROOM}:${termId}`, initStateRef.current[`rosterId:${HOMEROOM}:${termId}`]);
      }
    });

    return {
      rosterFields: f,
      hasUnsetRosters: f.some(({ id }) => !values[id]),
      terms: termObjs,
    };
  }, [
    rostersData,
    values,
    // values[`rosterId:${GRADE}`], // TODO
    // values[`rosterId:${HOMEROOM}`], // TODO
    // values[`rosterId:${MEAL_PERIOD}`], // TODO
    values.locationId,
    values.school,
    getRosterOptions,
    setFieldValue,
  ]);

  const handleTextFieldChange = useCallback((e, id, cb, value) => {
    const v = value || e?.target?.value;
    valuesRef.current = { ...valuesRef.current, [id]: v };
    handleChange(id)(v || '');
    setTimeout(() => setFieldTouched(id, !!v));
    if (cb) cb(v, id);
  }, [valuesRef]);

  const handleUsaStateFilterOptions = sortUsaStates;

  const handleUsaStateSelection = useCallback((s = null) => {
    if (!s || s !== usaState) {
      setSelectedLocationId(null);
      setFieldValue('locationId', null);
      setFieldValue('school', null);
      resetRosterValues();
    }
    setUsaState(s);
  }, [usaState, setUsaState]);

  const handleSchoolSelection = useCallback((val = null) => {
    if (!val) {
      setSelectedLocationId(null);
      resetRosterValues();
      return;
    }
    setFieldValue('school', val);
  }, []);

  const handleIsAutocompleteFieldOpen = useCallback((id, isDropdownOpenCb) => {
    const val = valuesRef.current[id];
    if (isDropdownOpenCb) {
      isDropdownOpenCb(val);
    }
    return;
  }, [valuesRef]);

  return [
    {
      usaState,
      usaStateOptions: USA_STATES,
      locationId: selectedLocationId,
      locations,
      rosterIds,
      rostersLoading,
      rosterFields,
      hasUnsetRosters,
      terms,
    },
    {
      onUsaStateChange: handleUsaStateSelection,
      onUsaStateFilterOptions: handleUsaStateFilterOptions,
      onSchoolSelection: handleSchoolSelection,
      onStateLocationsCallback: setLocations,
      //   onRosterChange: handleRosterSelection,
      onTextFieldChange: handleTextFieldChange,
      getIsAutocompleteFieldOpen: handleIsAutocompleteFieldOpen
    }
  ];
};

export default useRosterSelection;
