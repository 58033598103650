import { useMemo } from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router';
import useViewport from '../../hooks/useViewport';
import { SUBSCRIPTIONS_SETUP } from '../../routes';
import { constructQueryString } from '../../utils';
import useSubscriptions from '../../hooks/useSubscriptions';
import { PageContainer, SubscriptionConfig } from '../../components';

const Container = styled.div`
  width: 100%;
  max-width: 100hw;
  margin-bottom: 105rem;
`;

const MobileContainer = styled(Container)`
  margin-bottom: 145rem;
`;

const SubscriptionSettings = () => {
  const { isMobile } = useViewport();
  const history = useHistory();
  const { userIds, users, tags, onUpsertUserSubscription, loading, isSaving } =
    useSubscriptions();

  const handleSubscribe = (userId) => {
    const qs = constructQueryString({ userId });
    const route = `${SUBSCRIPTIONS_SETUP}${qs}`;
    history.push(route);
  };

  const renderContent = () => {
    return (
      <SubscriptionConfig
        userIds={userIds}
        users={users}
        tags={tags}
        onSubmit={onUpsertUserSubscription}
        showUserHeader={false}
        isSaving={isSaving}
        onSubscribe={handleSubscribe}
        showStatus={true}
      />
    );
  };

  const wrapperEl = useMemo(() => (isMobile ? MobileContainer : Container), []);

  return (
    <PageContainer
      pageTitle="Subscription Settings"
      showPageTitle={false}
      loading={loading}
      wrapper={loading ? null : wrapperEl}
    >
      {renderContent()}
    </PageContainer>
  );
};

export default SubscriptionSettings;
