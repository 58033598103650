import { useState, useMemo } from 'react';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import useMe from '../../hooks/useMe';
import useAccount from '../../hooks/useAccount';
import useOrders from '../../hooks/useOrders';
import { client as httpClient } from '../../http';
import { TICKET_FLAGS, TICKET_FLAGS_LABELS } from '../../utils/Consts';
import {
  ModalAdvanced,
  Form,
  TextArea,
  Spinner,
  IconButton,
  ToggleButton,
  Heading,
} from '../../common';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const Wrapper = styled.div`
  justify-content: flex-start;
  align-items: stretch;
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px !important;
`;

const TextAreaStyled = styled(TextArea)`
  margin-left: 1rem;
  width: 100%;
  padding: 0.5rem !important;
  border-color: var(--gray);
  border-radius: 4px;
  &:focus {
    border: 1px solid var(--darkGray) !important;
  }
`;

const [SELECT_ISSUE, OTHER_ISSUE] = ['selectIssue', 'otherIssue'];

const HelpSchema = Yup.object().shape({
  otherIssueInput: Yup.string().required('Please enter a message'),
});

const HelpForm = ({ orderId, onClose }) => {
  const { me } = useMe();
  const { accountId, aUsers } = useAccount();
  const { order, loading: orderLoading } = useOrders({
    orderId,
    shouldQueryOrders: !!orderId,
  });

  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(SELECT_ISSUE);
  const [selectedIssues, setSelectedIssues] = useState([]);

  const getUserLink = (userId) => {
    return `${process?.env?.AUX_BACKEND_API_URI}/admin/users/${userId}`;
  };

  const getOrderLink = (orderId) => {
    return `${process?.env?.AUX_BACKEND_API_URI}/admin/orders/${orderId}`;
  };

  const getAccountLink = (aId) => {
    return `${process?.env?.AUX_BACKEND_API_URI}/admin/accounts/${aId}`;
  };

  const showBackButton = useMemo(() => {
    let bool = step !== SELECT_ISSUE;
    // if (step === SELECT_ISSUE && orders.length < 2) {
    //   bool = false;
    // }
    return bool;
  }, [step, selectedIssues]);

  const options = useMemo(() => {
    const o = [
      {
        flag: TICKET_FLAGS.CANCEL_REQUEST,
      },
      {
        flag: TICKET_FLAGS.MISSING_ITEM,
      },
      {
        flag: TICKET_FLAGS.POOR_QUALITY,
      },
      {
        flag: TICKET_FLAGS.WRONG_ITEM,
      },
      {
        flag: TICKET_FLAGS.MENU_CHANGE,
      },
      {
        flag: TICKET_FLAGS.FINANCE_INQUIRY,
      },
      {
        flag: TICKET_FLAGS.OTHER_ISSUE,
      },
    ];
    return o;
  }, []);

  const handleSelectIssue = (issue) => {
    setSelectedIssues(issue ? [issue] : []); // Support only 1 selection
  };

  const handleSubmitHelpRequest = async (vals) => {
    setLoading(true);
    const flag = selectedIssues.length > 0 ? selectedIssues[0] : '';
    let usaState = null;
    let city = null;
    let organization = null;
    let location = null;
    // TODO Temp adding order details client-side; refactor to move to server
    const orderDetails = [];
    const orderItems = [];

    orderDetails.push(['User', me?.fullName]);
    orderDetails.push(['Email Address', me?.emailAddress]);
    orderDetails.push(['Phone Number', me?.phoneNumber]);
    orderDetails.push(['Account ID', accountId]);
    orderDetails.push(['Account', getAccountLink(accountId)]);

    if (order) {
      order.products.forEach(
        ({
          name,
          deliveryDate,
          quantity,
          subtotalCents: price,
          user,
          menu,
        }) => {
          const oName = menu?.location?.organization?.name || '';
          const lName = menu?.location?.name || '';
          const locName =
            oName === lName
              ? oName
              : oName && lName
              ? `${oName}: ${lName}`
              : lName || oName;
          const { id: userId } = user;
          const u = aUsers.find((au) => au.userId === userId);
          const preferredName = user?.preferredName ?? u?.preferredName ?? '';
          const firstName = user?.firstName ?? u?.firstName ?? '';
          const lastName = u?.lastName ?? '';
          const item = `${
            preferredName || firstName
          } ${lastName}: ${quantity}x ${name} @${price} ea \n(${deliveryDate} — ${locName})\n${getUserLink(
            userId,
          )}`;
          orderItems.push(item);

          if (!usaState || !organization || !location) {
            usaState = menu?.location?.address?.stateProvince;
            city = menu?.location?.address?.city;
            organization = menu?.location?.organization?.name;
            location = menu?.location?.name;
          }
        },
      );

      if (organization) orderDetails.push(['School', organization]);
      if (location) orderDetails.push(['Location', location]);
      if (usaState) orderDetails.push(['State', usaState]);
      if (city) orderDetails.push(['City', city]);

      orderDetails.push(['', getOrderLink(orderId)]);
      orderDetails.push(['Order Status', order?.status]);
      orderDetails.push(['Order Items', '']);
      orderDetails.push(['', orderItems.join('\n')]);
    } else {
      // no order
      // Try to find location info from account users
      aUsers.some((au) => {
        if (au.isArchived) return false;
        if (!usaState) {
          usaState =
            au?.location?.address?.stateProvince ??
            au?.location?.organization?.address?.stateProvince;
          city =
            au?.location?.address?.city ??
            au?.location?.organization?.address?.city;
          organization = au?.location?.organization?.name;
          location = au?.location?.name;
          return true;
        }
        return false;
      });
      if (usaState) orderDetails.push(['State', usaState]);
      if (city) orderDetails.push(['City', city]);
    }

    const mcAppName = process.env.APP_NAME
      ? `${process.env.APP_NAME.toUpperCase().replace(' ', '_')}_WEBAPP`
      : '';
    // TODO MC is temp solution to programatically route tickets for CS (might use email headers in future)
    const machineCode = `APP_NAME:${mcAppName} STATE:${
      usaState || ''
    } ORGANIZATION:${organization?.id || ''} LOCATION:${location?.id || ''}`;

    const message = `${process.env.APP_NAME} | Report an issue: ${flag} (${
      TICKET_FLAGS_LABELS[flag]
    })\n\n${vals?.otherIssueInput}\n\n================${orderDetails
      .map(([key, val]) => `\n${key}${key ? ': ' : ''}${val}`)
      .join('\n')}\n\n\n${machineCode}`;

    // TODO ======

    const payload = {
      orderId,
      ...vals,
      message, // TODO
    };
    const res = await httpClient.post({
      url: '/tickets',
      body: payload,
    });
    setLoading(false);
    if (onClose) onClose();
    return res;
  };

  const formik = useFormik({
    initialValues: {
      orderId,
      otherIssueInput: '',
      attachment: null,
    },
    validationSchema: HelpSchema,
    onSubmit: handleSubmitHelpRequest,
  });

  const { values, handleChange } = formik;

  const renderHeading = (title) => {
    return (
      <Heading variant="h6" sx={{ marginBottom: '1rem' }}>
        {title}
      </Heading>
    );
  };

  const renderOtherIssueInputField = () => {
    return (
      <>
        {renderHeading('Additional details:')}
        <Form formik={formik}>
          <FieldWrapper>
            <Form.ControlLabel
              name="otherIssueInput"
              control={<TextAreaStyled minRows={1} autoFocus />}
              onChange={handleChange}
              sx={{ display: 'flex', flex: '0 0 100%', width: '100%' }}
            />
          </FieldWrapper>
        </Form>
      </>
    );
  };

  const renderBackButton = () => {
    let prevStep = null;
    switch (true) {
      case step === OTHER_ISSUE:
        prevStep = SELECT_ISSUE;
    }
    if (!prevStep) return null;
    return (
      <IconButton
        onClick={() => {
          setStep(prevStep);
        }}
      >
        <ArrowBackIosIcon />
      </IconButton>
    );
  };

  const renderIssueOption = ({ flag }) => {
    const hasFlag = selectedIssues.some((issue) => issue === flag);
    const buttonSx = { marginBottom: '0.75rem' };
    return (
      <ToggleButton
        key={flag}
        onClick={() => handleSelectIssue(flag)}
        variant="outlined"
        sx={buttonSx}
        loading={loading}
        isSelected={hasFlag}
        fullWidth
      >
        {TICKET_FLAGS_LABELS[flag]}
      </ToggleButton>
    );
  };

  const renderIssueOptionList = () => {
    return (
      <>
        {renderHeading('Select issue:')}
        {options.map(renderIssueOption)}
      </>
    );
  };

  const renderStep = () => {
    let comp = null;
    switch (step) {
      case SELECT_ISSUE:
        comp = renderIssueOptionList();
        break;
      case OTHER_ISSUE:
        comp = renderOtherIssueInputField();
        break;
      default:
        break;
    }
    return (
      <>
        {showBackButton && renderBackButton()}
        {comp}
      </>
    );
  };

  const actions = useMemo(() => {
    const a = [];
    switch (step) {
      case SELECT_ISSUE:
        a.push({
          label: 'Next',
          onClick: () => setStep(OTHER_ISSUE),
          disabled: selectedIssues.length === 0,
          loading: loading,
        });
        break;
      case OTHER_ISSUE:
        a.push({
          label: 'Submit Help Request',
          onClick: () => {
            const isValid = HelpSchema.isValidSync(values);
            if (isValid) handleSubmitHelpRequest(values);
          },
          disabled: selectedIssues.length === 0 || !values.otherIssueInput,
          loading,
        });
        break;
      default:
        break;
    }
    return a;
  }, [step, selectedIssues, values, loading]);

  const renderContent = () => {
    if (orderLoading) return <Spinner />;
    return renderStep();
  };

  return (
    <ModalAdvanced
      title="Request Help"
      wrapper={Wrapper}
      onClose={onClose}
      allowOutsideClickToClose={false}
      actions={actions}
    >
      {renderContent()}
    </ModalAdvanced>
  );
};

HelpForm.defaultProps = {
  orderId: null,
};

export default HelpForm;
