import { useQuery } from '@apollo/client';
import { GET_ME } from '../graphql/queries';

const useMe = () => {
  const { data: meData = {}, loading, error } = useQuery(GET_ME);
  const { me = null } = meData;
  return { me, loading, error };
};

export default useMe;
