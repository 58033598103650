export const COLORS = {
  brand: '#FFFFFF',
  primary: '#FFFFFF',
  black: '#000000',
  white: '#FFFFFF',
  charcoal: '#323232',
  darkGray: '#9E9E9E',
  altGray: '#EBEBEB',
  gray: '#C4C4C4',
  lightGray: '#F7F7F7',
  yellow: '#FFD047',
  red: '#FF4848',
  salmon: '#FF7A7A',
  green: '#95BF9A',
  orange: '#FF7A41',
  purple: '#7B61FF',
  blue: '#1564FE',
};

export const BrandColor = COLORS.brand;
