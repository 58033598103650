import { Fragment, useMemo, useCallback } from 'react';
import styled from '@emotion/styled';
import { debounce } from 'lodash';
import { useQuery } from '@apollo/client';
import { GET_ME } from '../../graphql/queries';
import useQueryParams from '../../hooks/useQueryParams';
import useAccount from '../../hooks/useAccount';
import useViewport from '../../hooks/useViewport';
import { formatDateTime } from '../../utils';
import {
  ORDER_PRODUCTS_STATUSES,
  ORDER_PRODUCTS_STATUS_LABELS,
} from '../../utils/Consts';
import {
  Heading,
  Currency,
  Box,
  Form,
  TextField,
  Menu,
  Select,
  EmptyState,
} from '../../common';
import LocalDiningIcon from '@mui/icons-material/LocalDining';

const { Control: FormControl } = Form;
const { Item: MenuItem } = Menu;

const Wrapper = styled.span`
  display: flex;
  flex: 1;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
`;

const WrapperSpaced = styled(Wrapper)`
  justify-content: space-between;
`;

const QuantityWrapper = styled.span`
  margin-right: 3rem;
`;

const SectionWrapper = styled.div`
  padding: 0.5rem 0;
`;

const UserWrapper = styled.div`
  margin: 1rem 0;
`;

const ItemRow = styled.div`
  margin: 1rem 0;
`;

const BundleItemRow = styled(Box)``;

const Name = styled.span`
  font-weight: 700;
`;

const Status = styled.span`
  font-size: 0.8rem;
  color: var(--darkGray);
  margin-left: 0.5rem;
`;

const DeliveryDate = styled(Heading)`
  font-size: 1.3rem;
  margin-bottom: 2rem;
  margin-top: 0;
`;

const OrderItems = ({
  products,
  onEdit,
  loading,
  showPrice = true,
  showBundleItems = true,
  showBundleItemsQuantities = true,
}) => {
  const { isMobile } = useViewport();
  const { showBundlePrices = false } = useQueryParams();
  const { settings } = useAccount();
  const { data: meData = {} } = useQuery(GET_ME);
  const { me } = meData;

  const allowBulkOrdering = useMemo(
    () => !!settings?.allowBulkOrdering,
    [settings],
  );
  const quantityArr = useMemo(() => [0, 1, 2, 3, 4, 5, 6, 7, 8, 9], []);

  const handleQuantityChange = (quantity, { recId }, prevQuantity) => {
    if (quantity === prevQuantity) return;
    onEdit(recId, quantity);
  };

  const handleQuantityChangeDb = useCallback(
    debounce(handleQuantityChange, 1000),
    [handleQuantityChange],
  );

  const {
    menuKeys,
    sectionKeys,
    userKeys,
    users,
    items,
    bundleItems,
    prices,
    menus,
  } = useMemo(() => {
    const sectionKeys = [];
    const menuKeys = {};
    const userKeys = {};
    const menus = {};
    const users = {};
    const items = {};
    const bundleItems = {};
    const prices = {};

    products.forEach((p, idx) => {
      const {
        deliveryDate,
        user,
        orderProductId,
        recId,
        subtotalCents: price,
      } = p;
      const { id: userId } = user;
      const hasParent = !!orderProductId && orderProductId !== recId;
      const menu = p.product?.menu;
      const menuId = menu?.id;
      const menuName = menu?.name;

      if (!users[userId]) {
        users[userId] = user;
      }
      if (!sectionKeys.includes(deliveryDate)) {
        sectionKeys.push(deliveryDate);
      }
      if (!menuKeys[deliveryDate]) {
        menuKeys[deliveryDate] = {};
      }
      if (!menuKeys[deliveryDate][userId]) {
        menuKeys[deliveryDate][userId] = [];
      }
      if (!userKeys[deliveryDate]) {
        userKeys[deliveryDate] = [];
      }
      if (!items[deliveryDate]) {
        items[deliveryDate] = {};
      }
      if (!items[deliveryDate][userId]) {
        items[deliveryDate][userId] = [];
      }
      if (!userKeys[deliveryDate].includes(userId)) {
        userKeys[deliveryDate].push(userId);
      }
      if (!menuKeys[deliveryDate][userId].includes(menuId)) {
        menuKeys[deliveryDate][userId].push(menuId);
      }
      if (!hasParent) {
        items[deliveryDate][userId].push(idx);
        if (!Number.isInteger(prices[recId])) {
          prices[recId] = price;
        } else {
          prices[recId] += price;
        }
      } else {
        if (!bundleItems[orderProductId]) {
          bundleItems[orderProductId] = [];
        }
        bundleItems[orderProductId].push(idx);
        let progenitorId = orderProductId;
        if (hasParent) {
          // Find top-level ancestor
          const parent = products.find(({ recId }) => recId === orderProductId);
          if (parent?.orderProductId) {
            const ancestor = products.find(
              ({ recId }) => recId === parent.orderProductId,
            );
            if (ancestor) progenitorId = ancestor.orderProductId;
          }
        }
        if (!Number.isInteger(prices[progenitorId])) {
          prices[progenitorId] = price;
        } else {
          prices[progenitorId] += price;
        }
      }
      if (menuId && !menus[menuId]) {
        menus[menuId] = { id: menuId, name: menuName };
      }
    });
    return {
      menuKeys,
      sectionKeys,
      userKeys,
      users,
      items,
      bundleItems,
      prices,
      menus,
    };
  }, [products]);

  const renderBundlePrice = (price, currencyCode) => {
    return (
      <Currency
        amount={price}
        currencyCode={currencyCode}
        sx={{ marginLeft: '1rem', backgroundColor: 'var(--yellow)' }}
      />
    );
  };

  const renderBulkQuantity = ({ quantity, ...params }) => {
    const { recId, status } = params;
    const key = recId;
    const values = { [key]: quantity };
    return (
      <Fragment key={key}>
        <FormControl
          key={key}
          variant="standard"
          sx={{
            margin: '0.5rem 0',
            display: 'inline-block',
            '& .MuiInput-root:before': {
              borderBottom: 'none',
            },
          }}
          disabled={loading || status === ORDER_PRODUCTS_STATUSES.CANCELLED}
        >
          <TextField
            id={key}
            name={key}
            // label={label}
            type="number"
            inputProps={{ min: 0 }}
            onChange={(e) =>
              handleQuantityChangeDb(Number(e?.target?.value), params, quantity)
            }
            defaultValue={values[key]}
            // placeholder={placeholder}
            // error={touched[id] && !!(errors[id])}
            // helperText={touched[id] && errors[id]}
            sx={{
              width: '5rem',
              maxWidth: '5rem',
              marginRight: isMobile ? '6rem' : '4.5rem',
              marginLeft: '1rem',
            }}
          />
        </FormControl>
      </Fragment>
    );
  };

  const renderQuantityEdit = ({ quantity, ...params }) => {
    if (allowBulkOrdering || !quantityArr.includes(quantity)) {
      return renderBulkQuantity({ quantity, ...params });
    }
    const { recId, status } = params;
    const key = recId;
    return (
      <FormControl
        key={key}
        variant="standard"
        sx={{
          margin: '0.5rem 0',
          display: 'inline-block',
          '& .MuiInput-root:before': {
            borderBottom: 'none',
          },
        }}
        disabled={loading || status === ORDER_PRODUCTS_STATUSES.CANCELLED}
      >
        <Select
          id={key}
          value={quantity}
          onChange={(e) =>
            handleQuantityChange(e?.target?.value, params, quantity)
          }
          label=""
          sx={{
            backgroundColor: 'var(--lightGray)',
            color: 'var(--black)',
            padding: '0 0.5rem',
            margin: '0 1rem 0 0',
            maxWidth: '2.5rem',
            border: 'none',
            borderRadius: '5px',
            fontSize: '0.8rem',
          }}
        >
          {quantityArr.map((q) => {
            return (
              <MenuItem key={q} value={q}>
                {q === 0 && quantity !== 0 ? '0 (Cancel)' : q}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  const renderQuantity = (quantity, params) => {
    return onEdit ? (
      renderQuantityEdit({ quantity, ...params })
    ) : (
      <QuantityWrapper>{quantity}</QuantityWrapper>
    );
  };

  const renderBundleItem = (parentId, idx, isSubItem) => {
    const {
      quantity,
      id,
      recId,
      name,
      finalCents: price,
      finalCurrency: currencyCode,
    } = products[idx];
    const itemLabel = showBundleItemsQuantities
      ? `${quantity} × ${name}`
      : name;
    const sx = isSubItem
      ? {
          margin: '0.5rem 0 0 1.25rem',
          padding: 0,
        }
      : {
          borderLeft: '2px solid var(--gray)',
          marginLeft: '3.5rem',
          padding: '0.25rem 0 0.25rem 1rem',
        };
    return (
      <Fragment key={`${id}-${parentId}-${idx}`}>
        <BundleItemRow sx={sx}>
          {itemLabel}
          {showBundlePrices && renderBundlePrice(price, currencyCode)}
          {bundleItems[recId]?.map((idx) => renderBundleItem(recId, idx, true))}
        </BundleItemRow>
      </Fragment>
    );
  };

  const renderItemRow = ({
    quantity,
    name,
    status,
    statusLabel,
    amount,
    price,
    currencyCode,
    recId,
  }) => {
    return (
      <>
        <ItemRow>
          <WrapperSpaced>
            <Wrapper>
              {renderQuantity(quantity, { recId, status })}
              {name}
              {!statusLabel ? null : <Status>{statusLabel}</Status>}
              {showBundlePrices && renderBundlePrice(price, currencyCode)}
            </Wrapper>
            {showPrice && (
              <Currency
                sx={{ marginLeft: '1rem' }}
                amount={amount}
                currencyCode={currencyCode}
              />
            )}
          </WrapperSpaced>
        </ItemRow>
        {showBundleItems && (
          <ItemRow>
            {bundleItems[recId]?.map((idx) => renderBundleItem(recId, idx))}
          </ItemRow>
        )}
      </>
    );
  };

  const renderItem = (productIdx) => {
    const {
      id,
      name,
      quantity,
      subtotalCents: price,
      subtotalCurrency: currencyCode,
      status,
      recId,
    } = products[productIdx];
    let adjustment = null;
    let statusLabel = null;
    const amount = prices[recId] ?? price;
    switch (status?.toUpperCase()) {
      case ORDER_PRODUCTS_STATUSES.CANCELLED:
        statusLabel = ORDER_PRODUCTS_STATUS_LABELS.CANCELLED;
        break;
      default:
        break;
    }
    return (
      <Fragment key={`${id}-${productIdx}`}>
        {renderItemRow({
          recId,
          quantity,
          name,
          status: status?.toUpperCase(),
          statusLabel,
          amount,
          currencyCode,
          price,
        })}
        {adjustment &&
          renderItemRow({
            quantity,
            name: adjustment,
            status: status?.toUpperCase(),
            statusLabel,
            amount: -amount,
            currencyCode,
            price,
          })}
      </Fragment>
    );
  };

  const renderUser = (userKey, sectionKey, idx) => {
    const u = users[userKey];
    const displayName = `${u?.preferredName || u?.firstName} ${
      userKey === me?.id ? ' (me)' : ''
    }`;
    const productItems = items[sectionKey][userKey];
    const productMenus = menuKeys[sectionKey][userKey].sort((a, b) =>
      menus[a].name.localeCompare(menus[b].name),
    );
    const hasMultipleMenus = productMenus.length > 1;
    let menuItems = null;
    if (!hasMultipleMenus) {
      menuItems = (
        <>
          <Name>{displayName}</Name>
          {productItems.map(renderItem)}
        </>
      );
    } else {
      menuItems = productMenus.map((menuId) => {
        const menu = menus[menuId];
        const menuItems = productItems.filter((productIdx) => {
          const {
            product: {
              menu: { id },
            },
          } = products[productIdx];
          return id === menuId;
        });
        return (
          <Fragment key={menuId}>
            <Name>
              {displayName} ({menu.name})
            </Name>
            {menuItems.map(renderItem)}
          </Fragment>
        );
      });
    }
    return <UserWrapper key={`${userKey}-${idx}`}>{menuItems}</UserWrapper>;
  };

  const renderSection = (sectionKey) => {
    return (
      <SectionWrapper key={sectionKey}>
        <DeliveryDate variant="h6">
          {formatDateTime({ dateTime: sectionKey, format: 'ddd, MMM D YYYY' })}
        </DeliveryDate>
        {userKeys[sectionKey].map((userKey, idx) =>
          renderUser(userKey, sectionKey, idx),
        )}
      </SectionWrapper>
    );
  };

  const renderContent = () => sectionKeys.map(renderSection);

  const renderEmptyState = () => {
    return (
      <EmptyState icon={LocalDiningIcon} message="No items in this order" />
    );
  };

  return sectionKeys.length === 0 ? renderEmptyState() : renderContent();
};

export default OrderItems;
