{
    "local": {
      "apiKey": "AIzaSyCoHiZUSHPMOxKJKYpqa3smFUrOmlQ_zSI",
      "authDomain": "ordo-schools-dev.firebaseapp.com",
      "projectId": "ordo-schools-dev",
      "storageBucket": "ordo-schools-dev.appspot.com",
      "messagingSenderId": "851047444585",
      "appId": "1:724776563790:web:5256e3153b90aa65a13f1f",
      "measurementId": "G-G3XHCTF7ER",
      "databaseURL": "http://localhost:9000?ns=ordo-schools-dev"
    },
    "ordo-schools-dev": {
      "apiKey": "AIzaSyCoHiZUSHPMOxKJKYpqa3smFUrOmlQ_zSI",
      "authDomain": "ordo-schools-dev.firebaseapp.com",
      "projectId": "ordo-schools-dev",
      "storageBucket": "ordo-schools-dev.appspot.com",
      "messagingSenderId": "851047444585",
      "appId": "1:724776563790:web:5256e3153b90aa65a13f1f",
      "measurementId": "G-G3XHCTF7ER"
    },
    "ordo-schools": {
      "apiKey": "AIzaSyDxOO3g4JR1tKSMUbc1cyddNYoqmXKImLM",
      "authDomain": "ordo-schools.firebaseapp.com",
      "projectId": "ordo-schools",
      "storageBucket": "ordo-schools.appspot.com",
      "messagingSenderId": "887513828186",
      "appId": "1:887513828186:web:b98a276091d1c459310163",
      "measurementId": "G-XXE8SN0D9T"
    },
    "ordo-schools-demo": {
      "apiKey": "AIzaSyBrok6so88v7eFkTcW60xAaSeGAoNUxc6I",
      "authDomain": "ordo-schools-demo.firebaseapp.com",
      "projectId": "ordo-schools-demo",
      "storageBucket": "ordo-schools-demo.appspot.com",
      "messagingSenderId": "630205632939",
      "appId": "1:630205632939:web:5095db056b844139ce02ee",
      "measurementId": "G-CK14F3EFBZ"
    },
    "ordo-staging": {
      "apiKey": "AIzaSyDLy1GTaNvYCqXfzK_JRHUmH8u7FreUoSM",
      "authDomain": "ordo-staging.firebaseapp.com",
      "projectId": "ordo-staging",
      "storageBucket": "ordo-staging.appspot.com",
      "messagingSenderId": "600190062756",
      "appId": "1:600190062756:web:a76de436c7ef5ebc0c9631",
      "measurementId": "G-54EKRGTP5X"
    }
  }
