import { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { ModalAdvanced, Heading, Progress, Box } from '../../common';
import useOnlineStatus from '../../hooks/useOnlineStatus';
import SignalWifiStatusbarConnectedNoInternet4Icon from '@mui/icons-material/SignalWifiStatusbarConnectedNoInternet4';

const Container = styled.div`
    position: fixed;
    bottom: 0;
    left:0;
    right: 0;
    padding: 1rem 0 0 0;
    height: 4.5rem;
    color: var(--white);
    background-color: var(--red);
    opacity: 0.9;
    backdrop-filter: blur(15px);
    z-index: 9009;
`;

const Wrapper = styled.div`
  display: flex;
  flex: 1 auto;
  width: 100%;
  align-items: flex-end;
  justify-content: center;
`;

const ModalWrapper = styled.div`
  display: flex;
  flex: 1 auto;
  flex-direction: column;
  width: 100%;
  align-items:center;
  justify-content: center;
`;

const OfflineNotice = ({ showAsModal, forceOpen }) => {
  const isOnlineStatus = useOnlineStatus();
  const [isOnline, setIsOnline] = useState(isOnlineStatus);

  useEffect(() => {
    setIsOnline(isOnlineStatus);
  }, [isOnlineStatus]);

  const handleCloseOfflineNotice = () => {
    setIsOnline(true);
  };

  if (!showAsModal) {
    if (isOnline) return null;
    return (
      <Container>
        <Wrapper>
          <SignalWifiStatusbarConnectedNoInternet4Icon sx={{ fontSize: '2rem', marginRight: '1rem' }} />
          <Heading as="h6" sx={{ fontSize: '1.2rem' }}>You are offline. Please check your internet connection.</Heading>
        </Wrapper>
        <Box sx={{ width: '100%', marginTop: '1rem' }}>
          <Progress sx={{ backgroundColor: 'var(--white)' }} />
        </Box>
      </Container>
    );
  }

  return (
    <ModalAdvanced
      open={!isOnline}
      title="Network Problem Detected"
      onClose={() => handleCloseOfflineNotice()}
      allowOutsideClickToClose={false}
      forceOpen={forceOpen}
    >
      <ModalWrapper>
        <SignalWifiStatusbarConnectedNoInternet4Icon sx={{ fontSize: '6rem', marginBottom: '1rem' }} />
        <Heading as="h2" sx={{ fontSize: '2rem' }}>You are offline.</Heading>
        <p>Please check your internet connection.</p>
        <Box sx={{ width: '90%', marginTop: '1rem' }}>
          <Progress />
        </Box>
      </ModalWrapper>
    </ModalAdvanced>
  );
};

OfflineNotice.defaultProps = {
  showAsModal: true,
  forceOpen: true,
};

export default OfflineNotice;
