import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { SUBSCRIPTIONS_SETUP, SUBSCRIPTIONS } from '../../routes';
import useSubscriptions from '../../hooks/useSubscriptions';
import { constructQueryString } from '../../utils';
import { Heading } from '../../common';
import { PageContainer, SubscriptionUsersList } from '../../components';

const Subscribe = () => {
  const history = useHistory();
  const { users, loading } = useSubscriptions();

  useEffect(() => {
    if (!loading && users.length > 0 && users.every((u) => u.subscription)) {
      history.push(SUBSCRIPTIONS);
    }
  }, [loading, users]);

  const handleSubscribe = (userId) => {
    const qs = constructQueryString({ userId });
    const route = `${SUBSCRIPTIONS_SETUP}${qs}`;
    history.push(route);
  };

  const renderContent = () => {
    return (
      <>
        <Heading variant="h4">Setup subscriptions</Heading>
        <SubscriptionUsersList
          users={users}
          hideSubscribedUsers={true}
          onSubscribe={handleSubscribe}
        />
      </>
    );
  };

  return (
    <PageContainer
      pageTitle="Subscribe"
      showPageTitle={false}
      loading={loading}
    >
      {renderContent()}
    </PageContainer>
  );
};

export default Subscribe;
