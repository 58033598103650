import styled from '@emotion/styled';
import { styleConstructor } from '../../styles/Utils';
import { formatDateTime } from '../../utils';

const DateTimeWrapper = styled.time`
  ${({ sx }) => styleConstructor(sx)}
`;

const DateTime = ({ dateTime, timeZone, format = 'llll', sx = null }) => {
  const dt = formatDateTime({ dateTime, timeZone, format });
  return (
    <DateTimeWrapper dateTime={dateTime} sx={sx}>
      {dt}
    </DateTimeWrapper>
  );
};

export default DateTime;
