import Message from '../Message';

const Error = ({
  content, header, type, icon, children, ...rest
}) => {
  return (
    <Message
      severity={type}
      sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}
      {...rest}
    >
      {header && <Message.Title>{header}</Message.Title>}
      {content ?? children}
    </Message>
  );
};

Error.defaultProps = {
  type: 'error', // success | warning | error | info
  icon: 'warning sign',
};

export default Error;
