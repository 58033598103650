import { useState, useMemo, useEffect } from 'react';
import { createPortal } from 'react-dom';
import styled from '@emotion/styled';
// import Sticky from 'react-stickynode';
import { Button, Tabs, Menu, Form, Box, Select } from '../../common';
import { sortMenus } from '../../utils';
import { MENU_TYPE_LABELS, SECTION_ICONS } from '../../utils/Consts';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import TuneIcon from '@mui/icons-material/Tune';
import ListIcon from '@mui/icons-material/List';
// import FilterAltIcon from '@mui/icons-material/FilterAlt';
// import DateRangeIcon from '@mui/icons-material/DateRange';

const { Control: FormControl, InputLabel } = Form;
const { Item: MenuItem } = Menu;

const ContentWrapper = styled.div`
  display: flex;
  width: 100vw;
  align-items: center;
  > * {
    flex-shrink: 0;
  }
  > *:first-child {
    // Optional left content, it will take up the space it needs based on its content
    flex-grow: 0;
  }
  > *:nth-child(2) {
    // Main content, it will grow but has a max-width to restrict its size
    flex-grow: 1;
    flex-basis: 0; // This means it doesn't start with a default size before growing
    max-width: calc(
      100vw - 17em
    ); // Adjust the subtracted value based on the expected size of left/right content
  }
  > *:last-child {
    // Optional right content, it will take up the space it needs based on its content
    flex-grow: 0;
    margin-left: auto;
  }
`;

const ContentWithMenuSelectorWrapper = styled(ContentWrapper)`
  > *:nth-child(2) {
    max-width: calc(
      100vw - 30em
    ); // Adjust the subtracted value based on the expected size of left/right content
  }
`;

const MenuBarContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(15px);
  padding: 0.5rem 0;
  z-index: 10000;
`;

const MenuBarWrapper = styled.div`
  padding: 0 1.5rem;
`;

const MobileMenuBarWrapper = styled.div`
  width: 100vw;
`;

const InlineWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Sticky = styled(Box)`
  position: fixed;
  z-index: 100;
  left: 0;
  right: 0;
  width: 100%;
`;

const MenuBar = ({
  isMobile,
  sectionIds,
  sections,
  activeMenuSectionId,
  cartQty,
  filters,
  menuId,
  menus,
  onSelectMenu,
  onSelectMenuSection,
  onToggleCartView,
  onToggleFiltersView,
  showActionButtons,
  cartLoading,
}) => {
  const [anchorEl, setAnchorEl] = useState({});
  const [positionFromTop, setPositionFromTop] = useState(
    window?.document?.getElementById('main-header')?.getBoundingClientRect()
      ?.bottom,
  );

  const handleOpenMenu = (e, key) => {
    setAnchorEl({ ...anchorEl, [key]: e.currentTarget });
  };

  const handleCloseMenu = (key) => {
    setAnchorEl({ ...anchorEl, [key]: null });
  };

  const handleSelectMenu = (mId) => {
    onSelectMenu(mId);
  };

  const handleShowSectionPopup = (e, key) => {
    setAnchorEl({ ...anchorEl, [key]: e.currentTarget });
  };

  const handleCloseSectionsPopup = (key) => {
    setAnchorEl({ ...anchorEl, [key]: null });
  };

  useEffect(() => {
    const handleResize = () => {
      const h =
        window?.document?.getElementById('main-header')?.getBoundingClientRect()
          ?.bottom || 0;
      if (positionFromTop !== h) setPositionFromTop(h);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [positionFromTop]);

  const showSections = isMobile ? sectionIds?.length > 1 : true;
  const showMenus =
    menus?.filter(({ products }) =>
      products.some(({ datesAvailable }) => datesAvailable.length > 0),
    ).length > 1;

  const sortedMenus = useMemo(() => {
    return sortMenus(menus);
  }, [menus]);

  const cartLabel = useMemo(
    () => (cartQty > 0 ? `Cart • ${cartQty}` : 'Cart'),
    [cartQty],
  );

  const filtersLabel = useMemo(
    () =>
      Array.isArray(filters) && filters.length > 0
        ? `Filters • ${filters.length}`
        : 'Filters',
    [filters.length],
  );

  const buttons = useMemo(
    () => [
      {
        key: 'filters',
        label: filtersLabel,
        icon: <TuneIcon />,
        variant: 'outlined',
        onClick: onToggleFiltersView,
      },
      // {
      //   key: 'cart',
      //   label: cartLabel,
      //   icon: <ShoppingCartIcon />,
      //   variant: 'contained',
      //   onClick: onToggleCartView,
      //   loading: cartLoading,
      // },
    ],
    [
      cartLabel,
      filtersLabel,
      onToggleFiltersView,
      onToggleCartView,
      cartLoading,
    ],
  );

  const renderDropdownMenu = (content, key) => {
    return (
      <Menu
        anchorEl={anchorEl[key]}
        open={!!anchorEl[key]}
        onClose={() => handleCloseMenu(key)}
        onClick={() => null}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            border: '1px solid var(--gray)',
            // filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {content}
      </Menu>
    );
  };

  const renderButton = (key, label, icon, variant, onClick, loading) => {
    const onClickHandler = onClick || ((e) => handleOpenMenu(e, key));
    return (
      <Button
        onClick={onClickHandler}
        variant={variant}
        startIcon={icon}
        loading={loading}
        sx={{ marginLeft: '1rem' }}
      >
        {label}
      </Button>
    );
  };

  const renderButtonWithDropdown = ({
    key,
    label,
    icon,
    variant,
    content,
    onClick,
    loading = false,
  }) => {
    return (
      <span key={key}>
        {renderButton(key, label, icon, variant, onClick, loading)}
        {!!content && renderDropdownMenu(content, key)}
      </span>
    );
  };

  const renderMenuBarSection = (sectionId) => {
    const { id, name, icon } = sections[sectionId];
    const IconEl = icon && SECTION_ICONS[icon] ? SECTION_ICONS[icon] : null;
    return (
      <Tabs.Tab
        key={id}
        value={id}
        label={name}
        icon={
          IconEl ? (
            <IconEl
              sx={{ fontSize: '1rem', alignSelf: 'center', paddingRight: 0 }}
            />
          ) : null
        }
        iconPosition="start"
        sx={isMobile ? { padding: '0 0.5rem', minHeight: '40px' } : null}
      />
    );
  };

  const renderSectionPopup = () => {
    if (sectionIds.length === 1) return null;
    if (!isMobile && sectionIds.length <= 3) return null;
    return (
      <Button
        onClick={(e) => handleShowSectionPopup(e, 'sections')}
        variant="text"
        // size={isMobile ? 'large' : 'small'}
        sx={{
          padding: 0,
          margin: 0,
          minHeight: '2.5rem',
        }}
      >
        <ListIcon />
      </Button>
    );
  };

  const renderSectionsPopupMenuItems = () => {
    return sectionIds.map((sectionId) => {
      const { id, name, icon } = sections[sectionId];
      const IconEl = icon && SECTION_ICONS[icon] ? SECTION_ICONS[icon] : null;
      const isSelected = activeMenuSectionId === id;
      return (
        <MenuItem
          key={id}
          value={id}
          onClick={() => onSelectMenuSection(id)}
          sx={{
            display: 'flex',
            gap: '0 1rem',
            alignItems: 'center',
            borderLeft: isSelected ? '3px solid var(--charcoal)' : undefined,
          }}
        >
          {IconEl ? (
            <IconEl
              sx={{ fontSize: '1rem', alignSelf: 'center', paddingRight: 0 }}
            />
          ) : null}
          {name}
        </MenuItem>
      );
    });
  };

  const renderSectionsPopupMenu = () => {
    if (!showSections) return null;
    const key = 'sections';
    return (
      <Menu
        anchorEl={anchorEl[key]}
        open={!!anchorEl[key]}
        onClose={() => handleCloseSectionsPopup(key)}
        onClick={() => handleCloseSectionsPopup(key)}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'auto',
            border: '1px solid var(--gray)',
            minWidth: '16rem',
            maxWidth: '100vw',
            maxHeight: '80vh',
            filter: 'drop-shadow(0px 1px 6px rgba(0,0,0,0.10))',
            mt: 3,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            ml: showMenus && !isMobile ? '12em' : undefined,
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {renderSectionsPopupMenuItems()}
      </Menu>
    );
  };

  // const renderSectionsDropdown = () => {
  //   console.log(activeMenuSectionId);
  //   return (
  //     <MenuBarWrapper>
  //       <FormControl
  //         label={'Select section'}
  //         placeholder="Select section"
  //         size={isMobile ? undefined : 'small'}
  //         sx={{ width: isMobile ? '100%' : undefined, alignSelf: 'center' }}
  //       >
  //         <Select
  //           id={'section-select'}
  //           value={activeMenuSectionId || ''}
  //           onChange={(e, val) => onSelectMenuSection(val?.props?.value)}
  //           sx={{ width: isMobile ? '100%' : undefined }}
  //         >
  //           {sectionIds.map((sectionId) => {
  //             const { id: value, name, icon } = sections[sectionId];
  //             const IconEl = (icon && SECTION_ICONS[icon]) ? SECTION_ICONS[icon] : null;
  //             return (
  //               <MenuItem key={value} value={value}>
  //                 <IconEl sx={{ marginRight: '0.75rem' }} />{name}
  //               </MenuItem>
  //             );
  //           }
  //           )}
  //         </Select>
  //       </FormControl>
  //     </MenuBarWrapper>
  //   );
  // };

  const renderSectionTabs = () => {
    if (!showSections) return null;
    // if (isMobile) {
    //   return renderSectionsDropdown();
    // }
    return (
      <MenuBarWrapper>
        <InlineWrapper>
          {renderSectionPopup()}
          <Tabs
            value={activeMenuSectionId}
            onChange={(e, value) => onSelectMenuSection(value)}
            variant="scrollable"
            allowScrollButtonsMobile={false}
            scrollButtons
          >
            {sectionIds.map(renderMenuBarSection)}
          </Tabs>
        </InlineWrapper>
      </MenuBarWrapper>
    );
  };

  const renderActions = () => {
    if (!showActionButtons) return null;
    return (
      <MenuBarWrapper>{buttons.map(renderButtonWithDropdown)}</MenuBarWrapper>
    );
  };

  const renderMenusDropdown = () => {
    if (!showMenus) return null;
    return (
      <MenuBarWrapper>
        <FormControl
          label={'Select menu'}
          placeholder="Select menu"
          size={isMobile ? undefined : 'small'}
          sx={{ width: isMobile ? '100%' : undefined, alignSelf: 'center' }}
        >
          <Select
            id={menuId}
            value={menuId || ''}
            onChange={(e, val) => handleSelectMenu(val?.props?.value)}
            sx={{
              width: isMobile ? '100%' : undefined,
              marginBottom: isMobile ? '1rem' : undefined,
            }}
          >
            {sortedMenus.map(({ id: value, name, type }) => {
              const label = name || MENU_TYPE_LABELS[type];
              return (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </MenuBarWrapper>
    );
  };

  if (!showSections && !showMenus) return null;

  const renderMenuBar = () => {
    const ContentWrapperEl = isMobile
      ? MobileMenuBarWrapper
      : showMenus
      ? ContentWithMenuSelectorWrapper
      : ContentWrapper;

    return (
      <>
        <MenuBarContainer id="menu-bar">
          <ContentWrapperEl>
            <div>{renderMenusDropdown()}</div>
            <div>{renderSectionTabs()}</div>
            {!isMobile && <div>{renderActions()}</div>}
          </ContentWrapperEl>
          {renderSectionsPopupMenu()}
        </MenuBarContainer>
      </>
    );
  };

  const headerPortalEl = document.getElementById('header-portal');
  if (!headerPortalEl) {
    console.error('Header portal element not found');
    return <Sticky>{renderMenuBar()}</Sticky>;
  }

  return createPortal(renderMenuBar(), headerPortalEl);
};

MenuBar.defaultProps = {
  showActionButtons: true,
  sectionIds: [],
  menus: [],
};

export default MenuBar;
