import styled from '@emotion/styled';
import Link from '../Link';
import useViewport from '../../hooks/useViewport';

const Wrapper = styled.div`
  margin-top: 1.5rem;
  text-align: center;
  text-wrap: balanced;
`;

const ActionText = styled.span`
  font-weight: 500;
  color: var(--charcoal);
`;

const Break = styled.div`
  margin-top: 0.5rem;
`;

const SecondaryActionText = ({ text, actionLabel, onClick, route }) => {
  const { isMobile } = useViewport();
  return (
    <Wrapper>
      <ActionText>{text}</ActionText>
      {isMobile ? <Break /> : ' '}
      {route && <Link route={route}>{actionLabel}</Link>}
      {onClick && !route && <a onClick={onClick}>{actionLabel}</a>}
    </Wrapper>
  );
};

export default SecondaryActionText;
