import { Fragment } from 'react';
import styled from '@emotion/styled';
import useViewport from '../../hooks/useViewport';
import useGlobalContext from '../../hooks/useGlobalContext';
import { Button, Divider, Heading, List, Tag, EmptyState } from '../../common';
import { USER_ROLES, USER_ROLES_LABELS } from '../../utils/Consts';
import PersonIcon from '@mui/icons-material/Person';

const { ADMIN, GUARDIAN, STUDENT, STAFF } = USER_ROLES;

const UserList = styled.div`
  margin-bottom: 1.5rem;
`;

const AccountUserName = styled.span`
`;

const AccountUserRole = styled.span`
  color: var(--darkGray);
`;

const UserStatusWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`;

const AccountUserList = ({
  users, header, emptyStateMessage, onEdit,
}) => {
  const { isMobile } = useViewport();
  const [{ rosterUpdates }] = useGlobalContext();

  const renderTag = (label, color) => {
    return (
      <div>
        <Tag
          label={label}
          size="small"
          sx={{ background: color, color: 'var(--white)', marginTop: '0.5rem' }}
        />
      </div>
    );
  };

  const renderUserRow = (aUser, idx) => {
    const { user, roles, isMe, isArchived, hasTermRoster, location } = aUser;
    const { id, preferredName, firstName } = user;
    const userDisplayName = preferredName || firstName;
    const showAlert = hasTermRoster === false || !!rosterUpdates?.[user?.id];
    const showDivider = Array.isArray(users) && idx < (users.length - 1);
    let locationName = location?.name;
    if (locationName && locationName !== location?.organization?.name) {
      locationName = `${location?.organization?.name} (${locationName})`;
    }
    let roleLabel = '';
    if (roles.includes(ADMIN)) roleLabel = USER_ROLES_LABELS[ADMIN];
    if (roles.includes(GUARDIAN)) roleLabel = USER_ROLES_LABELS[GUARDIAN];
    if (roles.includes(STAFF)) roleLabel = USER_ROLES_LABELS[STAFF];
    if (roles.includes(STUDENT)) roleLabel = USER_ROLES_LABELS[STUDENT];

    return (
      <Fragment key={id}>
        <List.Item
          // sx={{ [':hover']: { backgroundColor: 'var(--lightGray)' } }}
          disableGutters={isMobile}
        >
          <UserStatusWrapper>
            <div>
              <AccountUserName>
                {`${userDisplayName}${isMe ? ' (me)' : ''}${roleLabel ? ', ' : ''}`}
              </AccountUserName>
              {!!roleLabel && <AccountUserRole>{roleLabel}</AccountUserRole>}
            </div>
            {showAlert && renderTag('Missing info', 'var(--red)')}
            {isArchived && renderTag('Archived', 'var(--darkGray)')}
            {(!showAlert && !isArchived && locationName) && renderTag('Enrolled', 'var(--green)')}
          </UserStatusWrapper>
          <Button
            onClick={() => onEdit(aUser)}
            variant="text"
            sx={showAlert ? { color: 'var(--red)' } : { fontWeight: 300 }}
          >
            Update
          </Button>
        </List.Item>
        {showDivider && (
          <Divider
            sx={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}
            component="li"
          />
        )}
      </Fragment>
    );
  };

  const renderUserList = () => {
    return (
      <List
        sx={{ width: '100%' }}
      >
        {users.map(renderUserRow)}
      </List>
    );
  };

  const renderEmptyState = () => (
    <EmptyState icon={PersonIcon} message={emptyStateMessage} />
  );

  return (
    <UserList>
      <Heading as="h5">
        {header}
      </Heading>
      {(users.length > 0) ? renderUserList() : renderEmptyState()}
    </UserList>
  );
};

AccountUserList.defaultProps = {
  users: [],
  emptyStateMessage: 'There are no users here yet.',
};

export default AccountUserList;
