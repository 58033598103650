import AccountOverview_COMP from './AccountOverview';
import AccountPosSettings_COMP from './AccountPosSettings';
import AccountUserForm_COMP from './AccountUserForm';
import AccountUserList_COMP from './AccountUserList';
import AccountUsersGrid_COMP from './AccountUsersGrid';
import CartView_COMP from './CartView';
import CatalogProduct_COMP from './CatalogProduct';
import CatalogProductFilters_COMP from './CatalogProductFilters';
import CatalogProductGrid_COMP from './CatalogProductGrid';
import FixedHeaderContent_COMP from './FixedHeaderContent';
import HelpForm_COMP from './HelpForm';
import LocationRosterForm_COMP from './LocationRosterForm';
import Logo_COMP from './Logo';
import MenuBar_COMP from './MenuBar';
import MenuFilters_COMP from './MenuFilters';
import MenuItem_COMP from './MenuItem';
import MenuItemGrid_COMP from './MenuItemGrid';
import MenuItemPreview_COMP from './MenuItemPreview';
import Navigation_COMP from './Navigation';
import OfflineNotice_COMP from './OfflineNotice';
import OnboardStepper_COMP from './OnboardStepper';
import OrderFilters_COMP from './OrderFilters';
import OrderItems_COMP from './OrderItems';
import OrderItemsEdit_COMP from './OrderItemsEdit';
import OrderSummary_COMP from './OrderSummary';
import PageContainer_COMP from './PageContainer';
import PauseSubscriptionForm_COMP from './PauseSubscriptionForm';
import PaymentMethods_COMP from './PaymentMethods';
import PaymentMethodsManagement_COMP from './PaymentMethodsManagement';
import RegisterAccountUsers_COMP from './RegisterAccountUsers';
import RegisterAvatar_COMP from './RegisterAvatar';
import ResetPassword_COMP from './ResetPassword';
import SchoolSelect_COMP from './SchoolSelect';
import SchoolSelectRegistration_COMP from './SchoolSelectRegistration';
import StripePaymentMethodForm_COMP from './StripePaymentMethodForm';
import StudentForm_COMP from './StudentForm';
import SubscribeCTA_COMP from './SubscribeCTA';
import SubscriptionAnnouncementModal_COMP from './SubscriptionAnnouncementModal';
import SubscriptionConfig_COMP from './SubscriptionConfig';
import SubscriptionConfigForm_COMP from './SubscriptionConfigForm';
import SubscriptionUserCard_COMP from './SubscriptionUserCard';
import SubscriptionUsersList_COMP from './SubscriptionUsersList';
import TosPrivacyStatement_COMP from './TosPrivacyStatement';
import UpdateNotification_COMP from './UpdateNotification';
import UserOnboard_COMP from './UserOnboard';
import UserProductReactionsCatalog_COMP from './UserProductReactionsCatalog';
import UserProductReactionsSelections_COMP from './UserProductReactionsSelections';
import UserTabs_COMP from './UserTabs';
import Wizard_COMP from './Wizard';

const x = {
  AccountOverview: AccountOverview_COMP,
  AccountPosSettings: AccountPosSettings_COMP,
  AccountUserForm: AccountUserForm_COMP,
  AccountUserList: AccountUserList_COMP,
  AccountUsersGrid: AccountUsersGrid_COMP,
  CartView: CartView_COMP,
  CatalogProduct: CatalogProduct_COMP,
  CatalogProductFilters: CatalogProductFilters_COMP,
  CatalogProductGrid: CatalogProductGrid_COMP,
  FixedHeaderContent: FixedHeaderContent_COMP,
  HelpForm: HelpForm_COMP,
  LocationRosterForm: LocationRosterForm_COMP,
  Logo: Logo_COMP,
  MenuBar: MenuBar_COMP,
  MenuFilters: MenuFilters_COMP,
  MenuItem: MenuItem_COMP,
  MenuItemGrid: MenuItemGrid_COMP,
  MenuItemPreview: MenuItemPreview_COMP,
  Navigation: Navigation_COMP,
  OfflineNotice: OfflineNotice_COMP,
  OnboardStepper: OnboardStepper_COMP,
  OrderFilters: OrderFilters_COMP,
  OrderItems: OrderItems_COMP,
  OrderItemsEdit: OrderItemsEdit_COMP,
  OrderSummary: OrderSummary_COMP,
  PageContainer: PageContainer_COMP,
  PauseSubscriptionForm: PauseSubscriptionForm_COMP,
  PaymentMethods: PaymentMethods_COMP,
  PaymentMethodsManagement: PaymentMethodsManagement_COMP,
  RegisterAccountUsers: RegisterAccountUsers_COMP,
  RegisterAvatar: RegisterAvatar_COMP,
  ResetPassword: ResetPassword_COMP,
  SchoolSelect: SchoolSelect_COMP,
  SchoolSelectRegistration: SchoolSelectRegistration_COMP,
  StripePaymentMethodForm: StripePaymentMethodForm_COMP,
  StudentForm: StudentForm_COMP,
  SubscribeCTA: SubscribeCTA_COMP,
  SubscriptionAnnouncementModal: SubscriptionAnnouncementModal_COMP,
  SubscriptionConfig: SubscriptionConfig_COMP,
  SubscriptionConfigForm: SubscriptionConfigForm_COMP,
  SubscriptionUserCard: SubscriptionUserCard_COMP,
  SubscriptionUsersList: SubscriptionUsersList_COMP,
  TosPrivacyStatement: TosPrivacyStatement_COMP,
  UpdateNotification: UpdateNotification_COMP,
  UserOnboard: UserOnboard_COMP,
  UserProductReactionsCatalog: UserProductReactionsCatalog_COMP,
  UserProductReactionsSelections: UserProductReactionsSelections_COMP,
  UserTabs: UserTabs_COMP,
  Wizard: Wizard_COMP,
};
module.exports = x;
