import { useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { sendResetPasswordEmail } from '../../firebase/auth';
import { Button, Card, TextField, Form, SecondaryActionText, Error } from '../../common';
import { PageContainer } from '../../components';
import { REGISTER, SIGN_IN } from '../../routes';

const ActionsWrapper = styled.div`
  margin-top: 1rem;
  font-size: 0.9rem;
`;

const Wrapper = styled.div`
  width: 500px;
  max-width: 100%;
`;

const FieldWrapper = styled.div`
  margin-bottom: 10px !important;
`;

const Container = styled.div`
  padding: 0;
  margin: 0;
  display: flex;
  /* flex: 1 auto; */
  align-items: center;
  justify-content: center;
  /* background-color: var(--white); */
`;

const ForgotPasswordSchema = Yup.object().shape({
  emailAddress: Yup.string()
    .required('Please enter a valid email address')
    .email('Please enter a valid email address'),
});

const ForgotPassword = () => {
  const [resetEmail, setResetEmail] = useState(null);
  const [error, setError] = useState(null);

  const history = useHistory();

  const handleGoToSignIn = () => {
    history.push(SIGN_IN);
  };

  const handleResetPassword = async (vals) => {
    if (error) setError(null);
    if (resetEmail) setResetEmail(null);
    const { emailAddress } = vals;
    await sendResetPasswordEmail(emailAddress)
      .then(() => setResetEmail(emailAddress))
      .catch((err) => setError(err));
  };

  const formik = useFormik({
    initialValues: {
      emailAddress: '',
    },
    validationSchema: ForgotPasswordSchema,
    onSubmit: handleResetPassword,
  });

  const fields = useMemo(() => [
    {
      id: 'emailAddress',
      label: 'Email Address',
      type: 'email',
      autoFocus: true,
    },
  ], []);

  const { values, errors, touched, dirty, handleChange, handleSubmit, isSubmitting } = formik;

  const renderField = ({ id, type, label, placeholder = null, autoFocus = false }) => {
    return (
      <FieldWrapper key={id}>
        <TextField
          id={id}
          name={id}
          label={label}
          type={type}
          onChange={handleChange}
          value={values[id]}
          placeholder={placeholder}
          autoFocus={autoFocus}
          error={touched[id] && !!(errors[id])}
          helperText={touched[id] && errors[id]}
          fullWidth
        />
      </FieldWrapper>
    );
  };

  const renderForgotPasswordForm = () => (
    <Form formik={formik} onSubmit={handleSubmit}>
      {fields.map(renderField)}
      <Button
        type="submit"
        disabled={isSubmitting || !dirty}
        loading={isSubmitting}
        fullWidth
      >
        Email Reset Password Link
      </Button>
    </Form>
  );

  const renderResetPasswordConfirmation = () => {
    return (
      <>
        <p>Reset link sent to <b>{resetEmail}</b>.</p>
        <p>Please check your email for instructions to update your password.</p>
      </>
    );
  };

  const renderMoreActions = () => {
    return (
      <>
        <ActionsWrapper>
          <Button
            sx={{ marginTop: '0.5rem' }}
            onClick={handleGoToSignIn}
            variant="outlined"
            fullWidth
          >
          Log in
          </Button>
        </ActionsWrapper>
        <SecondaryActionText
          text="Don't have an account?"
          actionLabel='Register.'
          route={REGISTER}
        />
      </>
    );
  };

  const renderError = () => {
    if (!error) return null;
    const { code } = error;
    const errMessage = code === 'auth/user-not-found'
      ? 'Email address not found in our system. Please verify and try again.'
      : 'There was an error sending a reset password link. Please try again.';
    return (<>
      <br />
      <Error header="Reset Password Error" content={errMessage} />
    </>);
  };

  const renderPageContent = () => {
    return (
      <Card>
        <Card.Header sx={{ paddingBottom: 0 }} title="Forgot Password" />
        <Card.Content>
          <Wrapper>
            {!resetEmail && renderForgotPasswordForm()}
            {resetEmail && renderResetPasswordConfirmation()}
            {error && renderError()}
            {renderMoreActions()}
          </Wrapper>
        </Card.Content>
      </Card>
    );
  };

  return (
    <PageContainer
      container={Container}
      loading={false}
      pageTitle='Forgot Password'
      showPageTitle={false}
    >
      {renderPageContent()}
    </PageContainer>
  );
};

export default ForgotPassword;
