import { gql } from '@apollo/client';
import { SUBSCRIPTIONS } from '../../routes';

export const CORE_USER_FIELDS = gql`
  fragment CoreUserFields on users {
    id
    avatarUri
    emailAddress
    phoneNumber
    firstName
    lastName
    middleName
    suffix
    preferredName
    fullName @client
    initials @client
    pronouns
  }
`;

export const CORE_COUPON_FIELDS = gql`
  fragment CoreCouponFields on coupons {
    id
    name
    description
    redemptionCode
    amountOff
    currencyCode
    percentOff
    duration
    appliesTo
    conditions
    startDateTime
    endDateTime
  }
`;

export const CART_ITEM_FIELDS = gql`
  fragment CartItemFields on CartItem {
    id
    quantity
    price {
      cents
      currency {
        isoCode
      }
    }
    product {
      id
      name
      description
    }
  }
`;

export const CORE_ORDER_COUPONS_FIELDS = gql`
  ${CORE_COUPON_FIELDS}
  fragment CoreOrderCouponFields on orders_coupons {
    # id // TODO Need to extract reference from resolver to cache like this
    couponApplication
    coupon {
      ...CoreCouponFields
    }
  }
`;

export const CONDIMENT_FIELDS = gql`
  fragment CondimentDetails on Condiment {
    id
    description
    name
    priceCents
    priceCurrency
    type
  }
`;

export const PRODUCT_CONDIMENT_FIELDS = gql`
  ${CONDIMENT_FIELDS}
  fragment ProductCondimentsDetails on ProductCondiment {
    id
    default
    type
    productId
    variantId
    productSelectionGroup {
      id
      productId
      variantId
      quantity
      required
      default
      selectionGroupId
      type
      selectionGroup {
        id
        icon
        name
      }
    }
    condiment {
      ...CondimentDetails
    }
  }
`;

export const CATALOG_TAG_FIELDS = gql`
  fragment CatalogTagDetails on CatalogTag {
    catalogTags {
      tag {
        id
        icon
        name
        type
        color
      }
    }
  }
`;

export const ASSETS_FIELDS = gql`
  fragment AssetDetails on Asset {
    assets {
      id
      title
      uri
    }
  }
`;

export const BEVERAGE_FIELDS = gql`
  ${ASSETS_FIELDS}
  ${CATALOG_TAG_FIELDS}
  fragment BeverageDetails on Beverage {
    id
    description
    name
    priceCents
    priceCurrency
    type
    ...AssetDetails
    ...CatalogTagDetails
  }
`;

export const SIDE_DISH_FIELDS = gql`
  ${CATALOG_TAG_FIELDS}
  ${PRODUCT_CONDIMENT_FIELDS}
  fragment SideDishDetails on SideDish {
    id
    description
    name
    priceCents
    priceCurrency
    type
    assets {
      id
      title
      uri
    }
    ...CatalogTagDetails
  }
`;

export const MAIN_DISH_FIELDS = gql`
  ${CATALOG_TAG_FIELDS}
  fragment MainDishDetails on MainDish {
    id
    description
    name
    priceCents
    priceCurrency
    type
    assets {
      id
      title
      uri
    }
    ...CatalogTagDetails
  }
`;

export const BUNDLE_FIELDS = gql`
  ${ASSETS_FIELDS}
  ${PRODUCT_CONDIMENT_FIELDS}
  ${MAIN_DISH_FIELDS}
  ${SIDE_DISH_FIELDS}
  ${BEVERAGE_FIELDS}
  fragment BundleDetails on Bundle {
    id
    description
    name
    priceCents
    priceCurrency
    type
    productSelectionGroups {
      id
      productId
      quantity
      required
      selectionGroupId
      selectionGroup {
        id
        icon
        name
      }
      linkedProducts {
        ... on ProductCondiment {
          id
          default
          type
          linkedId
          productId
          selectionGroupId
          condiment {
            id
            description
            name
            priceCents
            priceCurrency
            type
            tags {
              color
              icon
              id
              name
              type
            }
          }
        }
        ... on BundleProduct {
          id
          default
          productId
          type
          productSelectionGroup {
            id
            productId
            quantity
            required
            selectionGroupId
            sortOrder
            type
            selectionGroup {
              id
              icon
              name
            }
          }
          ... on MainDish {
            id
            default
            productId
            type
            productSelectionGroup {
              id
              productId
              quantity
              required
              selectionGroupId
              sortOrder
              type
              selectionGroup {
                id
                icon
                name
              }
            }
          }
          ... on SideDish {
            id
            default
            productId
            type
            productSelectionGroup {
              id
              productId
              quantity
              required
              selectionGroupId
              sortOrder
              type
              selectionGroup {
                id
                icon
                name
              }
            }
          }
          ... on Beverage {
            id
            default
            productId
            type
            productSelectionGroup {
              id
              productId
              quantity
              required
              selectionGroupId
              sortOrder
              type
              selectionGroup {
                id
                icon
                name
              }
            }
          }
          product {
            ... on MainDish {
              ...MainDishDetails
              ...AssetDetails
              productCondiments {
                id
                default
                type
                productId
                linkedId
                productSelectionGroup {
                  id
                  productId
                  quantity
                  required
                  type
                  selectionGroup {
                    id
                    icon
                    name
                  }
                }
                condiment {
                  id
                  description
                  name
                  priceCents
                  priceCurrency
                  type
                }
              }
            }
            ... on SideDish {
              ...SideDishDetails
              ...AssetDetails
              productCondiments {
                id
                default
                type
                productId
                linkedId
                productSelectionGroup {
                  id
                  productId
                  quantity
                  required
                  type
                  selectionGroup {
                    id
                    icon
                    name
                  }
                }
                condiment {
                  id
                  description
                  name
                  priceCents
                  priceCurrency
                  type
                }
              }
            }
            ... on Beverage {
              ...BeverageDetails
              ...AssetDetails
            }
          }
        }
      }
    }
  }
`;

export const SUBSCRIPTION_FIELDS = gql`
  fragment SubscriptionFields on Subscription {
    id
    status
    pauseUntil
    paymentMethodId
    dailyOptions {
      monday {
        isEnabled: enabled
      }
      tuesday {
        isEnabled: enabled
      }
      wednesday {
        isEnabled: enabled
      }
      thursday {
        isEnabled: enabled
      }
      friday {
        isEnabled: enabled
      }
      saturday {
        isEnabled: enabled
      }
      sunday {
        isEnabled: enabled
      }
    }
    subscriptionOptions {
      menuSlots {
        isActive: active
        isEnabled: enabled
        menuType
        slotName
        mealService
        productSlots {
          isEnabled: enabled
          quantity
          slotName
          productType
        }
      }
    }
    subscriptionTags {
      id
      tag {
        id
        name
      }
    }
  }
`;
