import { useHistory } from 'react-router-dom';
import { constructQueryString } from '../../utils';
import { SUBSCRIPTIONS_SETUP, SUBSCRIPTION_SETTINGS } from '../../routes';
import { Heading } from '../../common';
import { PageContainer, SubscriptionUsersList } from '../../components';
import useSubscriptions from '../../hooks/useSubscriptions';

const Subscriptions = () => {
  const history = useHistory();
  const { users, loading, onAutofillSubscriptionCart } = useSubscriptions();

  const handleChangePlan = (userId) => {
    const qs = constructQueryString({ userId });
    const route = `${SUBSCRIPTION_SETTINGS}${qs}`;
    history.push(route);
  };

  const handleSubscribe = (userId) => {
    const qs = constructQueryString({ userId });
    const route = `${SUBSCRIPTIONS_SETUP}${qs}`;
    history.push(route);
  };

  const renderContent = () => {
    return (
      <>
        <Heading variant="h4">Subscription settings</Heading>
        <SubscriptionUsersList
          users={users}
          loading={loading}
          onSubscribe={handleSubscribe}
          onChangePlan={handleChangePlan}
          onAutofillSubscriptionCart={onAutofillSubscriptionCart}
        />
      </>
    );
  };

  return (
    <PageContainer
      pageTitle="Subscriptions"
      loading={loading}
      showPageTitle={false}
    >
      {renderContent()}
    </PageContainer>
  );
};

export default Subscriptions;
