import { useState, useMemo, useRef } from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { DASHBOARD } from '../../routes';
import useAccount from '../../hooks/useAccount';
import { Button, Spinner } from '../../common';
import StudentForm from '../StudentForm';
import LocationRosterForm from '../LocationRosterForm';
import AccountUserList from '../AccountUserList';
import AccountPosSettings from '../AccountPosSettings';
import PaymentMethodsManagement from '../PaymentMethodsManagement';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import EditIcon from '@mui/icons-material/Edit';

const Wrapper = styled.div`
  width: 500px;
  max-width: 100%;
`;

const TextButton = styled(Button)`
  color: var(--link-color) !important;
  font-size: 1rem !important;
`;

const AccountOverview = ({ accountId, hideGoToMenu }) => {
  const history = useHistory();
  const [showStudentForm, setShowStudentForm] = useState(false);
  const [showLocationRosterForm, setShowLocationRosterForm] = useState(false);
  const [showAccountPosSettingsForm, setShowAccountPosSettingsForm] =
    useState(false);
  const [showPaymentMethodsManagement, setShowPaymentMethodsManagement] =
    useState(false);
  const {
    account,
    locationIds,
    self,
    studentUsers,
    staffUsers,
    needsRostersUsers,
    hasTermRosters,
    hasPosLocation,
    loading: accountLoading,
  } = useAccount({ accountId });

  const userData = useRef(null); // Edit user

  const handleGoToMenu = () => {
    history.push(DASHBOARD);
  };

  const handleToggleStudentForm = () => {
    if (userData?.current && showStudentForm) {
      userData.current = null;
    }
    setShowStudentForm(!showStudentForm);
  };

  const handleToggleLocationRosterForm = () => {
    if (userData?.current && showLocationRosterForm) {
      userData.current = null;
    }
    setShowLocationRosterForm(!showLocationRosterForm);
  };

  const handleToggleAccountPosSettingsForm = () => {
    setShowAccountPosSettingsForm((bool) => !bool);
  };

  const handleTogglePaymentMethodsManagement = () => {
    setShowPaymentMethodsManagement((bool) => !bool);
  };

  const handleEditLocationRosterUser = (user) => {
    userData.current = user;
    setShowLocationRosterForm(!showLocationRosterForm);
  };

  const handleEditUser = (student) => {
    userData.current = student;
    setShowStudentForm(true);
  };

  const forceOpen = useMemo(() => {
    return false;
    // if (needsRostersUsers?.length > 0) {
    //   const u = { ...needsRostersUsers[0] };
    //   if (locationIds?.length > 0) {
    //     // Init locationId to first location of other account users
    //     u.locationId = locationIds[0];
    //   }
    //   userData.current = u;
    //   setShowLocationRosterForm(true);
    // }
    // return !hasTermRosters
    //   && needsRostersUsers?.some(({ id }) => id === userData?.current?.id);
  }, [hasTermRosters, needsRostersUsers]);

  const renderAccountPosSettings = () => {
    return (
      <AccountPosSettings
        isOpen={showAccountPosSettingsForm}
        onClose={handleToggleAccountPosSettingsForm}
        accountId={accountId}
      />
    );
  };

  const renderAccountPosSettingsElement = () => {
    return (
      <>
        <TextButton
          onClick={handleToggleAccountPosSettingsForm}
          variant="text"
          sx={{
            marginTop: '0.5rem',
            marginBottom: '0.5rem',
            justifyContent: 'flex-start',
          }}
          fullWidth
        >
          Edit cafeteria self-order permissions
          <EditIcon
            sx={{
              color: 'var(--darkGray)',
              fontSize: '1rem',
              marginLeft: 'auto',
              marginRight: '0.5rem',
            }}
          />
        </TextButton>
        {renderAccountPosSettings()}
      </>
    );
  };

  const renderPaymentMethods = () => {
    return (
      <PaymentMethodsManagement
        isOpen={showPaymentMethodsManagement}
        onClose={handleTogglePaymentMethodsManagement}
        accountId={accountId}
        account={account}
      />
    );
  };

  const renderPaymentMethodsManagement = () => {
    return (
      <>
        <TextButton
          onClick={handleTogglePaymentMethodsManagement}
          variant="text"
          sx={{
            marginTop: '2rem',
            marginBottom: '0.5rem',
            justifyContent: 'flex-start',
          }}
          fullWidth
        >
          Manage payment methods
          <EditIcon
            sx={{
              color: 'var(--darkGray)',
              fontSize: '1rem',
              marginLeft: 'auto',
              marginRight: '0.5rem',
            }}
          />
        </TextButton>
        {renderPaymentMethods()}
      </>
    );
  };

  const renderStudentList = () => (
    <>
      <AccountUserList
        users={studentUsers}
        header="List of students:"
        onEdit={handleEditUser}
        onSetLocationRosters={handleEditLocationRosterUser}
        emptyStateMessage="There are no students yet."
      />
      <Button
        onClick={handleToggleStudentForm}
        variant="outlined"
        startIcon={<PersonAddIcon />}
        fullWidth
      >
        Add a student
      </Button>
    </>
  );

  const renderStudentForm = () => (
    <StudentForm
      isOpen={showStudentForm}
      onClose={handleToggleStudentForm}
      userData={userData?.current}
      accountId={accountId}
    />
  );

  // TODO !!!! Need to refactor to iterate all au and flag self — this method is making it difficult to enforce rosters check

  const renderStaffUsers = () => {
    if (staffUsers?.length === 0) return null;
    return (
      <AccountUserList
        users={staffUsers}
        header="List of staff:"
        onEdit={handleEditUser}
        onSetLocationRosters={handleEditLocationRosterUser}
      />
    );
  };

  const renderLocationRosterForm = () => {
    let usState = undefined;
    [...staffUsers, ...studentUsers].some(({ location, isArchived }) => {
      if (!isArchived && location?.address?.stateProvince) {
        usState = location?.address?.stateProvince;
        return true;
      }
      return false;
    });
    return (
      <LocationRosterForm
        isOpen={showLocationRosterForm}
        onClose={handleToggleLocationRosterForm}
        userData={userData?.current}
        accountId={accountId}
        forceOpen={forceOpen}
        usaState={usState}
      />
    );
  };

  const renderMenuButton = () => {
    return (
      <Button
        onClick={handleGoToMenu}
        disabled={
          (self?.isGuardian && studentUsers?.length === 0) ||
          forceOpen ||
          !hasTermRosters
        }
        sx={{ marginTop: '1rem' }}
        fullWidth
      >
        Go to menu
      </Button>
    );
  };

  const renderGuardianContent = () => {
    return (
      <>
        {renderStudentList()}
        {showStudentForm && renderStudentForm()}
      </>
    );
  };

  // TODO Staff + Student UX is same; rename
  const renderStaffContent = () => {
    return (
      <>
        {renderStaffUsers()}
        {showLocationRosterForm && renderLocationRosterForm()}
      </>
    );
  };

  if (accountLoading) {
    return <Spinner />;
  }

  return (
    <Wrapper>
      {renderStaffContent()}
      {renderGuardianContent()}
      {renderPaymentMethodsManagement()}
      {hasPosLocation && renderAccountPosSettingsElement()}
      {!hideGoToMenu && renderMenuButton()}
    </Wrapper>
  );
};

AccountOverview.defaultProps = {
  hideGoToMenu: false,
};

export default AccountOverview;
