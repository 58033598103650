import { Fragment, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { useLazyQuery, useMutation } from '@apollo/client';
import { client as httpClient } from '../../http';
import { GET_ORDER_BATCHES } from '../../graphql/queries';
import { REPROCESS_ORDER_PAYMENTS } from '../../graphql/mutations';
import useViewport from '../../hooks/useViewport';
import useAccount from '../../hooks/useAccount';
import { DASHBOARD, DELINQUENT, ORDER_ID, constructRouteWithParams } from '../../routes';
import { formatDateTime, constructQueryString } from '../../utils';
import { PAYMENT_STATUSES, PAYMENT_STATUS_LABELS } from '../../utils/Consts';
import { PageContainer, PaymentMethods } from '../../components';
import { Button, Card, Currency, Heading, Divider, Error, EmptyState, SecondaryActionText } from '../../common';
import ReceiptIcon from '@mui/icons-material/Receipt';

const BATCH_STATUS = 'FAILED';

const Container = styled.div`
  padding: 0;
  margin: 0;
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 33.25rem;
  padding: 0;
  margin: 0;
  margin-bottom: 2rem;
`;

const MobileContentWrapper = styled.div`
  width: 90%;
  margin-bottom: 2rem;
`;

const Wrapper = styled.span`
  display: flex;
  flex: 1;
  align-items: center;
  align-content: center;
  justify-content: space-between;
`;

const CardStyled = styled(Card)`
  border: 1px solid var(--gray);
  padding: 1.5rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

const OrderDate = styled(Heading)`
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0.5rem;
`;

const OrderInfo = styled.div`
  min-width: 50%;
`;

const OrderDetails = styled.div`
  margin-top: 0;
`;

const Summary = styled(CardStyled)`
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  justify-content: space-between;
`;

const Success = styled(Card)`
  border: 1px solid var(--gray);
  padding: 2.5rem;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
`;

const Message = styled.p`
  color: var(--charcoal);
  line-height: 1.25;
`;

const Delinquent = () => {
  const { isMobile } = useViewport();
  const history = useHistory();
  const [reprocessOrderPaymentsLoading, setReprocessOrderPaymentsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const { accountId, isPastDue, isSuspended, loading: accountLoading, refetch: getAccount } = useAccount();
  const [getOrderBatches, { data: orderBatchData = {}, loading: orderBatchesLoading }] = useLazyQuery(GET_ORDER_BATCHES);
  // const [reprocessOrderPayments, { data: reprocessOrderPaymentsData = {}, loading: reprocessOrderPaymentsLoading }] = useMutation(REPROCESS_ORDER_PAYMENTS); // TODO Alt gql client?

  const handleGoToOrderDetail = (orderId) => {
    const qs = constructQueryString({ origin: DELINQUENT });
    const route = constructRouteWithParams(ORDER_ID, { orderId });
    history.push(`${route}${qs}`);
  };

  const handleGoToDashboard = () => {
    history.push(DASHBOARD);
  };

  const handleSetError = (error) => {
    setError(error);
  };

  const handleReportError = async (report) => {
    const LOG_API = '/logs/report';
    await httpClient.post({
      url: LOG_API,
      body: report,
    });
  };

  const handleProcessOrderBatches = async ({ paymentMethodId }) => {
    const batchIds = batches.map(({ id }) => id);
    // TODO
    // await reprocessOrderPayments({ variables: {
    //   accountId, paymentMethodId, batchIds
    // } });

    if (error) {
      handleSetError(null);
    }
    setReprocessOrderPaymentsLoading(true);
    const payload = {
      accountId,
      paymentMethodId,
      batchIds,
    };
    let errors = [];
    await httpClient.post({
      url: '/orders/batches/pay',
      body: payload,
      checkStatus: true,
    })
      .then((r) => {
        if (r?.result?.resolveFailedPayments?.errors?.length > 0) {
          const [error] = r?.result?.resolveFailedPayments?.errors || [];
          const errorMessage = `There was an error processing your payment:\n${error?.message || ''}\n\nPlease try again or contact support.`;
          handleSetError({
            message: errorMessage,
          });
          console.error('handleProcessOrderBatches', r);
          errors.push(r);
          const scrollEl = document.getElementById('error');
          if (scrollEl) scrollEl.scrollIntoView({ behavior: 'smooth' });
        } else {
          setSuccess(true);
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      })
      .catch((err) => {
        const errorMessage = 'There was an error processing your payment. Please try again or contact support.';
        handleSetError({
          message: errorMessage,
        });
        console.error('handleProcessOrderBatches', err);
        errors.push(err);
        const scrollEl = document.getElementById('error');
        if (scrollEl) scrollEl.scrollIntoView({ behavior: 'smooth' });
      });
    if (errors.length > 0) {
      await handleReportError({ payload, err: errors[0] });
      await getOrderBatches({ variables: { accountId, status: BATCH_STATUS } });
    }
    setReprocessOrderPaymentsLoading(false);
    await getAccount();
  };

  const formik = useFormik({
    initialValues: {
      paymentMethodId: null,
    },
    onSubmit: handleProcessOrderBatches,
  });

  const { values, handleSubmit, setFieldValue } = formik;

  useEffect(() => {
    if (accountId) {
      getOrderBatches({ variables: { accountId, status: BATCH_STATUS } });
    }
  }, [accountId, isSuspended, isPastDue]);

  const { batches, totalDue, currencyCode, orderCount } = useMemo(() => {
    let totalDue = 0;
    let currencyCode = 'USD';
    let orderCount = 0;
    const { order_batches: orderBatches = [] } = orderBatchData;
    orderBatches.forEach((b) => {
      b.orders.forEach(({ order: o }) => {
        if (o.outstandingCents <= 0) return;
        if (currencyCode !== o.outstandingCurrency) currencyCode = o.outstandingCurrency;
        totalDue += o.outstandingCents;
        orderCount += 1;
      });
    });
    return {
      totalDue,
      currencyCode,
      orderCount,
      batches: orderBatches
    };
  }, [orderBatchData]);

  const renderSummary = () => {
    const title = isSuspended ? 'Account Suspended' : 'Account Delinquent';
    const message = isSuspended
      ? 'Your account has been suspended due to a payment issue. Immediate action is required to reinstate your account. Please resolve the outstanding balance by either retrying the payment with your existing payment method or adding a new one.'
      : 'We encountered an issue while attempting to process your payment. As a result, your account is at risk of suspension unless the outstanding balance is settled. To resolve this, you may either retry the payment with your existing payment method or add a new one.';
    return (
      <>
        <Heading variant="h4">{title}</Heading>
        <Summary>
          <Heading variant="h5">
            Past-due amount:
          </Heading>
          <Heading variant="h5">
            <Currency amount={totalDue} currencyCode={currencyCode} />
          </Heading>
        </Summary>
        {totalDue > 0 && <Message>{message}</Message>}
      </>
    );
  };

  const renderOrder = ({ order }) => {
    const WrapperEl = isMobile ? Fragment : Wrapper;
    const {
      id,
      createdAt,
      deliveryDate,
      totalCents: total,
      outstandingCents: totalDue,
      outstandingCurrency: currencyCode,
      paymentStatus,
      products,
    } = order;
    const userIds = [];
    const users = {};
    let quantity = 0;
    products.forEach(({ user, quantity: q }) => {
      quantity += q;
      const { id: userId } = user;
      if (!userIds.includes(userId)) {
        userIds.push(userId);
        users[userId] = user;
      }
    });
    const itemLabel = quantity > 1 ? `${quantity} items` : '1 item';
    return (
      <CardStyled
        key={id}
        sx={ isMobile ? { padding: '1rem' } : { cursor: 'pointer' }}
        onClick={() => handleGoToOrderDetail(id)}
      >
        <Card.Content>
          <WrapperEl>
            <OrderInfo>
              <OrderDate as="h3">
                {formatDateTime({ dateTime: deliveryDate, format:  'ddd, MMM D' })}
              </OrderDate>
              {(userIds.length > 0) && (
                <OrderDetails>
                  {userIds.map((userId, idx) => (
                    <span key={userId}>
                      {users[userId].preferredName || users[userId].firstName}
                      {idx < userIds.length -1 ? ', ' : null}
                    </span>
                  ))}
                </OrderDetails>
              )}
              <OrderDetails>
                {itemLabel} for <Currency amount={total} currencyCode={currencyCode} />
              </OrderDetails>
              <OrderDetails>
                Submitted {formatDateTime({ dateTime: createdAt, format:  'MMM D, h:mm a' })}
              </OrderDetails>
              <OrderDetails>
                Status: {PAYMENT_STATUS_LABELS[paymentStatus] || paymentStatus}
              </OrderDetails>
              <OrderDetails>
                Amount due: <Currency amount={totalDue} currencyCode={currencyCode} />
              </OrderDetails>
            </OrderInfo>
          </WrapperEl>
        </Card.Content>
      </CardStyled>
    );
  };

  const renderBatch = (batch) => {
    const { id, orders } = batch;
    return (
      <Fragment key={id}>
        {orders.map(renderOrder)}
      </Fragment>
    );
  };

  const renderPastDueBatches = () => {
    return (
      <>
        <Heading variant="h5">Orders</Heading>
        {batches.map(renderBatch)}
      </>
    );
  };

  const renderPaymentMethods = () => {
    return (
      <>
        <Divider sx={{ marginTop: '2rem', marginBottom: '2rem'}} />
        <PaymentMethods
          dividerPosition="top"
          onSelectPaymentMethod={(pmId) => setFieldValue('paymentMethodId', pmId)}
          formKey="paymentMethodId"
        />
        <Button
          sx={{ marginTop: '1rem', marginBottom: '1rem' }}
          onClick={handleSubmit}
          disabled={!values.paymentMethodId}
          loading={reprocessOrderPaymentsLoading}
          fullWidth
        >
          Pay now
        </Button>
      </>
    );
  };

  const renderError = () => {
    return (
      <Error id="error" header="Error" content={error?.message} />
    );
  };

  const renderSuccess = () => {
    const headingText = 'Payment successful';
    const message = 'You account is now in good standing — thank you!';
    return (
      <>
        <Success>
          <Card.Content>
            <Heading variant="h5">{headingText}</Heading>
            <p>
              <b>{message}</b>
            </p>
          </Card.Content>
        </Success>
        <SecondaryActionText
          text="Ready to order?"
          actionLabel="View menu here."
          route={DASHBOARD}
        />
      </>
    );
  };

  const renderDelinquent = () => {
    if (success) {
      return renderSuccess();
    }
    return (
      <>
        {renderSummary()}
        {renderPastDueBatches()}
        {renderPaymentMethods()}
        {!!error && renderError()}
      </>
    );
  };

  const renderEmptyState = () => (
    <>
      {renderSummary()}
      <EmptyState icon={ReceiptIcon} message={'There are no delinquent orders at this time.'} />
      <Button
        onClick={handleGoToDashboard}
        fullWidth
      >
        Go to menu
      </Button>
    </>
  );

  const renderPageContent = () => {
    const ContentWrapperEl = isMobile ? MobileContentWrapper : ContentWrapper;
    return (
      <ContentWrapperEl>
        {(orderCount === 0)
          ? renderEmptyState()
          : renderDelinquent()
        }
      </ContentWrapperEl>
    );
  };

  return (
    <PageContainer
      pageTitle='Account Delinquent'
      loading={orderBatchesLoading || accountLoading}
      showPageTitle={false}
      container={Container}
    >
      {renderPageContent()}
    </PageContainer>
  );
};

export default Delinquent;
