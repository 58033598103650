import { useRef, useEffect } from 'react';
import lottie from 'lottie-web';

const ICONS = {
  CONFETTI: '../public/confetti.json',
};

const IconAnimated = ({ renderer, loop, autoplay, src, size, ...rest }) => {
  const ref = useRef();

  useEffect(() => {
    lottie.loadAnimation({
      container: ref.current,
      path: src,
      renderer,
      loop,
      autoplay,
      ...rest
    });
  }, []);

  return (<div key={src} ref={ref} style={{ width: `${size}em` }} />);
};

IconAnimated.defaultProps = {
  rendered: 'svg',
  loop: true,
  autoplay: true,
  size: 13,
  src: ICONS.CONFETTI,
};

IconAnimated.ICONS = ICONS;

export default IconAnimated;
