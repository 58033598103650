import { useState } from 'react';
import { Button, Snackbar } from '../../common';

const UpdateNotification = ({ isOpen }) => {
  const [open, setOpen] = useState(isOpen);

  const handleClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleUpdate = () => {
    window.location.reload(); // Reload the page to update
  };

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      message="New version available"
      // autoHideDuration={6000}
      action={
        <>
          <Button color="secondary" size="small" onClick={handleUpdate}>
            Update
          </Button>
          <Button color="secondary" size="small" onClick={handleClose}>
            Dismiss
          </Button>
        </>
      }
    />
  );
};

UpdateNotification.defaultProps = {
  isOpen: true,
};

export default UpdateNotification;
