import client from './client';
import cache from './cache';
import * as queries from './queries';
import * as mutations from './mutations';
import * as fragments from './fragments';
import * as inputs from './inputs';

export {
  client,
  cache,
  queries,
  mutations,
  fragments,
  inputs,
};
