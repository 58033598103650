import { Fragment, useMemo, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { v4 as uuid } from 'uuid';
import { useFormik } from 'formik';
import useViewport from '../../hooks/useViewport';
import useQueryParams from '../../hooks/useQueryParams';
import {
  ModalAdvanced,
  Form,
  Checkbox,
  Radio,
  Card,
  Heading,
  Divider,
  Currency,
  Box,
  Carousel,
  Image,
  Tag,
  EmptyState,
  Transitions,
  Markdown,
} from '../../common';
import MenuItem from '../MenuItem';
import {
  sort,
  formatDateTime,
  sumBy,
  calculateProductBasePrice,
} from '../../utils';
import { TAG_TYPES } from '../../utils/Consts';
import ImageIcon from '@mui/icons-material/Image';

const SHOW_BUNDLE_IMAGES = false; // TODO Temp disable WIP bundle images

// TODO Refactor errors to a reusable component and/or hook
const ERROR_BACKGROUND_COLOR = '#ffffed';

const Container = styled.div`
  padding: 0 1rem 1rem;
`;

const MobileContainer = styled.div`
  padding: 0;
`;

const Wrapper = styled.span`
  display: flex;
  flex: 1;
  align-items: center;
  align-content: center;
  justify-content: space-between;
`;

const ScrollWrapper = styled.div`
  display: flex;
  flex: 1 auto;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 27rem);
`;

const SupplementalText = styled.span`
  font-size: 0.8rem;
  font-weight: 300;
  color: var(--darkGray);
`;

const FieldWrapper = styled.div`
  margin: 4px 0 !important;
`;

const ErrorWrapper = styled.div`
  background-color: ${ERROR_BACKGROUND_COLOR};
  border-radius: 4px;
  padding: 0.2rem;
`;

const RowWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0.5rem 1rem 1rem;
`;

const Row = styled(Box)`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

const RowItem = styled.div`
  width: 50%;
`;

const MenuItemPreview = ({
  item,
  addons,
  onClose,
  onAddItem,
  authUserUid,
  accountUsers,
  // timeZone,
  showPrice,
  showTags,
}) => {
  const { isMobile, height } = useViewport();
  const isNarrow = height < 600;

  // TODO Temp flag to show/hide options
  const { showOptions = false } = useQueryParams();

  const { recId: menuProductId, product = {}, datesAvailable } = item || {};

  const selectedGroupRef = useRef(null);

  const handleCalculatePrice = (
    product,
    optionPrices,
    selectionGroupIds,
    values,
  ) => {
    let p = product?.price ?? 0;

    // TODO Temp Exploded price calculation
    const cPrice = calculateProductBasePrice(product);
    return cPrice?.price;
    // TODO Temp

    for (const pId in selectionGroupIds) {
      selectionGroupIds[pId].forEach((sgId) => {
        const key = `${pId}_${sgId}`;
        if (!values[key]) return;
        if (Array.isArray(values[key])) {
          values[key].forEach((oId) => {
            const optionPrice = optionPrices[key][oId];
            p += optionPrice;
          });
        } else {
          const optionPrice = optionPrices[key][values[key]];
          p += optionPrice;
        }
      });
    }
    return p;
  };

  const handleScroll = (id, ancestorId) => {
    const wrapperEl = document.getElementById(ancestorId);
    const topPos = wrapperEl?.offsetTop || 0;
    const el = document.getElementById(id);
    if (!el || !wrapperEl) return;
    const y = el.getBoundingClientRect().top - window.pageYOffset - topPos;
    if (y === 0) return;
    wrapperEl.scroll({ top: y, behavior: 'smooth' });
  };

  const handleAddItem = async (vals, { setErrors }) => {
    const { selectedDates, selectedUsers } = vals;
    let cItem = vals;
    let price = product?.price;
    const selectedOptions = [];
    const errors = {};
    const isValidProds = {
      [catalogId]: true,
    };

    if (selectedDates?.length === 0) {
      errors.selectedDates = 'Please choose at least 1 date.';
      isValidProds[catalogId] = false;
    }
    if (selectedUsers?.length === 0) {
      errors.selectedUsers = 'Please choose at least 1 name.';
      isValidProds[catalogId] = false;
    }

    if (selectedGroupRef.current && showOptions) {
      const {
        selectionGroups,
        selectionGroupIds,
        catalogId,
        optionPrices,
        // selectionGroupDefaultPrices,
      } = selectedGroupRef.current;
      price = handleCalculatePrice(
        product,
        optionPrices,
        selectionGroupIds,
        vals,
      );

      const validateOptions = (prodId) => {
        isValidProds[prodId] =
          prodId === catalogId ? isValidProds[prodId] : true;
        selectionGroupIds[prodId].forEach((sgId) => {
          const key = `${prodId}_${sgId}`;
          const { minQuantity, maxQuantity, options } =
            selectionGroups[prodId][sgId];
          const selectedOptionIds = vals[key];
          const requiredLabel =
            minQuantity === maxQuantity
              ? minQuantity
              : `${minQuantity}–${maxQuantity}`;
          const optionLabel = maxQuantity === 1 ? 'option' : 'options';
          if (minQuantity === 1 && maxQuantity === 1) {
            if (
              Array.isArray(selectedOptionIds)
                ? selectedOptionIds.length === 0
                : !selectedOptionIds
            ) {
              errors[key] = `Please choose ${requiredLabel} ${optionLabel}.`;
              isValidProds[prodId] = false;
            }
          } else if (
            minQuantity > 0 &&
            (selectedOptionIds?.length || 0) < maxQuantity
          ) {
            errors[key] = `Please choose ${requiredLabel} ${optionLabel}.`;
            isValidProds[prodId] = false;
          } else if ((selectedOptionIds?.length || 0) > maxQuantity) {
            errors[key] = `Please choose ${requiredLabel} ${optionLabel}.`;
            isValidProds[prodId] = false;
          }
          options.forEach((o) => {
            if (
              Array.isArray(selectedOptionIds)
                ? selectedOptionIds.includes(o.id)
                : selectedOptionIds === o.id
            ) {
              const option = {
                id: o.id,
                name: o.name,
                description: o.description,
                isCondiment: o.isCondiment,
                productId: prodId,
                selectionGroupId: sgId,
                quantity: 1,
                price: o.priceCents,
                currencyCode: o.priceCurrency,
              };
              if (!o.isCondiment) {
                option.options = [];
              }
              selectedOptions.push(option);
            }
          });
        });
      };

      for (const prodId in selectionGroups) {
        validateOptions(prodId);
      }

      const itemOptionsProdIdMap = {}; // For bundle prod options: [bundleProdId]: idx
      const options = [];
      selectedOptions
        .filter(({ productId }) => productId === catalogId)
        .forEach((o) => {
          const { id, productId } = o;
          options.push(o);
          itemOptionsProdIdMap[id] = options.length - 1;
          validateOptions(productId);
        });
      selectedOptions
        .filter(({ productId }) => productId !== catalogId)
        .forEach((o) => {
          const { productId } = o;
          const idx = itemOptionsProdIdMap[productId];
          if (idx !== undefined) {
            options[idx].options.push(o);
            validateOptions(productId);
          }
        });
      cItem = { ...vals, options };
    }

    let isValid = isValidProds[catalogId];
    // Validate only selected nested options
    if (isValid && cItem.options) {
      isValid = cItem.options.every(({ productId, options }) => {
        return (
          isValidProds[productId] &&
          (!options ||
            options?.every(({ productId }) => isValidProds[productId]))
        );
      });
    }

    if (isValid) {
      const convertOptionsArrayToObject = (options) => {
        if (!Array.isArray(options)) return null;
        const optionsMap = {};
        options.forEach((o) => {
          if (!optionsMap[o.selectionGroupId]) {
            optionsMap[o.selectionGroupId] = [];
          }
          optionsMap[o.selectionGroupId].push({
            ...o,
            options: convertOptionsArrayToObject(o.options),
          });
        });
        return optionsMap;
      };
      cItem.options = convertOptionsArrayToObject(cItem.options);
      await onAddItem(cItem, item);
      onClose();
    } else {
      setErrors(errors);
    }
  };

  const formik = useFormik({
    initialValues: {
      selectedUsers: accountUsers?.length === 1 ? [accountUsers[0].id] : [],
      selectedDates: datesAvailable?.length === 1 ? [datesAvailable[0]] : [],
      selectedAddons: [],
    },
    onSubmit: handleAddItem,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const {
    values,
    errors,
    setValues,
    setFieldValue,
    handleChange,
    handleSubmit,
    isSubmitting,
    isValid,
    isValidating,
  } = formik;

  const getSx = (id) => {
    if (!errors[id]) return null;
    return { backgroundColor: ERROR_BACKGROUND_COLOR };
  };

  const handleSelectOption = (key, fieldType, option) => {
    switch (fieldType) {
      case 'checkbox':
        const setIds = new Set(values[key]);
        if (setIds.has(option.id)) {
          setIds.delete(option.id);
        } else {
          setIds.add(option.id);
        }
        setFieldValue(key, Array.from(setIds));
        break;
      case 'radio':
        setFieldValue(key, option.id);
        break;
      default:
        break;
    }
  };

  const {
    id,
    name: pName,
    description,
    currencyCode,
    tags = [],
    images = [],
    selectionGroups = {},
    selectionGroupIds = {},
    catalogId,
    optionPrices,
    selectionGroupDefaultPrices,
  } = useMemo(() => {
    const hasCondimentSelectionOptions =
      product?.catalogProduct?.productCondiments?.length > 0;
    const hasProductSelectionOptions =
      product?.catalogProduct?.productSelectionGroups?.length > 0;
    const cId = product?.catalogProduct?.id;
    const sGroups = {};
    const sGroupIds = {};

    const oPrices = {};
    const sgPrices = {};

    const processCondiments = (c, productId = cId) => {
      const {
        default: isDefault,
        condiment: {
          id: optionId,
          name: optionName,
          description: optionDescription,
          priceCents: optionPrice,
          priceCurrency: optionCurrency,
        },
        productSelectionGroup: {
          quantity: maxQuantity,
          required: minQuantity,
          selectionGroup: {
            id: selectionGroupId,
            name: groupName,
            icon: groupIcon,
          },
        },
      } = c;
      if (!sGroups[productId]) {
        sGroups[productId] = {};
        sGroupIds[productId] = [];
      }
      if (!sGroups[productId][selectionGroupId]) {
        sGroups[productId][selectionGroupId] = {
          id: selectionGroupId,
          name: groupName,
          icon: groupIcon,
          minQuantity,
          maxQuantity,
          defaultOptions: [],
          options: [],
        };
        sGroupIds[productId].push(selectionGroupId);
      }
      sGroups[productId][selectionGroupId].options.push({
        id: optionId,
        name: optionName,
        description: optionDescription,
        isDefault,
        isCondiment: true,
        price: optionPrice,
        currencyCode: optionCurrency,
      });
      const sgKey = `${productId}_${selectionGroupId}`;
      if (!oPrices[sgKey]) oPrices[sgKey] = {};
      oPrices[sgKey][optionId] = optionPrice || 0;
      if (!sgPrices[sgKey]) sgPrices[sgKey] = [];
      if (isDefault) {
        sgPrices[sgKey].push({ id: optionId, price: optionPrice || 0 });
        sGroups[productId][selectionGroupId].defaultOptions.push(optionId);
      }
    };

    if (hasCondimentSelectionOptions) {
      product.catalogProduct.productCondiments.forEach((c) =>
        processCondiments(c),
      );
    }
    if (hasProductSelectionOptions) {
      product.catalogProduct.productSelectionGroups.forEach((s) => {
        const {
          productId,
          quantity: maxQuantity,
          required: minQuantity,
          selectionGroup: {
            id: selectionGroupId,
            name: groupName,
            icon: groupIcon,
          },
          linkedProducts,
        } = s;
        if (!sGroups[productId]) {
          sGroups[productId] = {};
          sGroupIds[productId] = [];
        }
        if (!sGroups[productId][selectionGroupId]) {
          sGroups[productId][selectionGroupId] = {
            id: selectionGroupId,
            name: groupName,
            icon: groupIcon,
            minQuantity,
            maxQuantity,
            defaultOptions: [],
            options: [],
          };
          sGroupIds[productId].push(selectionGroupId);
        }
        linkedProducts.forEach(({ product: p, default: isDefault }) => {
          const {
            id: optionId,
            name: optionName,
            description: optionDescription,
            assets: images,
            productCondiments,
            priceCents: optionPrice,
            priceCurrency: optionCurrency,
          } = p;
          sGroups[productId][selectionGroupId].options.push({
            id: optionId,
            name: optionName,
            description: optionDescription,
            images,
            isDefault,
            isCondiment: false,
            price: optionPrice,
            currencyCode: optionCurrency,
          });
          const sgKey = `${productId}_${selectionGroupId}`;
          if (!oPrices[sgKey]) oPrices[sgKey] = {};
          oPrices[sgKey][optionId] = optionPrice || 0;
          if (!sgPrices[sgKey]) sgPrices[sgKey] = [];
          productCondiments?.forEach((c) => processCondiments(c, optionId));
          if (isDefault) {
            sgPrices[sgKey].push({ id: optionId, price: optionPrice || 0 });
            sGroups[productId][selectionGroupId].defaultOptions.push(optionId);
          }
        });
      });
    }

    const fields = {};
    const processFieldDefaults = (prodId) => {
      if (sGroupIds[prodId]?.length > 0) {
        sGroupIds[prodId].forEach((sgId) => {
          const key = `${prodId}_${sgId}`;
          const { defaultOptions, options, minQuantity, maxQuantity } =
            sGroups[prodId][sgId];
          const isRadio = minQuantity === 1 && maxQuantity === 1;
          if (
            defaultOptions.length > 0 ||
            (minQuantity === 1 && options.length === 1)
          ) {
            fields[key] = isRadio
              ? defaultOptions[0] || options[0]
              : defaultOptions;
          } else {
            fields[key] = isRadio ? null : [];
          }
        });
      }
    };
    for (const prodId in sGroups) {
      processFieldDefaults(prodId);
    }
    setValues({ ...values, ...fields });
    selectedGroupRef.current = {
      selectionGroups: sGroups,
      selectionGroupIds: sGroupIds,
      catalogId: product?.catalogProduct?.id,
      optionPrices: oPrices,
      selectionGroupDefaultPrices: sgPrices,
    };

    const sortedTags = () => {
      if (!Array.isArray(product?.tags) || !product?.tags?.length || !showTags)
        return [];
      return sort(
        product?.tags.filter(
          ({ tag: { type: tagType } }) =>
            !tagType || tagType?.toUpperCase() === TAG_TYPES.DIETARY,
        ),
        ['tag.name'],
      );
    };

    return {
      ...product,
      tags: sortedTags(),
      selectionGroups: sGroups,
      selectionGroupIds: sGroupIds,
      catalogId: product?.catalogProduct?.id,
      optionPrices: oPrices,
      selectionGroupDefaultPrices: sgPrices,
    };
  }, [product]);

  const price = useMemo(() => {
    return handleCalculatePrice(
      product,
      optionPrices,
      selectionGroupIds,
      values,
    );
  }, [values, product, optionPrices, selectionGroupIds]);

  const featureImageUri = useMemo(
    () => (images.length > 0 ? images[0].uri : product?.featureImageUri),
    [product],
  );

  const mealTypeTags = useMemo(() => {
    if (!Array.isArray(tags) || !tags?.length) return null;
    return (
      tags
        .filter(
          ({ tag: { type: tagType } }) =>
            tagType?.toUpperCase() === TAG_TYPES.MEAL_TYPE,
        )
        .map(({ tag: { name } }) => name)
        .join(', ') || null
    );
  }, [tags]);

  const name = useMemo(() => {
    return mealTypeTags ? `${pName} (${mealTypeTags})` : pName;
  }, [pName, mealTypeTags]);

  useEffect(() => {
    if (isValidating) return;
    if (!isValid) {
      ['selectedDates', 'selectedUsers'].some((id) => {
        if (errors[id]) {
          handleScroll(id, 'preview-content');
          return true;
        }
        return false;
      });
    }
  }, [isValid, isValidating]);

  const { itemsCount, addonSum } = useMemo(() => {
    const selectedAddons = values.selectedAddons.map((aId) => {
      const item = addons.find((a) => a.id === aId);
      return item;
    });
    const userCount = values.selectedUsers?.length || 1;
    const datesCount = values.selectedDates?.length || 1;
    return {
      itemsCount: userCount * datesCount,
      addonSum: sumBy(selectedAddons, 'amount'),
    };
  }, [values.selectedAddons, values.selectedDates, values.selectedUsers]);

  const dateFields = useMemo(
    () =>
      datesAvailable?.map((d) => {
        const date = `${d}`.trim();
        if (!date) {
          console.warn('Empty date detected in datesAvailable');
          return null;
        } else if (d !== date) {
          console.warn('Malformed date string in datesAvailable', `'${d}'`);
        }
        return {
          id: date,
          name: 'selectedDates',
          label: formatDateTime({ dateTime: date, format: 'ddd, MMM D' }),
          type: 'checkbox',
        };
      }),
    [datesAvailable],
  );

  const userFields = useMemo(
    () =>
      accountUsers.map((u) => {
        return {
          id: u.id,
          name: 'selectedUsers',
          label: `${u.preferredName || u.firstName}${
            u.id === authUserUid ? ' (me)' : ''
          }`,
          type: 'checkbox',
        };
      }),
    [accountUsers, authUserUid],
  );

  const getFieldEl = (type) => {
    let El = null;
    switch (type) {
      case 'checkbox':
        El = Checkbox;
        break;
      case 'radio':
        El = Radio;
        break;
      default:
        break;
    }
    return El;
  };

  const renderSectionHeader = (title, text) => {
    return (
      <>
        <Heading variant="h6">{title}</Heading>
        <SupplementalText>{text}</SupplementalText>
      </>
    );
  };

  const renderError = (id) => {
    return (
      <Transitions.Collapse in={errors[id]}>
        {!!errors[id] && (
          <ErrorWrapper id={`error-${id}`}>
            <Form.Error>{errors[id]}</Form.Error>
          </ErrorWrapper>
        )}
      </Transitions.Collapse>
    );
  };

  const renderField = ({ id, name, type, label, component }) => {
    const FieldEl = getFieldEl(type);
    if (component) {
      return <Fragment key={id}>{component()}</Fragment>;
    }
    const elProps = {};
    if (type === 'checkbox' || type === 'radio') {
      elProps.checked = Array.isArray(values[name])
        ? values[name]?.includes(id)
        : values[name] === id;
    }
    return (
      <FieldWrapper key={id}>
        <Form.ControlLabel
          name={name}
          value={id}
          control={<FieldEl {...elProps} />}
          label={label}
          onChange={handleChange}
          sx={{ display: 'flex', flex: '0 0 100%', width: '100%' }}
        />
      </FieldWrapper>
    );
  };

  const renderFieldsInGrid = (data) => {
    const rows = [];
    if (!Array.isArray(data)) return null;
    data.forEach((d, idx) => {
      if (!d) return null;
      if (idx % 2 === 0) {
        rows.push(
          <Row key={d.id}>
            <RowItem>{renderField(d)}</RowItem>
            <RowItem>
              {idx + 1 < data.length && renderField(data[idx + 1])}
            </RowItem>
          </Row>,
        );
      }
    });
    return rows.map((r) => r);
  };

  const renderUserOptions = () => {
    const key = 'selectedUsers';
    return (
      <Card.Content sx={getSx(key)}>
        {renderSectionHeader(
          'Name',
          `Required • Select at up to ${userFields.length}.`,
        )}
        {renderError(key)}
        {renderFieldsInGrid(userFields)}
      </Card.Content>
    );
  };

  const renderDateOptions = () => {
    const key = 'selectedDates';
    return (
      <Card.Content sx={getSx(key)}>
        {renderSectionHeader(
          'Delivery Date',
          `Required • Select up to ${dateFields?.filter((d) => !!d)?.length}.`,
        )}
        {renderError(key)}
        {renderFieldsInGrid(dateFields)}
      </Card.Content>
    );
  };

  const renderOptionTileField = (
    option,
    key,
    fieldType,
    isSelected,
    hasSubOptions,
  ) => {
    const product = {
      id: option.id,
      name: option.name,
      description: option.description,
      images: option.images || [],
      tags: option.tags || [],
    };
    return (
      <MenuItem
        key={key}
        product={product}
        section={{ id: key }}
        quantity={9999}
        datesAvailable={datesAvailable}
        showPrice={false}
        minWidth={null}
        isSelected={isSelected}
        onPreview={() => handleSelectOption(key, fieldType, option)}
        sx={{
          margin: '0 0.5rem 0.5rem 0',
          // sx: { width: '5rem', height: '6rem' },
          // border: isSelected ? '1px solid var(--red)' : '1px solid var(--lightGray)',
        }}
        imageSx={
          isSelected
            ? {
                width: '9rem',
                height: '9rem',
                // aspectRatio: '1/1',
                border: '2px solid var(--green)',
                // WebkitBoxSizing: 'border-box',
                // MozBoxSizing: 'border-box',
                boxSizing: 'border-box',
              }
            : {
                width: '9rem',
                height: '9rem',
                // aspectRatio: '1/1'
              }
        }
      />
    );
  };

  const renderOptions = (
    productId,
    sgId,
    parentKey = null,
    parentName = null,
  ) => {
    const WrapperEl = parentKey || !SHOW_BUNDLE_IMAGES ? Fragment : RowWrapper;
    const key = `${productId}_${sgId}`;
    const {
      name: sectionName,
      minQuantity,
      maxQuantity,
      options,
    } = selectionGroups[productId][sgId];
    const requiredLabel =
      minQuantity === maxQuantity
        ? minQuantity
        : `${minQuantity}–${maxQuantity}`;
    const subHead =
      minQuantity > 0
        ? `Required • Select ${requiredLabel}.`
        : `Optional • Select up to ${maxQuantity}.`;
    const fieldType =
      minQuantity === 1 && maxQuantity === 1 ? 'radio' : 'checkbox';
    const subOptions = [];
    const optionsArr = options.map((o) => {
      const isSelected = Array.isArray(values[key])
        ? values[key].includes(o.id)
        : values[key] === o.id;
      const hasSubOptions = Array.isArray(selectionGroupIds[o.id])
        ? selectionGroupIds[o.id].length > 0
        : selectionGroupIds[o.id] === 1;
      const component =
        o?.isCondiment || !SHOW_BUNDLE_IMAGES
          ? null
          : () =>
              renderOptionTileField(
                o,
                key,
                fieldType,
                isSelected,
                hasSubOptions,
              );
      if (hasSubOptions) {
        subOptions.push(
          <Box
            key={o.id}
            sx={{ flex: 1, flexGrow: 1, flexDirection: 'column' }}
          >
            {selectionGroupIds[o.id]?.map((oSgId) => (
              <Transitions.Collapse key={oSgId} in={isSelected}>
                {renderOptions(o.id, oSgId, key, o.name)}
              </Transitions.Collapse>
            ))}
          </Box>,
        );
      }
      // TODO Calculate priceDiff in useMemo
      let priceDiff = 0;
      if (!o.isDefault) {
        const sgKey = `${productId}_${sgId}`;
        const optionPrice = optionPrices[sgKey][o.id];
        const defaultPrices = selectionGroupDefaultPrices[sgKey];
        if (fieldType === 'radio') {
          const dPrice = sumBy(defaultPrices, 'price');
          priceDiff = optionPrice - dPrice;
        } else {
          // checkbox
          // TODO Need to handle multiple default options with min/max
          const dPrice = sumBy(
            defaultPrices.filter(({ id }) => values[key]?.includes(id)),
            'price',
          );
          priceDiff = optionPrice - dPrice;
        }
      }
      const displayLabel =
        showPrice && priceDiff ? (
          <>
            {o.name}
            <br />
            <Currency
              amount={priceDiff}
              currencyCode={o.currencyCode}
              showSign
            />
          </>
        ) : (
          o.name
        );
      return {
        id: o.id,
        name: key,
        label: displayLabel,
        type: fieldType,
        component,
      };
    });
    const renderFn =
      parentKey || !SHOW_BUNDLE_IMAGES ? renderFieldsInGrid : renderField;
    return (
      <Fragment key={key}>
        <Divider />
        <Card.Content id={key} sx={getSx(key)}>
          {renderSectionHeader(
            parentName ? `${sectionName} (${parentName})` : sectionName,
            subHead,
          )}
          {renderError(key)}
          <WrapperEl>
            {!parentKey && SHOW_BUNDLE_IMAGES
              ? optionsArr.map((o) => {
                  return (
                    <Fragment key={o.id}>
                      <Wrapper id={o.id}>{renderFn(o)}</Wrapper>
                    </Fragment>
                  );
                })
              : renderFn(optionsArr)}
          </WrapperEl>
          {subOptions.map((c) => c)}
        </Card.Content>
      </Fragment>
    );
  };

  const renderMenuItemForm = () => {
    return (
      <Form formik={formik} onSubmit={handleSubmit}>
        {renderDateOptions()}
        <Divider />
        {renderUserOptions()}
        {!!showOptions &&
          selectionGroupIds[catalogId]?.map((sgId) =>
            renderOptions(catalogId, sgId),
          )}
      </Form>
    );
  };

  const renderTag = ({ tag: { id, name, icon, color } }) => {
    return (
      <Tag
        key={id}
        label={name}
        sx={{ marginRight: '0.5rem', marginTop: '0.5rem' }}
        icon={icon}
        color={color}
      />
    );
  };

  const renderImagePlaceholder = () => {
    return (
      <EmptyState
        icon={ImageIcon}
        // message={name}
        sx={{
          backgroundColor: 'var(--lightGray)',
          width: '100%',
          height: '2rem',
          marginBottom: '0.5rem',
          marginLeft: '1rem',
          marginRight: '1rem',
          alignSelf: 'center',
        }}
      />
    );
  };

  const renderCarouselImage = ({ id, uri }) => {
    return (
      <Box
        key={id}
        sx={{
          display: 'flex',
          flex: '1 auto',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Image
          src={uri}
          alt={name}
          loading="lazy"
          sx={{
            borderRadius: '9px',
            width: '92%',
            aspectRatio: '6/4',
            objectFit: 'cover',
          }}
        />
      </Box>
    );
  };

  const renderCarousel = () => {
    if (!images.length && !featureImageUri) return renderImagePlaceholder();
    const imagesArr =
      images.length > 0
        ? images
        : [{ id: 'defaultImage', uri: featureImageUri }];
    return (
      <Carousel
        autoPlay={false}
        animation="slide"
        sx={{
          marginTop: '0.5rem',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'visible',
          alignSelf: 'center',
        }}
        navButtonsAlwaysInvisible={imagesArr.length === 1}
        indicators={imagesArr.length > 1}
        indicatorIconButtonProps={{
          sx: { margin: '0 0.25rem' },
        }}
      >
        {imagesArr.map(renderCarouselImage)}
      </Carousel>
    );
  };

  const renderItemName = () => (
    <Heading variant="h5" sx={{ fontSize: '1rem' }}>
      {name}
    </Heading>
  );

  // TODO Revisit Markdown support here
  const renderDescription = (params) => {
    return (
      <Heading
        variant="h6"
        sx={{
          marginTop: '0.25rem',
          color: 'var(--darkGray)',
          fontWeight: 300,
          fontSize: '0.8rem',
        }}
        {...params}
      />
    );
  };

  const renderItemDescription = () =>
    description ? (
      <Markdown
        components={{
          p(props) {
            const { node, ...rest } = props;
            return renderDescription(rest);
          },
          a(props) {
            const { node, ...rest } = props;
            return renderDescription(rest);
          },
          blockquote(props) {
            const { node, ...rest } = props;
            return renderDescription(rest);
          },
          br(props) {
            const { node, ...rest } = props;
            return renderDescription(rest);
          },
          code(props) {
            const { node, ...rest } = props;
            return renderDescription(rest);
          },
          em(props) {
            const { node, ...rest } = props;
            return renderDescription(rest);
          },
          h1(props) {
            const { node, ...rest } = props;
            return renderDescription(rest);
          },
          h2(props) {
            const { node, ...rest } = props;
            return renderDescription(rest);
          },
          h3(props) {
            const { node, ...rest } = props;
            return renderDescription(rest);
          },
          h4(props) {
            const { node, ...rest } = props;
            return renderDescription(rest);
          },
          h5(props) {
            const { node, ...rest } = props;
            return renderDescription(rest);
          },
          h6(props) {
            const { node, ...rest } = props;
            return renderDescription(rest);
          },
          hr(props) {
            const { node, ...rest } = props;
            return renderDescription(rest);
          },
          img(props) {
            const { node, ...rest } = props;
            return renderDescription(rest);
          },
          li(props) {
            const { node, ...rest } = props;
            return renderDescription(rest);
          },
          ol(props) {
            const { node, ...rest } = props;
            return renderDescription(rest);
          },
          pre(props) {
            const { node, ...rest } = props;
            return renderDescription(rest);
          },
          strong(props) {
            const { node, ...rest } = props;
            return renderDescription(rest);
          },
          ul(props) {
            const { node, ...rest } = props;
            return renderDescription(rest);
          },
        }}
      >
        {description}
      </Markdown>
    ) : null;
  // TODO Revisit Markdown support here ^^

  const renderTags = () => {
    if (!tags.length || !showTags) return null;
    return <RowWrapper id={`${id}-tags`}>{tags.map(renderTag)}</RowWrapper>;
  };

  const renderItemDetails = () => {
    return (
      <>
        <Card.Header
          title={renderItemName()}
          subheader={renderItemDescription()}
        />
        <Divider />
      </>
    );
  };

  const renderImages = renderCarousel;

  const renderHeader = renderItemDetails;

  const renderContent = () => {
    return (
      <>
        {renderTags()}
        {renderImages()}
        {renderMenuItemForm()}
      </>
    );
  };

  const renderActionLabel = () => (
    <Wrapper>
      {`Add to cart (${itemsCount})`}
      {showPrice && (
        <Currency
          amount={(price + addonSum) * itemsCount}
          currencyCode={currencyCode}
        />
      )}
    </Wrapper>
  );

  return (
    <ModalAdvanced
      title={pName}
      onClose={onClose}
      transition="grow"
      container={isMobile || isNarrow ? MobileContainer : Container}
      wrapper={isMobile || isNarrow ? null : ScrollWrapper}
      header={renderHeader()}
      showCloseButton={true}
      actions={[
        {
          label: renderActionLabel(),
          onClick: handleSubmit,
          disabled: isSubmitting,
          loading: isSubmitting,
        },
      ]}
    >
      {renderContent()}
    </ModalAdvanced>
  );
};

MenuItemPreview.defaultProps = {
  showePrice: true,
  showTags: true,
};

export default MenuItemPreview;
