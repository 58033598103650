import { useState, useEffect, useMemo, useCallback } from 'react';
import { createPortal } from 'react-dom';
import styled from '@emotion/styled';
import { debounce } from 'lodash';
import useViewport from '../../hooks/useViewport';
import useSubscriptions from '../../hooks/useSubscriptions';
import { EmptyState, Heading, Spinner, Button, Box } from '../../common';
import { sort } from '../../utils';
import UserTabs from '../UserTabs';
import CatalogProductGrid from '../CatalogProductGrid';
import CatalogProductFilters from '../CatalogProductFilters';
import TuneIcon from '@mui/icons-material/Tune';
import LocalDiningIcon from '@mui/icons-material/LocalDining';

const Wrapper = styled.div`
  margin-top: 2.5rem;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: 2rem;
`;

const MenuContentWrapper = styled.div`
  display: flex;
  width: 100vw;
  align-items: center;
  > * {
    flex-shrink: 0;
  }
  > *:first-child {
    // Optional left content, it will take up the space it needs based on its content
    flex-grow: 0;
  }
  > *:nth-child(2) {
    // Main content, it will grow but has a max-width to restrict its size
    flex-grow: 1;
    flex-basis: 0; // This means it doesn't start with a default size before growing
    max-width: calc(
      100vw - 17em
    ); // Adjust the subtracted value based on the expected size of left/right content
  }
  > *:last-child {
    // Optional right content, it will take up the space it needs based on its content
    flex-grow: 0;
    margin-left: auto;
  }
`;

const MenuBarContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(15px);
  padding: 0.5rem 0;
  z-index: 10000;
`;

const MenuBarWrapper = styled.div`
  padding: 0 1.5rem;
  margin-right: 1rem;
`;

const MobileMenuBarWrapper = styled.div`
  width: 100vw;
`;

const UserProductReactionsCatalog = ({
  userIds,
  users,
  products,
  userProducts,
  onSubmit,
  onChange,
  loading,
  isSaving,
  showUserHeader = false,
  showUserTabs = true,
}) => {
  const { isMobile, width } = useViewport();
  const [selectedUser, setSelectedUser] = useState(null);
  const [filters, setFilters] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [formValues, setFormValues] = useState({});
  const { refetch } = useSubscriptions();

  useEffect(() => {
    refetch();
  }, []);

  const onRefetchDb = useCallback(debounce(refetch, 1000), [refetch]);

  const handleSaveReaction = useCallback(
    (productId, userId, reaction = null) => {
      const v = formValues[userId] || {};
      v[productId] = v[productId] !== reaction ? reaction : null;
      setFormValues((prev) => ({
        ...prev,
        [userId]: v,
      }));
      if (onChange) onChange({ ...formValues, userId });
      onSubmit({ productId, userId, reaction: v[productId], cb: onRefetchDb });
    },
    [formValues, onChange, onSubmit],
  );

  const handleToggleFiltersView = useCallback(() => {
    setShowFilters((bool) => !bool);
  }, [showFilters, setShowFilters]);

  const header = useMemo(() => {
    if (showUserHeader && selectedUser) {
      return `Select ${selectedUser?.displayName}'s preferences`;
    }
    return 'Help us understand you a bit more';
  }, [selectedUser, showUserHeader]);

  const tags = useMemo(() => {
    const t = [];
    userProducts[selectedUser?.userId]?.forEach((idx) => {
      const p = products[idx];
      p.product?.tags?.forEach(({ tag }) => {
        if (tag.isSubscriptionTag && !t.some(({ id: tId }) => tId === tag.id)) {
          t.push(tag);
        }
      });
    });
    return sort(t, ['name', 'id']);
  }, [products, userProducts, selectedUser]);

  const filterCount = useMemo(() => filters.length, [filters]);

  const filtersLabel = useMemo(
    () => (filterCount > 0 ? `Filters • ${filterCount}` : 'Filters'),
    [filterCount],
  );

  const buttons = useMemo(
    () => [
      {
        key: 'filters',
        label: filtersLabel,
        icon: <TuneIcon />,
        variant: 'outlined',
        onClick: handleToggleFiltersView,
      },
    ],
    [filtersLabel, handleToggleFiltersView],
  );

  const renderMobileMenuContent = () => {
    if (!isMobile || !showUserTabs) return null;
    const headerInlinePortalEl = document.getElementById(
      'header-inline-portal',
    );
    const filterButton = (
      <Button
        onClick={handleToggleFiltersView}
        variant="outlined"
        sx={{ height: '2.8rem', padding: 0 }}
      >
        <TuneIcon />
        {filters?.length > 0 && ` • ${filters.length}`}
      </Button>
    );
    if (!headerInlinePortalEl) {
      console.error(
        'Cannot set portal content filters for header-inline-portal',
      );
      return <Sticky>{filterButton}</Sticky>;
    }
    return createPortal(filterButton, headerInlinePortalEl);
  };

  const filteredUserProducts = useMemo(() => {
    if (!selectedUser) return [];
    const p = userProducts[selectedUser.userId] || [];
    return filters.length === 0
      ? p
      : p.filter((idx) => {
          const p = products[idx];
          return p.product.tags.some(({ tag }) => filters.includes(tag.id));
        });
  }, [filters, products, userProducts, selectedUser]);

  useEffect(() => {
    if (!selectedUser) return;
    if (!formValues[selectedUser.userId]) {
      const u = users.find((u) => u.userId === selectedUser.userId);
      const v = u.reactions || {};
      setFormValues((prev) => ({
        ...prev,
        [selectedUser.userId]: v,
      }));
      if (onChange) onChange({ ...formValues, userId: selectedUser.userId });
    }
  }, [selectedUser, users]);

  const renderButton = (key, label, icon, variant, onClick, loading) => {
    const onClickHandler = onClick;
    const sx = { marginLeft: '1rem' };
    if (filterCount > 0) {
      sx.width = '10.5rem';
    }
    return (
      <Button
        key={key}
        onClick={onClickHandler}
        variant={variant}
        startIcon={icon}
        loading={loading}
        sx={isMobile ? null : sx}
        fullWidth={isMobile}
      >
        {label}
      </Button>
    );
  };

  const renderButtonWithDropdown = ({
    key,
    label,
    icon,
    variant,
    content,
    onClick,
    loading = false,
  }) => {
    return (
      <div key={key}>
        {renderButton(key, label, icon, variant, onClick, loading)}
        {!!content && renderDropdownMenu(content, key)}
      </div>
    );
  };

  const renderActions = () => {
    return (
      <MenuBarWrapper>{buttons.map(renderButtonWithDropdown)}</MenuBarWrapper>
    );
  };

  const renderCatalogProducts = (itemIdx) => {
    const items = itemIdx.map((idx) => products[idx]);
    const columns = Math.floor(width / 260);
    const params = isMobile
      ? {
          asCarousel: false,
          justifyContent: 'space-around',
          itemSx: { width: '100%', padding: '0 1rem' },
        }
      : {
          sx: { gridTemplateColumns: `repeat(${columns}, auto)` },
        };
    return (
      <CatalogProductGrid
        items={items}
        sectionId={null}
        userReactions={formValues[selectedUser.userId]}
        onReaction={(productId, reaction = null) =>
          handleSaveReaction(productId, selectedUser.userId, reaction)
        }
        loading={isSaving[selectedUser.userId] || {}}
        {...params}
      />
    );
  };

  const renderCatalogEmptyState = () => {
    return (
      <EmptyState
        title="Product Reactions"
        message="There are no products to select at this time."
        icon={LocalDiningIcon}
      />
    );
  };

  const renderUserEmptyState = () => {
    return (
      <EmptyState
        title="Users Reactions"
        message="There are no users to react."
        icon={LocalDiningIcon}
      />
    );
  };

  const renderMenuProducts = () => {
    if (!selectedUser) return null;
    if (loading) return <Spinner />;
    const { userId } = selectedUser;
    if (!userProducts[userId]) return null;
    const uProds =
      filters?.length === 0 ? userProducts[userId] : filteredUserProducts;
    return uProds?.length > 0
      ? renderCatalogProducts(uProds)
      : renderCatalogEmptyState();
  };

  const renderUserProducts = () => {
    return (
      <>
        {users.length > 0 ? (
          <Wrapper>{renderMenuProducts()}</Wrapper>
        ) : (
          renderUserEmptyState()
        )}
      </>
    );
  };

  const renderFilters = () => {
    return (
      <>
        <CatalogProductFilters
          filters={filters}
          tags={tags}
          userId={selectedUser?.userId}
          onToggleFiltersView={handleToggleFiltersView}
          onSetFilters={setFilters}
          isOpen={showFilters}
        />
        {isMobile && renderMobileMenuContent()}
      </>
    );
  };

  const renderTabs = () => {
    return (
      <UserTabs
        userIds={userIds}
        users={users}
        selectedUserId={selectedUser?.userId}
        onSelectUser={setSelectedUser}
        showUserTabs={showUserTabs}
      ></UserTabs>
    );
  };

  const renderMenuBar = () => {
    const ContentWrapperEl = isMobile
      ? MobileMenuBarWrapper
      : MenuContentWrapper;
    return (
      <>
        <MenuBarContainer id="menu-bar">
          <ContentWrapperEl>
            <div>{renderTabs()}</div>
            {!isMobile && <div>{renderActions()}</div>}
            {isMobile && showUserHeader && <div>{renderActions()}</div>}
          </ContentWrapperEl>
        </MenuBarContainer>
      </>
    );
  };

  return (
    <>
      {showUserHeader && <Heading variant="h5">{header}</Heading>}
      <p>
        We will offer your family the types of meals they like, and avoid the
        ones they don't when possible.
      </p>
      {renderMenuBar()}
      {renderUserProducts()}
      {selectedUser ? renderFilters() : null}
    </>
  );
};

export default UserProductReactionsCatalog;
