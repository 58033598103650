import { formatDateTime } from '../utils';
// import { USER_ROLES } from '../utils/Consts';
import { makeVar } from '@apollo/client';
export const accountsUsersVar = makeVar([]);
import { camelCase } from 'lodash';

export const getResolvers = ({ isAuthenticatedCache, authUserCache }) => ({
  typePolicies: {
    Query: {
      fields: {
        me: {
          read(_, { toReference }) {
            return toReference({
              __typename: 'users',
              id: authUserCache()?.uid,
            });
          },
        },
        isAuthenticated: {
          read() {
            return isAuthenticatedCache();
          },
        },
        authUser: {
          read() {
            return authUserCache();
          },
        },
      },
    },
    users: {
      fields: {
        fullName: {
          read(_, { readField }) {
            const names = [
              readField('firstName'),
              readField('preferredName'),
              readField('middleName'),
              readField('lastName'),
              readField('suffix'),
            ];
            const fullName = names.reduce((result, name, idx) => {
              const prev = result ?? '';
              if (idx === 1 && name && result) {
                return `${result} "${name}"`;
              }
              return prev && name ? `${prev} ${name}` : prev || name || '';
            });
            return fullName;
          },
        },
        displayName: {
          read(_, { readField }) {
            const names = [readField('firstName'), readField('preferredName')];
            const [firstName, preferredName] = names;
            return preferredName || firstName;
          },
        },
        initials: {
          read(_, { readField }) {
            let initials = '';
            const preferredName = readField('preferredName');
            if (preferredName) initials = preferredName[0];
            else {
              const firstName = readField('firstName');
              initials = firstName[0];
            }
            return initials;
          },
        },
      },
    },
    Roster: {
      //Rails type for accounts.users.rosters.roster
      fields: {
        group: {
          read(rGroup) {
            return rGroup?.toUpperCase();
          },
        },
      },
    },
    SchoolLocation: {
      //Rails type for location
      fields: {
        settings: {
          read(settings) {
            const s = {};
            // Camel case keys in settings
            Object.keys(settings).forEach((key) => {
              s[camelCase(key)] = settings[key];
            });
            return s;
          },
        },
      },
    },
    // accounts_users: {
    //   fields: {
    //     isStaff: {
    //       read(_, { readField }) {
    //         const roles = readField('roles');
    //         const bool = roles.includes(USER_ROLES.STAFF);
    //         return bool;
    //       }
    //     },
    //     isStudent: {
    //       read(_, { readField }) {
    //         const roles = readField('roles');
    //         const bool = roles.includes(USER_ROLES.STUDENT);
    //         return bool;
    //       }
    //     },
    //     isGuardian: {
    //       read(_, { readField }) {
    //         const roles = readField('roles');
    //         const bool = roles.includes(USER_ROLES.GUARDIAN);
    //         return bool;
    //       }
    //     },
    //     isAccountAdmin: {
    //       read(_, { readField }) {
    //         const roles = readField('roles');
    //         const bool = roles.includes(USER_ROLES.ADMIN);
    //         return bool;
    //       }
    //     }
    //   }
    // },
    menus: {
      fields: {
        startDateTime: {
          read(_, { readField }) {
            const startDate = readField('startDate');
            const timeZone = readField('timeZone');
            if (!startDate) return null;
            const dt = formatDateTime({
              asString: false,
              dateTime: startDate,
              timeZone,
            });
            const startDateTime = dt.startOf('day').toISOString();
            return startDateTime;
          },
        },
        endDateTime: {
          read(_, { readField }) {
            const endDate = readField('endDate');
            const timeZone = readField('timeZone');
            if (!endDate) return null;
            const dt = formatDateTime({
              asString: false,
              dateTime: endDate,
              timeZone,
            });
            const endDateTime = dt.endOf('day').toISOString();
            return endDateTime;
          },
        },
      },
    },
    orders_products: {
      fields: {
        gratisCount: {
          read(_, { readField }) {
            const isGratis = readField('isGratis');
            return isGratis ? 1 : 0; // TODO
          },
        },
      },
    },
    // orders: {
    //   fields: {
    //     summary: { // TODO Need to resolve nested objects, not just references
    //       read(_, { readField }) {
    //         const [coupons, products = []] = [
    //           readField('coupons'),
    //           readField('products'),
    //         ];
    //         return calculateOrderSummary({ coupons, products });
    //       }
    //     },
    //   }
    // },
  },
});
