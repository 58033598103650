import styled from '@emotion/styled';
import { Grid } from '../../common';
import CatalogProduct from '../CatalogProduct';

const HorizontalLayout = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  overflow-x: scroll;
  padding-bottom: 1rem;
`;

const HorizontalItemWrapper = styled.div`
  margin-left: 1.5rem;
  :last-child {
    margin-right: 1.5rem;
  }
`;

const CatalogProductGrid = ({
  items,
  sectionId,
  userReactions,
  onReaction,
  asCarousel,
  justifyContent,
  showTags,
  itemSx,
  sx,
  loading,
}) => {
  const renderItem = (
    { item, isLastItem },
    params = { showTags, sx: itemSx },
  ) => (
    <CatalogProduct
      {...item}
      isLastItem={isLastItem}
      sectionId={sectionId}
      reaction={userReactions?.[item.product.catalogProductId] || null}
      onReaction={onReaction}
      loading={loading[item.product.catalogProductId]}
      {...params}
    />
  );

  const renderItemInGrid = (item, idx) => {
    const isLastItem = idx === items.length - 1;
    return (
      <Grid
        key={item.recId}
        sx={{
          height: '100%',
        }}
        item
      >
        {renderItem({ item, isLastItem })}
      </Grid>
    );
  };

  const renderGrid = () => {
    return (
      <Grid
        container
        justifyContent={justifyContent}
        alignItems="flex-start"
        columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 3 }}
        rowSpacing={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 3 }}
        sx={{
          display: 'grid',
          ...sx,
        }}
      >
        {items.map(renderItemInGrid)}
      </Grid>
    );
  };

  const renderCarousel = () => {
    const params = {
      sx: { width: '70vw' },
    };
    return (
      <HorizontalLayout>
        {items.map((item, idx) => (
          <HorizontalItemWrapper key={item.product.id}>
            {renderItem({ item, isLastItem: idx === items.length - 1 }, params)}
          </HorizontalItemWrapper>
        ))}
      </HorizontalLayout>
    );
  };

  return asCarousel ? renderCarousel() : renderGrid();
};

CatalogProductGrid.defaultProps = {
  asCarousel: false,
  justifyContent: 'flex-start',
  sx: null,
};

export default CatalogProductGrid;
