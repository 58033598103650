import Accordion_COMP from './Accordion';
import Autocomplete_COMP from './Autocomplete';
import Avatar_COMP from './Avatar';
import Backdrop_COMP from './Backdrop';
import Badge_COMP from './Badge';
import Box_COMP from './Box';
import Button_COMP from './Button';
import Card_COMP from './Card';
import Carousel_COMP from './Carousel';
import Checkbox_COMP from './Checkbox';
import Currency_COMP from './Currency';
import DatePicker_COMP from './DatePicker';
import DateRangePicker_COMP from './DateRangePicker';
import DateTime_COMP from './DateTime';
import Debug_COMP from './Debug';
import Dialog_COMP from './Dialog';
import Divider_COMP from './Divider';
import Drawer_COMP from './Drawer';
import DrawerAdvanced_COMP from './DrawerAdvanced';
import EmptyState_COMP from './EmptyState';
import Error_COMP from './Error';
import Form_COMP from './Form';
import Grid_COMP from './Grid';
import Heading_COMP from './Heading';
import Icon_COMP from './Icon';
import IconAnimated_COMP from './IconAnimated';
import IconButton_COMP from './IconButton';
import Image_COMP from './Image';
import Input_COMP from './Input';
import Link_COMP from './Link';
import List_COMP from './List';
import Markdown_COMP from './Markdown';
import Menu_COMP from './Menu';
import Message_COMP from './Message';
import Modal_COMP from './Modal';
import ModalAdvanced_COMP from './ModalAdvanced';
import Popper_COMP from './Popper';
import Progress_COMP from './Progress';
import Radio_COMP from './Radio';
import Rating_COMP from './Rating';
import SecondaryActionText_COMP from './SecondaryActionText';
import Select_COMP from './Select';
import Skeleton_COMP from './Skeleton';
import Snackbar_COMP from './Snackbar';
import Spinner_COMP from './Spinner';
import Stepper_COMP from './Stepper';
import Switch_COMP from './Switch';
import Table_COMP from './Table';
import Tabs_COMP from './Tabs';
import Tag_COMP from './Tag';
import TextArea_COMP from './TextArea';
import TextField_COMP from './TextField';
import Timeline_COMP from './Timeline';
import ToggleButton_COMP from './ToggleButton';
import Tooltip_COMP from './Tooltip';
import Transitions_COMP from './Transitions';
import Typography_COMP from './Typography';
import _Base_COMP from './_Base';

const x = {
  Accordion: Accordion_COMP,
  Autocomplete: Autocomplete_COMP,
  Avatar: Avatar_COMP,
  Backdrop: Backdrop_COMP,
  Badge: Badge_COMP,
  Box: Box_COMP,
  Button: Button_COMP,
  Card: Card_COMP,
  Carousel: Carousel_COMP,
  Checkbox: Checkbox_COMP,
  Currency: Currency_COMP,
  DatePicker: DatePicker_COMP,
  DateRangePicker: DateRangePicker_COMP,
  DateTime: DateTime_COMP,
  Debug: Debug_COMP,
  Dialog: Dialog_COMP,
  Divider: Divider_COMP,
  Drawer: Drawer_COMP,
  DrawerAdvanced: DrawerAdvanced_COMP,
  EmptyState: EmptyState_COMP,
  Error: Error_COMP,
  Form: Form_COMP,
  Grid: Grid_COMP,
  Heading: Heading_COMP,
  Icon: Icon_COMP,
  IconAnimated: IconAnimated_COMP,
  IconButton: IconButton_COMP,
  Image: Image_COMP,
  Input: Input_COMP,
  Link: Link_COMP,
  List: List_COMP,
  Markdown: Markdown_COMP,
  Menu: Menu_COMP,
  Message: Message_COMP,
  Modal: Modal_COMP,
  ModalAdvanced: ModalAdvanced_COMP,
  Popper: Popper_COMP,
  Progress: Progress_COMP,
  Radio: Radio_COMP,
  Rating: Rating_COMP,
  SecondaryActionText: SecondaryActionText_COMP,
  Select: Select_COMP,
  Skeleton: Skeleton_COMP,
  Snackbar: Snackbar_COMP,
  Spinner: Spinner_COMP,
  Stepper: Stepper_COMP,
  Switch: Switch_COMP,
  Table: Table_COMP,
  Tabs: Tabs_COMP,
  Tag: Tag_COMP,
  TextArea: TextArea_COMP,
  TextField: TextField_COMP,
  Timeline: Timeline_COMP,
  ToggleButton: ToggleButton_COMP,
  Tooltip: Tooltip_COMP,
  Transitions: Transitions_COMP,
  Typography: Typography_COMP,
  _Base: _Base_COMP,
};
module.exports = x;
