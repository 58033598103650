import { Fragment, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { client as httpClient } from '../../http';
import { COLORS } from '../../styles/Consts';
import { getPaymentMethod3dsStatus } from '../../utils';
import { Card, Form, Spinner, ModalAdvanced, Error } from '../../common';
import HttpsIcon from '@mui/icons-material/Https';

const PaymentFieldLabel = styled(Form.Label)`
  color: var(--charcoal);
  margin-bottom: 0.5rem;
`;

const ContentWrapper = styled.div`
  padding: 1rem;
`;

const StripeElementWrapper = styled.span`
  .StripeElement {
    border: 1px solid ${COLORS.gray};
    border-radius: 4px;
    padding: 14px;
    margin-bottom: 8px;
  }
  .StripeElement--invalid {
    border: 1px solid ${COLORS.red};
    border-radius: 4px;
    padding: 14px;
    margin-bottom: 8px;
  }
`;

const StripePaymentMethodForm = ({
  me,
  accountId,
  paymentRef,
  onClose,
  stripeLoading,
  stripeError,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const stripe = useStripe();
  const elements = useElements();

  const handleSetError = (message, obj) => {
    if (!message) {
      setError(null);
      return;
    }
    const msg = obj?.error?.message || obj?.raw?.message;
    const err = msg ? `${message} Error: ${msg}` : message;
    setError(err);
  };

  const handleReportError = async (report) => {
    const LOG_API = '/logs/report';
    await httpClient.post({
      url: LOG_API,
      body: report,
    });
  };

  const handleCreateStripeCustomer = async () => {
    const payload = {
      customer: {
        phone: me?.phoneNumber,
        email: me?.emailAddress,
        description: me?.fullName,
        metadata: { accountId },
      },
    };
    const res = await httpClient.post({
      url: '/payments/customers',
      body: payload,
    });
    return res;
  };

  const handleAttachPaymentMethod = async (paymentMethodId, customerId) => {
    const payload = {
      paymentMethodId,
      customerId,
      accountId,
    };
    const res = await httpClient.post({
      url: '/payments/methods/attach',
      body: payload,
    });
    return res;
  };

  const handleSetupIntents = async (paymentMethodId, customerId) => {
    const payload = {
      paymentMethodId,
      customerId,
      accountId,
    };
    const res = await httpClient.post({
      url: '/payments/intents',
      body: payload,
    });
    return res;
  };

  const handleAddPaymentMethod = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    handleSetError(null);
    const cardElement = elements.getElement(CardNumberElement);
    if (!cardElement) {
      const err =
        "There was an error with Stripe's payment form. Please refresh and try again.";
      handleSetError(err);
      await handleReportError({ error: err, accountId });
      setIsLoading(false);
      return;
    }

    const customer =
      paymentRef?.customer || (await handleCreateStripeCustomer());

    const customerId = customer?.customer?.id || customer?.id;
    if (!customerId) {
      const err =
        'There was an error setting up Stripe customer. Please refresh and try again. If this problem persists, please contact support.';
      handleSetError(err, customer);
      await handleReportError({ error: err, customer, accountId });
      setIsLoading(false);
      return;
    }

    const paymentMethodRes = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      metadata: { accountId, uid: me?.id, customerId },
    });

    const paymentMethodId = paymentMethodRes?.paymentMethod?.id;
    if (!paymentMethodId) {
      const err = 'There was an error adding payment method.';
      handleSetError(err, paymentMethodRes);
      if (
        paymentMethodRes?.error &&
        paymentMethodRes?.error?.type !== 'validation_error'
      ) {
        // Show validation error in UI but don't report to API
        await handleReportError({
          error: err,
          paymentMethodRes,
          customer,
          accountId,
        });
      }
      setIsLoading(false);
      return;
    }

    const attachRes = await handleAttachPaymentMethod(
      paymentMethodId,
      customerId,
    );

    if (
      !attachRes ||
      (attachRes && attachRes.error) ||
      (attachRes &&
        attachRes.paymentMethod &&
        !attachRes.paymentMethod[paymentMethodId])
    ) {
      const err =
        'There was an error adding the payment method to your account. Try a different card or contact your issuer/bank.';
      handleSetError(err, attachRes);
      await handleReportError({
        error: err,
        attachRes,
        paymentMethodRes,
        customer,
        accountId,
      });
      setIsLoading(false);
      return;
    }

    const setupIntentRes = await handleSetupIntents(
      paymentMethodId,
      customerId,
    );

    // TODO Temp reject 3ds cards
    const is3ds = getPaymentMethod3dsStatus(paymentMethodId, setupIntentRes);
    if (is3ds) {
      handleSetError(
        "We're sorry, but we're currently unable to support this payment method. Please try again using a different card.",
      );
      setIsLoading(false);
      return;
    }

    setIsLoading(false);
    if (onClose) onClose({ hasChange: true });
  };

  const stripeElementOptions = useMemo(
    () => ({
      style: {
        base: {
          iconColor: COLORS.charcoal,
          color: COLORS.charcoal,
          fontWeight: 500,
          fontFamily: 'Outfit, sans-serif',
          fontSize: '16px',
          fontSmoothing: 'antialiased',
          ':-webkit-autofill': {
            color: COLORS.darkGray,
          },
          '::placeholder': {
            color: COLORS.gray,
          },
          ':focus': {},
        },
        invalid: {
          iconColor: COLORS.red,
          color: COLORS.red,
        },
      },
    }),
    [],
  );

  const memoCardNumber = useMemo(
    () => (
      <>
        <StripeElementWrapper>
          <CardNumberElement
            options={stripeElementOptions}
            // onChange={(e) => console.log(e)}
            hideIcon={false}
            showIcon
          />
        </StripeElementWrapper>
      </>
    ),
    [],
  );

  const memoCardExpiry = useMemo(
    () => (
      <StripeElementWrapper>
        <CardExpiryElement
          options={stripeElementOptions}
          // onChange={(e) => console.log(e)}
        />
      </StripeElementWrapper>
    ),
    [],
  );

  const memoCardCvc = useMemo(
    () => (
      <StripeElementWrapper>
        <CardCvcElement
          options={stripeElementOptions}
          // onChange={(e) => console.log(e)}
        />
      </StripeElementWrapper>
    ),
    [],
  );

  const paymentFields = useMemo(() => {
    const pFields = [
      {
        id: 'cardName',
        label: 'Card Name',
        initialValue: '',
      },
      {
        id: 'stripeCardNumber',
        label: 'Card Number',
        component: () => memoCardNumber,
      },
      {
        id: 'stripeCardExpiry',
        label: 'Card Expiry',
        component: () => memoCardExpiry,
      },
      {
        id: 'stripeCardCvc',
        label: 'Card CVC',
        component: () => memoCardCvc,
      },
    ];
    return pFields;
  }, []);

  const memoAccountFields = useMemo(() => {
    return paymentFields;
  }, []);

  const renderField = ({ id, label, component }) => {
    if (component) {
      return (
        <Fragment key={id}>
          <PaymentFieldLabel>{label}</PaymentFieldLabel>
          {component()}
        </Fragment>
      );
    }
    return null;
  };

  const renderError = () => {
    return <Error header="Error" content={error} />;
  };

  const renderForm = () => {
    return (
      <form>
        <Card.Content>
          {memoAccountFields.map(renderField)}
          {error && renderError()}
        </Card.Content>
      </form>
    );
  };

  const renderStripeError = () => {
    return (
      <ContentWrapper>
        <Error header="Stripe Error" content={stripeError} />
      </ContentWrapper>
    );
  };

  const renderSpinner = () => <Spinner />;

  const renderContent = () => {
    const showSpinner = !me || !accountId || stripeLoading;
    let content = null;
    if (showSpinner) content = renderSpinner();
    else content = stripeError ? renderStripeError() : renderForm();
    return content;
  };

  const renderTitle = () => (
    <>
      Add payment method
      <HttpsIcon
        sx={{
          marginLeft: '0.5rem',
          marginBottom: '0.4rem',
          verticalAlign: 'middle',
        }}
      />
    </>
  );

  return (
    <ModalAdvanced
      title={renderTitle()}
      onClose={onClose}
      actions={[
        {
          label: 'Add payment method',
          onClick: handleAddPaymentMethod,
          disabled: isLoading,
          loading: isLoading,
        },
      ]}
    >
      {renderContent()}
    </ModalAdvanced>
  );
};

export default StripePaymentMethodForm;
