import { Fragment, useMemo, useState, useCallback } from 'react';
import styled from '@emotion/styled';
import useViewport from '../../hooks/useViewport';
import { sort } from '../../utils';
import { TAG_TYPES, PRODUCT_REACTIONS } from '../../utils/Consts';
import {
  Card,
  Image,
  IconButton,
  Tag,
  EmptyState,
  Transitions,
  Progress,
  Divider,
} from '../../common';
import ImageIcon from '@mui/icons-material/Image';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';

const { Zoom, Grow } = Transitions;

const Wrapper = styled.div`
  justify-content: space-between;
  margin: 0.5rem 1rem;
  height: 100%;
`;

const MobileWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0.5rem 0;
  width: 100%;
`;

const ItemName = styled.div`
  font-weight: 600;
  height: 100%;
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 0.5rem auto;
  padding: 0 0.5rem;
  width: 50%;
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
`;

const TagsContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0.5rem;
  display: flex;
  flex-wrap: wrap;
`;

const HeartFlurry = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;

const Heart = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;
  background: var(--salmon);
  transform: rotate(-45deg);
  animation: ${({ rotate, scale }) => `heartFlurry 1s ease-in-out forwards`};
  animation-delay: ${({ delay }) => `${delay}s`};
  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background: var(--salmon);
    border-radius: 50%;
  }
  &:before {
    top: -5px;
    left: 0;
  }
  &:after {
    top: 0;
    left: 5px;
  }
  @keyframes heartFlurry {
    0% {
      transform: scale(0) translate(-50%, -50%) rotate(0);
      opacity: 1;
    }
    100% {
      transform: ${({ scale, rotate }) =>
        `scale(${scale}) translate(-50%, -50%) rotate(${rotate})`};
      opacity: 0;
    }
  }
`;

const CatalogProduct = ({
  recId,
  product,
  section,
  sx,
  imageSx: imgSx,
  showTags,
  minWidth,
  reaction,
  onReaction,
  loading,
  isLastItem,
}) => {
  const { isMobile } = useViewport();
  const { id, name: pName, tags = [], images = [], catalogProductId } = product;
  const [showFlurry, setShowFlurry] = useState(false);
  const [hearts, setHearts] = useState([]);
  const isLoading = loading === null || !!loading;

  const generateHearts = () => {
    const numHearts = Math.floor(Math.random() * 8) + 12;
    const newHearts = Array.from({ length: numHearts }).map(() => ({
      top: `${Math.random() * 40 - 20}px`,
      left: `${Math.random() * 40 - 20}px`,
      size: Math.random() * 1 + 0.2,
      rotate: Math.random() * 360,
      delay: Math.random() * 0.5,
    }));
    setHearts(newHearts);
  };

  const handleSelectReaction = useCallback(
    (reaction = null) => {
      if (reaction === PRODUCT_REACTIONS.LIKE) {
        generateHearts();
        setShowFlurry(true);
        setTimeout(() => setShowFlurry(false), 1200);
      }
      onReaction(catalogProductId, reaction);
    },
    [onReaction, catalogProductId],
  );

  const { isNeutral, isLiked, isDisliked } = useMemo(() => {
    return {
      isNeutral: !reaction,
      isLiked: reaction === PRODUCT_REACTIONS.LIKE,
      isDisliked: reaction === PRODUCT_REACTIONS.DISLIKE,
    };
  }, [reaction]);

  const featureImageUri = useMemo(
    () => (images.length > 0 ? images[0].uri : product?.featureImageUri),
    [product],
  );

  const mealTypeTags = useMemo(() => {
    if (!Array.isArray(tags) || !tags?.length || !showTags) return null;
    return (
      tags
        .filter(
          ({ tag: { type: tagType } }) =>
            tagType?.toUpperCase() === TAG_TYPES.MEAL_TYPE,
        )
        .map(({ tag: { name } }) => name)
        .join(', ') || null
    );
  }, [tags, showTags]);

  const name = useMemo(() => {
    return mealTypeTags ? `${pName} (${mealTypeTags})` : pName;
  }, [pName, mealTypeTags]);

  const sortedTags = useMemo(() => {
    if (!Array.isArray(tags) || !tags?.length || !showTags) return [];
    return sort(
      tags.filter(
        ({ tag: { type: tagType } }) =>
          !tagType || tagType?.toUpperCase() === TAG_TYPES.DIETARY,
      ),
      ['tag.name'],
    );
  }, [tags]);

  const renderActions = () => {
    const LikeIcon = isLiked ? FavoriteIcon : FavoriteBorderIcon;
    const DislikeIcon = DoNotDisturbAltIcon;
    return (
      <Actions>
        <Zoom in={loading !== PRODUCT_REACTIONS.DISLIKE}>
          <IconButton
            onClick={() => handleSelectReaction(PRODUCT_REACTIONS.DISLIKE)}
            sx={{ color: isDisliked ? 'var(--salmon)' : 'var(--gray)' }}
            disableFocusRipple
            disabled={isLoading}
          >
            <DislikeIcon />
          </IconButton>
        </Zoom>
        <Zoom in={loading !== PRODUCT_REACTIONS.LIKE}>
          <IconButton
            onClick={() => handleSelectReaction(PRODUCT_REACTIONS.LIKE)}
            sx={{ color: isLiked ? 'var(--salmon)' : 'var(--gray)' }}
            disableFocusRipple
            disabled={isLoading}
            style={{ position: 'relative' }}
          >
            <LikeIcon />
            {isLiked && showFlurry && (
              <Grow in={isLiked} timeout={1000}>
                <HeartFlurry>
                  {hearts.map((heart, index) => (
                    <Heart
                      key={index}
                      style={{
                        top: heart.top,
                        left: heart.left,
                        transform: `scale(${heart.size}) rotate(${heart.rotate}deg)`,
                      }}
                      scale={heart.size}
                      rotate={heart.rotate}
                      delay={heart.delay}
                    />
                  ))}
                </HeartFlurry>
              </Grow>
            )}
          </IconButton>
        </Zoom>
      </Actions>
    );
  };

  const renderTag = ({ tag: { id, name, icon, color } }) => {
    return (
      <Tag
        key={id}
        label={name}
        sx={{
          marginRight: '5px',
          marginBottom: '5px',
          marginTop: 0,
          marginLeft: 0,
          fontSize: '0.75rem',
        }}
        icon={icon}
        color={color}
        size="small"
      />
    );
  };

  const renderImage = () => {
    const ImageContainerEl = isMobile ? Fragment : ImageContainer;
    const imageSx = isMobile
      ? {
          width: '30%',
          padding: 0,
          marginRight: '1rem',
        }
      : { padding: 0 };
    const displayImg = featureImageUri ? (
      <Image
        src={featureImageUri}
        alt={name}
        loading="lazy"
        sx={{
          width: '100%',
          aspectRatio: '1 / 1',
          borderRadiusTopLeft: '5px',
          borderRadiusTopRight: '5px',
          marginBottom: '0.5rem',
          zIndex: 0,
          opacity: isDisliked ? 0.3 : 1,
          cursor: 'default',
          ...imgSx,
        }}
        disableRipple
      />
    ) : (
      <EmptyState
        icon={ImageIcon}
        // message={name}
        sx={{
          backgroundColor: 'var(--lightGray)',
          width: '100%',
          minWidth: minWidth ?? '3rem',
          aspectRatio: '1 / 1',
          borderRadiusTopLeft: '5px',
          borderRadiusTopRight: '5px',
          marginBottom: '0.5rem',
          opacity: isDisliked ? 0.3 : 1,
          cursor: 'default',
          ...imgSx,
        }}
      />
    );
    return (
      <ImageContainerEl>
        <IconButton sx={imageSx} disableRipple>
          {displayImg}
        </IconButton>
        {!isMobile && (
          <TagsContainer>{sortedTags.map(renderTag)}</TagsContainer>
        )}
      </ImageContainerEl>
    );
  };

  const renderLoading = () => (
    <Progress
      variant="query"
      sx={{
        visibility: isLoading ? 'visible' : 'hidden',
        position: 'relative',
        top: 4,
        margin: 'auto',
        width: isMobile ? '72%' : '42%',
        height: '1px',
        ['& .MuiLinearProgress-bar']: {
          backgroundColor: 'var(--darkGray)',
        },
        backgroundColor: 'var(--gray)',
      }}
    />
  );

  if (isMobile) {
    return (
      <>
        <MobileWrapper key={recId} id={recId}>
          {renderImage(renderLoading())}
          <ItemName id={id}>{name}</ItemName>
          {renderActions()}
        </MobileWrapper>
        {sortedTags.map(renderTag)}
        {!isLastItem && <Divider sx={{ marginTop: '1rem' }} />}
      </>
    );
  }

  return (
    <Card key={recId} id={recId} variant={null} sx={sx}>
      {renderImage()}
      <Wrapper>
        <ItemName id={id}>{name}</ItemName>
      </Wrapper>
      {renderLoading()}
      {renderActions()}
    </Card>
  );
};

CatalogProduct.defaultProps = {
  sx: {
    maxWidth: '14rem',
    border: '1px solid var(--darkGray)',
    borderRadius: '12px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  imageSx: {},
  showTags: true,
  minWidth: '288px',
  reaction: null,
};

export default CatalogProduct;
