import { useState, useMemo, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import useQueryParams from '../../hooks/useQueryParams';
import useViewport from '../../hooks/useViewport';
import { Tabs } from '../../common';

const UserTabs = ({
  children,
  userIds,
  users,
  onSelectUser,
  showUserTabs = true,
}) => {
  const { isMobile } = useViewport();
  const { userId: uidQ } = useQueryParams();
  const { userId: uidP } = useParams();
  const uid = uidP || uidQ;
  const [activeTab, setActiveTab] = useState();

  const initRef = useRef(false);

  const userDisplayNames = useMemo(() => {
    const u = {};
    userIds.forEach((id) => {
      const user = users.find((u) => u.userId === id);
      u[id] = user.displayName;
    });
    return u;
  }, [userIds, users]);

  useEffect(() => {
    if (initRef.current) return;
    if (userIds.length > 0) {
      if (uid && userIds.includes(uid)) {
        initRef.current = true;
        setActiveTab(uid);
        return;
      }
      initRef.current = true;
      setActiveTab(userIds[0]);
    }
  }, [userIds, uid]);

  useEffect(() => {
    if (activeTab) {
      const user = users.find((u) => u.userId === activeTab) || null;
      onSelectUser(user);
    }
  }, [activeTab, users]);

  const userTabs = useMemo(() => {
    return userIds.map((id) => {
      return {
        id: id,
        label: userDisplayNames[id],
      };
    });
  }, [userIds, userDisplayNames]);

  const renderTab = ({ id, label }) => {
    return <Tabs.Tab key={id} value={id} label={label} />;
  };

  const renderTabs = () => {
    if (!showUserTabs || uidP) return null;
    return (
      <Tabs
        value={activeTab}
        onChange={(_, id) => setActiveTab(id)}
        tabs={userTabs}
        variant="scrollable"
        allowScrollButtonsMobile={isMobile}
        scrollButtons={false}
      >
        {userTabs.map(renderTab)}
      </Tabs>
    );
  };

  return activeTab ? (
    <>
      {renderTabs()}
      {children}
    </>
  ) : null;
};

export default UserTabs;
