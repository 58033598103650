import { ModalAdvanced, Divider, Error } from '../../common';
import PaymentMethods from '../PaymentMethods';
import usePaymentMethods from '../../hooks/usePaymentMethods';

const PaymentMethodsManagement = ({ accountId, onClose, isOpen }) => {
  const { loading, error, onUpdatePaymentMethod, onDeletePaymentMethod } =
    usePaymentMethods();

  const renderError = () => {
    return <Error header="Error" content={error} />;
  };

  const renderPaymentMethods = () => {
    return (
      <PaymentMethods
        showOptionsAs="list"
        showHeader={false}
        autoSelectPaymentMethod={false}
        onUpdatePaymentMethod={onUpdatePaymentMethod}
        onDeletePaymentMethod={onDeletePaymentMethod}
        loading={loading}
        fullWidthAddButton
      />
    );
  };

  const renderPaymentMethodsManagement = () => {
    return (
      <>
        <p>
          Add or remove payment methods for your account. You may also set a
          default payment method to be selected at checkout.
        </p>
        <Divider sx={{ marginTop: '1rem', marginBottom: '2rem' }} />
        {renderPaymentMethods()}
      </>
    );
  };

  const renderContent = () => {
    return <div>{renderPaymentMethodsManagement()}</div>;
  };

  return (
    <ModalAdvanced
      open={isOpen}
      title="Payment methods"
      onClose={onClose}
      actions={[
        {
          label: 'Close',
          onClick: onClose,
          loading,
        },
      ]}
    >
      {renderContent()}
      {!!error && renderError()}
    </ModalAdvanced>
  );
};

export default PaymentMethodsManagement;
