import { Fragment, useMemo, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import useViewport from '../../hooks/useViewport';
import useMe from '../../hooks/useMe';
import useAccount from '../../hooks/useAccount';
import { client } from '../../graphql';
import { signOutAuthUser, getAuthRoles } from '../../firebase/auth';
import {
  DrawerAdvanced,
  Avatar,
  Box,
  Menu,
  Dialog,
  Heading,
  Divider,
  IconButton,
  Spinner,
  Badge,
} from '../../common';
import HelpForm from '../HelpForm';
import SubscribeCTA from '../SubscribeCTA';
import {
  DASHBOARD,
  ORDERS,
  ACCOUNT,
  SETTINGS,
  DELINQUENT,
  SUBSCRIBE,
  SUBSCRIPTIONS,
  USER_PROFILES,
} from '../../routes';
import MenuIcon from '@mui/icons-material/Menu';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HelpIcon from '@mui/icons-material/Help';
import InventoryIcon from '@mui/icons-material/Inventory';
import LogoutIcon from '@mui/icons-material/Logout';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const { Item: MenuItem } = Menu;

const { HELP_URI } = process.env;

const MobileContainer = styled.div`
  padding: 0;
`;

const Nav = styled.nav`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

const SubItemLabel = styled.span`
  font-weight: 400;
`;

const SubscribeCtaWrapper = styled.div`
  position: absolute;
  bottom: 5rem;
  left: 0;
  right: 0;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

const Navigation = ({ isAuthenticated }) => {
  const { isMobile } = useViewport();
  const history = useHistory();
  const { me, loading: meLoading } = useMe();
  const {
    accountId,
    loading: accountLoading,
    isPastDue,
    hasTodos,
    hasSubscriptions,
    subscriptionUsers,
  } = useAccount();
  const loading = meLoading || accountLoading;

  const anchorEl = useRef(null);
  const [confirmation, setConfirmation] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [showHelpForm, setShowHelpForm] = useState(false);

  const handleToggleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleToggleShowHelpForm = () => {
    setShowHelpForm(!showHelpForm);
  };

  const handleNavigate = (route) => {
    if (route === HELP_URI) {
      const win = window.open(route, '_blank');
      win.focus();
    } else {
      if (isMobile) setIsOpen(false);
      history.push(route);
    }
  };

  const handleOpenMenu = (e) => {
    anchorEl.current = e.currentTarget;
    setIsOpen(true);
  };

  const handleCloseMenu = () => {
    setIsOpen(false);
    anchorEl.current = null;
  };

  const handleSignOutConfirm = () => {
    signOutAuthUser();
    client.clearStore();
    client.cache.reset();
  };

  const handleSignOut = async () => {
    setConfirmation({
      title: 'Confirm Log Out',
      message: 'Are you sure you want to log out?',
      onConfirm: handleSignOutConfirm,
      onCancel: () => setConfirmation(null),
      confirmText: 'Log out',
      cancelText: 'Cancel',
    });
  };

  const handleForceSignOut = async (m) => {
    setConfirmation({
      title: 'Unauthorized',
      message: m,
      onConfirm: handleSignOutConfirm,
      confirmText: 'Log out',
    });
  };

  useEffect(() => {
    if (!isAuthenticated || !me) return;
    (async () => {
      const { roles } = await getAuthRoles();
      if (!roles.includes('user')) {
        // Not an account user (accounts_users)
        const { APP_NAME } = process.env;
        const m = `Account ${me?.emailAddress} is not an authorized ${APP_NAME} user. Please login with an admin account. Contact ${APP_NAME} support if you need assistance.`;
        handleForceSignOut(m);
        return;
      }
    })();
  }, [isAuthenticated, me]);

  const hasUnsubscribedUsers = useMemo(() => {
    if (hasSubscriptions) {
      return !subscriptionUsers.every((u) => u.subscription);
    }
    return false;
  }, [hasSubscriptions, subscriptionUsers]);

  const renderHelpForm = () => {
    if (!showHelpForm) return null;
    return <HelpForm onClose={handleToggleShowHelpForm} />;
  };

  const renderConfirmationAlert = () => {
    if (!confirmation) return null;
    return <Dialog {...confirmation} />;
  };

  const renderButton = () => {
    const button = (
      <IconButton
        id="main-navigation"
        onClick={handleOpenMenu}
        sx={{
          marginRight: '1rem',
          color: 'var(--charcoal)',
          ['&:hover']: {
            color: 'var(--black)',
          },
        }}
      >
        <MenuIcon fontSize="large" />
      </IconButton>
    );
    const mobileBadgeProps = { overlap: 'circular' };
    if (!hasTodos && !isPastDue) return button;
    return (
      <Badge
        badgeContent={1}
        color="error"
        variant={isPastDue ? 'standard' : 'dot'}
        {...mobileBadgeProps}
      >
        {button}
      </Badge>
    );
  };

  const renderSubscribeCTA = () => {
    return (
      <SubscribeCtaWrapper>
        <SubscribeCTA />
        <span>
          <b>Want to save time?</b> Subscribe to 'set and forget'.
        </span>
      </SubscribeCtaWrapper>
    );
  };

  const renderUser = () => {
    return me ? (
      <Box
        key="user"
        sx={{
          display: 'flex',
          flex: '1 auto',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: '0.5rem',
          padding: '1rem',
        }}
        onClick={isMobile ? () => handleNavigate(ACCOUNT) : undefined}
      >
        <Avatar sx={{ fontWeight: 700 }}>{me?.initials.toLowerCase()}</Avatar>
        <Heading variant="h6" sx={{ fontSize: isMobile ? '1.25rem' : '1rem' }}>
          Hi, {me?.preferredName || me?.firstName}!
        </Heading>
      </Box>
    ) : (
      <Spinner key="user-spinner" message="Loading your profile …" />
    );
  };

  const menuItems = useMemo(() => {
    const items = [
      {
        id: 'user',
        component: renderUser(),
      },
      {
        id: 'divider-1',
        isDivider: true,
      },
      {
        id: 'dashboard',
        label: 'Menu',
        onClick: () => handleNavigate(DASHBOARD),
        icon: MenuBookIcon,
      },
      {
        id: 'orders',
        label: 'Orders',
        onClick: () => handleNavigate(ORDERS),
        icon: InventoryIcon,
      },
      {
        id: 'account',
        label: 'Account',
        icon: AccountCircleIcon,
        onClick: () => handleNavigate(ACCOUNT),
        items: [
          {
            id: 'account-profiles',
            label: 'My family',
            onClick: () => handleNavigate(ACCOUNT),
          },
          {
            id: 'account-settings',
            label: 'Security & payments',
            onClick: () => handleNavigate(SETTINGS),
          },
        ],
      },
      {
        id: 'help',
        label: 'Help',
        onClick: () => handleToggleShowHelpForm(),
        icon: HelpIcon,
      },
    ];
    if (isPastDue) {
      const accountIdx = items.findIndex((i) => i.id === 'account');
      items[accountIdx].items.unshift({
        id: 'past-due',
        label: 'Past due',
        color: 'var(--white)',
        backgroundColor: 'var(--red)',
        onClick: () => handleNavigate(DELINQUENT),
      });
    }
    if (!isMobile) {
      items.push({
        id: 'divider-2',
        isDivider: true,
      });
      items.push({
        id: 'sign-out',
        label: 'Log out',
        onClick: handleSignOut,
        icon: LogoutIcon,
      });
    }
    if (hasSubscriptions) {
      items.splice(5, 0, {
        id: 'subscriptions',
        label: 'Subscriptions',
        icon: FlashOnIcon,
        onClick: () => handleNavigate(SUBSCRIPTIONS),
        items: [
          {
            id: 'subscriptions-settings',
            label: 'Settings',
            onClick: () => handleNavigate(SUBSCRIPTIONS),
          },
          {
            id: 'user-profiles',
            label: 'Taste profiles',
            onClick: () => handleNavigate(USER_PROFILES),
          },
        ],
      });
    }
    if (hasUnsubscribedUsers) {
      const subscriptionsIdx = items.findIndex((i) => i.id === 'subscriptions');
      items[subscriptionsIdx].items.unshift({
        id: 'subscribe',
        label: 'Subscribe now',
        onClick: () => handleNavigate(SUBSCRIBE),
      });
    }
    if (isMobile && hasSubscriptions === false) {
      items.push({
        id: 'subscribe-cta',
        component: renderSubscribeCTA(),
      });
    }

    return items;
  }, [
    me,
    accountId,
    isPastDue,
    isMobile,
    hasSubscriptions,
    hasUnsubscribedUsers,
  ]);

  const renderSignIn = () => {
    return null;
  };

  const renderMenuItem = ({
    id,
    label,
    color,
    backgroundColor,
    component,
    onClick,
    isDivider,
    icon,
    items,
    isSubItem = false,
  }) => {
    if (isDivider) {
      return (
        <Divider key={id} sx={isMobile ? { marginBottom: '1rem' } : null} />
      );
    }
    if (!component) {
      const sx = {
        margin: '0.5rem 0',
        color: color || 'var(--charcoal)',
        fontWeight: 600,
      };
      if (backgroundColor) {
        sx.backgroundColor = backgroundColor;
        sx[':hover'] = {
          backgroundColor,
        };
      }
      if (isMobile) {
        sx.fontSize = '1.25rem';
      }
      const Icon = icon;
      const LabelEl = isSubItem ? SubItemLabel : Fragment;
      return (
        <MenuItem key={id} id={id} onClick={onClick} sx={sx}>
          {icon && <Icon sx={{ marginRight: '0.5rem' }} />}
          {isSubItem && <Box sx={{ marginLeft: '2rem' }} />}
          <LabelEl>{label}</LabelEl>
          {items?.length > 0 && <KeyboardArrowDownIcon />}
        </MenuItem>
      );
    }
    return component;
  };

  const renderDropdownMenu = () => {
    if (loading) return <Spinner />;
    return menuItems.map((m) => {
      if (m?.items?.length > 0) {
        const subItems = m.items.map((i) =>
          renderMenuItem({ ...i, isSubItem: true }),
        );
        const parentItem = renderMenuItem(m);
        return [parentItem, ...subItems];
      }
      return renderMenuItem(m);
    });
  };

  const renderMobileMenuItems = () => {
    if (loading) return <Spinner />;
    return menuItems.map((m) => {
      if (m?.items?.length > 0) {
        const subItems = m.items.map((i) =>
          renderMenuItem({ ...i, isSubItem: true }),
        );
        const parentItem = renderMenuItem(m);
        return [parentItem, ...subItems];
      }
      return renderMenuItem(m);
    });
  };

  const renderMenu = () => {
    return (
      <Menu
        anchorEl={anchorEl.current}
        open={isOpen}
        onClose={handleCloseMenu}
        onClick={handleCloseMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            border: '1px solid var(--gray)',
            width: '16rem',
            maxWidth: '100vw',
            // filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 3,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {renderDropdownMenu()}
      </Menu>
    );
  };

  const renderMobileFooter = () => {
    return (
      <>
        {renderMenuItem({
          id: 'divider-footer',
          isDivider: true,
        })}
        {renderMenuItem({
          id: 'sign-out',
          label: 'Log out',
          onClick: handleSignOut,
          icon: LogoutIcon,
        })}
      </>
    );
  };

  const renderMobileMenu = () => {
    return (
      <DrawerAdvanced
        open={isOpen}
        title="Account"
        anchor="left"
        container={MobileContainer}
        onClose={handleToggleIsOpen}
        footer={renderMobileFooter()}
      >
        {renderMobileMenuItems()}
      </DrawerAdvanced>
    );
  };

  const renderNav = () => {
    return (
      <Nav>
        {renderButton()}
        {isMobile ? renderMobileMenu() : renderMenu()}
        {renderHelpForm()}
        {renderConfirmationAlert()}
      </Nav>
    );
  };

  return isAuthenticated ? renderNav() : renderSignIn();
};

export default Navigation;
