import React from 'react';
import styled from '@emotion/styled';
import * as Sentry from '@sentry/react';
import cache from './graphql/cache';
import { Error, Debug, Button } from './common';
import { client as httpClient } from './http';
import RefreshIcon from '@mui/icons-material/Refresh';

const Emoji = styled.div`
  font-size: 7.5rem;
  margin: auto;
  margin-bottom: 1rem;
`;

const Wrapper = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 80%;
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  align-self: auto;
  align-items: center;
  vertical-align: middle;
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  border-radius: 6px;
`;

const LOG_API = '/logs/report';
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false, error: null, report: null,
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, info) {
    const { APP_NAME, DOMAIN } = process.env;
    const {
      languages, language, vendor, userAgent, product, platform, appVersion, appName, appCodeName,
    } = window.navigator;
    // const { message, stack } = error || {};
    const report = {
      info,
      error,
      env: { appName: APP_NAME, domain: DOMAIN },
      browser: { location: window.location,
        languages, language, vendor, userAgent, product, platform, appVersion, appName, appCodeName,
      },
      store: cache?.data?.data,
    };
    this.setState({ report });
    const isLocal = `${APP_NAME}`.includes('LOCAL');
    const isDev = process?.env?.NODE_ENV === 'development';
    if (isDev) {
      console.error(error);
    }
    if (!isLocal) {
      Sentry.captureException(report, 'fatal');
      this.handleLogReport(report);
    }
  }

  async handleLogReport(report) {
    const payload = report;
    const res = await httpClient.post({
      url: LOG_API,
      body: payload,
    });
    return res;
  }

  render() {
    const { children } = this.props;
    const { hasError, error, report } = this.state;
    const isDev = process?.env?.NODE_ENV === 'development';

    if (hasError) {
      // You can render any custom fallback UI
      return (
        <Container>
          <Wrapper>
            <Emoji>😳</Emoji>
            <Error header="Something went wrong">
              <p>We've logged the error and will be investigating to fix asap.</p>
              <Button
                onClick={() => window.location.reload(false)}
              >
                <RefreshIcon sx={{ marginRight: '0.5rem' }} />
                Reload App
              </Button>
              {isDev && (
                <>
                  <h3>{error.message}</h3>
                  <pre style={{ whiteSpace: 'pre-wrap' }}>{error.stack}</pre>
                </>
              )}
            </Error>
            {isDev && <Debug {...report} />}
          </Wrapper>
        </Container>
      );
    }
    return children;
  }
}

export default ErrorBoundary;
