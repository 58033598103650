import { Fragment, useState, useRef, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import useViewport from '../../hooks/useViewport';
import useQueryParams from '../../hooks/useQueryParams';
import useOrders from '../../hooks/useOrders';
import { ORDER_PRODUCTS_STATUSES } from '../../utils/Consts';
import { ORDERS } from '../../routes';
import {
  PageContainer,
  HelpForm,
  OrderSummary,
  OrderItems,
  OrderItemsEdit,
} from '../../components';
import { Button, Card, Heading, Dialog } from '../../common';

const Container = styled.div`
  padding: 0;
  margin: 0;
  margin-top: 3rem;
  padding-bottom: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 33.25rem;
  padding: 0;
  margin: 0;
`;

const MobileContentWrapper = styled.div`
  width: 90%;
`;

const Wrapper = styled.span`
  display: flex;
  flex: 1;
  align-items: center;
  align-content: center;
  justify-content: space-between;
`;

const Order = styled(Card)`
  border: 1px solid var(--darkGray);
  padding: 2.5rem;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  border-radius: 12px;
`;

const OrderInfo = styled.div`
  margin-bottom: 1rem;
  width: 100%;
  min-width: 50%;
`;

const OrderDetails = styled.div`
  margin: 0;
  margin-bottom: 0.25rem;
`;

const OrderStatus = styled(Order)`
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const OrderDetail = () => {
  const { isMobile } = useViewport();
  const init = useRef(false);
  const [helpFormOrderId, setHelpFormOrderId] = useState(null);
  const [showEditOrder, setShowEditOrder] = useState(false);
  const history = useHistory();
  const [confirmation, setConfirmation] = useState(null);
  const { orderId } = useParams();
  const { origin } = useQueryParams();
  const {
    order,
    loading,
    onCancelOrder,
    onCancelOrderItem,
    onAdjustOrderItem,
    refetchOrder,
  } = useOrders({ orderId });

  useEffect(() => {
    if (order) init.current = true;
  }, [order]);

  const handleGoToOrders = () => {
    const route = origin || ORDERS;
    history.push(route);
  };

  const handleToggleShowEditOrder = () => {
    setShowEditOrder((bool) => !bool);
  };

  const handleRequestHelpForOrder = (orderId) => {
    setHelpFormOrderId(orderId);
  };

  const handleCancelOrder = async (id) => {
    await onCancelOrder(id);
    await refetchOrder();
  };

  const handleConfirmCancelOrder = async (id = null) => {
    if (id) {
      setConfirmation({
        title: 'Confirm Order Cancellation',
        message:
          'Are you sure you want to cancel this order? This action cannot be undone.',
        onConfirm: () => handleCancelOrder(id),
        onCancel: () => setConfirmation(null),
        confirmText: 'Confirm cancellation',
        cancelText: 'Keep order',
      });
      return;
    }
    handleCancelOrder(id);
  };

  const handleEditOrderItems = async (revisedProducts, onConfirm, onError) => {
    const { products } = order;
    const revisedQty = [];
    const updateFns = [];

    revisedProducts.forEach((revisedProduct) => {
      const { id, quantity, status } = revisedProduct;
      const foundProduct = products.find((product) => product.id === id);
      const isBundleItem = foundProduct?.recId !== foundProduct?.orderProductId;
      if (!foundProduct || quantity < 0 || isBundleItem) return;
      const q =
        status === ORDER_PRODUCTS_STATUSES.CANCELLED
          ? 0
          : foundProduct?.quantity;
      if (quantity === q) {
        revisedQty.push(q);
        return;
      }
      revisedQty.push(quantity);
      if (quantity === 0) {
        updateFns.push(() => onCancelOrderItem(id));
        return;
      }
      updateFns.push(() => onAdjustOrderItem(id, quantity));
    });

    const areAllItemsZero = revisedQty.every((qty) => qty === 0);
    if (areAllItemsZero) {
      onConfirm({
        title: 'Confirm Order Cancellation',
        message:
          'All items in this order have been cancelled. This will cancel the order. This action cannot be undone.',
        onConfirm: () => handleCancelOrder(orderId),
        onCancel: () => onConfirm(null),
        confirmText: 'Confirm cancellation',
        cancelText: 'Keep order',
      });
      return;
    }

    const errors = [];
    await Promise.all(
      updateFns.map((fn) => fn().catch((err) => errors.push(err))),
    );
    if (errors.length > 0) {
      onError(
        'There was an error updating the order. Please try again. If this issue persists, please contact support.',
      );
    } else {
      await refetchOrder();
    }
    return errors.length === 0;
  };

  const renderHelpForm = () => {
    if (!helpFormOrderId) return null;
    return (
      <HelpForm
        orderId={helpFormOrderId}
        onClose={() => handleRequestHelpForOrder(null)}
      />
    );
  };

  const renderConfirmationAlert = () => {
    if (!confirmation) return null;
    return <Dialog {...confirmation} />;
  };

  const renderCancelOrderAction = (sx, isFullWidth, size) => {
    if (!order) return null;
    const { userCancellable } = order;
    if (!userCancellable) return null;
    return (
      <Button
        id="cancel-order-button"
        onClick={() => handleConfirmCancelOrder(orderId)}
        variant="outlined"
        size={size}
        sx={sx}
        fullWidth={isFullWidth}
      >
        Cancel order
      </Button>
    );
  };

  const renderEditOrderAction = (sx, isFullWidth, size) => {
    if (!order) return null;
    const { userAdjustable } = order;
    if (!userAdjustable) return null;
    return (
      <Button
        id="edit-order-button"
        onClick={handleToggleShowEditOrder}
        variant="outlined"
        size={size}
        sx={sx}
        fullWidth={isFullWidth}
      >
        Edit order
      </Button>
    );
  };

  const renderOrderStatus = () => {
    if (!order) return null;
    const { statusLabel, paymentStatusLabel } = order;
    if (!statusLabel) return null;
    return (
      <OrderStatus>
        <span>Status</span>
        <>
          {statusLabel} / {paymentStatusLabel}
          {/* {!isMobile && renderCancelOrderAction({ marginLeft: '1rem' }, false, 'small')} */}
        </>
      </OrderStatus>
    );
  };

  const renderOrder = () => {
    if (!order) return null;
    const { products } = order;
    const WrapperEl = isMobile ? Fragment : Wrapper;
    return (
      <Order sx={isMobile ? { padding: '1rem' } : {}}>
        <Card.Content>
          <WrapperEl>
            <OrderInfo>
              <OrderDetails>
                <OrderItems products={products} />
              </OrderDetails>
            </OrderInfo>
          </WrapperEl>
        </Card.Content>
      </Order>
    );
  };

  const renderSummary = () => {
    return (
      <Order sx={isMobile ? { padding: '1rem' } : {}}>
        <Card.Content>
          <OrderInfo>
            <OrderSummary orderId={orderId} order={order} />
          </OrderInfo>
        </Card.Content>
      </Order>
    );
  };

  const renderHeader = () => <Heading variant="h5">Order details</Heading>;

  const renderOrderItemsEdit = () => {
    if (!order || !showEditOrder) return null;
    const { products, userAdjustable } = order;
    if (!userAdjustable) return null;
    return (
      <OrderItemsEdit
        products={products}
        s
        onEdit={handleEditOrderItems}
        onClose={handleToggleShowEditOrder}
        loading={loading}
      />
    );
  };

  const renderContent = () => {
    const ContentWrapperEl = isMobile ? MobileContentWrapper : ContentWrapper;
    return (
      <>
        <ContentWrapperEl>
          {renderHeader()}
          {renderOrderStatus()}
          {renderOrder()}
          {renderSummary()}
          <Button
            id="back-to-orders-button"
            onClick={handleGoToOrders}
            sx={{ marginBottom: '1rem' }}
            fullWidth
          >
            Back to orders
          </Button>
          {renderEditOrderAction({ marginBottom: '1rem' }, true)}
          {renderCancelOrderAction({ marginBottom: '1rem' }, true)}
          <Button
            id="request-help-button"
            onClick={() => handleRequestHelpForOrder(orderId)}
            variant="outlined"
            fullWidth
          >
            Request help
          </Button>
        </ContentWrapperEl>
        {renderOrderItemsEdit()}
        {renderHelpForm()}
        {renderConfirmationAlert()}
      </>
    );
  };

  return (
    <PageContainer
      pageTitle="Order details"
      loading={loading && !init.current}
      showPageTitle={false}
      container={Container}
    >
      {renderContent()}
    </PageContainer>
  );
};

export default OrderDetail;
