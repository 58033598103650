import { useState, useMemo, forwardRef } from 'react';
import InputMask from 'react-input-mask';
import { TextField as MuiTextField } from '@mui/material';
import IconButton from '../IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const TextField = forwardRef(({ icon, iconProps, ...props }, ref) => {
  const [type, setType] = useState(props.type);
  const isPasswordType = (props.type === 'password');
  const isTelType = (props.type === 'tel');

  const handleToggleType = () => {
    setType((type === 'password') ? 'text' : 'password');
  };

  const handleChange = (e) => {
    if (isTelType) {
      e.target.value = e.target.value.replace(/\D/g, '');
    }
    if (props.onChange) props.onChange(e);
  };

  const renderIconEl = () => (type === 'password')
    ? <VisibilityOffIcon />
    : <VisibilityIcon />;

  const overrides = useMemo(() => {
    let params = null;
    if (isPasswordType) {
      params = {
        type,
        InputProps: {
          endAdornment: (
            <InputAdornment position={props.position}>
              <IconButton onClick={handleToggleType} tabIndex={-1}>
                {renderIconEl()}
              </IconButton>
            </InputAdornment>
          )
        }
      };
    }
    if (icon) {
      const IconEl = icon;
      params = {
        type,
        InputProps: {
          endAdornment: (
            <InputAdornment>
              <IconEl {...iconProps} />
            </InputAdornment>
          )
        }
      };
    }
    else if (isTelType) {
      params = {
        type,
        InputProps: {
          inputComponent: InputMask,
          inputProps: {
            mask: '+1 (999) 999-9999',
          },
        },
      };
    }
    return params;
  }, [type, props.value]);

  return (
    <MuiTextField
      ref={ref}
      {...props}
      {...overrides}
      onChange={handleChange}
    />
  );
});

TextField.defaultProps = {
  variant: 'outlined',
  position: 'end',
  icon: null,
  iconProps: null,
};

export default TextField;
