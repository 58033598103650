import { Fragment, useState, useMemo, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import useViewport from '../../hooks/useViewport';
import useOrders from '../../hooks/useOrders';
import useAccount from '../../hooks/useAccount';
import { ORDER_ID, constructRouteWithParams } from '../../routes';
import { PageContainer, HelpForm, OrderFilters } from '../../components';
import {
  Heading,
  Button,
  Currency,
  Tabs,
  Tag,
  Link,
  Box,
  Menu,
  EmptyState,
} from '../../common';
import { formatDateTime } from '../../utils';
import {
  ORDER_STATUSES,
  ORDER_STATUS_LABELS,
  PAYMENT_STATUSES,
  PAYMENT_STATUS_LABELS,
} from '../../utils/Consts';
import ReceiptIcon from '@mui/icons-material/Receipt';
import TuneIcon from '@mui/icons-material/Tune';
import Sticky from 'react-stickynode';

const MenuContentWrapper = styled.div`
  display: flex;
  width: 100vw;
  align-items: center;
  > * {
    flex-shrink: 0;
  }
  > *:first-child {
    // Optional left content, it will take up the space it needs based on its content
    flex-grow: 0;
  }
  > *:nth-child(2) {
    // Main content, it will grow but has a max-width to restrict its size
    flex-grow: 1;
    flex-basis: 0; // This means it doesn't start with a default size before growing
    max-width: calc(
      100vw - 17em
    ); // Adjust the subtracted value based on the expected size of left/right content
  }
  > *:last-child {
    // Optional right content, it will take up the space it needs based on its content
    flex-grow: 0;
    margin-left: auto;
  }
`;

const MenuBarContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(15px);
  padding: 0.5rem 0;
  z-index: 10000;
`;

const MenuBarWrapper = styled.div`
  padding: 0 1.5rem;
`;

const MobileMenuBarWrapper = styled.div`
  width: 100vw;
`;

const ContentWrapper = styled.div`
  width: 80vw;
  max-width: 1200px;
`;

const MobileContentWrapper = styled.div`
  margin-bottom: 2rem;
`;

const OrderDate = styled(Heading)`
  margin-bottom: 1rem;
`;

const UserDisplayName = styled.span`
  font-size: 1rem;
  font-weight: 700;
`;

const TabWrapper = styled.div`
  margin-bottom: 2rem;
`;

const Wrapper = styled.span`
  display: flex;
  flex: 1;
  align-items: center;
  align-content: center;
  justify-content: space-between;
`;

const PaddingWrapper = styled(Wrapper)`
  padding: 4rem 2rem 3rem 2rem;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
`;

const MenuContainer = styled(Box)`
  padding: 0 3rem 4rem;
  margin-top: 8rem;
  margin-bottom: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MobileContainer = styled(Box)`
  padding: 1rem;
  padding-bottom: 3rem;
  margin-top: 7rem;
  margin-bottom: 2rem;
  overflow: visible;
`;

const Orders = () => {
  const { isMobile } = useViewport();
  const history = useHistory();
  const [helpFormOrderId, setHelpFormOrderId] = useState(null);
  const {
    orders,
    aggregations,
    loading,
    hasMore,
    fetchMoreLoading,
    onFetchMore,
    PENDING,
    COMPLETED,
    FILTERED,
    filters,
    filterCount,
    showFilters,
    onToggleFiltersView,
    onSetFilters,
  } = useOrders();
  const { aUsers, locationIds, locations } = useAccount();
  const [activeTab, setActiveTab] = useState(PENDING);
  const [anchorEl, setAnchorEl] = useState({});
  const [positionFromTop, setPositionFromTop] = useState(
    window?.document?.getElementById('main-header')?.getBoundingClientRect()
      ?.bottom,
  );

  useEffect(() => {
    const handleResize = () => {
      const h =
        window?.document?.getElementById('main-header')?.getBoundingClientRect()
          ?.bottom || 0;
      if (positionFromTop !== h) setPositionFromTop(h);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [positionFromTop]);

  const containerPadding = useMemo(() => {
    if (!isMobile) return 0;
    let m = 0;
    return m;
  }, [isMobile]);

  const containerEl = useMemo(() => {
    return isMobile
      ? (p) => (
          <MobileContainer
            {...p}
            sx={{ ...p?.sx, paddingTop: `${containerPadding}rem` }}
          />
        )
      : MenuContainer;
  }, [isMobile, containerPadding]);

  useEffect(() => {
    if (orders.pending.length === 0 && orders.completed.length > 0) {
      setActiveTab(COMPLETED);
    }
  }, [orders]);

  const { dates, groupedOrdersIdx } = useMemo(() => {
    const d = [];
    const gOrders = {};
    orders[activeTab].forEach((order, idx) => {
      const { deliveryDate } = order;
      const date = formatDateTime({
        dateTime: deliveryDate,
        format: 'YYYY-MM-DD',
      });
      if (!d.includes(date)) d.push(date);
      if (!gOrders[activeTab]) gOrders[activeTab] = {};
      if (!gOrders[activeTab][date]) gOrders[activeTab][date] = [];
      gOrders[activeTab][date].push(idx);
    });
    // d.sort();
    // if (activeTab === COMPLETED || activeTab === FILTERED) d.reverse();
    d.forEach((date) => {
      gOrders[activeTab][date].sort((a, b) => {
        const orderA = orders[activeTab][a];
        const orderB = orders[activeTab][b];
        const userNamesA = orderA.userIds.map((userId) => orderA.users[userId]);
        const userNamesB = orderB.userIds.map((userId) => orderB.users[userId]);
        const nameA = userNamesA.join(', ');
        const nameB = userNamesB.join(', ');
        if (nameA === nameB) return orderA.id.localeCompare(orderB.id);
        return nameA.localeCompare(nameB);
      });
    });
    return { dates: d, groupedOrdersIdx: gOrders };
  }, [orders, activeTab]);

  const filterOptions = useMemo(() => {
    const users = aUsers.map((u, idx) => {
      return {
        id: u.userId,
        sortOrder: idx + 1,
        label: u.displayName,
        type: 'checkbox',
      };
    });
    const aLocations = locationIds.map((id) => {
      return {
        id,
        label: locations[id].name,
        type: 'checkbox',
      };
    });
    const dateRange = [];
    const statuses = [
      {
        id: PAYMENT_STATUSES.UNPAID,
        label: PAYMENT_STATUS_LABELS[PAYMENT_STATUSES.UNPAID],
        type: 'checkbox',
      },
      {
        id: PAYMENT_STATUSES.PAID,
        label: PAYMENT_STATUS_LABELS[PAYMENT_STATUSES.PAID],
        type: 'checkbox',
      },
      {
        id: PAYMENT_STATUSES.REFUNDED,
        label: PAYMENT_STATUS_LABELS[PAYMENT_STATUSES.REFUNDED],
        type: 'checkbox',
      },
      {
        id: PAYMENT_STATUSES.VOID,
        label: PAYMENT_STATUS_LABELS[PAYMENT_STATUSES.VOID],
        type: 'checkbox',
      },
    ];

    // Merge in the filter options from the aggregations
    for (const key in aggregations[COMPLETED]) {
      const a = aggregations[COMPLETED][key];
      switch (key) {
        case 'users':
          a.forEach((u) => {
            const { label, value } = u;
            const found = users.find((o) => o.id === value);
            if (!found) {
              users.push({
                id: value,
                sortOrder: users.length + 1,
                label,
                type: 'checkbox',
              });
            }
          });
          break;
        case 'locations':
          a.forEach((l) => {
            const { label, value } = l;
            const found = aLocations.find((o) => o.id === value);
            if (!found) {
              aLocations.push({
                id: value,
                label,
                type: 'checkbox',
              });
            }
          });
          break;
        default:
          break;
      }
    }

    return {
      users,
      locations: aLocations,
      dateRange,
      statuses,
    };
  }, [aUsers, locationIds, locations, aggregations]);

  const filterForms = useMemo(() => {
    const forms = [];
    if (filterOptions.users.length > 0) {
      forms.push({
        sectionTitle: 'Name',
        fieldName: 'users',
        // matchFieldPath: 'user.rosters.rosterId',
        options: filterOptions.users,
        isMulti: true,
        columns: 2,
        displayLimit: 6,
        getLabel: (showAllOptions) =>
          showAllOptions
            ? 'Show fewer account users'
            : 'Show all account users',
      });
    }
    if (filterOptions.locations.length > 0) {
      forms.push({
        sectionTitle: 'Location',
        fieldName: 'locations',
        // matchFieldPath: 'user.rosters.rosterId',
        options: filterOptions.locations,
        isMulti: true,
        columns: 2,
        displayLimit: 6,
        getLabel: (showAllOptions) =>
          showAllOptions ? 'Show fewer locations' : 'Show all locations',
      });
    }
    forms.push({
      sectionTitle: 'Date Range',
      fieldName: 'dateRange',
      type: 'dateRangePicker',
      columns: 1,
      // matchFieldPath: 'user.rosters.rosterId',
      // options: filterOptions.statuses,
      // isMulti: true,
      // columns: 2,
      // displayLimit: 10,
      // getLabel: (showAllOptions) => (
      //   showAllOptions
      //     ? 'Show fewer statuses'
      //     : 'Show all statuses'
      // ),
    });
    if (filterOptions.statuses.length > 0) {
      forms.push({
        sectionTitle: 'Status',
        fieldName: 'statuses',
        // matchFieldPath: 'user.rosters.rosterId',
        options: filterOptions.statuses,
        isMulti: true,
        columns: 2,
        displayLimit: 10,
        getLabel: (showAllOptions) =>
          showAllOptions ? 'Show fewer statuses' : 'Show all statuses',
      });
    }
    return forms;
  }, [filterOptions]);

  const filtersLabel = useMemo(
    () => (filterCount > 0 ? `Filters • ${filterCount}` : 'Filters'),
    [filterCount],
  );

  const buttons = useMemo(
    () => [
      {
        key: 'filters',
        label: filtersLabel,
        icon: <TuneIcon />,
        variant: 'outlined',
        onClick: onToggleFiltersView,
      },
    ],
    [filtersLabel, onToggleFiltersView],
  );

  const handleCloseMenu = (key) => {
    setAnchorEl({ ...anchorEl, [key]: null });
  };

  const renderDropdownMenu = (content, key) => {
    return (
      <Menu
        anchorEl={anchorEl[key]}
        open={!!anchorEl[key]}
        onClose={() => handleCloseMenu(key)}
        onClick={() => null}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            border: '1px solid var(--gray)',
            // filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {content}
      </Menu>
    );
  };

  const renderButton = (key, label, icon, variant, onClick, loading) => {
    const onClickHandler = onClick;
    const sx = { marginLeft: '1rem' };
    if (filterCount > 0) {
      sx.width = '8rem';
    }
    return (
      <Button
        onClick={onClickHandler}
        variant={variant}
        startIcon={icon}
        loading={loading}
        sx={sx}
      >
        {label}
      </Button>
    );
  };

  const renderButtonWithDropdown = ({
    key,
    label,
    icon,
    variant,
    content,
    onClick,
    loading = false,
  }) => {
    return (
      <span key={key}>
        {renderButton(key, label, icon, variant, onClick, loading)}
        {!!content && renderDropdownMenu(content, key)}
      </span>
    );
  };

  const handleSetActiveTab = (e, tab) => {
    setActiveTab(tab);
  };

  const handleGoToOrderDetail = (orderId) => {
    const route = constructRouteWithParams(ORDER_ID, { orderId });
    history.push(route);
  };

  const handleRequestHelpForOrder = (orderId) => {
    setHelpFormOrderId(orderId);
  };

  const renderMobileMenuContent = () => {
    if (!isMobile) return null;
    const headerInlinePortalEl = document.getElementById(
      'header-inline-portal',
    );
    const filterButton = (
      <Button
        onClick={onToggleFiltersView}
        variant="outlined"
        sx={{ width: '2.8rem', height: '2.8rem', padding: 0 }}
      >
        <TuneIcon />
        {filterCount > 0 && ` • ${filterCount}`}
      </Button>
    );
    if (!headerInlinePortalEl) {
      console.error(
        'Cannot set portal content filters for header-inline-portal',
      );
      return <Sticky>{filterButton}</Sticky>;
    }
    return createPortal(filterButton, headerInlinePortalEl);
  };

  const renderEmptyStateWithFilters = () => {
    return (
      <PaddingWrapper>
        <Heading as="h1">
          {"Uh oh! We currently don't have any items that match your search."}
        </Heading>
        <p>Try removing some filters to see the full menu.</p>
        <Button onClick={() => onSetFilters([])} fullWidth={isMobile}>
          Clear all filters
        </Button>
      </PaddingWrapper>
    );
  };

  const renderHelpForm = () => {
    if (!helpFormOrderId) return null;
    return (
      <HelpForm
        orderId={helpFormOrderId}
        onClose={() => handleRequestHelpForOrder(null)}
      />
    );
  };

  const renderOrder = (idx) => {
    const order = orders[activeTab][idx];
    const {
      id,
      itemLabel,
      total,
      currencyCode,
      statusLabel,
      paymentStatusLabel,
      userIds,
      users,
      userAdjustable,
      userCancellable,
    } = order;
    return (
      <Button
        key={id}
        id={id}
        onClick={() => handleGoToOrderDetail(id)}
        variant="outlined"
        sx={{
          width: isMobile ? '100%' : '18rem',
          padding: '1.5rem',
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
          marginBottom: '1rem',
          marginRight: '1rem',
          borderRadius: '12px',
        }}
        fullWidth={isMobile}
      >
        <Box sx={{ display: 'flex', width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              flexGrow: 1,
              minWidth: '60%',
              marginRight: '0.5rem',
            }}
          >
            <Box sx={{ textAlign: 'left' }}>
              {userIds.map((userId, idx) => (
                <UserDisplayName key={userId}>
                  {users[userId]}
                  {idx < userIds.length - 1 ? ', ' : null}
                </UserDisplayName>
              ))}
              <div>
                {itemLabel} for{' '}
                <Currency amount={total} currencyCode={currencyCode} />
              </div>
              <Link
                to={constructRouteWithParams(ORDER_ID, { orderId: id })}
                sx={{ marginTop: '1rem' }}
              >
                {userAdjustable || userCancellable
                  ? 'Review/Edit order'
                  : 'View details'}
              </Link>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexGrow: 1,
              justifyContent: 'flex-end',
              alignItems: 'flex-start',
              margin: 0,
            }}
          >
            <Tag
              label={paymentStatusLabel}
              sx={{ display: 'flex', margin: 0 }}
            />
          </Box>
        </Box>
      </Button>
    );
  };

  const renderDate = (date) => {
    const formattedDate = formatDateTime({
      dateTime: date,
      format: 'ddd, MMM D YYYY',
    });
    return (
      <Fragment key={date}>
        <OrderDate variant="h5">{formattedDate}</OrderDate>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', marginBottom: '2rem' }}>
          {groupedOrdersIdx?.[activeTab]?.[date]?.map(renderOrder)}
        </Box>
      </Fragment>
    );
  };

  const renderLoadMoreButton = () => {
    const { pending, completed } = orders;
    let offset = null;
    switch (activeTab) {
      case PENDING:
        offset = pending.length;
        break;
      case COMPLETED:
        offset = completed.length;
        break;
      case FILTERED:
        offset = orders[FILTERED].length;
        break;
      default:
        offset = 0;
        break;
    }
    const loading = fetchMoreLoading[activeTab];
    return (
      <Button
        variant="outlined"
        onClick={() => onFetchMore(activeTab, offset)}
        loading={loading}
        showLoadingLabel={loading}
        fullWidth
      >
        Load more
      </Button>
    );
  };

  const tabs = useMemo(() => {
    const t = [
      { id: PENDING, name: 'Upcoming', icon: null },
      { id: COMPLETED, name: 'Past', icon: null },
    ];
    const c = aggregations?.[FILTERED]?.scopeCount || 0;
    if (filterCount > 0) {
      t.push({ id: FILTERED, name: `Filtered (${c})`, icon: null });
    } else {
      if (orders.pending.length === 0 && orders.completed.length > 0) {
        setActiveTab(COMPLETED);
      } else {
        setActiveTab(PENDING);
      }
    }
    return t;
  }, [filterCount, aggregations?.[FILTERED]]);

  useEffect(() => {
    if (tabs.length === 3) {
      setActiveTab(FILTERED);
    } else {
      setActiveTab(PENDING);
    }
  }, [tabs]);

  const renderTab = ({ id, name, icon }) => {
    return <Tabs.Tab key={id} value={id} label={name} />;
  };

  const renderTabs = () => {
    return (
      <TabWrapper>
        <Tabs value={activeTab} onChange={handleSetActiveTab}>
          {tabs.map(renderTab)}
        </Tabs>
      </TabWrapper>
    );
  };

  const renderActions = () => {
    return (
      <MenuBarWrapper>{buttons.map(renderButtonWithDropdown)}</MenuBarWrapper>
    );
  };

  const renderMenuBar = () => {
    const ContentWrapperEl = isMobile
      ? MobileMenuBarWrapper
      : MenuContentWrapper;
    return (
      <>
        <MenuBarContainer id="menu-bar">
          <ContentWrapperEl>
            <div>{renderTabs()}</div>
            {!isMobile && <div>{renderActions()}</div>}
          </ContentWrapperEl>
        </MenuBarContainer>
      </>
    );
  };

  const renderEmptyState = (message) => (
    <EmptyState icon={ReceiptIcon} message={message} />
  );

  const renderFiltersView = () => {
    return (
      <OrderFilters
        filters={filters}
        forms={filterForms}
        onToggleFiltersView={onToggleFiltersView}
        onSetFilters={onSetFilters}
        isOpen={showFilters}
      />
    );
  };

  const renderContent = () => {
    const ContentWrapperEl = isMobile ? MobileContentWrapper : ContentWrapper;
    const hasOrders = orders[activeTab].length > 0;
    const hasMoreSet = hasMore[activeTab];
    return (
      <ContentWrapperEl>
        {renderFiltersView()}
        {hasOrders
          ? dates.map(renderDate)
          : activeTab === FILTERED && filterCount > 0
          ? renderEmptyStateWithFilters()
          : renderEmptyState('There are no orders here yet.')}
        {hasOrders && hasMoreSet && renderLoadMoreButton()}
        {renderHelpForm()}
        {isMobile && renderMobileMenuContent()}
      </ContentWrapperEl>
    );
  };

  const headerPortalEl = document.getElementById('header-portal');
  if (!headerPortalEl) {
    console.error('Header portal element not found');
    return <Sticky>{renderMenuBar()}</Sticky>;
  }

  return (
    <PageContainer
      pageTitle="Orders"
      loading={loading}
      showPageTitle={false}
      container={containerEl}
    >
      {renderContent()}
      {createPortal(renderMenuBar(), headerPortalEl)}
    </PageContainer>
  );
};

export default Orders;
