import { useMemo } from 'react';
import styled from '@emotion/styled';
import useViewport from '../../hooks/useViewport';
import { Stepper, Box, Button, Typography } from '../../common';
import WestIcon from '@mui/icons-material/West';
import { alignProperty } from '@mui/material/styles/cssUtils';

const { Step, StepLabel } = Stepper;

const Wrapper = styled.div`
  width: 100%;
  padding: 1rem;
  display: grid;
  grid-template-columns: 3rem auto 3rem;
  grid-template-areas: 'left steps right';
`;

const WrapperWithActions = styled(Wrapper)`
  grid-template-columns: 2rem 3fr 1fr;
  grid-template-areas: 'left steps actions';
`;

const StepperWrapper = styled(Box)`
  grid-area: steps;
  width: 100%;
  padding: 0;
  align-self: center;
`;

const Side = styled(Box)`
  display: flex;
  flex-grow: 0;
  align-items: center;
  justify-content: center;
`;

const LeftButton = styled(Side)`
  grid-area: left;
`;

const RightButton = styled(Side)`
  grid-area: right;
`;

const OnboardStepper = ({
  activeStep,
  steps,
  showBackButton,
  showActionsPortal,
  onGoBack,
  onGoToStep,
}) => {
  const { isMobile } = useViewport();

  const shouldShowBackButton = showBackButton && activeStep !== 0;

  const handleGoToStep = (step) => {
    if (onGoToStep) onGoToStep(step);
  };

  const handleGoBack = () => {
    if (onGoBack) onGoBack(activeStep);
  };

  const renderBackButton = () => {
    return (
      <Button onClick={handleGoBack} variant="text" startIcon={<WestIcon />} />
    );
  };

  const [StepperEl, stepperElParams] = useMemo(() => {
    return [
      isMobile ? Box : Stepper,
      isMobile
        ? {
            display: 'inline-flex',
            alignItems: 'space-around',
            justifyContent: 'space-around',
            width: '100%',
            height: '100%',
            // position: 'static',
            // variant: 'text',
            // steps: steps.length,
            // position: 'static',
            // sx: {
            //   justifyContent: 'center',
            //   '& .MuiMobileStepper-dot': {
            //     backgroundColor: 'var(--gray)'
            //   },
            //   '& .MuiMobileStepper-dotActive': {
            //     backgroundColor: 'var(--purple)'
            //   },
            // }
          }
        : { activeStep },
    ];
  }, [isMobile, activeStep, steps]);

  const renderNumberStep = (idx) => {
    const isActive = activeStep === idx;
    const stepNum = idx + 1;
    const size = '34px';
    return (
      <Box
        key={stepNum}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: size,
          height: size,
          borderRadius: '50%',
          color: 'var(--white)',
          backgroundColor: isActive ? 'var(--purple)' : 'var(--darkGray)',
          margin: '0 0.5rem',
        }}
      >
        <Typography>{stepNum}</Typography>
      </Box>
    );
  };

  const renderStep = ({ id, label, ...p }, idx) => {
    if (isMobile) return renderNumberStep(idx);

    const key = id || label;
    return (
      <Step key={key} {...p}>
        <StepLabel
          StepIconProps={{
            sx: {
              color: 'var(--darkGray)',
              ['&.Mui-active']: {
                color: 'var(--purple)',
              },
              ['&.Mui-completed']: {
                color: 'var(--darkGray)',
              },
            },
          }}
        >
          {label}
        </StepLabel>
      </Step>
    );
  };

  const renderActionsPortal = () => {
    return <Box id="actions-portal" />;
  };

  const WrapperEl = showActionsPortal ? WrapperWithActions : Wrapper;

  return (
    <WrapperEl>
      <LeftButton>{shouldShowBackButton && renderBackButton()}</LeftButton>
      <StepperWrapper>
        <StepperEl {...stepperElParams}>{steps.map(renderStep)}</StepperEl>
      </StepperWrapper>
      {!showActionsPortal && <RightButton />}
      {showActionsPortal && (
        <Box
          sx={{
            gridArea: 'actions',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            flexGrow: 0,
            flexShrink: 1,
          }}
        >
          {renderActionsPortal()}
        </Box>
      )}
    </WrapperEl>
  );
};

OnboardStepper.defaultProps = {
  showBackButton: false,
  showActionsPortal: false,
  activeStep: 0,
  steps: [{ label: 'Step 1' }, { label: 'Step 2' }, { label: 'Step 3' }],
};

export default OnboardStepper;
