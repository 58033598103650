import { Fragment, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import useViewport from '../../hooks/useViewport';
import useMe from '../../hooks/useMe';
import useAccount from '../../hooks/useAccount';
import useCart from '../../hooks/useCart';
import useGlobalContext from '../../hooks/useGlobalContext';
import { ORDERS, DASHBOARD } from '../../routes';
import {
  PageContainer,
  CartView,
  OrderSummary,
  PaymentMethods,
} from '../../components';
import {
  Heading,
  Divider,
  Button,
  Card,
  EmptyState,
  Error,
  SecondaryActionText,
} from '../../common';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

const Container = styled.div`
  padding: 0;
  margin: 0;
  margin-top: 3rem;
  padding-bottom: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MobileContainer = styled(Container)`
  padding: 1rem 0;
  padding-bottom: 3rem;
  margin-bottom: 3.5rem;
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 33.25rem;
  padding: 0;
  margin: 0;
`;

const MobileContentWrapper = styled.div`
  width: 90%;
`;

// TODO Temp adding for everyone; Add check for Meal Plan enrollment for accountsUsers
const MealPlanNote = styled.span`
  font-size: 0.8rem;
  color: var(--darkGray);
`;

const MobileFooterWrapper = styled.div`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  left: 1rem;
`;

const SectionWrapper = styled.div`
  padding: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const Success = styled(Card)`
  border: 1px solid var(--gray);
  padding: 2.5rem;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
`;

const Checkout = () => {
  const { isMobile } = useViewport();
  const history = useHistory();
  const [{ isEmbedded }] = useGlobalContext();

  const { me, loading: meLoading } = useMe();
  const { settings, loading: accountLoading } = useAccount({ redirect: true });
  const {
    transformedCart,
    quantity,
    summary,
    cartLoading,
    onClearCart,
    onRemoveCartItem,
    onUpdateCartItem,
    onCheckout,
    selectedPaymentMethod,
    onSetSelectedPaymentMethod,
    checkoutLoading: loading,
    success,
    errors,
  } = useCart({ validate: true });

  const handleSetSelectedPaymentMethod = (pmId) => {
    if (pmId !== selectedPaymentMethod) {
      onSetSelectedPaymentMethod(pmId);
    }
  };

  const allowBulkOrdering = useMemo(
    () => !!settings?.allowBulkOrdering,
    [settings],
  );

  // const handleGoToOrders = () => {
  //   history.push(ORDERS);
  // };

  const handleGoToDashboard = () => {
    history.push(DASHBOARD);
  };

  const renderSectionHeader = (text) => {
    return <Heading variant="h6">{text}</Heading>;
  };

  const renderError = () => {
    return (
      <SectionWrapper>
        <Error
          id="checkout-error"
          header="Checkout Error"
          content={errors?.message}
        />
      </SectionWrapper>
    );
  };

  const renderActions = () => {
    const WrapperEl = isMobile ? MobileFooterWrapper : Fragment;
    return quantity > 0 ? (
      <WrapperEl>
        <Button
          onClick={onCheckout}
          disabled={!selectedPaymentMethod || cartLoading || loading}
          loading={loading || cartLoading}
          fullWidth
        >
          Submit order
        </Button>
      </WrapperEl>
    ) : (
      <WrapperEl>
        <Button onClick={handleGoToDashboard} fullWidth>
          Go to menu
        </Button>
      </WrapperEl>
    );
  };

  const renderOrderSummary = () => {
    return (
      <SectionWrapper>
        <OrderSummary order={summary} />
      </SectionWrapper>
    );
  };

  const renderMealPlanNotice = () => {
    return (
      <SectionWrapper>
        <MealPlanNote>
          If applicable, any subsidies from your school will be applied after
          checkout.
        </MealPlanNote>
      </SectionWrapper>
    );
  };

  const renderCart = () => {
    return (
      <SectionWrapper>
        {renderSectionHeader('Cart')}
        <CartView
          cart={transformedCart}
          unavailableItems={errors?.unavailableItems}
          me={me}
          onClearCart={onClearCart}
          onRemoveCartItem={onRemoveCartItem}
          onUpdateCartItem={onUpdateCartItem}
          loading={cartLoading}
          showInDrawer={false}
          disableForm={loading}
        />
      </SectionWrapper>
    );
  };

  const renderEmptyCart = () => {
    return (
      <EmptyState
        icon={ShoppingCartIcon}
        message="You don't have any items in your cart."
      />
    );
  };

  const renderHeader = () => (
    <Heading as="h1" sx={{ fontSize: '1.5rem' }}>
      Checkout
    </Heading>
  );

  const renderPaymentMethods = () => {
    return (
      <PaymentMethods
        selectedPaymentMethodId={selectedPaymentMethod}
        onSelectPaymentMethod={handleSetSelectedPaymentMethod}
        formKey="selectedPaymentMethod"
        disableForm={loading}
      />
    );
  };

  const renderCheckout = () => {
    const CartWrapperEl = isMobile ? Fragment : Fragment;
    return quantity > 0 ? (
      <>
        <CartWrapperEl>{renderCart()}</CartWrapperEl>
        {/* <Divider /> */}
        {/* {renderCouponForm()} */}
        {!allowBulkOrdering && <Divider />}
        {!allowBulkOrdering && renderOrderSummary()}
        {!allowBulkOrdering && <Divider />}
        {!allowBulkOrdering && renderMealPlanNotice()}
        <Divider />
        {renderPaymentMethods()}
        {renderActions()}
      </>
    ) : (
      <>
        {renderEmptyCart()}
        {renderActions()}
      </>
    );
  };

  const renderSuccess = () => {
    const headingText = isEmbedded
      ? 'Order placed'
      : `Thank you, ${me?.preferredName || me?.firstName}!`;
    const message = isEmbedded
      ? 'Close modal to proceed'
      : 'Your order has been placed.';
    return (
      <>
        <Success>
          <Card.Content>
            <Heading variant="h5">{headingText}</Heading>
            <p>
              <b>{message}</b>
            </p>
          </Card.Content>
        </Success>
        {!isEmbedded && (
          <SecondaryActionText
            text="Need to review your orders?"
            actionLabel="Check your order history here."
            route={ORDERS}
          />
        )}
      </>
    );
  };

  const renderContent = () => {
    const ContentWrapperEl = isMobile ? MobileContentWrapper : ContentWrapper;
    return (
      <ContentWrapperEl>
        {!success && renderHeader()}
        {!success && renderCheckout()}
        {success && renderSuccess()}
        {errors?.message && renderError()}
      </ContentWrapperEl>
    );
  };

  return (
    <PageContainer
      pageTitle="Checkout"
      loading={accountLoading || meLoading}
      showPageTitle={false}
      container={isMobile ? MobileContainer : Container}
    >
      {renderContent()}
    </PageContainer>
  );
};

export default Checkout;
