import { useMemo } from 'react';
import Button from '../Button';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const ToggleButton = ({ isSelected = false, label, children, ...props }) => {
  const sx = useMemo(() => {
    const buttonSx = {
      ...(props?.sx || null),
      borderRadius: '12px',
    };
    if (isSelected) {
      buttonSx.backgroundColor = 'var(--lightGray)';
      buttonSx.borderColor = 'var(--charcoal)';
      buttonSx.fontWeight = 700;
    }
    return buttonSx;
  }, [isSelected, props?.sx]);

  return (
    <Button {...props} variant="outlined" sx={sx}>
      {isSelected && (
        <CheckCircleIcon sx={{ fontSize: '1rem', marginRight: '0.25rem' }} />
      )}
      {label || children}
    </Button>
  );
};

export default ToggleButton;
