import { gql } from '@apollo/client';
import {
  CORE_USER_FIELDS,
  CORE_COUPON_FIELDS,
  CONDIMENT_FIELDS,
  PRODUCT_CONDIMENT_FIELDS,
  MAIN_DISH_FIELDS,
  SIDE_DISH_FIELDS,
  BEVERAGE_FIELDS,
  BUNDLE_FIELDS,
  CATALOG_TAG_FIELDS,
  CART_ITEM_FIELDS,
  SUBSCRIPTION_FIELDS,
} from '../fragments';
// import { ORDER_PRODUCTS_STATUSES } from '../../utils/Consts';

export const GET_IS_AUTHENTICATED = gql`
  query getIsAuthenticated {
    isAuthenticated @client
  }
`;

export const GET_ME = gql`
  ${CORE_USER_FIELDS}
  query GetMe {
    me @client {
      ...CoreUserFields
    }
  }
`;

export const GET_USER_BY_ID = gql`
  ${CORE_USER_FIELDS}
  query GetUserById($id: uuid!) {
    me(id: $id) @client {
      id
    }
    users(where: { id: { _eq: $id } }) {
      ...CoreUserFields
    }
  }
`;

export const GET_MY_CART = gql`
  ${CART_ITEM_FIELDS}
  query GetMyCart {
    myCart {
      id
      updatedAt
      subtotalCents
      subtotalCurrency
      finalCents
      finalCurrency
      taxesCents
      taxesCurrency
      cartItems {
        date
        user {
          id
        }
        menuProduct {
          id
        }
        ...CartItemFields
        cartItems {
          ...CartItemFields
          cartItems {
            ...CartItemFields
          }
        }
      }
    }
  }
`;

export const VALIDATE_CART = gql`
  query GetMyCart {
    myCart {
      id
      validation {
        isValid
        unavailableItems {
          cartItemId: id
          message
        }
      }
    }
  }
`;

export const GET_ACCOUNT_CARTS = gql`
  ${CART_ITEM_FIELDS}
  query GetAccountCarts {
    accountCarts {
      id
      __typename
      carts {
        id
        updatedAt
        isSubscription: subscription
        cartItems {
          date
          user {
            id
          }
          menuProduct {
            id
          }
          ...CartItemFields
          cartItems {
            ...CartItemFields
            cartItems {
              ...CartItemFields
            }
          }
        }
        finalCents
        finalCurrency
        subtotalCents
        subtotalCurrency
        taxesCents
        taxesCurrency
        totalCents
        totalCurrency
      }
      finalCents
      finalCurrency
      subtotalCents
      subtotalCurrency
      taxesCents
      taxesCurrency
      totalCents
      totalCurrency
    }
  }
`;

export const VALIDATE_ACCOUNT_CARTS = gql`
  query ValidateAccountCarts {
    accountCarts {
      id
      carts {
        id
        updatedAt
        validation {
          isValid
          unavailableItems {
            cartItemId: id
            message
          }
        }
      }
    }
  }
`;

export const GET_ACCOUNT_TODOS = gql`
  query GetAccountTodos($accountId: ID) {
    accountTodos(accountId: $accountId, pageSize: 50) {
      pageInfo {
        hasNextPage
      }
      errors {
        code
        message
      }
      rowCount
      rows {
        id
        name
        description
        summary
        status
        userId
        resolved
        definition {
          id
          name
          description
          summary
          required
          blocking
          subjectType
          type
        }
        metadata
        createdAt
        subjectId
      }
    }
  }
`;

export const GET_MY_ACCOUNTS = gql`
  ${SUBSCRIPTION_FIELDS}
  query GetMyAccounts {
    accounts: userAccounts {
      id
      balance
      autoReload
      autoReloadAmount
      delinquentSince
      delinquentFor
      isArchived
      paymentReference
      settings
      reactions {
        id
        userId
        productId
        reaction
        # product {
        #   id
        #   catalogProductId
        #   name
        #   featureImageUri: featuredImageUri
        #   images {
        #     id
        #     uri
        #   }
        #   tags: productTags {
        #     tag {
        #       id
        #       name
        #       type
        #       icon
        #       color
        #     }
        #   }
        # }
      }
      users: accountUsers {
        id
        locationId
        roles
        isArchived
        settings
        subscription {
          ...SubscriptionFields
        }
        user {
          id
          avatarUri
          emailAddress
          phoneNumber
          firstName
          lastName
          middleName
          suffix
          preferredName
          pronouns
          rosters: activeRosterUsers {
            roster {
              id
              name
              description
              rosterGroup: group
              group @client
              term {
                id
                name
                startDateTime
                endDateTime
                location {
                  id
                  name
                  address {
                    id
                    stateProvince
                  }
                  organization {
                    id
                    name
                  }
                  settings @client
                  locationSettings: settings
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_MENUS = gql`
  query GetMenus($now: date!, $locationIds: [uuid!]) {
    menus(
      where: {
        isArchived: { _neq: true }
        locationId: { _in: $locationIds }
        _and: [{ startDate: { _lte: $now }, endDate: { _gte: $now } }]
      }
    ) {
      id
      name
      type
      timeZone
      startDate
      endDate
      startDateTime @client
      endDateTime @client
      isActive
      organization {
        id
        name
      }
      location {
        id
        name
      }
      products: menu_products {
        datesAvailable: availableDates
      }
    }
  }
`;

export const GET_MENU_PRODUCTS = gql`
  query GetMenuProducts(
    $locationIds: [uuid!]
    $startDate: date
    $endDate: date
  ) {
    menus(
      where: {
        isArchived: { _neq: true }
        locationId: { _in: $locationIds }
        _and: [
          { startDate: { _lte: $endDate } }
          { endDate: { _gte: $startDate } }
        ]
      }
    ) {
      id
      type
      locationId
      products: menu_products {
        id
        recId: id
        isVisible: visible
        product {
          id
          name
          description
          catalogProductId
          ... on MainDish {
            id
            type
          }
          featureImageUri: featuredImageUri
          images {
            id
            uri
          }
          tags: productTags {
            tag {
              id
              name
              type
              icon
              color
              isSubscriptionTag: subscriptionTag
            }
          }
        }
      }
    }
  }
`;

export const GET_MENU_BY_ID = gql`
  ${CONDIMENT_FIELDS}
  ${PRODUCT_CONDIMENT_FIELDS}
  ${MAIN_DISH_FIELDS}
  ${SIDE_DISH_FIELDS}
  ${BEVERAGE_FIELDS}
  ${BUNDLE_FIELDS}
  query GetMenuById($id: uuid!) {
    menus_by_pk(id: $id) {
      id
      name
      type
      timeZone
      startDate
      endDate
      startDateTime @client
      endDateTime @client
      isActive
      isArchived
      organization {
        id
        name
      }
      location {
        id
        name
        taxRate
      }
      products: menu_products {
        id
        recId: id
        quantity
        datesAvailable: availableDates
        isVisible: visible
        sortOrder
        section {
          id
          name
          icon
        }
        variant {
          id
          product {
            id
            name
            description
            price
            currencyCode
          }
        }
        product {
          id
          name
          description
          price: transitionPrice
          calculatedPrice: price
          currencyCode
          catalogProductId
          featureImageUri: featuredImageUri
          images {
            id
            uri
          }
          tags: productTags {
            tag {
              id
              name
              type
              icon
              color
            }
          }
          catalogProduct {
            ... on Condiment {
              ...CondimentDetails
            }
            ... on MainDish {
              ...MainDishDetails
              productCondiments {
                id
                default
                type
                productId
                productSelectionGroup {
                  id
                  productId
                  quantity
                  required
                  type
                  selectionGroup {
                    id
                    icon
                    name
                  }
                }
                condiment {
                  id
                  description
                  name
                }
              }
            }
            ... on SideDish {
              ...SideDishDetails
              productCondiments {
                id
                default
                type
                productId
                linkedId
                productSelectionGroup {
                  id
                  productId
                  quantity
                  required
                  type
                  selectionGroup {
                    id
                    icon
                    name
                  }
                }
                condiment {
                  id
                  description
                  name
                }
              }
            }
            ... on Beverage {
              ...BeverageDetails
            }
            ... on Bundle {
              ...BundleDetails
            }
            catalogTags {
              tag {
                id
                icon
                color
                name
                type
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ROSTERS = gql`
  query GetRosters($locationIds: [uuid], $dateTime: timestamptz!) {
    rosters(
      where: {
        isVisible: { _eq: true }
        isArchived: { _neq: true }
        term: {
          startDateTime: { _lte: $dateTime }
          endDateTime: { _gte: $dateTime }
          locationId: { _in: $locationIds }
          isArchived: { _neq: true }
        }
      }
    ) {
      id
      name
      description
      group
      sortOrder
      term {
        id
        name
        startDateTime
        endDateTime
      }
    }
  }
`;

export const GET_ORDER_BATCHES = gql`
  query GetOrderBatches($accountId: uuid!, $status: String) {
    order_batches(
      where: { accountId: { _eq: $accountId }, status: { _eq: $status } }
      order_by: { createdAt: desc }
    ) {
      id
      startDate
      endDate
      status
      paymentMethodId
      orders(
        where: { order: { outstandingCents: { _gt: 0 } } }
        order_by: { order: { deliveryDate: desc, id: asc } }
      ) {
        order {
          id
          deliveryDate
          createdAt
          status
          outstandingCents
          outstandingCurrency
          sponsoredCents
          sponsoredCurrency
          totalCents
          totalCurrency
          paymentStatus
          products {
            quantity
            user {
              id
              firstName
              preferredName
            }
          }
        }
      }
    }
  }
`;

// Uses elastic search — local dev env requires a running instance of elastic search
export const GET_ORDERS = gql`
  query GetOrders(
    $filters: QueryFiltersInput
    $sort: QuerySortDirectionEnum!
    $page: Int
    $pageSize: Int
  ) {
    myOrders: accountOrders(
      filters: $filters
      sorting: { field: "delivery_date", sort: $sort }
      page: $page
      pageSize: $pageSize
    ) {
      orders: rows {
        id
        createdAt
        updatedAt
        userAdjustable
        userCancellable
        status
        paymentStatus
        deliveryTimestamp
        deliveryDate
        finalCents
        finalCurrency
        quantity
        orderProducts {
          recId: id
          orderProductId
          user {
            id
            firstName
            preferredName
          }
        }
      }
      count: rowCount
      aggregations {
        field
        buckets {
          label
          value
          count
          total
        }
      }
      pageInfo {
        hasNextPage
        truncated
      }
    }
  }
`;

export const GET_ORDERS_BY_ID = gql`
  query GetOrdersById($id: [ID!]) {
    myOrders(id: $id) {
      id
      createdAt
      updatedAt
      userAdjustable
      userCancellable
      status
      paymentStatus
      deliveryTimestamp
      deliveryDate
      finalCents
      finalCurrency
      sponsoredCents
      sponsoredCurrency
      subtotalCents
      subtotalCurrency
      totalCents
      totalCurrency
      outstandingCents
      outstandingCurrency
      receivedCents
      receivedCurrency
      taxesCents
      taxesCurrency
      refundedCents
      refundedCurrency
      voidedCents
      voidedCurrency
      orderProducts {
        id
        recId: id
        orderProductId
        name
        quantity
        deliveryDate
        status
        finalCents
        finalCurrency
        subtotalCents
        subtotalCurrency
        totalCents
        totalCurrency
        voidedCents
        voidedCurrency
        user {
          id
          firstName
          preferredName
        }
        menu {
          location {
            id
            name
            organization {
              id
              name
            }
            address {
              id
              city
              stateProvince
            }
          }
        }
      }
    }
  }
`;

export const GET_COUPON_BY_REDEMPTION_CODE = gql`
  ${CORE_COUPON_FIELDS}
  query GetCouponByRedemptionCode($redemptionCode: String!) {
    coupons(
      where: {
        redemptionCode: { _ilike: $redemptionCode }
        isArchived: { _neq: true }
      }
    ) {
      ...CoreCouponFields
    }
  }
`;

export const GET_LISTED_SCHOOLS_BY_STATE = gql`
  query GetListedSchoolsByState(
    $state: String
    $query: String
    $locationId: String
    $limit: Int
  ) {
    listedSchools(
      state: $state
      query: $query
      locationId: $locationId
      limit: $limit
    ) {
      id
      name
      city
      stateProvince
      locationId
      digest
    }
  }
`;

export const GET_SUBSCRIPTION_TAGS = gql`
  query GetSubscriptionTags {
    tags(where: { subscriptionTag: { _eq: true } }, order_by: { name: desc }) {
      id
      name
    }
  }
`;
