import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { CREATE_USER, UPDATE_USER, CREATE_ACCOUNTS_USERS } from '../graphql/mutations';
import { client as httpClient } from '../http';
import { ROSTER_GROUPS } from '../utils/Consts';
import { cleanInputs } from '../utils';
import useAccount from './useAccount';

const { GRADE, HOMEROOM, MEAL_PERIOD } = ROSTER_GROUPS;

const USER_FIELDS = [
  'avatarUri',
  'pronouns',
  'preferredName',
  'firstName',
  'middleName',
  'lastName',
  'suffix',
  'phoneNumber',
];

const useUpsertAccountUser = () => {
  const [loading, setLoading] = useState(false);
  const [createUser] = useMutation(CREATE_USER);
  const [createAccountsUsers] = useMutation(CREATE_ACCOUNTS_USERS);
  const [updateUser] = useMutation(UPDATE_USER);
  const { accountId, refetch } = useAccount();

  const handleArchiveAccountUser = async ({ userId, recId, isArchived, locationId = null }, cb) => {
    if (!userId) {
      console.error('Missing user id in handleArchiveAccountUser()', { userId, recId, isArchived, locationId });
      return null;
    }
    const payload = {
      recId,
      isArchived,
      locationId,
    };
    const res = await httpClient.patch({
      url: `/accounts/${accountId}/users/${userId}`,
      body: payload,
    });
    await refetch();
    if (cb) cb(res);
    return res;
  };

  const handleUpdateUser = async (vals) => {
    if (!vals?.id) {
      console.error('Missing user id in handleUpdateUser()', vals);
      return null;
    }
    const u = {};
    USER_FIELDS.forEach((key) => {
      u[key] = vals[key] || null;
    });
    return updateUser({ variables: { id: vals?.id, user: cleanInputs(u) } });
  };

  const handleUpdateAccountUser = async (vals) => {
    const rosterIds = vals?.rosterIds || [];
    if (rosterIds.length === 0) {
      if (vals[`rosterId:${GRADE}`]) rosterIds.push(vals[`rosterId:${GRADE}`]);
      if (vals[`rosterId:${HOMEROOM}`]) rosterIds.push(vals[`rosterId:${HOMEROOM}`]);
      if (vals[`rosterId:${MEAL_PERIOD}`]) rosterIds.push(vals[`rosterId:${MEAL_PERIOD}`]);
    }
    const accountUser = {
      recId: vals?.id,
      locationId: vals?.locationId || null,
      rosterIds,
    };
    const userId = vals?.user?.id;
    if (!userId) {
      console.error('Missing user id in handleUpdateAccountUser()', vals);
      return null;
    }
    const res = await httpClient.patch({
      url: `/accounts/${accountId}/users/${userId}`,
      body: accountUser,
    });
    return res;
  };

  const handleCreateUserAndAccountUser = async (vals) => {
    let res = null;
    let auRes = null;
    const u = {};
    USER_FIELDS.forEach((key) => {
      u[key] = vals.user?.[key] || null;
    });
    const { data } = await createUser({ variables: { user: cleanInputs(u) } });
    const userId = data?.insert_users_one?.id;
    if (userId) {
      let roles = vals?.roles;
      if (!Array.isArray(vals.roles)) roles = vals.role ? [vals.role] : [];
      const accountUser = {
        accountId,
        userId,
        roles,
      };
      res = await createAccountsUsers({ variables: accountUser });
      if (res?.data?.insert_accounts_users_one?.id) {
        auRes = await handleUpdateAccountUser({
          ...vals,
          id: res?.data?.insert_accounts_users_one?.id,
          user: { ...vals.user, id: userId },
        });
      }
    }
    return { createAuRes: res, updateAuRes: auRes };
  };

  const handleUpsertAccountUser = async (vals, cb) => {
    setLoading(true);
    const user = {
      id: vals?.userId || null,
      avatarUri: vals?.avatarUri || null,
      pronouns: vals?.pronouns || null,
      preferredName: vals?.preferredName || null,
      firstName: vals?.firstName || null,
      middleName: vals?.middleName || null,
      lastName: vals?.lastName || null,
      suffix: vals?.suffix || null,
      phoneNumber: vals?.phoneNumber || null,
      emailAddress: vals?.emailAddress || null,
    };
    const aUser = {
      id: vals?.id || null,
      locationId: vals?.locationId || null,
      rosterIds: vals?.rosterIds || [],
      isArchived: false,
      user,
    };
    // TODO Handle adjusting roles
    if (!aUser.id && vals?.role) {
      aUser.roles = [vals?.role];
    }
    const isUpdate = !!(user?.id && aUser?.id);
    let res = null;
    if (isUpdate) {
      res = await Promise.all([
        handleUpdateUser(user),
        handleUpdateAccountUser(aUser),
      ]);
    } else {
      res = await handleCreateUserAndAccountUser({ ...aUser, user });
    }
    await refetch();
    setLoading(false);
    if (cb) cb(res);
    return res;
  };

  return {
    onArchiveAccountUser: handleArchiveAccountUser,
    onUpsertAccountUser: handleUpsertAccountUser,
    loading,
  };
};

export default useUpsertAccountUser;
