import { Image } from '../../common';

const logo = '/public/ordo-logo.svg';

const Logo = (props) => {
  return (
    <Image
      alt="Ordo"
      sx={{ width: '5rem' }}
      {...props}
      src={logo}
    />
  );
};

export default Logo;
