import { Fragment, useRef, useState, useMemo, useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import useViewport from '../../hooks/useViewport';
import useAccount from '../../hooks/useAccount';
import { client as httpClient } from '../../http';
import { ModalAdvanced, List, Divider, Switch, Checkbox } from '../../common';
import PaymentMethods from '../PaymentMethods';

const PosSettingsSchema = Yup.object().shape({
  paymentMethodId: Yup.string().required('Please select a payment method'),
});

const AccountPosSettings = ({ onClose, isOpen }) => {
  const { isMobile } = useViewport();
  const { account, accountId, aUsers, refetch: getAccount } = useAccount();
  const [userSettings, setUserSettings] = useState([]);
  const initRef = useRef(false);

  // TODO --- Temp bypassing gql by proxying calls to REST endpoint
  const handleUpdateAccountSettings = async ({ variables }) => {
    const { settings } = variables;
    await httpClient.patch({
      url: `/accounts/${accountId}/settings`,
      body: settings,
    });
  };

  const handleUpdateAccountUserSettings = async ({ variables }) => {
    const { recId, settings } = variables;
    const userId = aUsers.find((u) => u?.id === recId)?.user?.id;
    if (!userId) console.error('Unable to find user id for recId', recId);
    await httpClient.patch({
      url: `/accounts/${accountId}/users/${userId}/settings`,
      body: { ...settings, recId },
    });
  };
  // TODO ----

  const handleUpdateAccountPosSettings = async (vals) => {
    const { paymentMethodId } = vals;
    if (!paymentMethodId) return;
    // TODO --- Temp bypassing gql by proxying calls to REST endpoint
    // await Promise.all([
    //   updateAccountSettings({ variables: {
    //     id: accountId,
    //     settings: { posPaymentMethod: paymentMethodId },
    //   } }),
    //   ...userSettings.map(({ id, posEnabled }) => {
    //     return updateAccountUserSettings({ variables: {
    //       recId: id,
    //       settings: { posEnabled },
    //     }});
    //   })
    // ]);
    await Promise.all([
      handleUpdateAccountSettings({
        variables: {
          id: accountId,
          settings: { posPaymentMethod: paymentMethodId },
        },
      }),
      ...userSettings.map(({ id, posEnabled }) => {
        return handleUpdateAccountUserSettings({
          variables: {
            recId: id,
            settings: { posEnabled },
          },
        });
      }),
    ]);
    await getAccount(); // TODO Temp
    if (onClose) onClose();
  };

  const handleUserSettingChange = (id, bool) => {
    const updatedUserSettings = [...userSettings];
    const idx = updatedUserSettings.findIndex((user) => user.id === id);
    if (idx >= 0) {
      updatedUserSettings[idx].posEnabled = bool;
    } else {
      updatedUserSettings.push({ id, posEnabled: bool });
    }
    setUserSettings(updatedUserSettings);
  };

  const formik = useFormik({
    initialValues: {
      paymentMethodId: account?.settings?.posPaymentMethod || '',
    },
    validationSchema: PosSettingsSchema,
    onSubmit: handleUpdateAccountPosSettings,
    enableReinitialize: true,
  });

  const { values, errors, handleSubmit, isSubmitting, setFieldValue } = formik;

  useEffect(() => {
    const { posPaymentMethod } = account?.settings || {};
    if (posPaymentMethod && !values.paymentMethodId)
      setFieldValue('paymentMethodId', posPaymentMethod);
  }, [account]);

  const { posUsers } = useMemo(() => {
    const pUsers = aUsers.filter((au) => {
      const { isArchived, hasPosLocation } = au;
      if (isArchived || !hasPosLocation) return false;
      return true;
    });

    const initSettings = pUsers.map((au) => {
      const { id, settings } = au;
      const { posEnabled = false } = settings || {};
      return { id, posEnabled };
    });
    if (!initRef.current && accountId) {
      initRef.current = true;
      setUserSettings(initSettings);
    }
    return { posUsers: pUsers, initUserSettings: initSettings };
  }, [aUsers, accountId]);

  const renderPaymentMethods = () => {
    const key = 'paymentMethodId';
    return (
      <PaymentMethods
        onSelectPaymentMethod={(pmId) => setFieldValue(key, pmId)}
        selectedPaymentMethodId={values?.paymentMethodId || ''}
        showOptionsAs="select"
        showHeader={false}
        autoSelectPaymentMethod={false}
        formKey={key}
        errors={errors}
        fullWidthAddButton
      />
    );
  };

  const renderPosManagement = () => {
    const CheckEl = isMobile ? Switch : Checkbox;
    const sx = {
      width: '100%',
      display: 'flex',
      justifyContent: isMobile ? 'space-between' : 'flex-end',
      flexFlow: isMobile ? 'row' : 'row-reverse',
    };
    return (
      <>
        <p>
          Enable students/staff to order and purchase food at school with your
          choice of payment method.
        </p>
        <Divider sx={{ marginTop: '1rem', marginBottom: '2rem' }} />
        {renderPaymentMethods()}
        <List>
          {posUsers.map(({ id: recId, user }, idx) => {
            const isChecked = !!userSettings.find((au) => au.id === recId)
              ?.posEnabled;
            return (
              <Fragment key={user.id}>
                <List.Item
                  sx={sx}
                  onClick={() => handleUserSettingChange(recId, !isChecked)}
                >
                  <span>{user?.preferredName || user?.firstName}</span>
                  <CheckEl
                    checked={isChecked}
                    onChange={(e) =>
                      handleUserSettingChange(recId, e.target.checked)
                    }
                  />
                </List.Item>
                {idx < posUsers.length - 1 && <Divider />}
              </Fragment>
            );
          })}
        </List>
      </>
    );
  };

  const renderContent = () => {
    return <div>{renderPosManagement()}</div>;
  };

  return (
    <ModalAdvanced
      open={isOpen}
      title="Self-order settings"
      onClose={onClose}
      actions={[
        {
          label: 'Save settings',
          onClick: handleSubmit,
          disabled: isSubmitting,
          loading: isSubmitting,
        },
      ]}
    >
      {renderContent()}
    </ModalAdvanced>
  );
};

export default AccountPosSettings;
