import { InMemoryCache, makeVar } from '@apollo/client';
import { getResolvers } from './resolvers';

export const isAuthenticatedCache = makeVar(null);
export const authUserCache = makeVar(null);

const resolvers = getResolvers({ isAuthenticatedCache, authUserCache });
const cacheConfig = { ...resolvers };
const cache = new InMemoryCache(cacheConfig);

export default cache;
