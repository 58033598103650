import { Autocomplete as MuiAutocomplete } from '@mui/material';
import {
  createFilterOptions,
  autocompleteClasses,
} from '@mui/material/Autocomplete';
import styled from '@emotion/styled';
import { matchSorter } from 'match-sorter';
import useViewport from '../../hooks/useViewport';
import Box from '../Box';
import TextField from '../TextField';
import Popper from '../Popper';
// import Listbox from './Listbox';

const PopperStyled = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

const Autocomplete = ({
  id,
  label,
  placeholder,
  onChange,
  options,
  autocompleteProps,
  inputProps,
  textFieldProps,
  renderOption,
  filterOptionsCb,
  ...rest
}) => {
  const { isMobile } = useViewport();

  const handleFilterOptions = (o, e) => {
    const { inputValue } = e;
    if (!inputValue) return o;
    let fOptions = filterOptionsCb ? filterOptionsCb(o, e, matchSorter) : o;
    const words = inputValue.split(' ');
    const m = words.reduceRight(
      (options, word) =>
        matchSorter(options, word, {
          keys: ['label'],
          threshold: matchSorter.rankings.WORD_STARTS_WITH,
        }),
      fOptions,
    );
    return m;
  };

  const renderInput = (params) => (
    <TextField
      {...params}
      id={id}
      name={id}
      placeholder={placeholder}
      label={label}
      inputProps={{
        ...params.inputProps,
        ...inputProps,
      }}
      {...textFieldProps}
    />
  );

  const renderDropdownOption = (option) => {
    if (renderOption) {
      return renderOption(option);
    }
    return option.label;
  };

  const renderOptions = (props, option) => (
    <Box
      component="li"
      sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
      {...props}
      key={option.value}
    >
      {renderDropdownOption(option)}
    </Box>
  );

  const componentProps = isMobile
    ? {
        popper: {
          sx: {
            [`& .${autocompleteClasses.listbox}`]: {
              maxHeight: '8rem',
            },
          },
        },
      }
    : undefined;

  return (
    <MuiAutocomplete
      id={id}
      sx={{ width: '100%' }}
      onChange={onChange}
      options={options || []}
      getOptionLabel={(val) => {
        const item = options?.find((item) => item.value === val);
        return item?.label || '';
      }}
      isOptionEqualToValue={(option, value) => option.value === value}
      renderOption={renderOptions}
      renderInput={renderInput}
      filterOptions={handleFilterOptions}
      // renderGroup={(params) => params}
      PopperComponent={PopperStyled}
      componentsProps={componentProps}
      autoHighlight
      disableClearable
      disableListWrap
      {...rest}
      {...autocompleteProps}
    />
  );
};

Autocomplete.defaultProps = {
  autocompleteProps: null,
  inputProps: null,
  textFieldProps: null,
};

Autocomplete.createFilterOptions = createFilterOptions;

export default Autocomplete;
