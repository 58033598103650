import { useState } from 'react';
import styled from '@emotion/styled';
import useViewport from '../../hooks/useViewport';
import { Error, Button } from '../../common';
import { PageContainer, HelpForm } from '../../components';

const Container = styled.div`
  padding: 0;
  margin: 0;
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 33.25rem;
  padding: 0;
  margin: 0;
`;

const MobileContentWrapper = styled.div`
  width: 90%;
`;

const AccountSuspended = () => {
  const { isMobile } = useViewport();
  const [showHelpForm, setShowHelpForm] = useState(false);

  const handleNavigate = () => {
    const { HELP_URI } = process.env;
    const win = window.open(HELP_URI, '_blank');
    win.focus();
  };

  const renderHelpForm = () => (
    <HelpForm onClose={() => setShowHelpForm(false)} />
  );

  const renderMessage = () => {
    return (
      <>
        <p>This usually means the login you used is for administrative functions in our other apps. To place orders for yourself and family, please register a new account. You may contact support for assistance.</p>
        {showHelpForm && renderHelpForm()}
      </>
    );
  };

  const renderContent = () => {
    const ContentWrapperEl = isMobile ? MobileContentWrapper : ContentWrapper;
    return (
      <ContentWrapperEl>
        <Error header="No accounts found for this app" content={renderMessage()} />
        <Button
          sx={{ marginTop: '1rem' }}
          onClick={() => handleNavigate()}
          fullWidth={true}
          variant="outlined"
        >
            Visit Help Center
        </Button>
        <Button
          sx={{ marginTop: '1rem' }}
          onClick={() => setShowHelpForm(true)}
          fullWidth={true}
        >
            Contact Support
        </Button>
      </ContentWrapperEl>
    );
  };

  return (
    <PageContainer
      pageTitle='No accounts found'
      loading={false}
      showPageTitle={true}
      container={Container}
    >
      {renderContent()}
    </PageContainer>
  );
};

export default AccountSuspended;
