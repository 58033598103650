import React, { useState, useEffect, createContext, useContext, useRef } from 'react';

const OnlineStatusContext = createContext(navigator.onLine);

export const OnlineStatusProvider = ({ children }) => {
  const [onlineStatus, setOnlineStatus] = useState(navigator.onLine);
  const offlineCount = useRef(0);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data && event.data.type === 'ONLINE_STATUS') {
        if (event.data.online) {
          offlineCount.current = 0;
          setOnlineStatus(true);
        } else {
          offlineCount.current += 1;
          if (offlineCount.current >= 3) {
            setOnlineStatus(false);
          }
        }
      }
    };

    navigator?.serviceWorker?.addEventListener('message', handleMessage);

    return () => {
      navigator?.serviceWorker?.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <OnlineStatusContext.Provider value={onlineStatus}>
      {children}
    </OnlineStatusContext.Provider>
  );
};

export const useOnlineStatus = () => useContext(OnlineStatusContext);

export default useOnlineStatus;
