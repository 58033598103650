import { Button as MuiButton } from '@mui/material';
import { ButtonGroup } from '@mui/material';
import Spinner from '../Spinner';

const Button = ({ showLoadingLabel, loading, ...props }) => {
  const { variant, children } = props;
  return !loading ? (
    <MuiButton {...props} />
  ) : (
    <MuiButton {...props} disabled={loading}>
      <Spinner
        size={16}
        message={showLoadingLabel ? children : null}
        sx={{
          color: variant === 'contained' ? 'var(--white)' : 'var(--gray)',
        }}
        inline={showLoadingLabel ? 'center' : false}
      />
    </MuiButton>
  );
};

Button.Group = ButtonGroup;

Button.defaultProps = {
  variant: 'contained',
  loading: false,
  showLoadingLabel: false,
};

export default Button;
