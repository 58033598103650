import { DateRangePicker as MuiDateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const DateRangePicker = ({ dateAdapter = AdapterDayjs, ...props }) => {
  return (
    <LocalizationProvider dateAdapter={dateAdapter}>
      <MuiDateRangePicker
        slots={{ field: SingleInputDateRangeField }}
        slotProps={{
          actionBar: { actions: ['clear'] },
        }}
        {...props}
      />
    </LocalizationProvider>
  );
};

export default DateRangePicker;
