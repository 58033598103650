import { useEffect, useMemo, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { backendContext } from '../graphql/client';
import { GET_MENU_PRODUCTS } from '../graphql/queries';
import {
  UPDATE_PRODUCT_REACTION,
  REMOVE_PRODUCT_REACTION,
} from '../graphql/mutations';
import useSubscriptions from './useSubscriptions';
import { formatDateTime, isDateTimeAfter, sort } from '../utils';

const useProductReactions = () => {
  const [savingState, setSavingState] = useState({});

  const { users } = useSubscriptions();

  const [getMenuProducts, { data: menusData, loading }] =
    useLazyQuery(GET_MENU_PRODUCTS);
  const [upsertProductReaction] = useMutation(
    UPDATE_PRODUCT_REACTION,
    backendContext,
  );
  const [removeProductReaction] = useMutation(
    REMOVE_PRODUCT_REACTION,
    backendContext,
  );

  const handleUpdateProductReaction = async ({
    productId,
    userId,
    reaction = null,
    cb,
  }) => {
    setSavingState((prev) => ({
      ...prev,
      [userId]: {
        ...prev[userId],
        [productId]: reaction,
      },
    }));
    if (!reaction) {
      await removeProductReaction({
        variables: {
          productId,
          userId,
        },
      });
    } else {
      await upsertProductReaction({
        variables: {
          productId,
          userId,
          reaction: reaction.toLowerCase(),
        },
      });
    }
    setSavingState((prev) => ({
      ...prev,
      [userId]: {
        ...prev[userId],
        [productId]: false,
      },
    }));
    if (cb) cb({ productId, userId, reaction });
  };

  useEffect(() => {
    const locIds = [];
    let minTermStartDate = null;
    let maxTermEndDate = null;
    users.forEach((u) => {
      if (!u?.subscription) return;
      u?.locationIds.forEach((locId) => {
        if (!locIds.includes(locId)) locIds.push(locId);
        // Get the earliest term start date and the latest term end date
        if (u.locationTerms[locId]) {
          u.locationTerms[locId].forEach((term) => {
            const { startDateTime, endDateTime } = term;
            if (
              !minTermStartDate ||
              isDateTimeAfter({
                dateTime: startDateTime,
                afterDateTime: minTermStartDate,
                precision: 'day',
              })
            ) {
              minTermStartDate = startDateTime;
            }
            if (
              !maxTermEndDate ||
              !isDateTimeAfter({
                dateTime: endDateTime,
                afterDateTime: maxTermEndDate,
                precision: 'day',
              })
            ) {
              maxTermEndDate = endDateTime;
            }
          });
        }
      });
    });
    if (locIds.length === 0 || !minTermStartDate || !maxTermEndDate) return;

    const variables = {
      locationIds: locIds,
      startDate: formatDateTime({
        dateTime: minTermStartDate,
        format: 'YYYY-MM-DD',
      }),
      endDate: formatDateTime({
        dateTime: maxTermEndDate,
        format: 'YYYY-MM-DD',
      }),
    };

    (async () => {
      await getMenuProducts({ variables });
    })();
  }, [users]);

  const { products, userProducts } = useMemo(() => {
    const mp = [];
    const uProducts = {};

    menusData?.menus.forEach((menu) => {
      const locId = menu.locationId;
      const locUserIds = users
        .filter((u) => u.locationIds.includes(locId))
        .map((u) => u.userId);
      locUserIds.forEach((userId) => {
        if (!uProducts[userId]) uProducts[userId] = [];
      });
      menu.products.forEach((product) => {
        const {
          isVisible,
          product: { catalogProductId },
        } = product;
        if (
          !isVisible ||
          !catalogProductId ||
          mp.some(({ product: p }) => p.catalogProductId === catalogProductId)
        )
          return;
        mp.push(product);
        const idx = mp.length - 1;
        locUserIds.forEach((userId) => {
          uProducts[userId].push(idx);
        });
      });
    });
    for (const userId in uProducts) {
      uProducts[userId] = sort(uProducts[userId], [
        (idx) => mp[idx]?.product?.name?.toLowerCase(),
        (idx) => mp[idx]?.product?.id,
      ]);
    }

    return {
      products: mp,
      userProducts: uProducts,
    };
  }, [menusData, users]);

  return {
    products,
    userProducts,
    onUpdate: handleUpdateProductReaction,
    loading,
    isSaving: savingState,
  };
};

export default useProductReactions;
