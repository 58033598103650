import { useMemo } from 'react';
import styled from '@emotion/styled';
import { Fade, Grow, Slide, Zoom } from '@mui/material';
import useViewport from '../../hooks/useViewport';
import Modal from '../Modal';
import Card from '../Card';
import Box from '../Box';
import Base from '../_Base';

const { ResponsiveHeader, ResponsiveFooter, ResponsiveScrollableArea } = Base;

// TODO Need to find better scalable solution
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  max-height: calc(var(--doc-height) - 8.75rem);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;

const ModalAdvanced = ({
  open,
  onClose,
  allowOutsideClickToClose,
  showCloseButton,
  forceOpen,
  title,
  headerRight,
  header,
  footer,
  actions,
  content,
  children,
  container,
  wrapper,
  containerId,
  wrapperId,
  transition,
  sx,
}) => {
  const { isMobile } = useViewport();

  const handleClose = () => {
    if (onClose && !forceOpen) {
      onClose();
    }
  };

  const TransitionEl = useMemo(() => {
    switch (transition) {
    case 'fade':
      return Fade;
    case 'grow':
      return Grow;
    case 'slide':
      return Slide;
    case 'zoom':
      return Zoom;
    default:
      return isMobile ? Fade : Zoom;
    }
  }, [isMobile, transition]);

  const renderHeader = () => {
    return (
      <ResponsiveHeader
        onClose={handleClose}
        title={title}
        header={header}
        headerRight={headerRight}
        showCloseButton={showCloseButton && !forceOpen}
      />
    );
  };

  const renderFooter = () => {
    return (
      <ResponsiveFooter
        actions={actions}
        footer={footer}
      />
    );
  };

  const renderContent = () => {
    const containerEl = (container === undefined)
      ? container
      : null;
    const wrapperEl = (wrapper !== undefined)
      ? wrapper
      : Wrapper;
    return (
      <ResponsiveScrollableArea wrapperId={wrapperId} containerId={containerId} container={containerEl} wrapper={wrapperEl} >
        {content || children}
      </ResponsiveScrollableArea>
    );
  };

  const renderModalContent = () => {
    return (
      <>
        {renderHeader()}
        {renderContent()}
        {renderFooter()}
      </>
    );
  };

  return (
    <Modal
      closeAfterTransition
      open={open}
      onClose={allowOutsideClickToClose ? handleClose : () => {}}
      sx={isMobile
        ? { height: '100vh', ...sx }
        : { maxHeight: 'calc(100vh - 30rem)', margin: '15rem 0', ...sx }
      }
      BackdropProps={isMobile
        ? { sx: { backgroundColor: 'var(--white)' } }
        : null
      }
    >
      <TransitionEl in={open}>
        <Box sx={{
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          height: isMobile ? '100vh' : 'inherit',
          maxHeight: isMobile ? '100vh' : 'calc(100vh - 30rem)',
          ['&:focus-visible']: {
            outline: 'none',
          },
        }}>
          <Card variant={null} sx={{
            maxWidth: '28rem',
            width: '100%',
            minWidth: '20rem',
            // borderRadius: 0,
            height: isMobile ? '100vh' : '100%',
          // maxHeight: isMobile ? '100vh' : 'calc(100vh - 30rem)',
          }}>
            {renderModalContent()}
          </Card>
        </Box>
      </TransitionEl>
    </Modal>
  );
};

ModalAdvanced.defaultProps = {
  open: true,
  forceOpen: false,
  showCloseButton: true,
  allowOutsideClickToClose: true,
  transition: null,
  sx: null,
};

export default ModalAdvanced;
