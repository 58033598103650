import { Button, Dialog as MuiDialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

const Dialog = ({ title, message, onConfirm, onCancel, confirmText, cancelText }) => {
  const handleCancel = () => {
    if (onCancel) onCancel();
  };

  const handleConfirm = () => {
    if (onConfirm) onConfirm();
    handleCancel(); // Close;
  };

  return (
    <MuiDialog
      open
      onClose={handleCancel}
    >
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {!!cancelText && (
          <Button onClick={handleCancel} variant="contained">
            {cancelText}
          </Button>
        )}
        <Button
          onClick={handleConfirm}
          variant="contained"
          sx={{ backgroundColor: 'var(--red)', color: 'var(--white)' }}
        >
          {confirmText}
        </Button>
      </DialogActions>
    </MuiDialog>
  );
};

export default Dialog;
