import styled from '@emotion/styled';

const Copyright = styled.div`
  display: flex;
  flex: 1;
  font-size: 0.75rem;
  justify-content: center;
  padding: 1rem 1.5rem;
  color: var(--charcoal);
`;

const AuthCopyright = styled.div`
  display: none;
  /* display: flex;
  flex: 1;
  font-size: 0.75rem;
  justify-content: left;
  padding: 1rem 1.5rem;
  background-color: var(--lightGray);
  color: var(--charcoal); */
`;

const Footer = ({ navigation, isAuthenticated }) => {
  const renderCopyright = () => {
    const El = isAuthenticated ? AuthCopyright : Copyright;
    const year = new Date().getFullYear();
    const copyrightNotice = `© ${year} Ordo, Inc. All rights reserved`;
    return <El id="main-footer">{copyrightNotice}</El>;
  };
  if (isAuthenticated === null) return null;
  return renderCopyright();
};

export default Footer;
