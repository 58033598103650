import { useHistory } from 'react-router-dom';
import { SUBSCRIBE } from '../../routes';
import { Button } from '../../common';
import useViewport from '../../hooks/useViewport';
import FlashOnIcon from '@mui/icons-material/FlashOn';

const SubscribeCTA = () => {
  const { isMobile } = useViewport();
  const history = useHistory();

  const handleGoToSubscribeOnboard = () => {
    history.push(SUBSCRIBE);
  };

  return (
    <Button
      id="subscribe-cta"
      onClick={handleGoToSubscribeOnboard}
      sx={{
        backgroundColor: 'var(--blue)',
        margin: 0,
      }}
      size="large"
      fullWidth={isMobile}
    >
      <FlashOnIcon sx={{ marginRight: '0.5rem' }} />
      Subscribe
    </Button>
  );
};

export default SubscribeCTA;
