import { Fragment, useState } from 'react';
import styled from '@emotion/styled';
import useViewport from '../../hooks/useViewport';
import { Card, Heading, Button, Divider, Spinner } from '../../common';
import {
  PageContainer,
  PaymentMethods,
  ResetPassword,
  AccountPosSettings,
} from '../../components';
import useAccount from '../../hooks/useAccount';
import usePaymentMethods from '../../hooks/usePaymentMethods';

const Wrapper = styled.div`
  width: 500px;
`;

const Container = styled.div`
  padding: 0;
  margin: 0;
  margin-top: 3rem;
  display: flex;
  /* flex: 1 auto; */
  align-items: center;
  justify-content: center;
  /* background-color: var(--white); */
`;

const Settings = () => {
  const { isMobile } = useViewport();
  const { accountId, hasPosLocation, loading: accountLoading } = useAccount();
  const { loading, error, onUpdatePaymentMethod, onDeletePaymentMethod } =
    usePaymentMethods();

  const [showResetPasswordForm, setShowResetPasswordForm] = useState(false);
  const [showAccountPosSettingsForm, setShowAccountPosSettingsForm] =
    useState(false);

  const renderAccountPosSettingsForm = () => {
    return (
      <AccountPosSettings
        isOpen={showAccountPosSettingsForm}
        onClose={() => setShowAccountPosSettingsForm(false)}
        accountId={accountId}
      />
    );
  };

  const renderPosSettings = (WrapperEl, sx) => {
    return (
      <Card.Content sx={sx}>
        <Divider />
        <WrapperEl>
          <Heading as="h2" sx={{ marginTop: '1rem' }}>
            Self-order settings
          </Heading>
          <p>
            If your school participates in our "point-of-sale" program, enable
            self-order settings to allow students to pay and order for
            themselves in the cafeteria.
          </p>
          <Button
            sx={{ marginTop: '1rem', display: 'block' }}
            onClick={() => setShowAccountPosSettingsForm(true)}
            fullWidth={isMobile}
          >
            Manage self-order
          </Button>
        </WrapperEl>
        {showAccountPosSettingsForm && renderAccountPosSettingsForm()}
      </Card.Content>
    );
  };

  const renderPaymentMethods = (WrapperEl, sx) => {
    return (
      <Card.Content sx={sx}>
        <Divider />
        <WrapperEl>
          <Heading as="h2" sx={{ marginTop: '1rem' }}>
            Payment methods
          </Heading>
          <p>
            Add and manage your payment methods using our secure payment system.
          </p>
          <PaymentMethods
            showOptionsAs="list"
            showHeader={false}
            showActionButtonIcon={false}
            actionButtonVariant="contained"
            dividerPosition="top"
            onUpdatePaymentMethod={onUpdatePaymentMethod}
            onDeletePaymentMethod={onDeletePaymentMethod}
          />
        </WrapperEl>
      </Card.Content>
    );
  };

  const renderResetPasswordForm = () => {
    return <ResetPassword onClose={() => setShowResetPasswordForm(false)} />;
  };

  const renderSecuritySettings = (WrapperEl, sx) => {
    return (
      <Card.Content sx={sx}>
        <Divider />
        <WrapperEl>
          <Heading as="h2" sx={{ marginTop: '1rem' }}>
            Security
          </Heading>
          <p>
            Concerned about security? Email yourself a "reset password" link.
          </p>
          <Button
            sx={{ marginTop: '1rem', display: 'block' }}
            onClick={() => setShowResetPasswordForm(true)}
            fullWidth={isMobile}
          >
            Reset password
          </Button>
        </WrapperEl>
        {showResetPasswordForm && renderResetPasswordForm()}
      </Card.Content>
    );
  };

  const renderSections = (WrapperEl, sx) => {
    if (!accountId || accountLoading) return <Spinner />;
    return (
      <>
        {renderPaymentMethods(WrapperEl, sx)}
        {hasPosLocation && renderPosSettings(WrapperEl, sx)}
        {renderSecuritySettings(WrapperEl, sx)}
      </>
    );
  };

  const renderPageContent = () => {
    const WrapperEl = isMobile ? Fragment : Wrapper;
    const sx = {
      paddingLeft: 0,
      paddingRight: 0,
    };
    return (
      <Card>
        {!isMobile && <Card.Header title="Account Settings" sx={sx} />}
        {renderSections(WrapperEl, sx)}
      </Card>
    );
  };

  return (
    <PageContainer
      container={isMobile ? null : Container}
      pageTitle="Account Settings"
      showPageTitle={isMobile}
    >
      {renderPageContent()}
    </PageContainer>
  );
};

export default Settings;
