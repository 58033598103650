import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signOut,
  signInWithEmailAndPassword,
  signInWithCustomToken,
  onAuthStateChanged,
  sendPasswordResetEmail,
  setPersistence,
  inMemoryPersistence,
  // connectAuthEmulator
} from 'firebase/auth';
import config from './keys.json';
import { client as httpClient } from '../http';

const { ENV_INSTANCE } = process.env;
const firebaseConfig = config[ENV_INSTANCE];
const ACCESS_KEY = process?.env?.CLOUD_ACCESS_KEY;

if (!firebaseConfig) {
  console.error('Missing firebase config', ENV_INSTANCE);
}

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

let tempToken = null;

// if (true) { // TODO
//   connectAuthEmulator(auth, 'http://localhost:9099');
// }

const setAuthPersistence = async (persistence = inMemoryPersistence) => {
  await setPersistence(auth, persistence)
    .catch((err) => {
      console.error(err);
    });
};

export const getAuthToken = async (useTempToken = false) => {
  if (useTempToken && tempToken) return tempToken;
  let token = null;
  const authUser = auth.currentUser;
  if (authUser) await authUser.getIdToken().then((jwt) => {
    if (jwt) token = jwt;
  });
  return token;
};

export const onAuthChanged = (cb) => {
  onAuthStateChanged(auth, (authUser) => {
    if (cb) cb(authUser);
  });
};

export const signInAuthUser = async (emailAddress, password, persistAuth = true) => {
  let authUser = null;
  if (!persistAuth) await setAuthPersistence(inMemoryPersistence);
  await signInWithEmailAndPassword(auth, emailAddress, password)
    .then((userCredential) => {
      authUser = userCredential.user;
    });
  return { authUser, isAuthenticated: !!authUser };
};

export const registerAuthUser = async (userPayload) => {
  const res = await httpClient.post({
    url: '/users/register',
    headers: { _accessKey: ACCESS_KEY },
    body: userPayload,
    useJwt: false,
  });
  const { emailAddress, password } = userPayload;
  if (res?.id) {
    await signInAuthUser(emailAddress, password);
  }
  if (res?.error) {
    console.error('registerAuthUser', emailAddress, res.error);
  }
  return res;
};

export const signOutAuthUser = (cb) => {
  signOut(auth)
    .then(() => {
      if (cb) cb();
    })
    .catch((err) => console.error(err));
};

export const sendResetPasswordEmail = async (emailAddress) => {
  return sendPasswordResetEmail(auth, emailAddress);
};

export const signInWithToken = async (token, persistAuth = true) => {
  let authUser = null;
  if (!persistAuth) await setAuthPersistence(inMemoryPersistence);
  await signInWithCustomToken(auth, token)
    .then((userCredential) => {
      authUser = userCredential.user;
    });
  if (token) tempToken = token;
  return { authUser, isAuthenticated: !!authUser };
};

export const getAuthClaims = async () => {
  const token = await auth.currentUser.getIdTokenResult();
  const claims = token?.claims;
  return claims;
};

export const getAuthRoles = async () => {
  const claims = await getAuthClaims();
  const defaultRole = claims?.['https://hasura.io/jwt/claims']?.['x-hasura-default-role'];
  const roles = claims?.['https://hasura.io/jwt/claims']?.['x-hasura-allowed-roles'];
  return { defaultRole, roles: roles || [] };
};
