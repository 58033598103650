import { useEffect, useState, useCallback, useRef } from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { CaptureConsole } from '@sentry/integrations';
import { LicenseInfo } from '@mui/x-license-pro';
import { useIdleTimer } from 'react-idle-timer';
import { ApolloProvider, useLazyQuery } from '@apollo/client';
import {
  register as registerServiceWorker,
  unregister as unregisterServiceWorker,
  config as swConfig,
} from './utils/registerServiceWorker';
import { GET_USER_BY_ID } from './graphql/queries';
import { ViewportProvider } from './hooks/useViewport';
import useCart from './hooks/useCart';
import useGlobalContext, {
  GlobalContextProvider,
} from './hooks/useGlobalContext';
import useQueryParams from './hooks/useQueryParams';
import useAccount from './hooks/useAccount';
import useMaintenanceMode from './hooks/useMaintenanceMode';
import useVersionCheck from './hooks/useVersionCheck';
import { TemplateContextProvider } from './hooks/useTemplateContext'; // TODO Move template state in App to hook
import { GlobalStyles, GlobalThemeProvider } from './styles/Global';
import { OnlineStatusProvider } from './hooks/useOnlineStatus';
import {
  onAuthChanged,
  signInAuthUser,
  signInWithToken,
} from './firebase/auth';
import { RouterProvider, Routes } from './routes';
import { client } from './graphql';
import { isAuthenticatedCache, authUserCache } from './graphql/cache';
import { client as httpClient } from './http';
import MaintenanceMode from './containers/MaintenanceMode';
import ErrorBoundary from './ErrorBoundary';
import AccountSuspended from './containers/AccountSuspended';
import AccountsEmpty from './containers/AccountsEmpty';
import BaseLayout from './templates/BaseLayout';
import Header from './templates/Header';
import Footer from './templates/Footer';
import { Spinner } from './common';
import {
  Navigation,
  UpdateNotification,
  FixedHeaderContent,
  SubscriptionAnnouncementModal,
} from './components';

if (process?.env?.SENTRY_DSN) {
  const sentryInit = {
    dsn: process?.env?.SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing(),
      new CaptureConsole({
        levels: process?.env?.SENTRY_CAPTURE_LEVELS?.split(',') || [],
      }),
      new Sentry.Replay({
        maskAllText: !!(process?.env?.SENTRY_REPLAY_MASK_ALL_TEXT === 'true'),
        maskAllInputs: !!(
          process?.env?.SENTRY_REPLAY_MASK_ALL_INPUTS === 'true'
        ),
        blockAllMedia: !!(
          process?.env?.SENTRY_REPLAY_BLOCK_ALL_MEDIA === 'true'
        ),
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: parseFloat(process?.env?.SENTRY_TRACE_SAMPLE_RATE) || 0,
    replaysSessionSampleRate:
      parseFloat(process?.env?.SENTRY_REPLAY_SAMPLE_SESSIONS) || 0,
    replaysOnErrorSampleRate:
      parseFloat(process?.env?.SENTRY_REPLAY_SAMPLE_ERRORS) || 0,

    beforeSend(event, hint) {
      // Check if the error message matches a pattern indicative of the third-party errors
      const message = hint?.originalException?.message;
      if (message?.includes('Connection to Indexed Database server lost')) {
        return null; // Don't send the event to Sentry
      }
      if (message?.includes('Websocket close was unclean: 1006')) {
        return null; // Don't send the event to Sentry
      }
      return event; // Send all other events normally
    },
  };
  Sentry.init(sentryInit);
}
if (process?.env?.MUI_X_LICENSE_KEY) {
  LicenseInfo.setLicenseKey(process.env.MUI_X_LICENSE_KEY);
}

const INIT_HEADER_FOOTER_STATE = { hideHeader: false, hideFooter: false };
const IDLE_TIMEOUT = 1000 * 60 * 600; // 600 mins (10 hrs)

const App = () => {
  const [{ isAuthenticated = null, authUser = null }, setGlobalState] =
    useGlobalContext();

  const wasIdleRef = useRef(false);
  const isIdleRef = useRef(false);
  const onIdle = useCallback(() => {
    isIdleRef.current = true;
  }, []);
  const onActive = useCallback(() => {
    if (wasIdleRef.current && isIdleRef.current) {
      window.location.reload();
    }
    wasIdleRef.current = isIdleRef.current;
    isIdleRef.current = false;
  }, []);
  useIdleTimer({ onIdle, onActive, timeout: IDLE_TIMEOUT });

  const { hasAppUpdate } = useVersionCheck();
  const isMaintenanceMode = useMaintenanceMode();
  const [{ hideHeader, hideFooter }, setHideHeaderFooter] = useState(
    INIT_HEADER_FOOTER_STATE,
  );

  const [getMe] = useLazyQuery(GET_USER_BY_ID);
  const { onClearCart } = useCart();
  const {
    login = null,
    jwt = null,
    locationId: locId,
    embedded = false,
    enableSubscriptions = false, // TODO SUBS_FLAG
    ...queryParams
  } = useQueryParams();
  const authWithJwtLoadingRef = useRef(!!jwt);
  const isEmbeddedRef = useRef(embedded);
  const locationIdRef = useRef(locId);
  const [isEmbedded, setIsEmbedded] = useState(isEmbeddedRef.current);
  const qParamsRef = useRef(queryParams);
  const initQueryParamsRef = useRef(false);

  const { noAccounts, isSuspended } = useAccount();

  const setAuthUser = ({ isAuthenticated = null, authUser = null }) => {
    setGlobalState({ isAuthenticated, authUser });
  };

  useEffect(() => {
    if (jwt) {
      (async () =>
        await signInWithToken(jwt, false) // Do not persist session
          .catch((err) => console.error(err)))();
    }
  }, [jwt]);

  useEffect(() => {
    if (embedded) {
      isEmbeddedRef.current = true;
      setIsEmbedded(true);
      setGlobalState({ isEmbedded: true });
    }
  }, [embedded]);

  useEffect(() => {
    // TODO SUBS_FLAG
    if (enableSubscriptions) {
      setGlobalState({ enableSubscriptions });
    }
  }, [enableSubscriptions]);

  useEffect(() => {
    if (initQueryParamsRef.current) return;
    const hasQueryParams = Object.keys(queryParams)?.length > 0;
    if (locId || hasQueryParams) {
      initQueryParamsRef.current = true;
      const state = { ...queryParams };
      if (queryParams) {
        qParamsRef.current = queryParams;
      }
      if (locId) {
        locationIdRef.current = locId;
        state.selectedLocationId = locId;
      }
      setGlobalState(state);
    }
  }, [locId, queryParams]);

  const handleSignInUser = async (vals) => {
    const { emailAddress, password } = vals;
    await signInAuthUser(emailAddress, password).catch((err) =>
      console.error(err),
    );
  };

  useEffect(() => {
    if (isEmbedded && isAuthenticated) {
      // Clear cart when in POS mode
      onClearCart();
    }
  }, [isEmbedded, isAuthenticated]);

  useEffect(() => {
    // Method auto-logs in user on demo site with base64-encoded credentials
    if (isAuthenticated !== false) return;
    if (
      !(
        process?.env?.APP_NAME === 'Ordo-DEMO' ||
        process?.env?.APP_NAME === 'Ordo-LOCAL'
      )
    )
      return;
    if (login) {
      try {
        const vals = atob(login);
        const creds = JSON.parse(vals);
        if (vals && creds) {
          const LOG_API = '/logs/report';
          handleSignInUser(creds)
            .then(() => {
              if (process?.env?.APP_NAME === 'Ordo-DEMO') {
                httpClient.post({
                  url: LOG_API,
                  body: {
                    emailAddress: creds?.emailAddress,
                    event: 'DEMO_AUTO_SIGNIN',
                  },
                });
              } else console.log('Auto-login succeeeded', creds?.emailAddress);
            })
            .catch((err) => {
              if (process?.env?.APP_NAME === 'Ordo-DEMO') {
                httpClient.post({
                  url: LOG_API,
                  body: {
                    emailAddress: creds?.emailAddress,
                    event: 'DEMO_AUTO_SIGNIN_ERROR',
                    error: err,
                  },
                });
              } else console.error(err);
            });
        } else {
          console.error('Invalid login query provided');
        }
      } catch (err) {
        console.error(err);
      }
    }
  }, [login, isAuthenticated]);

  useEffect(() => {
    onAuthChanged((au) => {
      if (au) {
        setAuthUser({ isAuthenticated: true, authUser: au });
        isAuthenticatedCache(true);
        authUserCache(au);
        Sentry.setUser({
          id: au?.uid,
          emailAddress: au?.email,
        });
        if (authWithJwtLoadingRef.current) {
          authWithJwtLoadingRef.current = false;
        }
      } else if (!authWithJwtLoadingRef.current) {
        setAuthUser({ isAuthenticated: false, authUser: null });
        isAuthenticatedCache(false);
        authUserCache(null);
        client.clearStore();
      }
    });
  }, [authWithJwtLoadingRef.current]);

  useEffect(() => {
    if (isAuthenticated) {
      const { uid } = authUser;
      getMe({ variables: { id: uid } });
      // getMyAccounts({ variables: { id: uid } });
    }
  }, [isAuthenticated]);

  const handleSetHideHeaderFooter = useCallback((hideSettings) => {
    setHideHeaderFooter(hideSettings);
  }, []);

  const renderFixedHeaderContent = (template) =>
    isAuthenticated ? (
      <FixedHeaderContent
        isAuthenticated={isAuthenticated}
        authUser={authUser}
        template={template}
      />
    ) : null;

  const renderTopNav = () => (
    <Navigation isAuthenticated={isAuthenticated} authUser={authUser} />
  );

  const renderHeader = (template) => (
    <>
      <Header
        isAuthenticated={isAuthenticated}
        navigation={renderTopNav()}
        isEmbedded={isEmbedded}
      />
      {renderFixedHeaderContent(template)}
    </>
  );

  const renderFooter = (template) => (
    <Footer
      isAuthenticated={isAuthenticated}
      navigation={null}
      template={template}
    />
  );

  const renderComponentInBaseLayout = (component, template = null) => {
    return (
      <BaseLayout
        header={!hideHeader ? renderHeader(template) : null}
        footer={!hideFooter && !isEmbedded ? renderFooter(template) : null}
        isAuthenticated={isAuthenticated}
        template={template}
      >
        <ErrorBoundary>{component}</ErrorBoundary>
        {isAuthenticated && <SubscriptionAnnouncementModal />}
      </BaseLayout>
    );
  };

  const renderView = () => {
    return (
      <>
        <Routes
          isAuthenticated={isAuthenticated}
          authUser={authUser}
          onSetHideHeaderFooter={handleSetHideHeaderFooter}
          renderTemplateWrapper={renderComponentInBaseLayout}
        />
      </>
    );
  };

  const renderContent = () => {
    if (isAuthenticated === null)
      return renderComponentInBaseLayout(<Spinner />);
    if (isMaintenanceMode)
      return renderComponentInBaseLayout(<MaintenanceMode />);
    if (isEmbedded && isSuspended)
      return renderComponentInBaseLayout(<AccountSuspended />);
    if (noAccounts) return renderComponentInBaseLayout(<AccountsEmpty />);
    return renderView();
  };

  return (
    <ErrorBoundary>
      {renderContent()}
      {hasAppUpdate && <UpdateNotification />}
    </ErrorBoundary>
  );
};

const globalStyles = <GlobalStyles />;

const AppWithProviders = () => (
  <ViewportProvider>
    <OnlineStatusProvider>
      <TemplateContextProvider>
        <GlobalContextProvider>
          <RouterProvider>
            <GlobalThemeProvider>
              {globalStyles}
              <ApolloProvider client={client}>
                <App />
              </ApolloProvider>
            </GlobalThemeProvider>
          </RouterProvider>
        </GlobalContextProvider>
      </TemplateContextProvider>
    </OnlineStatusProvider>
  </ViewportProvider>
);

ReactDOM.render(<AppWithProviders />, document.getElementById('root'));

if (process?.env?.NODE_ENV === 'production') {
  registerServiceWorker(swConfig);
}
if (process?.env?.NODE_ENV === 'development') {
  unregisterServiceWorker();
}
