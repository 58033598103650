import { getAuthToken } from '../firebase/auth';

const HTTP_API_URI = process.env.HTTP_API_URI;
const NAME = process.env.APP_NAME.replace(' ', '_');

const SLOW_THRESHOLD = 1000;

const defaultHeaders = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
};

const checkStatusFn = async (rawResponse, logError = true) => {
  if (rawResponse.status >= 200 && rawResponse.status < 300) {
    return Promise.resolve(rawResponse);
  } else {
    if (logError) console.error(rawResponse.statusText, rawResponse);
    return Promise.reject(new Error(rawResponse.statusText));
  }
};

const loggingHttpFetch = async (url, options) => {
  const start = Date.now();
  const rawResponse = await fetch(url, options);
  const end = Date.now();
  const requestTime = end - start;
  const isSlow = requestTime > SLOW_THRESHOLD;
  if (isSlow) {
    const resClone = await rawResponse?.clone();
    const result = await resClone?.text();
    console.warn(`SLOW ${NAME} • ${requestTime}ms (${result.length} bytes) • [${options.method} ${url}] • Logged: ${new Date().toISOString()} | `, options?.body || null);
  }
  return rawResponse;
};

const httpClient = async ({ method = 'GET', url, headers, body, useJwt = true, checkStatus = false, isJson = true, logError }) => {
  const reqHeaders = {
    ...defaultHeaders,
    ...headers,
  };
  if (useJwt) {
    reqHeaders.authorization = await getAuthToken();
  }
  const completeUrl = /^http/.test(url)
    ? url
    : `${HTTP_API_URI}${url}`;

  const res = await loggingHttpFetch(completeUrl, {
    method,
    headers: reqHeaders,
    body: (typeof body === 'string') ? body : JSON.stringify(body),
  })
    .then((r) => checkStatus
      ? checkStatusFn(r, logError)
      : r
    )
    .then((r) => (isJson && r.status !== 204 && (reqHeaders.Accept === defaultHeaders.Accept))
      ? r.json()
      : r
    );

  return res;
};

httpClient.get = async (params) =>
  httpClient({ ...params, method: 'GET' });

httpClient.post = async (params) =>
  httpClient({ ...params, method: 'POST' });

httpClient.put = async (params) =>
  httpClient({ ...params, method: 'PUT' });

httpClient.patch = async (params) =>
  httpClient({ ...params, method: 'PATCH' });

httpClient.delete = async (params) =>
  httpClient({ ...params, method: 'DELETE' });

httpClient.options = async (params) =>
  httpClient({ ...params, method: 'OPTIONS' });

export const client = httpClient;
