import { useEffect, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { validate as uuidValidate } from 'uuid';
import { useLazyQuery } from '@apollo/client';
import { GET_MY_ACCOUNTS } from '../graphql/queries';
import { ACCOUNT_UPDATE, ACCOUNT, DELINQUENT } from '../routes';
import { sort } from '../utils';
import { USER_ROLES, SUBSCRIPTIONS } from '../utils/Consts';
import useMe from './useMe';
import useTodos from './useTodos';
import useGlobalContext from './useGlobalContext';

const SUSPENDED_AT_MS = 86400 * 14; // 14 days
// const isProd = process.env.ENV_INSTANCE === 'ordo-schools'; // TODO Temp

// TODO Consider using context to store account data to avoid processing multiple times
const useAccount = ({ redirect = false } = {}) => {
  const history = useHistory();
  // TODO SUBS_FLAG
  const [
    { rosterUpdates, enableSubscriptions: enableSubs = true },
    setGlobalState,
  ] = useGlobalContext();

  const { me } = useMe();
  const { todos, loading: todosLoading } = useTodos();
  const [
    getMyAccounts,
    {
      data: accountsData,
      loading: accountsLoading,
      refetch: accountDataRefetch,
    },
  ] = useLazyQuery(GET_MY_ACCOUNTS, {
    fetchPolicy: 'cache-first', // Won't query server if data is in cache
  });

  const augmentAccountUser = useCallback(
    (au) => {
      if (!me) return au;
      const { user, isArchived, roles, locationId: auLocId } = au;
      const isMe = user.id === me?.id;
      const isStudent = roles.includes(USER_ROLES.STUDENT);
      const isStaff = roles.includes(USER_ROLES.STAFF);
      const isGuardian = roles.includes(USER_ROLES.GUARDIAN);
      const isAccountAdmin = roles.includes(USER_ROLES.ADMIN);
      const reactions = {};
      const displayName = user.preferredName || user.firstName;
      const locationIds = [];
      const locations = {};
      const locationTerms = {};

      const { rosters } = user;
      let hasTermRoster = null;
      let hasPosLoc = null;
      let hasSubscriptionLocation = null;
      if (!isArchived && (isStudent || isStaff)) {
        hasTermRoster = rosters?.length > 0;
        if (hasTermRoster) {
          rosters.forEach(({ roster: r }) => {
            if (r?.term?.location?.id) {
              const l = r?.term?.location;
              if (!hasSubscriptionLocation) {
                hasSubscriptionLocation =
                  l?.locationSettings?.optInPrograms?.includes(SUBSCRIPTIONS);
              }
              if (l?.settings?.posEnabled) hasPosLoc = true;
              if (!locationTerms[l.id]) locationTerms[l.id] = [];
              const term = {
                id: r?.term?.id,
                name: r?.term?.name,
                startDateTime: r?.term?.startDateTime,
                endDateTime: r?.term?.endDateTime,
                hasSubscriptionLocation,
              };
              locationTerms[l.id].push(term);
              if (!locationIds.includes(l?.id)) {
                const location = {
                  id: l?.id,
                  name: l?.name,
                  address: {
                    id: l?.address?.id,
                    stateProvince: l?.address?.stateProvince,
                  },
                  organization: {
                    id: l?.organization?.id,
                    name: l?.organization?.name,
                  },
                  settings: l?.settings,
                };
                locationIds.push(l.id);
                locations[l.id] = location;
              }
            }
          });
        }
        if (auLocId && !locationIds.includes(auLocId)) {
          locationIds.push(auLocId);
        }
      }

      return {
        ...au,
        locationIds,
        locations,
        locationTerms,
        hasTermRoster,
        hasPosLocation: hasPosLoc,
        hasSubscriptionLocation,
        isMe,
        isStudent,
        isStaff,
        isGuardian,
        isAccountAdmin,
        roles,
        reactions,
        displayName,
        userId: user.id,
      };
    },
    [me],
  );

  const account = useMemo(() => {
    let a = null;
    if (accountsData?.accounts?.length > 0) {
      if (accountsData.accounts.length === 1) {
        a = accountsData.accounts.find((a) => {
          return !a.isArchived;
        });
      }
    }
    return a;
  }, [accountsData]);

  useEffect(() => {
    getMyAccounts();
  }, []);

  const {
    isSuspended = null,
    isPastDue = null,
    noAccounts = null,
    aUsers = [],
    studentUsers = [],
    staffUsers = [],
    subscriptionUsers = [],
    hasSubscriptions = null,
    needsRostersUsers = [],
    self = null,
    hasStudentStaffUsers = null,
    hasTermRosters = null,
    hasPosLocation = null,
    locationIds = [],
    locations = {},
    locationTerms = {},
  } = useMemo(() => {
    if (!account || !me) return {};

    const { users: accountUsers = [], reactions } = account;
    const augmentAu = sort(
      accountUsers.map(augmentAccountUser),
      ['isArchived', 'isMe', 'isAccountAdmin', 'displayName', 'userId'],
      ['asc', 'desc', 'desc', 'asc', 'asc'],
    );

    const hasStudentOrStaffAu = augmentAu.some(
      ({ isStudent, isStaff, isArchived }) => {
        return !isArchived && (isStudent || isStaff);
      },
    );

    let s = null;
    let hasRosters = true;
    let hasPosLoc = false;
    const studentU = [];
    const staffU = [];
    const subU = [];
    const needsTermRostersU = [];
    const locIds = [];
    const locs = {};
    const terms = {};
    augmentAu.forEach((au) => {
      const {
        isMe,
        isStaff,
        isStudent,
        isArchived,
        hasTermRoster,
        hasSubscriptionLocation,
        locations,
        locationIds,
        locationTerms,
      } = au;
      if (Array.isArray(reactions)) {
        const userReactions = reactions.filter((r) => r.userId === au.userId);
        userReactions.forEach((r) => {
          au.reactions[r.productId] = r.reaction?.toUpperCase();
        });
      }
      if (isMe) s = au;
      if (isStudent) studentU.push(au);
      if (isStaff) staffU.push(au);
      if (hasSubscriptionLocation) subU.push(au);
      if (hasTermRoster === false) {
        hasRosters = false;
        needsTermRostersU.push(au);
      }
      if (!isArchived && (isStaff || isStudent) && hasTermRoster) {
        locationIds.forEach((locationId) => {
          if (!uuidValidate(locationId) || !locationTerms[locationId]) return;
          locationTerms[locationId].forEach((term) => {
            if (!terms[locationId]) terms[locationId] = [];
            const hasTerm = terms[locationId].some((t) => t.id === term.id);
            if (!hasTerm) terms[locationId].push(term);
          });
          if (!locIds.includes(locationId)) {
            locIds.push(locationId);
            locs[locationId] = locations[locationId];
          }
        });
      }
      if (!hasPosLoc)
        hasPosLoc = locationIds.some(
          (locationId) => locs[locationId]?.settings?.posEnabled,
        );
    });

    return {
      isSuspended: account?.delinquentFor > SUSPENDED_AT_MS,
      isPastDue: !!account?.delinquentFor,
      noAccounts: !account,
      aUsers: augmentAu,
      studentUsers: studentU,
      staffUsers: staffU,
      subscriptionUsers: enableSubs ? subU : [],
      hasSubscriptions:
        subU.length === 0 || !enableSubs
          ? null
          : subU.some((u) => u.hasSubscriptionLocation && !!u.subscription),
      needsRostersUsers: needsTermRostersU,
      self: s,
      hasStudentStaffUsers: hasStudentOrStaffAu,
      hasTermRosters: hasRosters,
      hasPosLocation: hasPosLoc,
      locationIds: locIds,
      locations: locs,
      locationTerms: terms,
    };
  }, [account, me, augmentAccountUser]);

  const hasTodos = useMemo(() => {
    return false; // TODO
    // return todos?.length > 0;
  }, [todos]);

  const shouldRedirect = useMemo(() => {
    if (!me) return false;
    if (!redirect) return false;
    if (redirect && isSuspended) return true;
    if (rosterUpdates) return true;
    if (hasStudentStaffUsers && hasTermRosters) return false;
    return true;
  }, [
    me,
    redirect,
    isSuspended,
    hasStudentStaffUsers,
    hasTermRosters,
    needsRostersUsers,
    hasStudentStaffUsers,
    rosterUpdates,
  ]);

  useEffect(() => {
    if (shouldRedirect && isSuspended) {
      history.push(DELINQUENT);
    } else if (shouldRedirect && account?.id) {
      const route =
        !hasStudentStaffUsers && !rosterUpdates ? ACCOUNT : ACCOUNT_UPDATE;
      history.push(route);
    }
  }, [
    isSuspended,
    shouldRedirect,
    hasStudentStaffUsers,
    rosterUpdates,
    account?.id,
  ]);

  const handleRefetch = useCallback(() => {
    accountDataRefetch();
  }, [accountDataRefetch]);

  useEffect(() => {
    const globalAccountState = {
      accountId: account?.id,
      noAccounts,
      account,
      aUsers,
      self,
      studentUsers,
      staffUsers,
      subscriptionUsers,
      needsRostersUsers,
      hasTermRosters,
      hasPosLocation,
      locationIds,
      locations,
      terms: locationTerms,
      isSuspended,
      isPastDue,
      todos,
      hasTodos,
      refetch: handleRefetch,
      loading: accountsLoading || todosLoading,
      settings: self?.settings,
    };
    if (account?.id) setGlobalState({ accountState: globalAccountState });
  }, [
    account?.id,
    noAccounts,
    account,
    aUsers,
    self,
    studentUsers,
    staffUsers,
    subscriptionUsers,
    needsRostersUsers,
    hasTermRosters,
    hasPosLocation,
    locationIds,
    locations,
    locationTerms,
    isSuspended,
    isPastDue,
    todos,
    hasTodos,
    handleRefetch,
    accountsLoading,
    todosLoading,
    self?.settings,
  ]);

  return {
    accountId: account?.id,
    noAccounts,
    account,
    aUsers,
    self,
    studentUsers,
    staffUsers,
    subscriptionUsers,
    hasSubscriptions,
    needsRostersUsers,
    hasTermRosters,
    hasPosLocation,
    locationIds,
    locations,
    terms: locationTerms,
    isSuspended,
    isPastDue,
    todos,
    hasTodos,
    refetch: handleRefetch,
    loading: accountsLoading || todosLoading,
    settings: self?.settings,
  };
};

export default useAccount;
