import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { SUBSCRIBE } from '../../routes';
import useStore from '../../hooks/useStore';
import useSubscriptions from '../../hooks/useSubscriptions';
import useViewport from '../../hooks/useViewport';
import { ModalAdvanced, Box } from '../../common';
import Heading from '../../common/Heading';
import FlashOn from '@mui/icons-material/FlashOn';

const SubscriptionAnnouncementModal = () => {
  const { isMobile } = useViewport();
  const history = useHistory();
  const storeInstance = useStore('announcements');
  const { hasSubscriptions, loading } = useSubscriptions();
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    storeInstance.setAll({ acknowledgeSubscriptionAccouncement: true });
    setIsOpen(false);
  };

  const handleGoToSubscribe = () => {
    storeInstance.setAll({ acknowledgeSubscriptionAccouncement: true });
    setIsOpen(false);
    history.push(SUBSCRIBE);
  };

  useEffect(() => {
    const { acknowledgeSubscriptionAccouncement = false } = {
      ...storeInstance.getAll(),
    };
    if (acknowledgeSubscriptionAccouncement || loading) return;
    if (!hasSubscriptions) {
      setIsOpen(true);
    }
  }, [hasSubscriptions, loading]);

  const renderContent = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          height: '100%',
          minHeight: isMobile ? '66vh' : undefined,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <FlashOn sx={{ fontSize: '8rem', color: 'var(--charcoal)' }} />
        <Heading variant="h2" sx={{ textAlign: 'center' }}>
          Auto-order is now available!
        </Heading>
      </Box>
    );
  };

  if (!isOpen) return null;

  return (
    <ModalAdvanced
      title="Set & Forget"
      onClose={handleClose}
      actions={[
        {
          label: (
            <>
              <FlashOn sx={{ marginRight: '0.5rem' }} />
              Subscribe here
            </>
          ),
          sx: {
            color: 'var(--white)',
            backgroundColor: 'var(--blue)',
          },
          size: 'large',
          onClick: handleGoToSubscribe,
        },
      ]}
    >
      {renderContent()}
    </ModalAdvanced>
  );
};

export default SubscriptionAnnouncementModal;
