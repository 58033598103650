import { Suspense, lazy, useMemo } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom';
import useQueryParams from '../hooks/useQueryParams';
import { forEach, constructQueryString } from '../utils';

export const RouterProvider = Router;

export const WILD = '*';
export const BASE = '/';

// Auth
export const REGISTER = '/register';
export const SIGN_IN = '/signin';
export const LOG_IN = '/login';
export const PASSWORD_FORGET = '/reset-password';
export const JOIN_WAITLIST = '/join-waitlist';
export const JOIN_WAITLIST_SUCCESS = '/join-waitlist/success';

// Main — Adapts based on user role(s)
export const DASHBOARD = '/dashboard';

// User
export const ACCOUNT = '/account';
export const ACCOUNT_UPDATE = '/account/update';
export const DELINQUENT = '/account/delinquent';
export const SETTINGS = '/account/settings';

// Subscriptions
export const SUBSCRIBE = '/subscribe'; // CTA
export const SUBSCRIPTIONS = '/subscriptions'; // Overview
export const SUBSCRIPTIONS_SETUP = '/subscriptions/setup'; // Onboarding wizard
export const SUBSCRIPTION_SETTINGS = '/subscriptions/manage'; // Manage

// Product Reactions
export const USER_PROFILES = '/profiles'; // Taste profiles
export const PRODUCT_CATALOG = '/profiles/catalog'; // Product catalog
export const USER_PROFILE_ID = '/profile/:userId';

// TODO Deprecate
export const SETTINGS_TO_DEPRECATE = '/settings';

// User Account + Orders
export const ORDERS = '/orders';
export const ORDER_ID = '/order/:orderId';
export const CHECKOUT = '/checkout';
export const MENU_ID = '/menu/:menuId';

// Redirect deprecated routes
export const ACCOUNT_ID = '/account/:accountId';
export const PROFILE = '/profile';

export const FAVORITES = '/favorites';
export const REWARDS = '/rewards';

const pages = [
  {
    component: null,
    route: BASE,
    withAuth: true,
    redirect: DASHBOARD,
    exact: true,
  },
  {
    component: lazy(() => import('../containers/Dashboard')),
    route: DASHBOARD,
    withAuth: true,
    redirect: SIGN_IN,
    exact: true,
  },
  {
    component: lazy(() => import('../containers/SignIn')),
    route: SIGN_IN,
    withAuth: false,
    redirect: DASHBOARD,
    exact: true,
  },
  {
    component: lazy(() => import('../containers/Register')),
    route: REGISTER,
    withAuth: false,
    redirect: DASHBOARD,
    exact: true,
    template: 'alternate',
  },
  {
    component: lazy(() => import('../containers/PasswordForgot')),
    route: PASSWORD_FORGET,
    withAuth: false,
    redirect: PROFILE,
    exact: true,
  },
  {
    component: lazy(() => import('../containers/Waitlist')),
    route: JOIN_WAITLIST,
    withAuth: false,
    redirect: DASHBOARD,
    exact: true,
    template: 'alternate',
  },
  {
    component: lazy(() => import('../containers/Waitlist')),
    route: JOIN_WAITLIST_SUCCESS,
    withAuth: false,
    redirect: DASHBOARD,
    exact: true,
    template: 'alternate',
  },
  {
    component: lazy(() => import('../containers/Profile')), // TODO
    route: ACCOUNT,
    withAuth: true,
    redirect: SIGN_IN,
    exact: true,
  },
  {
    component: lazy(() => import('../containers/Settings')),
    route: SETTINGS,
    withAuth: true,
    redirect: SIGN_IN,
    exact: true,
  },
  {
    component: lazy(() => import('../containers/Delinquent')),
    route: DELINQUENT,
    withAuth: true,
    redirect: SIGN_IN,
    exact: true,
  },
  {
    component: lazy(() => import('../containers/AccountUpdate')),
    route: ACCOUNT_UPDATE,
    withAuth: true,
    redirect: SIGN_IN,
    exact: true,
  },
  {
    component: lazy(() => import('../containers/Menu')),
    route: MENU_ID,
    withAuth: true,
    redirect: SIGN_IN,
    exact: false,
  },
  {
    component: lazy(() => import('../containers/Checkout')),
    route: CHECKOUT,
    withAuth: true,
    redirect: SIGN_IN,
    exact: false,
  },
  {
    component: lazy(() => import('../containers/Orders')),
    route: ORDERS,
    withAuth: true,
    redirect: SIGN_IN,
    exact: false,
  },
  {
    component: lazy(() => import('../containers/OrderDetail')),
    route: ORDER_ID,
    withAuth: true,
    redirect: SIGN_IN,
    exact: false,
  },
  {
    component: lazy(() => import('../containers/Subscribe')),
    route: SUBSCRIBE,
    withAuth: true,
    redirect: SIGN_IN,
    exact: false,
  },
  {
    component: lazy(() => import('../containers/Subscriptions')),
    route: SUBSCRIPTIONS,
    withAuth: true,
    redirect: SIGN_IN,
    exact: true,
  },
  {
    component: lazy(() => import('../containers/SubscriptionSetup')),
    route: SUBSCRIPTIONS_SETUP,
    withAuth: true,
    redirect: SIGN_IN,
    exact: false,
    template: 'alternate',
  },
  {
    component: lazy(() => import('../containers/SubscriptionSettings')),
    route: SUBSCRIPTION_SETTINGS,
    withAuth: true,
    redirect: SIGN_IN,
    exact: true,
  },
  {
    component: lazy(() => import('../containers/UserProfiles')),
    route: USER_PROFILE_ID,
    withAuth: true,
    redirect: SIGN_IN,
    exact: true,
  },
  {
    component: lazy(() => import('../containers/UserProfiles')),
    route: USER_PROFILES,
    withAuth: true,
    redirect: SIGN_IN,
    exact: true,
  },
  {
    component: lazy(() => import('../containers/ProductCatalog')),
    route: PRODUCT_CATALOG,
    withAuth: true,
    redirect: SIGN_IN,
    exact: true,
  },
  {
    component: null,
    route: SETTINGS_TO_DEPRECATE,
    withAuth: true,
    redirect: SETTINGS,
    exact: true,
  },
  {
    component: null,
    route: ACCOUNT_ID,
    withAuth: true,
    redirect: ACCOUNT,
    exact: true,
  },
  {
    component: null,
    route: WILD,
    withAuth: true,
    redirect: SIGN_IN,
  },
  {
    component: null,
    route: LOG_IN,
    withAuth: false,
    redirect: SIGN_IN,
  },
];

export const constructRouteWithParams = (routeTemplate, vals) => {
  let string = `${routeTemplate}`;
  forEach(vals, (val, key) => {
    string = string.replace(`:${key}`, val);
  });
  return string;
};

export const Routes = (props) => {
  const { renderTemplateWrapper, ...viewProps } = props;
  const { isAuthenticated } = viewProps;
  const { origin = null } = useQueryParams();
  const location = useLocation();

  const renderRoute = ({
    component: C,
    route,
    exact,
    redirect,
    withAuth,
    template = 'default',
  }) => {
    const redirectParams = useMemo(() => {
      let params = null;
      if (isAuthenticated === false && withAuth) {
        // User is not authenticated, needs to be redirected to sign in
        const originPath = encodeURIComponent(location?.pathname || route);
        const searchQuery = origin
          ? ''
          : constructQueryString({ origin: originPath });
        params = {
          key: redirect,
          to: {
            pathname: redirect,
            search: searchQuery,
          },
        };
      } else if (isAuthenticated && !withAuth) {
        // User is authenticated but accessing a route for unauthenticated users
        params = {
          key: redirect,
          to: {
            pathname: redirect,
            search: '',
          },
        };
      } else if (origin && isAuthenticated) {
        // User is authenticated and there's an origin to redirect to
        params = {
          key: decodeURIComponent(origin),
          to: {
            pathname: decodeURIComponent(origin),
            search: '',
          },
        };
      } else if (!C && redirect) {
        // Route doesn't have a component and needs redirection
        params = {
          key: redirect,
          to: {
            pathname: redirect,
            search: '',
          },
        };
      }
      return params;
    }, [isAuthenticated, origin, location, redirect, withAuth]);

    return (
      <Route key={route} path={route} exact={exact}>
        {redirectParams ? (
          <Redirect {...redirectParams} />
        ) : (
          renderTemplateWrapper(<C {...viewProps} />, template)
        )}
      </Route>
    );
  };

  return (
    <Suspense fallback={null}>
      <Switch>{pages.map(renderRoute)}</Switch>
    </Suspense>
  );
};
