import { useEffect, useMemo, useState, useRef } from 'react';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  ModalAdvanced,
  Form,
  TextField,
  Select,
  Autocomplete,
  Card,
  Menu,
  Divider,
  Error,
  Spinner,
  SecondaryActionText,
  Dialog,
} from '../../common';
import SchoolSelect from '../SchoolSelect';
import {
  PRONOUNS,
  USER_ROLES,
  USER_ROLES_LABELS,
  ROSTER_GROUPS,
} from '../../utils/Consts';
import { isDateTimeAfter } from '../../utils';
import useRosterSelection from '../../hooks/useRosterSelection';
import useQueryParams from '../../hooks/useQueryParams';
import useGlobalContext from '../../hooks/useGlobalContext';

const { Control: FormControl, InputLabel } = Form;
const { Item: MenuItem } = Menu;

const { GRADE, HOMEROOM, MEAL_PERIOD, LOCATION } = ROSTER_GROUPS;

const { GUARDIAN, STAFF, STUDENT } = USER_ROLES;
const ROLE_OPTIONS = [GUARDIAN, STAFF, STUDENT];
const ROLES = ROLE_OPTIONS.map((value) => ({
  label: USER_ROLES_LABELS[value],
  value,
}));

const pronouns = [null];
for (const p in PRONOUNS) {
  pronouns.push(PRONOUNS[p]);
}

const FieldWrapper = styled.div`
  margin: 1rem 0 !important;
`;

const AccountUserSchema = Yup.object().shape({
  role: Yup.mixed().oneOf(ROLE_OPTIONS).required('Please select a role'),
  firstName: Yup.string().required('Please enter first name'),
  lastName: Yup.string().required('Please enter last name'),
  middleName: Yup.string().nullable(),
  suffix: Yup.string().nullable(),
  preferredName: Yup.string().nullable(),
  // pronouns: Yup.mixed().oneOf(pronouns).nullable(),
  avatarUri: Yup.string().nullable(),
  phoneNumber: Yup.string().nullable(),
  locationId: Yup.string().nullable(),
  rosterIds: Yup.array().of(Yup.string()).nullable(),
  school: Yup.string()
    .when('role', {
      is: (r) => r !== GUARDIAN,
      then: Yup.string().required('Please select a school').nullable(),
      otherwise: Yup.string().nullable(),
    })
    .nullable(),
});

// TODO Refactor to use component in /profile for live account users (right now this is just for registration flow)
const AccountUserForm = ({
  accountId,
  locationId: locId,
  usaState,
  userData,
  onClose,
  isOpen,
  forceOpen,
  onSubmit,
  onArchiveUser,
  disabledFields,
  hiddenFields: hf,
  showArchiveAction,
  actionLoading,
}) => {
  const isEditMode = !!userData;
  const [{ rosterUpdates }] = useGlobalContext();

  const { user, locationId, roles = [], isArchived, ...au } = userData || {};
  const role = useMemo(() => {
    if (!isEditMode) return '';
    if (roles.includes(STAFF)) return STAFF;
    if (roles.includes(STUDENT)) return STUDENT;
    if (roles.includes(GUARDIAN)) return GUARDIAN;
    return '';
  }, [roles]);

  const { userRosterTermIds, date } = useMemo(() => {
    let d = null;
    const termIds = [];
    if (rosterUpdates && rosterUpdates[user?.id]) {
      const { termId = null, lastMenuDeliveryDate = null } =
        rosterUpdates[user?.id] || {};
      if (termId && !termIds.includes(termId)) {
        termIds.push(termId);
        if (
          !d ||
          (lastMenuDeliveryDate &&
            isDateTimeAfter({
              dateTime: lastMenuDeliveryDate,
              afterDateTime: d,
            }))
        )
          d = lastMenuDeliveryDate;
      }
    }
    user?.rosters?.forEach(({ roster }) => {
      const termId = roster?.term?.id;
      if (termId && !termIds.includes(termId)) termIds.push(termId);
    });
    return { userRosterTermIds: termIds, date: d };
  }, [user, rosterUpdates]);

  const [confirmation, setConfirmation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [schoolWarning, setSchoolWarning] = useState(null);

  const { forceShowUsaState = true } = useQueryParams();
  const hiddenFields = useMemo(() => {
    const f = hf || [];
    if (forceShowUsaState) return f.filter((f) => f !== 'usaState');
    return f;
  }, [hf, forceShowUsaState]);

  const handleArchiveUser = async (bool) => {
    // Confirm archive
    if (bool) {
      setConfirmation({
        title: 'Confirm Archive User',
        message:
          'You are about to archive this user. You can unarchive this user at any time.',
        onConfirm: () => onArchiveUser(bool),
        onCancel: () => setConfirmation(null),
        confirmText: 'Archive user',
        cancelText: 'Cancel',
      });
      return;
    }
    // else unarchive
    return onArchiveUser(bool);
  };

  const handleIsAutocompleteFieldOpen = (id, isDropdownOpenCb) => {
    const val = valuesRef.current[id];
    if (isDropdownOpenCb) {
      isDropdownOpenCb(val); // TODO!
    }
    return;
  };

  const handleSubmitForm = async (vals) => {
    const isValid = AccountUserSchema.isValidSync(vals);
    if (!isValid) {
      console.error('Invalid account user form', vals);
    }

    const termIds = [];
    Object.keys(vals).forEach((key) => {
      if (key.includes('rosterId:')) {
        const [, , termId] = key.split(':');
        if (termId && !termIds.includes(termId)) termIds.push(termId);
      }
    });

    const rosterIds = [];
    termIds.forEach((termId) => {
      if (vals[`rosterId:${GRADE}:${termId}`])
        rosterIds.push(vals[`rosterId:${GRADE}:${termId}`]);
      if (vals[`rosterId:${HOMEROOM}:${termId}`])
        rosterIds.push(vals[`rosterId:${HOMEROOM}:${termId}`]);
      if (vals[`rosterId:${MEAL_PERIOD}:${termId}`])
        rosterIds.push(vals[`rosterId:${MEAL_PERIOD}:${termId}`]);
    });

    const { school, undefined, ...data } = vals;
    const accountUser = {
      ...data,
      rosterIds,
    };
    if (onSubmit) return onSubmit(accountUser);
  };

  const getRosterFieldValues = (u) => {
    let s = null;
    const rIds = {};
    if (u?.rosters) {
      u.rosters.forEach(({ roster: r }) => {
        const termId = r?.term?.id;
        if (!s) s = r.term?.location?.address?.stateProvince;
        if (
          r.group === GRADE ||
          r.group === HOMEROOM ||
          r.group === MEAL_PERIOD ||
          r.group === LOCATION
        ) {
          rIds[`rosterId:${[r.group]}:${termId}`] = r.id;
        }
      });
    } else {
      // if (u?.[`rosterId:${GRADE}:${termId}`]) {
      //   rIds[`rosterId:${GRADE}:${termId}`] = u[`rosterId:${GRADE}:${termId}`];
      // }
      // if (u?.[`rosterId:${HOMEROOM}:${termId}`]) {
      //   rIds[`rosterId:${HOMEROOM}:${termId}`] = u[`rosterId:${HOMEROOM}:${termId}`];
      // }
      // if (u?.[`rosterId:${MEAL_PERIOD}:${termId}`]) {
      //   rIds[`rosterId:${MEAL_PERIOD}:${termId}`] = u[`rosterId:${MEAL_PERIOD}:${termId}`];
      // }
    }
    return { rosterFieldValues: rIds, locationUsaState: s };
  };
  const { rosterFieldValues, locationUsaState } = useMemo(
    () => getRosterFieldValues(user),
    [user],
  );

  const formik = useFormik({
    initialValues: isEditMode
      ? {
          usaState: locationUsaState || usaState || '',
          locationId: locationId || locId || '',
          ...user,
          isArchived,
          role,
          ...au,
          ...rosterFieldValues,
        }
      : {
          avatarUri: '',
          pronouns: '',
          preferredName: '',
          firstName: '',
          middleName: '',
          lastName: '',
          suffix: '',
          emailAddress: '',
          phoneNumber: '',
          locationId: locationId || locId || '',
          role: USER_ROLES.STUDENT,
          isArchived: false,
          usaState: usaState || '',
        },
    validationSchema: AccountUserSchema,
    enableReinitialize: true,
    onSubmit: handleSubmitForm,
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    setFieldTouched,
    isSubmitting,
  } = formik;

  const valuesRef = useRef(values);
  useEffect(() => {
    valuesRef.current = values;
  }, [values]);

  const handleDropdownSelection = (e, id, cb, value) => {
    const v = value || e?.target?.value;
    valuesRef.current = { ...valuesRef.current, [id]: v };
    handleChange(id)(v || '');
    setTimeout(() => setFieldTouched(id, !!v));
    if (cb) cb(v, id);
  };

  const handleTextFieldChange = (e, id, cb, value) => {
    const v = value || e?.target?.value;
    valuesRef.current = { ...valuesRef.current, [id]: v };
    handleChange(id)(v || '');
    setTimeout(() => setFieldTouched(id, !!v));
    if (cb) cb(v, id);
  };

  const [
    { usaStateOptions, rostersLoading, rosterFields, hasUnsetRosters },
    {
      onUsaStateChange,
      onUsaStateFilterOptions,
      onSchoolSelection,
      onStateLocationsCallback,
      onTextFieldChange,
      getIsAutocompleteFieldOpen,
    },
  ] = useRosterSelection(
    {
      usaState: values.usaState,
      locationId: values.locationId,
      termIds: userRosterTermIds,
      date,
      ...rosterFieldValues,
    },
    {
      form: formik,
      isPublic: true,
      // queryLimit: 1000,
      // filterCb: (val) => (!!val?.locationId),
    },
  );

  const fields = useMemo(() => {
    const f = [
      {
        id: 'role',
        label: 'Role',
        type: 'dropdown',
        placeholder: 'Select a role',
        options: ROLES,
        disabled: isArchived || disabledFields.includes('role'),
      },
      {
        id: 'firstName',
        label: 'First Name',
        type: 'text',
        autoFocus: !forceOpen,
        disabled: isArchived || disabledFields.includes('firstName'),
      },
      {
        id: 'lastName',
        label: 'Last Name',
        type: 'text',
        disabled: isArchived || disabledFields.includes('lastName'),
      },
      {
        id: 'preferredName',
        label: 'Preferred Name (optional)',
        type: 'text',
        disabled: isArchived || disabledFields.includes('preferredName'),
      },
      // {
      //   id: 'birthday',
      //   label: 'Birthday',
      //   type: 'text', // TODO
      //   disabled: isArchived,
      // },
    ];

    if (userData?.isAccountAdmin) {
      f.push({
        id: 'phoneNumber',
        label: 'Mobile phone number',
        type: 'tel',
        disabled: isArchived || disabledFields.includes('phoneNumber'),
      });
      f.push({
        id: 'emailAddress',
        label: 'Email address',
        type: 'email',
        disabled: true,
      });
    }

    if ((values.role === STUDENT || values.role === STAFF) && !isArchived) {
      f.push({
        id: 'usaState',
        label: 'State',
        type: 'autocomplete',
        placeholder: 'Select state',
        cb: onUsaStateChange,
        options: usaStateOptions,
        filterOptions: onUsaStateFilterOptions || rostersLoading,
      });
      f.push({
        id: 'school',
        label: 'School',
        usState: values.usaState,
        selectedLocationId: values.locationId || locationId || locId,
        stateLocationsCallback: onStateLocationsCallback,
        filterCb: (val) => !!val?.locationId,
        type: 'schoolSelect',
        cb: onSchoolSelection,
        isDropdownOpenCb: (val) => {
          return val?.length > 0;
        },
        disabled:
          !values.usaState ||
          rostersLoading ||
          disabledFields.includes('school'),
        queryLimit: 1000,
      });

      rosterFields.forEach((field) => f.push(field));
    }
    return f.filter(({ id }) => !hiddenFields.includes(id));
  }, [
    values?.usaState,
    values?.locationId,
    values?.school,
    errors?.locationId,
    values?.role,
    errors?.school,
    schoolWarning,
    usaStateOptions,
    isEditMode,
    locationId,
    locId,
    disabledFields,
    hiddenFields,
    isArchived,
    userData?.isAccountAdmin,
    rosterFields,
    rostersLoading,
  ]);

  const renderDropdownField = ({
    id,
    label,
    options,
    placeholder,
    cb,
    disabled = false,
    dropdownParams = null,
  }) => {
    return (
      <FieldWrapper key={id}>
        <FormControl
          label={label}
          placeholder={placeholder}
          sx={{ width: '100%' }}
        >
          <InputLabel id={id}>{label}</InputLabel>
          <Select
            id={id}
            value={values[id] || ''}
            onChange={(e) => handleDropdownSelection(e, id, cb)}
            label={label}
            sx={{ width: '100%' }}
            disabled={disabled}
            {...dropdownParams}
          >
            {options.map(({ value, label }) => {
              return (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        {!!(touched[id] && errors[id]) && <Form.Error>{errors[id]}</Form.Error>}
      </FieldWrapper>
    );
  };

  const renderAutocompleteField = ({
    id,
    label,
    options,
    placeholder = null,
    autoFocus = false,
    autoComplete = null,
    cb,
    isDropdownOpenCb,
    ...rest
  }) => {
    return (
      <FieldWrapper key={id}>
        <Autocomplete
          id={id}
          label={label}
          placeholder={placeholder}
          options={options}
          value={values[id] || ''}
          onChange={(e, { value } = {}) =>
            handleTextFieldChange(e, id, cb, value)
          }
          autoComplete={true}
          textFieldProps={{
            autoFocus,
            autoComplete,
            touched: touched,
            errors: errors,
            helperText: touched[id] && errors[id],
            fullWidth: true,
          }}
          open={handleIsAutocompleteFieldOpen(id, isDropdownOpenCb)}
          {...rest}
        />
      </FieldWrapper>
    );
  };

  const renderSchoolSelectField = ({
    id,
    label,
    placeholder = null,
    autoFocus = false,
    autoComplete = null,
    disabled,
    cb,
    isDropdownOpenCb,
    ...rest
  }) => {
    return (
      <FieldWrapper key={id}>
        <SchoolSelect
          id={id}
          label={label}
          placeholder={placeholder}
          // options={options}
          value={values[id] || ''}
          onChange={(e, v) => onTextFieldChange(e, id, cb, v?.value)}
          autoComplete={true}
          disabled={disabled}
          textFieldProps={{
            autoFocus,
            autoComplete,
            error: touched[id] && !!errors[id],
            helperText: touched[id] && errors[id],
            fullWidth: true,
          }}
          open={getIsAutocompleteFieldOpen(id, isDropdownOpenCb)}
          {...rest}
        />
        {!!errors[id] && <Form.Error>{errors[id]}</Form.Error>}
        {!!schoolWarning && <Form.Error>{schoolWarning}</Form.Error>}
      </FieldWrapper>
    );
  };

  const renderField = (field, idx) => {
    const {
      id,
      component,
      type,
      label,
      placeholder = null,
      autoFocus = false,
      autoComplete = null,
      disabled,
      cb,
    } = field;
    if (component) {
      const C = component;
      return (
        <FieldWrapper key={id}>
          <C
            onChange={(e, value) => handleTextFieldChange(e, id, cb, value)}
            // data={data}
            disabled={disabled}
          />
          {!!(touched[id] && errors[id]) && (
            <Form.Error>{errors[id]}</Form.Error>
          )}
        </FieldWrapper>
      );
    }
    if (type === 'divider')
      return (
        <Divider
          key={id || `divider-${idx}`}
          sx={{ marginTop: '1.5rem', marginBottom: '1.5rem' }}
        />
      );
    if (type === 'dropdown') return renderDropdownField(field);
    if (type === 'autocomplete') return renderAutocompleteField(field);
    if (type === 'schoolSelect') return renderSchoolSelectField(field);
    return (
      <FieldWrapper key={id}>
        <TextField
          id={id}
          name={id}
          label={label}
          type={type}
          onChange={(e) => handleTextFieldChange(e, id, cb)}
          value={values[id] || ''}
          placeholder={placeholder}
          autoFocus={autoFocus}
          autoComplete={autoComplete}
          error={touched[id] && !!errors[id]}
          helperText={touched[id] && errors[id]}
          disabled={disabled}
          fullWidth
        />
      </FieldWrapper>
    );
  };

  const renderRosterMessage = () => {
    if (!(hasUnsetRosters && [STAFF, STUDENT].includes(values.role)))
      return null;
    return rostersLoading ? null : (
      <Error
        content={'Select grade, homeroom, and/or meal period for current term.'}
      />
    );
  };

  const renderRostersLoading = () => {
    if (![STAFF, STUDENT].includes(values.role)) return null;
    return rostersLoading ? <Spinner /> : null;
  };

  const renderAccountUserForm = () => {
    return (
      <Form onSubmit={handleSubmit}>
        <Card.Content>
          {fields.map(renderField)}
          {renderRosterMessage()}
          {renderRostersLoading()}
        </Card.Content>
      </Form>
    );
  };

  const renderContent = renderAccountUserForm;

  const actions = useMemo(() => {
    const a = [];
    if (!isArchived) {
      a.push({
        label: isEditMode ? 'Save' : 'Add',
        onClick: handleSubmit,
        disabled:
          isSubmitting ||
          loading ||
          actionLoading ||
          ([STAFF, STUDENT].includes(values.role) &&
            (hasUnsetRosters || rostersLoading)),
        loading: isSubmitting || loading || actionLoading,
      });
      if (isEditMode && showArchiveAction && onArchiveUser) {
        a.push({
          id: 'archive-action',
          component: () => (
            <SecondaryActionText
              text="User no longer active?"
              actionLabel="Archive user."
              onClick={() => handleArchiveUser(true)}
            />
          ),
        });
      }
    } else if (onArchiveUser) {
      a.push({
        label: 'Unarchive user',
        onClick: () => handleArchiveUser(false),
        disabled: isSubmitting || loading || actionLoading,
        loading: isSubmitting || loading || actionLoading,
      });
    }
    return a;
  }, [
    isArchived,
    showArchiveAction,
    isEditMode,
    isSubmitting,
    loading,
    values.role,
    hasUnsetRosters,
    rostersLoading,
  ]);

  const renderConfirmationAlert = () => {
    if (!confirmation) return null;
    return <Dialog {...confirmation} />;
  };

  return (
    <ModalAdvanced
      open={isOpen}
      forceOpen={forceOpen}
      title={
        isEditMode
          ? `Edit ${user?.preferredName || user?.firstName}`
          : 'Add family member'
      }
      onClose={onClose}
      actions={actions}
    >
      {renderContent()}
      {renderConfirmationAlert()}
    </ModalAdvanced>
  );
};

AccountUserForm.defaultProps = {
  forceOpen: false,
  locationId: null,
  disabledFields: [],
  hiddenFields: [],
  showArchiveAction: false,
  actionLoading: false,
};

export default AccountUserForm;
