import { useMemo } from 'react';
import styled from '@emotion/styled';
import { Heading, Currency } from '../../common';

const Wrapper = styled.span`
  display: flex;
  flex: 1;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  margin: 0.5rem 0;
`;

const TotalWrapper = styled(Wrapper)`
  border-top: 4px double var(--darkGray);
  margin-top: 0.5rem;
  padding-top: 0.5rem;
`;

const OrderSummary = ({
  showHeading,
  headingText,
  showSubTotalItemCount,
  renderHeading,
  order,
}) => {
  const {
    currencyCode, sponsored, quantity, refunded, voided, subtotal, taxAmount, total, coupon,
  } = useMemo(
    () => order || {},
    [order]
  );

  const subtotalLabel = useMemo(() => {
    return showSubTotalItemCount
      ? quantity === 1 ? `${quantity} item:` : `${quantity} items`
      : 'Subtotal';
  }, [quantity, showSubTotalItemCount]);

  const renderSummaryRow = (label, amount, WrapperEl = Wrapper) => {
    return (
      <WrapperEl>
        <span>{label}</span>
        <Currency amount={amount} currencyCode={currencyCode} />
      </WrapperEl>
    );
  };

  const renderDiscountRow = ({ discountAmount, code }, WrapperEl = Wrapper) => {
    const label = code ? `Discount (${code})` : 'Discount';
    return renderSummaryRow(label, -(discountAmount), WrapperEl);
  };

  const renderSectionHeader = () => renderHeading
    ? renderHeading()
    : <Heading variant="h6">{headingText}</Heading>;

  return (
    <>
      {showHeading && renderSectionHeader()}
      {renderSummaryRow(subtotalLabel, subtotal)}
      {coupon && renderDiscountRow(coupon)}
      {!!refunded && renderSummaryRow('Refunded', refunded)}
      {!!voided && renderSummaryRow('Voided', voided)}
      {renderSummaryRow('Tax', taxAmount)}
      {!!sponsored && renderSummaryRow('Meal Plan', -(sponsored))}
      {renderSummaryRow('Total', total, TotalWrapper)}
    </>
  );
};

OrderSummary.defaultProps = {
  order: null,
  showHeading: true,
  headingText: 'Order Summary',
  showSubTotalItemCount: false,
  coupons: [],
  appliedCoupon: null,
  couponValidation: null,
};

export default OrderSummary;
