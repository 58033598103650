import { useState } from 'react';
import { ModalAdvanced, Card, Error } from '../../common';
import { sendResetPasswordEmail } from '../../firebase/auth';
import useMe from '../../hooks/useMe';

const ResetPassword = ( { onClose }) => {
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState(null);
  const { me } = useMe();

  const handleResetPassword = async () => {
    setError(null);
    setLoading(true);
    await sendResetPasswordEmail(me.emailAddress)
      .then(() => setEmailSent(true))
      .catch((err) => setError('There was an error sending reset password email. Please try again.'));
    setLoading(false);
  };

  const renderError = () => {
    if (!error) return null;
    return (<>
      <br />
      <Error header="Reset Password Error" content={error} />
    </>);
  };


  const renderContent = () => {
    const message = emailSent
      ? (
        <>
          An email has been sent to <b>{me.emailAddress}</b> with instructions to reset your password.
        </>
      ) : (
        <>
          You will receive an email at <b>{me?.emailAddress}</b> with a link to reset your password.
        </>
      );
    return (
      <Card.Content>
        {message}
        {renderError()}
      </Card.Content>
    );
  };

  return (
    <ModalAdvanced
      title="Change my password"
      onClose={onClose}
      actions={!emailSent
        ? [{
          label: 'Email reset password link',
          onClick: handleResetPassword,
          disabled: loading,
          loading,
        }]
        : [{
          label: 'Close',
          onClick: onClose,
        }]
      }
    >
      {renderContent()}
    </ModalAdvanced>
  );
};

export default ResetPassword;
