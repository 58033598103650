import styled from '@emotion/styled';
import Link from '../../common/Link';

const Wrapper = styled.div`
  margin-top: 1.5rem;
  text-align: center;
  text-wrap: balanced;
`;

const Text = styled.span`
  color: var(--darkGray);
`;

const TosPrivacyStatement = () => {
  return (
    <Wrapper>
      <Text>
        By clicking above, you agree to our Terms of Use and Privacy Policy.
      </Text>
    </Wrapper>
  );

  // TODO Temp disable links on TOS, PP
  return (
    <Wrapper>
      <Text>
        By clicking above, you agree to our <Link route={process.env.TOS_URI} target="_blank">Terms of Use</Link> and <Link route={process.env.PRIVACY_URI} target="_blank">Privacy Policy</Link>.
      </Text>
    </Wrapper>
  );
};

export default TosPrivacyStatement;
