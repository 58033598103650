import { useMemo, useEffect, useCallback, useRef } from 'react';
import { createPortal } from 'react-dom';
import styled from '@emotion/styled';
import { useFormik } from 'formik';
import useViewport from '../../hooks/useViewport';
import {
  Card,
  Form,
  Box,
  Menu,
  TextField,
  Select,
  Autocomplete,
  Button,
  Heading,
  Divider,
} from '../../common';
// import SchoolSelect from '../SchoolSelect';
// TODO
import SchoolSelect from '../SchoolSelectRegistration';

const { Control: FormControl, InputLabel } = Form;
const { Item: MenuItem } = Menu;

const Wrapper = styled(Box)`
  width: 500px;
  max-width: 100%;
`;

const FieldWrapper = styled.div`
  margin-bottom: 10px !important;
`;

const ContentWrapper = styled(Box)`
  margin-bottom: 1.25rem;
  text-align: center;
`;

const ActionsWrapper = styled(Box)`
  display: flex;
  flex: 1 auto;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  gap: 1rem;
`;

const HeaderWrapper = styled(Box)`
  display: flex;
  flex: 1 auto;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
`;

const TitleWrapper = styled(Box)`
  margin-bottom: 1rem;
`;

const Description = styled.p`
  margin-top: 1rem;
  text-wrap: balance;
`;

const MobileActionsWrapper = styled(ActionsWrapper)`
  flex-direction: column;
`;

const WizardStep = ({
  data,
  node,
  wrapper,
  showActionsInHeader,
  onChange,
  onUpdate,
  sx,
}) => {
  const { isMobile } = useViewport();
  const {
    component,
    icon,
    title,
    description,
    content,
    wrapper: nodeWrapper,
    form,
    footer,
    titleAlign = 'left',
  } = node;

  const isFormAutoFilled = useMemo(() => {
    // Override isDirty to enable action button if field(s) pre-filled
    let isAutoFilled = false;
    if (node?.form?.fields?.length) {
      isAutoFilled = node.form.fields.some(({ id }) => {
        if (
          node?.form?.initialValues?.[id] !== undefined ||
          node?.form?.initialValues?.[id] !== null ||
          data?.[id] !== undefined ||
          data?.[id] !== null
        ) {
          return true;
        }
        return false;
      });
    }
    return isAutoFilled;
  }, [data, node]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...node?.form?.initialValues, ...data },
    validationSchema: node?.form?.validationSchema,
    onSubmit: () => null,
  });

  const {
    values,
    errors,
    touched,
    dirty,
    handleSubmit,
    handleChange,
    setFieldTouched,
    setFieldValue,
    isSubmitting,
  } = formik;

  useEffect(() => {
    Object.keys(data).forEach((key) => {
      if (data[key] !== values[key]) {
        setFieldValue(key, data[key]);
      }
    });
  }, [data]);

  const valuesRef = useRef(values);
  useEffect(() => {
    valuesRef.current = values;
  }, [values]);

  useEffect(() => {
    if (node?.form?.setValues) {
      node?.form?.setValues(values, setFieldValue);
    }
  }, [values, node?.form?.setValues]);

  const handleIsAutocompleteFieldOpen = (id, isDropdownOpenCb) => {
    const val = valuesRef.current[id];
    if (isDropdownOpenCb) {
      isDropdownOpenCb(val); // TODO!
    }
    return;
  };

  const handleButtonClick = useCallback((nextNodeId, keyValues, cb) => {
    const valuesState = valuesRef.current;
    if (form?.fields?.length) {
      form.fields.forEach(({ id }) => {
        if (!touched[id]) setTimeout(() => setFieldTouched(id, true));
      });
    }
    let isValid = true;
    if (node?.form?.validationSchema) {
      isValid = node.form.validationSchema.isValidSync(valuesState);
    }
    if (isValid) onChange(valuesState, nextNodeId, keyValues, cb);
  }, []);

  const handleDropdownSelection = (e, id, cb, value) => {
    const v = value || e?.target?.value;
    valuesRef.current = { ...valuesRef.current, [id]: v };
    handleChange(id)(v || '');
    setTimeout(() => setFieldTouched(id, !!v));
    if (cb) cb(v, id);
  };

  const handleTextFieldChange = (e, id, cb, value) => {
    const v = value || e?.target?.value;
    valuesRef.current = { ...valuesRef.current, [id]: v };
    handleChange(id)(v || '');
    setTimeout(() => setFieldTouched(id, !!v));
    if (cb) cb(v, id);
  };

  const handleKeyDownEvent = useCallback((e) => {
    if (node?.form?.preventSubmitOnEnter) {
      return;
    }
    const params = form.actions[0];
    const { nextNodeId, value, cb } = params;
    const kv = value !== undefined ? { [node.id]: value } : null;

    switch (e.code) {
      case 'Enter': {
        handleButtonClick(nextNodeId, kv, cb);
        break;
      }
      case 'NumpadEnter': {
        handleButtonClick(nextNodeId, kv, cb);
        break;
      }
      default:
        break;
    }
  }, []);

  const isMountedRef = useRef(true);
  useEffect(() => {
    if (
      isMountedRef.current &&
      form?.fields?.length &&
      form?.actions?.length === 1
    ) {
      window.addEventListener('keydown', handleKeyDownEvent);
      return () => {
        isMountedRef.current = false;
        window.removeEventListener('keydown', handleKeyDownEvent);
      };
    }
  }, []);

  const renderDropdownField = ({
    id,
    label,
    options,
    placeholder,
    cb,
    dropdownParams = null,
  }) => {
    return (
      <FieldWrapper key={id}>
        <FormControl
          label={label}
          placeholder={placeholder}
          sx={{ width: '100%' }}
        >
          <InputLabel id={id}>{label}</InputLabel>
          <Select
            id={id}
            value={values[id] || ''}
            onChange={(e) => handleDropdownSelection(e, id, cb)}
            label={label}
            sx={{ width: '100%' }}
            {...dropdownParams}
          >
            {options.map(({ value, label }) => {
              return (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        {!!(touched[id] && errors[id]) && <Form.Error>{errors[id]}</Form.Error>}
      </FieldWrapper>
    );
  };

  const renderAutocompleteField = ({
    id,
    label,
    options,
    placeholder = null,
    autoFocus = false,
    autoComplete = null,
    cb,
    isDropdownOpenCb,
    ...rest
  }) => {
    return (
      <FieldWrapper key={id}>
        <Autocomplete
          id={id}
          label={label}
          placeholder={placeholder}
          options={options}
          value={values[id] || ''}
          onChange={(e, { value } = {}) =>
            handleTextFieldChange(e, id, cb, value)
          }
          autoComplete={true}
          textFieldProps={{
            autoFocus,
            autoComplete,
            error: touched[id] && !!errors[id],
            helperText: touched[id] && errors[id],
            fullWidth: true,
          }}
          open={handleIsAutocompleteFieldOpen(id, isDropdownOpenCb)}
          {...rest}
        />
      </FieldWrapper>
    );
  };

  const renderSchoolSelectField = ({
    id,
    label,
    placeholder = null,
    autoFocus = false,
    autoComplete = null,
    cb,
    isDropdownOpenCb,
    ...rest
  }) => {
    return (
      <FieldWrapper key={id}>
        <SchoolSelect
          id={id}
          label={label}
          placeholder={placeholder}
          // options={options}
          value={values[id] || ''}
          onChange={(e, v) => handleTextFieldChange(e, id, cb, v?.value)}
          autoComplete={true}
          textFieldProps={{
            autoFocus,
            autoComplete,
            error: touched[id] && !!errors[id],
            helperText: touched[id] && errors[id],
            fullWidth: true,
          }}
          open={handleIsAutocompleteFieldOpen(id, isDropdownOpenCb)}
          {...rest}
        />
      </FieldWrapper>
    );
  };

  const renderField = (field, idx) => {
    const {
      id,
      component,
      type,
      label,
      placeholder = null,
      autoFocus = false,
      autoComplete = null,
      disabled,
      cb,
    } = field;
    if (component) {
      const C = component;
      return (
        <FieldWrapper key={id}>
          <C
            onUpdate={(value, altId) => onUpdate({ [altId || id]: value })}
            onChange={(e, value) => handleTextFieldChange(e, id, cb, value)}
            data={data}
            disabled={disabled}
          />
          {!!(touched[id] && errors[id]) && (
            <Form.Error>{errors[id]}</Form.Error>
          )}
        </FieldWrapper>
      );
    }
    if (type === 'divider')
      return (
        <Divider
          key={id || `divider-${idx}`}
          sx={{ marginTop: '1.5rem', marginBottom: '1.5rem' }}
        />
      );
    if (type === 'dropdown') return renderDropdownField(field);
    if (type === 'autocomplete') return renderAutocompleteField(field);
    if (type === 'schoolSelect') return renderSchoolSelectField(field);
    return (
      <FieldWrapper key={id}>
        <TextField
          id={id}
          name={id}
          label={label}
          type={type}
          onChange={(e) => handleTextFieldChange(e, id, cb)}
          value={values[id] || ''}
          placeholder={placeholder}
          autoFocus={autoFocus}
          autoComplete={autoComplete}
          error={touched[id] && !!errors[id]}
          helperText={touched[id] && errors[id]}
          disabled={disabled}
          fullWidth
        />
      </FieldWrapper>
    );
  };

  const renderAction = (params) => {
    const {
      nextNodeId,
      label,
      cb,
      value,
      disabled = false,
      loading = false,
      ...buttonProps
    } = params;
    const kv = value !== undefined ? { [node.id]: value } : null;
    const isDirty =
      isFormAutoFilled || (form?.fields?.length === 0 ? true : dirty);
    const disabledParam =
      typeof disabled === 'function'
        ? disabled({ ...values, ...data })
        : disabled;
    const loadingParam =
      typeof loading === 'function' ? loading({ ...values, ...data }) : loading;
    const sxProps = isMobile ? {} : {};
    const isFullWidth = showActionsInHeader ? false : true;
    return (
      <Button
        disabled={disabledParam || isSubmitting || !isDirty}
        loading={loadingParam || isSubmitting}
        fullWidth={isFullWidth}
        {...sxProps}
        {...buttonProps}
        onClick={() => handleButtonClick(nextNodeId, kv, cb)}
      >
        {label}
      </Button>
    );
  };

  const renderActions = () => {
    const actions = form?.actions.map(renderAction);
    if (!showActionsInHeader) {
      const ActionsWrapperEl = isMobile ? MobileActionsWrapper : ActionsWrapper;
      return <ActionsWrapperEl>{actions}</ActionsWrapperEl>;
    }
    const actionsEl = document.getElementById('actions-portal');
    if (!actionsEl) {
      console.warn('No header element found');
      return null;
    }
    return createPortal(
      <Box sx={{ display: 'flex', flexGrow: 0, flexShrink: 1 }}>{actions}</Box>,
      actionsEl,
    );
  };

  const renderForm = () => {
    return (
      <div>
        <Form onSubmit={handleSubmit}>
          {form?.fields.map(renderField)}
          {renderActions()}
        </Form>
      </div>
    );
  };

  const renderContent = () => <ContentWrapper>{content}</ContentWrapper>;

  const renderIcon = () => {
    const iconSx = isMobile
      ? { fontSize: '5rem', marginBottom: '0.25rem' }
      : { fontSize: '5rem', marginBottom: '0.25rem' };
    const IconEl = icon;
    return (
      <ActionsWrapper>
        <IconEl sx={iconSx} />
      </ActionsWrapper>
    );
  };

  const renderTitle = () => {
    if (typeof title === 'string') {
      const headerSx = isMobile
        ? { paddingBottom: 0, textAlign: 'center', width: '100%' }
        : { paddingBottom: 0, textAlign: titleAlign };
      return (
        <Heading variant={isMobile ? 'h4' : 'h2'} sx={headerSx}>
          {title}
        </Heading>
      );
    }
    return <TitleWrapper>{title}</TitleWrapper>;
  };

  const renderDescription = () => {
    if (typeof description === 'string') {
      return <Description>{description}</Description>;
    }
    return description;
  };

  const renderHeader = () => {
    return (
      <HeaderWrapper>
        {title && renderTitle()}
        {description && renderDescription()}
      </HeaderWrapper>
    );
  };

  const renderStep = () => {
    const WrapperEl = nodeWrapper || wrapper || Wrapper;
    return (
      <Card sx={sx}>
        {icon && renderIcon()}
        {(title || description) && renderHeader()}
        <Card.Content>
          <WrapperEl>
            {content && renderContent()}
            {renderForm()}
          </WrapperEl>
        </Card.Content>
        {footer || null}
      </Card>
    );
  };

  const renderComponent = (C) => <C data={data} onClick={handleButtonClick} />;

  return component ? renderComponent(component) : renderStep();
};

export default WizardStep;
