import { Fragment } from 'react';
import styled from '@emotion/styled';
import useViewport from '../../hooks/useViewport';
import useMe from '../../hooks/useMe';
import { PageContainer, AccountUsersGrid } from '../../components';
import { Card } from '../../common';
import { PRONOUNS } from '../../utils/Consts';

const pronouns = [null];
for (const p in PRONOUNS) {
  pronouns.push(PRONOUNS[p]);
}

const Wrapper = styled.div`
  width: 80vw;
  max-width: 800px;
`;

const Container = styled.div`
  padding: 0;
  margin: 0;
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  /* flex: 1 auto; */
  align-items: center;
  justify-content: center;
`;

const Profile = () => {
  const { isMobile } = useViewport();
  const { loading: meLoading } = useMe();

  const renderPageContent = () => {
    const WrapperEl = isMobile ? Fragment : Wrapper;
    const sx = {
      paddingLeft: 0,
      paddingRight: 0,
    };
    return (
      <Card>
        <Card.Header
          title="My family"
          sx={{ ...sx, textAlign: 'center' }}
          titleTypographyProps={{ sx: { fontSize: '2.5rem' } }}
        />
        <Card.Content sx={sx}>
          <WrapperEl>
            <AccountUsersGrid />
          </WrapperEl>
        </Card.Content>
      </Card>
    );
  };

  return (
    <PageContainer
      container={isMobile ? null : Container}
      loading={meLoading}
      pageTitle="Account"
      showPageTitle={false}
    >
      {renderPageContent()}
    </PageContainer>
  );
};

export default Profile;
