import { useRef, useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';

const useObserver = ({
  onSetActiveId, scrollableElement, marginTop = 0, disabled = false,
}) => {
  const [sectionRefSize, setSectionRefSize] = useState(0);
  const sectionRefs = useRef(new Map());
  const isDebouncing = useRef(false);

  const setSectionRefSizeDb = useCallback(
    debounce((size) => {
      if (!isDebouncing.current) {
        setSectionRefSize(size);
      }
    }, 250),
    []
  );

  const setActiveIdDb = useCallback(
    debounce((id) => {
      isDebouncing.current = false;
      onSetActiveId(id);
    }, 200),
    [onSetActiveId]
  );

  const setSectionRef = useCallback((id, el) => {
    if (el) {
      sectionRefs.current.set(id, el);
    } else {
      sectionRefs.current.delete(id);
    }
    setSectionRefSizeDb(sectionRefs.current.size);
    return sectionRefs.current;
  }, []);

  const handleScroll = () => {
    isDebouncing.current = true;
    const scrollTop = scrollableElement.scrollTop + marginTop;
    let firstMatchId = null;
    sectionRefs.current.forEach((s) => {
      const sectionTop = s.offsetTop;
      const sectionHeight = s.offsetHeight;
      if (scrollTop >= sectionTop && scrollTop <= sectionTop + sectionHeight) {
        if (!firstMatchId) {
          firstMatchId = s.id;
        }
      }
    });
    if (firstMatchId) {
      setActiveIdDb(firstMatchId);
    }
  };

  useEffect(() => {
    if (disabled) return;
    scrollableElement.addEventListener('scroll', handleScroll);
    return () => scrollableElement.removeEventListener('scroll', handleScroll);
  }, [sectionRefSize, disabled]);

  return { setSectionRef, sectionRefs: sectionRefs.current };
};

export default useObserver;
