import { useState, useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import { isEqual, get } from 'lodash';
import { Tag } from '../../common';
import { USER_ROLES, USER_ROLES_LABELS, ROSTER_GROUPS } from '../../utils/Consts';
import RegisterAvatar from '../RegisterAvatar';
import AccountUserForm from '../AccountUserForm';

const { ADMIN, GUARDIAN, STAFF, STUDENT } = USER_ROLES;

const AvatarGrid = styled.div`
  width: 100%;
  display: grid;
  align-items: start;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
`;

const AccountUserWrapper = styled.div`
  max-width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 0;
  margin: auto;
`;

const AccountUserNameRoleWrapper = styled.div`
  text-align: center;
`;

const AccountUserName = styled.span`
`;

const AccountUserRole = styled.span`
  color: var(--darkGray);
`;

const RegisterAccountUsers = ({ data, onChange, onUpdate }) => {
  const initRoles = data?.roles?.split(',') || [];
  const [roles, setRoles] = useState(initRoles);
  const [showAccountUserForm, setShowAccountUserForm] = useState(false);
  const [accountUsers, setAccountUsers] = useState([]);
  const [selectedAccountUser, setSelectedAccountUser] = useState(null);

  const getDisplayName = (user) => (user?.preferredName || user?.firstName);

  const makeAccountUser = (userData) => {
    const adminUser = {
      user: {
        id: userData?.id || getDisplayName(userData),
        firstName: userData?.firstName,
        lastName: userData?.lastName,
        preferredName: userData?.preferredName,
        emailAddress: userData?.emailAddress,
        phoneNumber: userData?.phoneNumber,
      },
      roles: userData?.roles || [],
      locationId: null,
      isArchived: false,
    };
    if (!userData.roles && userData.role) adminUser.roles.push(userData.role);
    if (!adminUser.roles.includes(ADMIN)) adminUser.roles.push(ADMIN);
    if (
      adminUser.roles.includes(STAFF)
      || adminUser.roles.includes(STUDENT)
    ) {
      adminUser.locationId = userData?.locationId;
      adminUser.user.rosterIds = userData?.rosterIds || [];
      Object.keys(userData).forEach((key) => {
        if (key.includes('rosterId:') && userData[key]) {
          adminUser.user[key] = userData[key];
        }
      });
    }
    return adminUser;
  };

  const [showAdminAccountUserForm, setShowAdminAccountUserForm] = useState(false);
  const [adminAccountUser, setAdminAccountUser] = useState(makeAccountUser(data));

  const handleSetRole = (role) => {
    if (roles.includes(role)) {
      setRoles(roles.filter((r) => r !== role));
    } else {
      setRoles([...roles, role]);
    }
  };

  useEffect(() => {
    if (!onUpdate) return;
    // TODO Definitely not the most elegant solution, but it works for now
    const keyValsToUpdate = [];
    [
      'user.firstName',
      'user.lastName',
      'user.preferredName',
      'user.emailAddress',
      'user.phoneNumber',
      'user.rosterIds',
      `user.rosterId:${ROSTER_GROUPS.GRADE}`,
      `user.rosterId:${ROSTER_GROUPS.HOMEROOM}`,
      `user.rosterId:${ROSTER_GROUPS.MEAL_PERIOD}`,
      // 'locationId',
      // 'school', // TODO Need to check if user is student|staff for these
    ].forEach((key) => {
      const val = get(adminAccountUser, key);
      const compareVal = get(data, key);
      if (!isEqual(val, compareVal)) {
        keyValsToUpdate.push({ key, val });
      }
    });
    if (keyValsToUpdate.length > 0) {
      keyValsToUpdate.forEach(({ key, val }) => {
        const k = key.replace('user.', '');
        onUpdate(val || null, k);
      });
    }
  }, [adminAccountUser]);

  useEffect(() => {
    const aUsers = JSON.stringify(accountUsers);
    if (onChange) onChange(null, aUsers);
    if (onUpdate) onUpdate(aUsers);
  }, [accountUsers]);

  useEffect(() => {
    const adminUser = makeAccountUser(data);
    return setAdminAccountUser(adminUser);
  }, [data.firstName, data.lastName, data.emailAddress, data.phoneNumber, data.roles, data.role]);

  useEffect(() => {
    let au = [];
    let hasChange = false;
    if (data?.accountUsers) {
      try {
        au = JSON.parse(data.accountUsers);
        hasChange = au.some((u, idx) => {
          return !isEqual(u, accountUsers[idx]);
        });
      } catch (err) {
        console.error(err);
      }
    }
    if (hasChange) setAccountUsers(au);
  }, [data]);

  const handleToggleShowAdminAccountUserForm = () => {
    setShowAdminAccountUserForm((bool) => {
      return !bool;
    });
  };

  const handleToggleShowAccountUserForm = () => {
    setShowAccountUserForm((bool) => {
      if (bool) setSelectedAccountUser(null);
      return !bool;
    });
  };

  const handleSaveAccountUser = (idx, userData) => {
    const { isArchived = false, locationId = null, roles, role, ...user } = userData;
    const au = [...accountUsers];
    const aUser = {
      user,
      locationId,
      isArchived,
      roles: Array.isArray(roles) ? roles : [role],
    };
    if (idx !== null && idx !== undefined && idx >= 0) au[idx] = aUser;
    else au.push(aUser);
    setAccountUsers(au);
    handleToggleShowAccountUserForm();
  };

  const handleSaveAdminUser = (userData) => {
    const adminUser = makeAccountUser(userData);
    setAdminAccountUser(adminUser);
    handleToggleShowAdminAccountUserForm();
  };

  const handleSetAccountUser = (idx = -1) => {
    if (idx !== null && idx >= 0) {
      setSelectedAccountUser(idx);
    }
    setShowAccountUserForm(true);
  };

  const handleRemoveAccountUser = (idx) => {
    if (idx >= 0) {
      const au = [...accountUsers].splice(idx, 1);
      setAccountUsers(au);
    }
  };

  const forceOpenAdminUserForm = useMemo(() => {
    if (
      (
        !Array.isArray(adminAccountUser?.user?.rosterIds)
        || adminAccountUser?.user?.rosterIds?.length === 0
      ) && (
        adminAccountUser?.roles?.includes(STAFF)
        || adminAccountUser?.roles?.includes(STUDENT)
      )
    ) {
      setShowAdminAccountUserForm(true);
      return true;
    }
    return false;
  }, [adminAccountUser]);

  // const AccountUsersWrapperEl = isMobile ? MobileAccountUsersWrapper : AccountUsersWrapper;

  const renderAdminTag = () => {
    return (
      <Tag
        label={'Account admin'}
        size="small"
        sx={{ background: 'var(--orange)', color: 'var(--white)', marginTop: '0.5rem' }}
      />
    );
  };

  const renderNameRole = (name, role) => {
    const roleLabel = USER_ROLES_LABELS[role];
    return (
      <AccountUserNameRoleWrapper>
        <AccountUserName>{`${name}${!!roleLabel && ', '}`}</AccountUserName>
        {!!roleLabel && <AccountUserRole>{roleLabel}</AccountUserRole>}
      </AccountUserNameRoleWrapper>
    );
  };

  const renderAccountUser = ({ user, roles }, idx) => {
    const displayName = getDisplayName(user);
    const isAdmin = roles.includes(ADMIN);
    let role = null;
    if (roles.includes(STAFF)) role = STAFF;
    else if (roles.includes(STUDENT)) role = STUDENT;
    else if (roles.includes(GUARDIAN)) role = GUARDIAN;
    return (
      <AccountUserWrapper key={`${user.id || 'au'}-${idx || 'u'}`}>
        <RegisterAvatar
          onClick={isAdmin
            ? handleToggleShowAdminAccountUserForm
            : () => handleSetAccountUser(idx)
          }
        />
        {renderNameRole(displayName, role)}
        {isAdmin && renderAdminTag()}
      </AccountUserWrapper>
    );
  };

  const renderAddAccountUser = () => {
    return (
      <AccountUserWrapper>
        <RegisterAvatar
          onClick={() => handleSetAccountUser()}
          icon="add"
          iconColor="var(--black)"
          color="lightGray"
          random={false}
          sx={{
            '&:hover': { background: 'var(--white)' }
          }}
        />
      </AccountUserWrapper>
    );
  };

  const renderAccountUserForm = () => {
    if (!showAccountUserForm) return null;
    return (
      <AccountUserForm
        isOpen={showAccountUserForm}
        onClose={handleToggleShowAccountUserForm}
        userData={(selectedAccountUser === null)
          ? null
          : accountUsers[selectedAccountUser]
        }
        usaState={data?.usaState}
        locationId={data?.locationId}
        onSubmit={(userData) => handleSaveAccountUser(selectedAccountUser, userData)}
      />
    );
  };

  const renderAccountUsers = () => {
    return (
      <AvatarGrid>
        {adminAccountUser && renderAccountUser(adminAccountUser)}
        {accountUsers.map(renderAccountUser)}
        {renderAddAccountUser()}
      </AvatarGrid>
    );
  };

  const renderAdminAccountUserForm = () => {
    if (!showAdminAccountUserForm) return null;
    return (
      <AccountUserForm
        isOpen={showAdminAccountUserForm}
        forceOpen={forceOpenAdminUserForm}
        onClose={handleToggleShowAdminAccountUserForm}
        userData={(adminAccountUser)}
        usaState={data?.usaState}
        locationId={data?.locationId}
        onSubmit={handleSaveAdminUser}
        disabledFields={['emailAddress', 'school', 'locationId', 'role']}
      />
    );
  };

  return (
    <>
      {renderAccountUsers()}
      {renderAccountUserForm()}
      {renderAdminAccountUserForm()}
    </>
  );
};

export default RegisterAccountUsers;
