import { Fragment } from 'react';
import styled from '@emotion/styled';
import Box from '../Box';

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex: 1;
  padding: 5rem 1rem;
  color: var(--darkGray);
`;

const BorderWrapper = styled(Wrapper)`
  border-radius: 12px;
  border: 1px solid var(--darkGray);
`;

const EmptyState = ({ icon, iconSize, iconSx, message, showBorder, sx }) => {
  const WrapperEl = showBorder ? BorderWrapper : Wrapper;
  const IconEl = icon || Fragment;
  return (
    <WrapperEl sx={sx}>
      {icon && (
        <IconEl sx={{ fontSize: iconSize, marginBottom: '1rem', ...iconSx }} />
      )}
      {message}
    </WrapperEl>
  );
};

EmptyState.defaultProps = {
  showBorder: false,
  iconSize: '4rem',
  iconSx: null,
  sx: null,
};

export default EmptyState;
