import { useMemo, useEffect, useState, useRef } from 'react';
import { cloneDeep } from 'lodash';
import { ORDER_PRODUCTS_STATUSES } from '../../utils/Consts';
import { ModalAdvanced, Dialog, Error } from '../../common';
import OrderItems from '../OrderItems';

const OrderItemsEdit = ({ products, onEdit, onClose, loading }) => {
  const [revisedProducts, setRevisedProducts] = useState(cloneDeep(products));
  const [confirmation, setConfirmation] = useState(null);
  const [error, setError] = useState(null);

  const hasUpdatedProducts = useRef(false);

  const handleScroll = (id, ancestorId) => {
    const wrapperEl = document.getElementById(ancestorId);
    const topPos = wrapperEl?.offsetTop || 0;
    const el = document.getElementById(id);
    if (!el || !wrapperEl) return;
    const y = el.getBoundingClientRect().top - window.pageYOffset - topPos;
    if (y === 0) return;
    wrapperEl.scroll({ top: y, behavior: 'smooth' });
  };

  useEffect(() => {
    if (!error) return;
    handleScroll('edit-order-error', 'edit-order-items');
  }, [error]);

  const handleUpdateOrderItems = async () => {
    if (loading) return;
    const isSaved = await onEdit(revisedProducts, setConfirmation, setError);
    if (isSaved) onClose();
  };

  const handleClose = () => {
    if (loading) return;
    if (!hasUpdatedProducts.current) {
      onClose();
      return;
    }
    setConfirmation({
      title: 'Unsaved changes',
      message: 'You changes will not be saved. Are you sure you want to close?',
      onConfirm: onClose,
      onCancel: () => {
        setConfirmation(null);
      },
      confirmText: 'Close without saving',
      cancelText: 'Cancel',
    });
  };

  const handleEditOrderItem = (id, quantity) => {
    const foundProductIdx = products.findIndex((product) => product.id === id);
    if (foundProductIdx === -1) return;
    if (!hasUpdatedProducts.current) hasUpdatedProducts.current = true;
    setRevisedProducts((prevProducts) => {
      // TODO Add subroutine to update bundle item pricing, quantities, unitPrices
      const updatedProducts = [...prevProducts];
      updatedProducts[foundProductIdx].quantity = quantity;
      return updatedProducts;
    });
  };

  const actions = useMemo(() => {
    return [
      {
        label: 'Save order',
        onClick: handleUpdateOrderItems,
        loading,
      },
    ];
  }, [loading]);

  const renderConfirmationAlert = () => {
    if (!confirmation) return null;
    return <Dialog {...confirmation} />;
  };

  const renderError = () => {
    if (!error) return null;
    return (
      <>
        <br />
        <Error
          id="edit-order-error"
          header="Error saving order"
          content={error}
        />
      </>
    );
  };

  const renderContent = () => {
    return (
      <OrderItems
        products={revisedProducts}
        onEdit={handleEditOrderItem}
        showPrice={false}
        showBundleItemsQuantities={false}
      />
    );
  };

  return (
    <ModalAdvanced
      open={true}
      title="Edit Order"
      onClose={handleClose}
      showCloseButton={!loading}
      allowOutsideClickToClose={false}
      actions={actions}
      wrapperId="edit-order-items"
    >
      {renderContent()}
      {renderError()}
      {renderConfirmationAlert()}
    </ModalAdvanced>
  );
};

export default OrderItemsEdit;
