import { useState, useMemo, useEffect, useRef, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { debounce } from 'lodash';
import useQueryParams from '../../hooks/useQueryParams';
import useStore from '../../hooks/useStore';
import {
  encryptString,
  decryptString,
  constructQueryString,
} from '../../utils';
import OnboardStepper from '../OnboardStepper';
import Step from './Step';

// @flows step node schema
// {
//     [nodeId]: {
//         component?, // Take precedence!
//         title,
//         content,
//         header,
//         footer,
//         form: {
//             initialValues,
//             fields[],
//             actions[{ nextNodeId, label, cb }],
//             validationSchema,
//         },
//     }
// }

const Wizard = ({
  updateUrl,
  namespace,
  wrapper = null,
  flows,
  steps,
  onGetSteps,
  showStepper,
  onSetHeader,
  showActionsInHeader,
  rootNodeId,
  onChange,
  onInit,
  onGoBack,
  persistState,
  secureFields,
  sx,
}) => {
  const storeInstance = useStore(namespace);
  const { prev, nodeId: nId, ...queryParams } = useQueryParams();
  const history = useHistory();
  const location = useLocation();

  const { initState, secureState, initStep } = useMemo(() => {
    const storeInit = persistState ? { ...storeInstance.getAll() } : {};
    const sState = {};
    if (secureFields?.length > 0) {
      secureFields.forEach((key) => {
        // Remove secure fields from initState to decrypt in useEffect on mount
        if (Object.hasOwn(storeInit, key)) {
          sState[key] = storeInit[key];
          delete storeInit[key];
        }
      });
    }
    return {
      initState: storeInit,
      secureState: sState,
      initStep: storeInit?.rootNodeId
        ? flows?.[storeInit?.rootNodeId]?.step
        : 0,
    };
  }, []);

  const [state, setState] = useState(initState);
  const [activeStep, setActiveStep] = useState(initStep);
  const { currentNodeId, ...data } = state;
  const prevIdRef = useRef(null);
  const pendingUpdatesRef = useRef({});

  const handleSetStep = (nodeId) => {
    if (showStepper) {
      const step = flows?.[nodeId]?.step;
      if (step !== undefined && step !== null && step !== activeStep) {
        setActiveStep(step);
      }
    }
  };

  const handleSetStepDb = useCallback(debounce(handleSetStep, 20), [
    showStepper,
    flows,
    activeStep,
  ]);

  useEffect(() => {
    let nodeId = null;
    for (const key of Object.keys(flows)) {
      if (flows[key].step === activeStep) {
        nodeId = key;
        break;
      }
    }
    if (nodeId && updateUrl) {
      const qs = constructQueryString({ ...queryParams, nodeId });
      history.push({
        pathname: location.pathname,
        search: qs,
      });
    }
  }, [activeStep, updateUrl]);

  const handleClearState = () => {
    storeInstance.clear();
  };

  const transformData = async (data, transformer) => {
    const transformedData = {};
    await Promise.all(
      secureFields.map(async (field) => {
        if (!data[field]) return;
        const value = data[field];
        transformedData[field] = await transformer(value);
      }),
    );
    return transformedData;
  };

  useEffect(() => {
    return () => {
      handleClearState();
    };
  }, []);

  useEffect(() => {
    let decryptedData = {};
    if (secureFields.length > 0) {
      (async () => {
        // Note: More accurately, these fields are obfuscated with client-side encryption by default
        decryptedData = await transformData(secureState, decryptString);
        setState({ ...initState, ...decryptedData });
      })();
    }
    if (onInit) onInit({ ...initState, ...decryptedData }, flows);
  }, []);

  const handlePersistState = (state) => {
    storeInstance.setAll(state);
  };

  const flushUpdates = useCallback(() => {
    setState((s) => ({ ...s, ...pendingUpdatesRef.current }));
    handlePersistState(pendingUpdatesRef.current);
    pendingUpdatesRef.current = {};
  }, []);

  // TODO Disabling for now to avoid missing state updates
  const debouncedFlushUpdates = useCallback(debounce(flushUpdates, 0), []);

  const handleUpdateState = (values) => {
    pendingUpdatesRef.current = { ...pendingUpdatesRef.current, ...values };
    debouncedFlushUpdates();
  };

  const handleGoBack = useCallback((d) => {
    const prevNodeId = onGoBack(d);
    if (prevNodeId) {
      setState((s) => ({ ...s, currentNodeId: prevNodeId }));
    }
  }, []);

  useEffect(() => {
    handleSetStepDb(currentNodeId);
    if (rootNodeId !== currentNodeId) {
      (async () => {
        let s = state;
        if (secureFields.length > 0) {
          // Note: More accurately, these fields are obfuscated with client-side encryption by default
          const encryptedData = await transformData(state, encryptString);
          s = { ...state, ...encryptedData };
        }
        handlePersistState(s);
      })();
    }
  }, [state, data, rootNodeId, currentNodeId]);

  const currentNode = useMemo(() => {
    const id = updateUrl && nId ? nId : currentNodeId || rootNodeId;
    let n = flows?.[id];

    if (updateUrl && prev && prev !== prevIdRef.current) {
      n = flows?.[prev];
    }
    const node = n ? { ...n, id } : null;
    prevIdRef.current = node?.id;
    return node;
  }, [currentNodeId, flows, prev, nId]);

  const handleChange = useCallback(
    async (updatedData, nextId = null, value = null, cb) => {
      const updatedState = { ...state, ...updatedData, ...value };
      const nextNodeId = cb
        ? await cb(updatedState, {
            onClearState: handleClearState,
            onUpdateState: handleUpdateState,
          })
        : nextId;
      if (nextNodeId && nextNodeId !== currentNodeId) {
        prevIdRef.current = currentNodeId;
        updatedState.currentNodeId = nextNodeId;
      }
      if (onChange) onChange(updatedData, updatedState);
      if (updateUrl) {
        const qs = constructQueryString({ ...queryParams, nodeId: nextNodeId });
        history.push({
          pathname: location.pathname,
          search: qs,
        });
      }
      setState((s) => ({ ...s, ...updatedState }));
    },
    [updateUrl],
  );

  useEffect(() => {
    if (!showStepper || !onSetHeader) return;
    if (activeStep === null) {
      onSetHeader(null);
      return;
    }
    const s = onGetSteps ? onGetSteps(activeStep) : steps;
    const n = currentNodeId || rootNodeId;
    if (n && flows[n].step === null) {
      onInit({ ...state, currentNodeId: n });
      return;
    }

    onSetHeader(
      <OnboardStepper
        activeStep={currentNode?.step}
        steps={s}
        onGoBack={() => handleGoBack(state)}
        showBackButton
        showActionsPortal={showActionsInHeader}
      />,
    );
    return () => {
      onSetHeader(null);
    };
  }, [
    state,
    currentNodeId,
    handleGoBack,
    flows,
    showStepper,
    showActionsInHeader,
    activeStep,
    steps,
  ]);

  return currentNode ? (
    <Step
      key={currentNodeId}
      data={data}
      node={currentNode}
      wrapper={wrapper}
      sx={sx}
      onChange={handleChange}
      onUpdate={handleUpdateState}
      showActionsInHeader={showActionsInHeader}
    />
  ) : null;
};

Wizard.defaultProps = {
  sx: { paddingTop: '5rem' },
  secureFields: ['password', 'confirmPassword'],
  updateUrl: false,
  persistState: false, // TODO Temp default to avoid stuck state in signup flow
};

export default Wizard;
