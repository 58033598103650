import { ApolloClient, ApolloLink, split } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws';
import { RetryLink } from '@apollo/client/link/retry';
import { getMainDefinition } from '@apollo/client/utilities';
import cache from './cache';
import { getAuthToken } from '../firebase/auth';
import { sleep } from '../utils';

const BACKEND = 'backend';

export const backendContext = { context: { clientName: BACKEND } };

const GQL_API_URI = process.env.GQL_API_URI;
const BACKEND_GQL_API_URI = process.env.BACKEND_GQL_API_URI;
const WS_GQL_API_URI = GQL_API_URI.replace('https://', 'wss://');
const NAME = process.env.APP_NAME.replace(' ', '_');

const SLOW_THRESHOLD = 1000;

// TODO Temp extended timeout for slow queries
const MAX_REQUEST_TIMEOUT = 1000 * 60 * 0.6;

const defaultHeaders = {};

const fetchWithTimeout = (uri, options, timeout = MAX_REQUEST_TIMEOUT) => {
  const controller = new AbortController();
  const config = { ...options, signal: controller.signal };
  const timeoutId = setTimeout(() => controller.abort(), timeout);
  return fetch(uri, config)
    .then((response) => {
      clearTimeout(timeoutId);
      return response;
    })
    .catch((error) => {
      clearTimeout(timeoutId);
      throw error;
    });
};

const operationNamesRe = /\"operationName\":\"(.*?)\"/gm;
const getReMatched = (str, re = operationNamesRe) => {
  const matches = [];
  str.match(re).forEach((match) => {
    matches.push(match.replace(re, '$1'));
  });
  return matches;
};

const loggingGqlFetch = async (input, init) => {
  const body = init?.body ?? '[]';
  const start = Date.now();
  const response = await fetchWithTimeout(input, init);
  const end = Date.now();
  const requestTime = end - start;
  const isSlow = requestTime > SLOW_THRESHOLD;
  return {
    ...response,
    async text() {
      const result = await response.text();
      if (isSlow) {
        const operationNames = getReMatched(body).join(', ');
        console.warn(
          `SLOW ${NAME} • ${requestTime}ms (${
            result.length
          } bytes) • [${operationNames}] • Logged: ${new Date().toISOString()} | `,
          body,
        );
      }
      return result;
    },
  };
};

const getToken = async (retryCount = 0) => {
  let token = null;
  if (retryCount > 5) {
    console.warn('Unable to get gql token');
  }
  token = await getAuthToken();
  if (!token) {
    await sleep(500 * retryCount);
    token = await getToken(retryCount++);
  }
  return token;
};

const getHeaders = async (headers = null, withAuth = true) => {
  let h = { ...defaultHeaders, ...headers };
  if (withAuth) {
    const jwt = await getToken();
    if (jwt) {
      h['Authorization'] = `Bearer ${jwt}`;
      h['x-hasura-role'] = 'user'; // TODO User app always uses user role
    } else console.error('GQL call has no JWT');
  }
  return { headers: h };
};

// Create a WebSocket link
const wsLink = new WebSocketLink({
  uri: WS_GQL_API_URI,
  options: {
    reconnect: true,
    timeout: 30000,
    lazy: true,
    connectionParams: getHeaders,
  },
});

const authLink = setContext(async (_, { headers }) => {
  return getHeaders(headers, true);
});

const publicLink = setContext(async (_, { headers }) => {
  return getHeaders(headers, false);
});

// Docs: https://www.apollographql.com/docs/react/api/link/apollo-link-batch-http/
const batchHttpLink = new BatchHttpLink({
  uri: GQL_API_URI,
  batchMax: 1, // No more than N operations per batch
  batchInterval: 20, // Wait no more than N ms after first batched operation
  fetch: loggingGqlFetch,
});

const batchHttpBackendLink = new BatchHttpLink({
  uri: BACKEND_GQL_API_URI,
  batchMax: 1, // No more than N operations per batch
  batchInterval: 20, // Wait no more than N ms after first batched operation
  fetch: loggingGqlFetch,
});

const selectApiEndpoint = new ApolloLink((operation) => {
  const context = operation.getContext();
  const apiEndpoint =
    context.clientName === BACKEND ? batchHttpBackendLink : batchHttpLink;
  return apiEndpoint.request(operation);
});

const retryLink = new RetryLink();

// const httpLink = createHttpLink({ uri: GQL_API_URI });

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  authLink.concat(selectApiEndpoint),
  retryLink,
);

const clientConfig = {
  name: NAME,
  // version: VERSION,
  link: splitLink,
  shouldBatch: true,
  cache,
};

const client = new ApolloClient(clientConfig);

export const publicClient = new ApolloClient({
  name: NAME,
  link: publicLink.concat(batchHttpLink),
  shouldBatch: true,
  cache,
});

export default client;
