import React, {
  useReducer,
  useRef,
  useCallback,
  useContext,
  createContext,
} from 'react';
import { debounce } from 'lodash';

const GlobalContext = createContext({});

const INITIAL_STATE = {
  isAuthenticated: null,
  authUser: null,
  showMobileSidebar: false,
  showLogo: true,
  accountState: null,
  rosterUpdates: null,
};

const reducer = (state, action) => ({ ...state, ...action.payload });

export const GlobalContextProvider = ({ children }) => {
  const [globalState, dispatch] = useReducer(reducer, INITIAL_STATE);
  const pendingUpdatesRef = useRef({});

  const flushUpdates = useCallback(() => {
    dispatch({ type: 'update', payload: pendingUpdatesRef.current });
    pendingUpdatesRef.current = {};
  }, []);

  const debouncedFlushUpdates = useCallback(debounce(flushUpdates, 250), []);

  const handleSetGlobalState = useCallback(
    (payload) => {
      // Merge the new payload with existing pending updates
      pendingUpdatesRef.current = { ...pendingUpdatesRef.current, ...payload };
      debouncedFlushUpdates();
    },
    [debouncedFlushUpdates],
  );

  return (
    <GlobalContext.Provider value={[globalState, handleSetGlobalState]}>
      {children}
    </GlobalContext.Provider>
  );
};

const useGlobalContext = () => {
  const state = useContext(GlobalContext);
  return state;
};

export default useGlobalContext;
