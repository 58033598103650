import Star from '@mui/icons-material/Star';
import DinnerDining from '@mui/icons-material/DinnerDining';
import LocalPizza from '@mui/icons-material/LocalPizza';
import RamenDining from '@mui/icons-material/RamenDining';
import Fastfood from '@mui/icons-material/Fastfood';
import Icecream from '@mui/icons-material/Icecream';
import Cake from '@mui/icons-material/Cake';
import BrunchDining from '@mui/icons-material/BrunchDining';
import Bento from '@mui/icons-material/Bento';
import RiceBowl from '@mui/icons-material/RiceBowl';
import Tapas from '@mui/icons-material/Tapas';

export const NAME_SPACE = ''; // TODO

export const PRONOUNS = {
  THEY_THEM: 'They/Them',
  SHE_HER: 'She/Her',
  HE_HIM: 'He/Him',
};

export const ORDER_STATUSES = {
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED',
  FULFILLED: 'FULFILLED',
  CANCELLED: 'CANCELLED',
  GRATIS: 'GRATIS',
};

export const ORDER_STATUS_LABELS = {
  PENDING: 'Confirmed',
  COMPLETED: 'Completed',
  FULFILLED: 'Completed',
  CANCELLED: 'Cancelled',
  GRATIS: 'Gratis (Not Charged)',
};

export const ORDER_PRODUCTS_STATUSES = {
  PENDING: 'PENDING',
  FULFILLED: 'FULFILLED',
  CANCELLED: 'CANCELLED',
  FLAGGED: 'FLAGGED',
};

export const ORDER_PRODUCTS_STATUS_LABELS = {
  PENDING: 'Pending',
  FULFILLED: 'Fulfilled',
  CANCELLED: 'Cancelled',
  FLAGGED: 'Flagged',
};

export const PAYMENT_STATUSES = {
  VOID: 'VOID',
  PAID: 'PAID',
  UNPAID: 'UNPAID',
  REFUNDED: 'REFUNDED',
};

export const PAYMENT_STATUS_LABELS = {
  VOID: 'Void',
  PAID: 'Paid',
  UNPAID: 'Processing',
  REFUNDED: 'Refunded',
};

// https://mui.com/components/material-icons/
export const SECTION_ICONS = {
  STAR: Star,
  PIZZA: LocalPizza,
  RAMEN: RamenDining,
  BURGER: Fastfood,
  DINNER: DinnerDining,
  ICE_CREAM: Icecream,
  CAKE: Cake,
  BRUCH: BrunchDining,
  BENTO: Bento,
  RICE_BOWL: RiceBowl,
  TAPAS: Tapas,
};

export const USER_ROLES = {
  ADMIN: 'ADMIN',
  GUARDIAN: 'GUARDIAN',
  STAFF: 'STAFF',
  STUDENT: 'STUDENT',
};

export const USER_ROLES_LABELS = {
  ADMIN: 'Account admin',
  GUARDIAN: 'Parent',
  STAFF: 'Staff',
  STUDENT: 'Student',
};

export const USER_ROLES_ARR = [
  USER_ROLES.ADMIN,
  USER_ROLES.GUARDIAN,
  USER_ROLES.STAFF,
  USER_ROLES.STUDENT,
];

export const MENU_TYPES = {
  BREAKFAST: 'Menus::BreakfastMenu',
  LUNCH: 'Menus::LunchMenu',
  DINNER: 'Menus::DinnerMenu',
  HOME_DELIVERY: 'Menus::AtHomeMenu',
};

export const MENU_TYPE_SORT_ORDER = {
  [MENU_TYPES.BREAKFAST]: 1,
  [MENU_TYPES.LUNCH]: 2,
  [MENU_TYPES.DINNER]: 3,
  [MENU_TYPES.HOME_DELIVERY]: 4,
};

export const MENU_TYPE_LABELS = {
  [MENU_TYPES.BREAKFAST]: 'Breakfast Menu',
  [MENU_TYPES.LUNCH]: 'Lunch Menu',
  [MENU_TYPES.DINNER]: 'Dinner Menu',
  [MENU_TYPES.HOME_DELIVERY]: 'Home Delivery Menu',
};

export const ROSTER_GROUPS = {
  LOCATION: 'LOCATION',
  GRADE: 'GRADE',
  HOMEROOM: 'HOMEROOM',
  MEAL_PERIOD: 'MEAL_PERIOD',
};

export const ROSTER_GROUP_LABELS = {
  LOCATION: 'School',
  GRADE: 'Grade',
  HOMEROOM: 'Homeroom',
  MEAL_PERIOD: 'Meal period',
};

export const TICKET_FLAGS = {
  CANCEL_REQUEST: 'CANCEL_REQUEST',
  MISSING_ITEM: 'MISSING_ITEM',
  POOR_QUALITY: 'POOR_QUALITY',
  WRONG_ITEM: 'WRONG_ITEM',
  MENU_CHANGE: 'MENU_CHANGE',
  FINANCE_INQUIRY: 'FINANCE_INQUIRY',
  OTHER_ISSUE: 'OTHER_ISSUE',
};

export const TICKET_FLAGS_LABELS = {
  [TICKET_FLAGS.CANCEL_REQUEST]: 'Cancel an upcoming order',
  [TICKET_FLAGS.MISSING_ITEM]: 'My order was missing items',
  [TICKET_FLAGS.POOR_QUALITY]: 'My order had a quality issue',
  [TICKET_FLAGS.WRONG_ITEM]: 'Received wrong items',
  [TICKET_FLAGS.MENU_CHANGE]: 'Request a menu update',
  [TICKET_FLAGS.FINANCE_INQUIRY]: 'Get help with billing',
  [TICKET_FLAGS.OTHER_ISSUE]: 'Other Issue',
};

export const TAG_TYPES = {
  DIETARY: 'DIETARY',
  TEMPERATURE: 'TEMPERATURE',
  MEAL_TYPE: 'MEAL_TYPE',
};

export const TODO_TYPES = {
  ACCOUNT_DELINQUENT: 'AccountDelinquent',
  PLACE_USER_IN_ROSTER: 'PlaceAccountUser',
};

export const PRODUCT_REACTIONS = {
  LIKE: 'LIKE',
  DISLIKE: 'DISLIKE',
};

export const SUBSCRIPTIONS = 'SUBSCRIPTIONS';

export const SUBSCRIPTION_STATUSES = {
  ENABLED: 'enabled',
  CANCELLED: 'cancelled',
  PAUSED: 'paused',
};

export const SUBSCRIPTION_STATUS_LABELS = {
  ENABLED: 'Active',
  CANCELLED: 'Stopped',
  PAUSED: 'Paused',
};

export const SUBSCRIPTION_OPTIONS = {
  DIETARY: {
    OMNIVORE: 'OMNIVORE',
    VEGETARIAN: 'VEGETARIAN',
    NO_NUTS: 'NO_NUTS',
    NO_PORK: 'NO_PORK',
    NO_BEEF: 'NO_BEEF',
  },
  DAY: {
    MONDAY: 'MONDAY',
    TUESDAY: 'TUESDAY',
    WEDNESDAY: 'WEDNESDAY',
    THURSDAY: 'THURSDAY',
    FRIDAY: 'FRIDAY',
    SATURDAY: 'SATURDAY',
    SUNDAY: 'SUNDAY',
  },
  MEAL_TIME: {
    BREAKFAST: 'BREAKFAST',
    LUNCH: 'LUNCH',
    DINNER: 'DINNER',
    AM_SNACK: 'AM_SNACK',
    PM_SNACK: 'PM_SNACK',
  },
};

export const SUBSCRIPTION_OPTIONS_LABELS = {
  DIETARY: {
    [SUBSCRIPTION_OPTIONS.DIETARY.OMNIVORE]: 'No dietary needs',
    [SUBSCRIPTION_OPTIONS.DIETARY.VEGETARIAN]: 'Vegetarian',
    [SUBSCRIPTION_OPTIONS.DIETARY.NO_NUTS]: 'No Nuts',
    [SUBSCRIPTION_OPTIONS.DIETARY.NO_PORK]: 'No Pork',
    [SUBSCRIPTION_OPTIONS.DIETARY.NO_BEEF]: 'No Beef',
  },
  DIETARY_TEXT: {
    [SUBSCRIPTION_OPTIONS.DIETARY.OMNIVORE]: 'I eat everything!',
    [SUBSCRIPTION_OPTIONS.DIETARY.VEGETARIAN]: 'No meat or seafood.',
    [SUBSCRIPTION_OPTIONS.DIETARY.NO_NUTS]: 'No peanuts or tree-nuts.',
    [SUBSCRIPTION_OPTIONS.DIETARY.NO_PORK]: 'No products containing pork.',
    [SUBSCRIPTION_OPTIONS.DIETARY.NO_BEEF]: 'No products containing beef.',
  },
  DAY: {
    [SUBSCRIPTION_OPTIONS.DAY.MONDAY]: 'Monday',
    [SUBSCRIPTION_OPTIONS.DAY.TUESDAY]: 'Tuesday',
    [SUBSCRIPTION_OPTIONS.DAY.WEDNESDAY]: 'Wednesday',
    [SUBSCRIPTION_OPTIONS.DAY.THURSDAY]: 'Thursday',
    [SUBSCRIPTION_OPTIONS.DAY.FRIDAY]: 'Friday',
    [SUBSCRIPTION_OPTIONS.DAY.SATURDAY]: 'Saturday',
    [SUBSCRIPTION_OPTIONS.DAY.SUNDAY]: 'Sunday',
  },
  MEAL_TIME: {
    [SUBSCRIPTION_OPTIONS.MEAL_TIME.BREAKFAST]: 'Breakfast',
    [SUBSCRIPTION_OPTIONS.MEAL_TIME.LUNCH]: 'Lunch',
    [SUBSCRIPTION_OPTIONS.MEAL_TIME.DINNER]: 'Dinner',
    [SUBSCRIPTION_OPTIONS.MEAL_TIME.AM_SNACK]: 'AM Snack',
    [SUBSCRIPTION_OPTIONS.MEAL_TIME.PM_SNACK]: 'PM Snack',
  },
};
