import { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { client as httpClient } from '../../http';
import useAccount from '../../hooks/useAccount';
import useRosterSelection from '../../hooks/useRosterSelection';
import { ROSTER_GROUPS } from '../../utils/Consts';
import {
  ModalAdvanced,
  Heading,
  Form,
  Autocomplete,
  TextField,
  Card,
  Divider,
  Spinner,
} from '../../common';
import SchoolSelect, { NOT_LISTED } from '../SchoolSelect';

const { GRADE, HOMEROOM, MEAL_PERIOD } = ROSTER_GROUPS;

const FieldWrapper = styled.div`
  margin: 1rem 0 !important;
`;

const ArchiveMessage = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  font-weight: bold;
`;

const AccountUserSchema = Yup.object().shape({
  recId: Yup.string().nullable(),
  locationId: Yup.string().required('Please select a school'),
  isArchived: Yup.boolean().nullable(),
  rosterIds: Yup.array().of(Yup.string()),
});

const LocationRosterForm = ({
  locationId: locId,
  usaState: usState,
  userData,
  accountId,
  onClose,
  isOpen,
  forceOpen,
}) => {
  const isEditMode = !!userData;
  const { user, locationId, location, isMe, isArchived } = userData || {};

  const [loadingArchive, setLoadingArchive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [schoolWarning, setSchoolWarning] = useState(null);

  const userDisplayName = useMemo(() => {
    if (!user) return '';
    return user?.displayName || user?.preferredName || user?.firstName;
  }, [user]);

  const userFullName = useMemo(() => {
    if (!user) return '';
    return user?.fullName || `${user?.preferredName || user?.firstName} ${user?.lastName}`;
  }, [user]);

  const getRosterFieldValues = (u) => {
    const rIds = {};
    if (u?.rosters) {
      u.rosters.forEach(({ roster: r }) => {
        // TODO v1 only supporting grade, homeroom, and meal period
        if (
          r.group === GRADE
          || r.group === HOMEROOM
          || r.group === MEAL_PERIOD
        ) {
          rIds[`rosterId:${[r.group]}`] = r.id;
        }
      });
    }
    return rIds;
  };
  const rosterFieldValues = useMemo(() => getRosterFieldValues(user), [user]);

  const { refetch } = useAccount();

  const handleArchiveAccountUser = async () => {
    setLoadingArchive(true);
    const payload = {
      recId: userData?.id,
      locationId: null,
      isArchived: !isArchived,
    };
    const res = await httpClient.patch({
      url: `/accounts/${accountId}/users/${user.id}`,
      body: payload,
    });
    if (res?.result) {
      await refetch();
      if (onClose && payload.isArchived) onClose();
    }
    setLoadingArchive(false);
    // TODO Handle error
  };

  const handleUpdateAccountUser = async (vals) => {
    setLoading(true);
    const rosterIds = [];
    if (vals[`rosterId:${GRADE}`]) rosterIds.push(vals[`rosterId:${GRADE}`]);
    if (vals[`rosterId:${HOMEROOM}`]) rosterIds.push(vals[`rosterId:${HOMEROOM}`]);
    if (vals[`rosterId:${MEAL_PERIOD}`]) rosterIds.push(vals[`rosterId:${MEAL_PERIOD}`]);

    const accountUser = {
      recId: userData?.id,
      locationId: vals.locationId,
      rosterIds,
    };
    const res = await httpClient.patch({
      url: `/accounts/${accountId}/users/${user.id}`,
      body: accountUser,
    });
    if (res?.result) {
      await refetch();
      if (onClose) onClose();
    }
    // TODO Handle error
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      ...user,
      ...rosterFieldValues,
      usaState: location?.address?.stateProvince || usState || '*', // TODO School List query requires an init value
      school: '',
      locationId: isEditMode ? locationId : locId,
    },
    validationSchema: AccountUserSchema,
    onSubmit: handleUpdateAccountUser,
    enableReinitialize: true,
  });

  const { values, errors, touched, handleChange, handleSubmit, isSubmitting } = formik;

  useEffect(() => {
    if (values.school === NOT_LISTED) {
      setSchoolWarning('If you\'re unable to find the user\'s school, you can archive user. If you believe this is an error, please contact support via the Help menu.');
    }
    else {
      setSchoolWarning(null);
    }
  }, [values.school]);

  const [
    {
      usaStateOptions,
      rosters,
      rostersLoading,
      rosterFields,
      hasUnsetRosters,
    },
    {
      onUsaStateChange,
      onUsaStateFilterOptions,
      onSchoolSelection,
      onStateLocationsCallback,
      onTextFieldChange,
      getIsAutocompleteFieldOpen,
    }
  ] = useRosterSelection(
    {
      usaState: values.usaState,
      locationId: values.locationId,
      ...rosterFieldValues,
    },
    {
      form: formik,
      // queryLimit: 1000,
      // filterCb: (val) => (!!val?.locationId),
    }
  );

  const renderAutocompleteField = ({ id, label, options, placeholder = null, autoFocus = false, autoComplete = null, disabled, cb, isDropdownOpenCb, ...rest }) => {
    return (
      <FieldWrapper key={id}>
        <Autocomplete
          id={id}
          label={label}
          placeholder={placeholder}
          options={options}
          value={values[id] || ''}
          onChange={(e, { value } = {}) => onTextFieldChange(e, id, cb, value)}
          autoComplete={true}
          disabled={disabled}
          textFieldProps={{
            autoFocus,
            autoComplete,
            touched: touched,
            errors: errors,
            helperText: touched[id] && errors[id],
            fullWidth: true,
          }}
          open={getIsAutocompleteFieldOpen(id, isDropdownOpenCb)}
          {...rest}
        />
        {!!(errors[id]) && <Form.Error>{errors[id]}</Form.Error>}
      </FieldWrapper>
    );
  };

  // TODO Apply to StudentForm once working here

  const renderSchoolSelectField = ({ id, label, placeholder = null, autoFocus = false, autoComplete = null, disabled, cb, isDropdownOpenCb, ...rest }) => {
    return (
      <FieldWrapper key={id}>
        <SchoolSelect
          id={id}
          label={label}
          placeholder={placeholder}
          // options={options}
          value={values[id] || ''}
          onChange={(e, v) => onTextFieldChange(e, id, cb, v?.value)}
          autoComplete={true}
          disabled={disabled}
          textFieldProps={{
            autoFocus,
            autoComplete,
            error: touched[id] && !!(errors[id]),
            helperText: touched[id] && errors[id],
            fullWidth: true,
          }}
          open={getIsAutocompleteFieldOpen(id, isDropdownOpenCb)}
          {...rest}
        />
        {!!(errors[id]) && <Form.Error>{errors[id]}</Form.Error>}
        {!!(schoolWarning) && <Form.Error>{schoolWarning}</Form.Error>}
      </FieldWrapper>
    );
  };

  const fields = useMemo(() => {
    const f = [
      {
        id: 'usaState',
        label: 'State',
        type: 'autocomplete',
        placeholder: 'Select state',
        cb: onUsaStateChange,
        options: usaStateOptions,
        filterOptions: onUsaStateFilterOptions || rostersLoading,
      },
      {
        id: 'school',
        label: 'School',
        usState: values.usaState,
        selectedLocationId: values.locationId || (isEditMode ? locationId : locId),
        stateLocationsCallback: onStateLocationsCallback,
        filterCb: (val) => (!!val?.locationId),
        type: 'schoolSelect',
        cb: onSchoolSelection,
        isDropdownOpenCb: (val) => {
          return val?.length > 0;
        },
        disabled: !values.usaState || rostersLoading,
        queryLimit: 1000,
      },
      ...rosterFields,
    ];
    return f;
  }, [
    values.usaState,
    values.locationId,
    values.school,
    errors,
    schoolWarning,
    usaStateOptions,
    rosterFields,
    rostersLoading,
    isEditMode,
  ]);

  const renderField = (field, idx) => {
    const { id, component, type, label, placeholder = null, autoFocus = false, autoComplete = null, disabled, cb, ...rest } = field;
    if (component) {
      const C = component;
      return (
        <FieldWrapper key={id}>
          <C
            onChange={(e, value) => onTextFieldChange(e, id, cb, value)}
            // data={data}
            disabled={disabled}
          />
          {!!(touched[id] && errors[id]) && <Form.Error>{errors[id]}</Form.Error>}
        </FieldWrapper>
      );
    }
    if (type === 'divider') return (
      <Divider
        key={id || `divider-${idx}`}
        sx={{ marginTop: '1.5rem', marginBottom: '1.5rem' }}
      />
    );
    // if (type === 'dropdown') return (
    //   renderDropdownField(field)
    // );
    if (type === 'autocomplete') return (
      renderAutocompleteField(field)
    );
    if (type === 'schoolSelect') return (
      renderSchoolSelectField(field)
    );
    return (
      <FieldWrapper key={id}>
        <TextField
          id={id}
          name={id}
          label={label}
          type={type}
          onChange={handleChange}
          value={values[id] || ''}
          placeholder={placeholder}
          autoFocus={autoFocus}
          autoComplete={autoComplete}
          error={touched[id] && !!(errors[id])}
          helperText={touched[id] && errors[id]}
          fullWidth
          {...rest}
        />
      </FieldWrapper>
    );
  };

  const renderMessage = () => {
    const message = 'In order to place an order, you\'ll need to confirm some information for the current school year.';
    const archivedMessage = 'If the user below is no longer attending an available school, you can archive their account.';
    return (
      <Card.Content>
        {message}
        {!isMe && <ArchiveMessage>
          {archivedMessage}
        </ArchiveMessage>}
      </Card.Content>
    );
  };

  const renderStudentForm = () => {
    return (
      <Card.Content>
        {renderMessage()}
        <Heading variant="h5">
          {userFullName}
        </Heading>
        <Form onSubmit={handleSubmit}>
          {fields.map(renderField)}
          {rostersLoading && <Spinner />}
        </Form>
      </Card.Content>
    );
  };

  const renderContent = () => renderStudentForm();

  const actions = useMemo(() => {
    const a = [
      {
        label: 'Save school',
        onClick: handleSubmit,
        disabled: (
          isSubmitting
          || isArchived
          || !values['locationId']
          || hasUnsetRosters
        ),
        loading: isSubmitting || loading,
      }
    ];
    if (!isMe) {
      a.unshift(
        {
          label: isArchived
            ? `Unarchive ${userDisplayName}`
            : `Archive ${userDisplayName}`,
          variant: 'outlined',
          onClick: handleArchiveAccountUser,
          disabled: isSubmitting || loading,
          loading: loadingArchive,
        }
      );
    }
    return a;
  }, [isSubmitting, isArchived, userDisplayName, isMe, hasUnsetRosters, loading, loadingArchive, values['locationId']]);

  return (
    <ModalAdvanced
      open={isOpen}
      forceOpen={forceOpen}
      title="Set school"
      onClose={onClose}
      actions={actions}
    >
      {renderContent()}
    </ModalAdvanced>
  );
};

export default LocationRosterForm;
