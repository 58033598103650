import React, {
  useEffect, useState, useContext, createContext,
} from 'react';
import { breakpoints } from '../styles/Utils';

const viewportContext = createContext({});

export const ViewportProvider = ({ children }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [isShrunk, setShrunk] = useState(false);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  useEffect(() => {
    const handler = () => {
      setShrunk((isShrunk) => {
        if (
          !isShrunk &&
          (document.body.scrollTop > 20 ||
            document.documentElement.scrollTop > 20)
        ) {
          return true;
        }

        if (
          isShrunk &&
          document.body.scrollTop < 4 &&
          document.documentElement.scrollTop < 4
        ) {
          return false;
        }
        return isShrunk;
      });
    };

    window.addEventListener('scroll', handler);
    return () => window.removeEventListener('scroll', handler);
  }, []);

  return (
    React.createElement(
      viewportContext.Provider,
      { value: { width, height, isShrunk } },
      children
    )
  );
};

const useViewport = () => {
  const { width, height, isShrunk } = useContext(viewportContext);
  const isMobile = width <= breakpoints.s;
  const isTablet = width > breakpoints.s && width <= breakpoints.m;
  const isDesktop = width > breakpoints.m;
  const isLargeDesktop = width > breakpoints.xl && width <= breakpoints.xxl;
  const isVeryLargeDesktop = width > breakpoints.xxl;
  return {
    width, height, isMobile, isTablet, isDesktop, isLargeDesktop, isVeryLargeDesktop, isShrunk,
  };
};

export default useViewport;
