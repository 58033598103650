// TODO Refactor modal to be reusable (eg with MenuItemPreview)
import { useMemo } from 'react';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { client as httpClient } from '../../http';
import { useMutation } from '@apollo/client';
import { CREATE_USER, UPDATE_USER, CREATE_ACCOUNTS_USERS, UPDATE_ACCOUNTS_USERS } from '../../graphql/mutations';
import {
  ModalAdvanced,
  Form,
  TextField,
  Checkbox,
  Card,
} from '../../common';
import useAccount from '../../hooks/useAccount';
import { cleanInputs } from '../../utils';
import { PRONOUNS, USER_ROLES } from '../../utils/Consts';

const pronouns = [null];
for (const p in PRONOUNS) {
  pronouns.push(PRONOUNS[p]);
}

const FieldWrapper = styled.div`
  margin: 1rem 0 !important;
`;

const StudentSchema = Yup.object().shape({
  firstName: Yup.string().required('Please enter student\'s first name'),
  lastName: Yup.string().required('Please enter student\'s last name'),
  middleName: Yup.string().nullable(),
  suffix: Yup.string().nullable(),
  preferredName: Yup.string().nullable(),
  pronouns: Yup.mixed().oneOf(pronouns).nullable(),
  avatarUri: Yup.string().nullable(),
  phoneNumber: Yup.string().nullable(),
});

// TODO !!!! Remove location/roster here; make generic AccountUserForm to support updating user info (name). STAFF FORM will be refactored/renamed to manage location and rosters for STAFF|STUDENT au
// TODO Deprecate this form; use AccountUserForm instead (need to refactor AccountUserForm as it's tailored for registration flow atm)
const AcctUserForm = ({
  accountId,
  userData,
  onClose,
  isOpen,
}) => {
  const isEditMode = !!userData;

  const [createUser] = useMutation(CREATE_USER);
  const [createAccountsUsers] = useMutation(CREATE_ACCOUNTS_USERS);
  // const [updateAccountsUsers] = useMutation(UPDATE_ACCOUNTS_USERS);
  const [updateUser] = useMutation(UPDATE_USER);
  const { refetch } = useAccount(accountId);

  const { user, isArchived, isMe } = userData || {};

  const userDisplayName = useMemo(() => {
    if (!user) return '';
    return user?.displayName || user?.preferredName || user?.firstName;
  }, [user]);

  const handleArchiveAccountUser = async (payload) => {
    return httpClient.patch({
      url: `/accounts/${accountId}/users/${user.id}`,
      body: payload,
    });
    // TODO Handle error
  };

  const handleCreateUser = async (vals) => {
    const { ...user } = vals;
    const { data } = await createUser({ variables: { user: cleanInputs(user) } });
    const userId = data?.insert_users_one?.id;
    if (userId) {
      const accountUser = {
        accountId,
        userId,
        roles: [USER_ROLES.STUDENT],
      };
      await createAccountsUsers({ variables: accountUser });
      // TODO Handle errors (use mutation states above)
      await refetch();
      if (onClose) onClose();    }
  };

  const handleUpdateUser = async (vals) => {
    const { isArchived: isUserArchived, emailAddress, ...user } = vals;
    const u = {};
    // Update user fields
    [
      'avatarUri',
      'pronouns',
      'preferredName',
      'firstName',
      'middleName',
      'lastName',
      'suffix',
      'phoneNumber',
    ].forEach((key) => {
      u[key] = user[key] || null;
    });
    await updateUser({ variables: { id: user?.id, user: cleanInputs(u) } });

    const shouldUpdateAccountUser = isUserArchived !== isArchived;
    if (shouldUpdateAccountUser) {
      const payload = {
        recId: userData?.id,
        locationId: null,
        isArchived: isUserArchived,
      };
      await handleArchiveAccountUser(payload);
      // await updateAccountsUsers({ variables: accountUser });
    }
    await refetch();
    if (onClose) onClose();
  };

  const formik = useFormik({
    initialValues: isEditMode
      ? {...user, isArchived }
      : {
        avatarUri: '',
        pronouns: '',
        preferredName: '',
        firstName: '',
        middleName: '',
        lastName: '',
        suffix: '',
        emailAddress: '',
        phoneNumber: '',
        isArchived: false,
      },
    validationSchema: StudentSchema,
    onSubmit: isEditMode ? handleUpdateUser : handleCreateUser,
    enableReinitialize: true,
  });

  const { values, errors, touched, handleChange, handleSubmit, isSubmitting } = formik;

  const fields = useMemo(() => {
    const f = [
      {
        id: 'firstName',
        label: 'First Name',
        type: 'text',
        autoFocus: true,
        disabled: values.isArchived,
      },
      {
        id: 'lastName',
        label: 'Last Name',
        type: 'text',
        disabled: values.isArchived,
      },
      {
        id: 'preferredName',
        label: 'Preferred Name (optional)',
        type: 'text',
        disabled: values.isArchived,
      },
    ];
    if (isEditMode) {
      f.push({
        id: 'isArchived',
        label: `Archive ${userDisplayName}`,
        type: 'checkbox',
        disabled: isMe,
      });
    }
    return f;
  }, [isEditMode, isMe, values.isArchived]);

  const renderField = ({ id, type, label, placeholder = null, autoFocus = false, component, ...rest }) => {
    if (component) return component;
    if (type === 'checkbox') { // TODO Move to Form component to manage field types
      return (
        <FieldWrapper key={id}>
          <Form.ControlLabel
            control={
              <Checkbox
                {...rest}
                checked={values?.[id] || false}
                onChange={handleChange}
                name={id}
              />
            }
            label={label}
          />
        </FieldWrapper>
      );
    }
    return (
      <FieldWrapper key={id}>
        <TextField
          id={id}
          name={id}
          label={label}
          type={type}
          onChange={handleChange}
          value={values[id] || ''}
          placeholder={placeholder}
          autoFocus={autoFocus}
          error={touched[id] && !!(errors[id])}
          helperText={touched[id] && errors[id]}
          fullWidth
          {...rest}
        />
      </FieldWrapper>
    );
  };

  const renderStudentForm = () => {
    return (
      <Form onSubmit={handleSubmit}>
        <Card.Content>
          {fields.map(renderField)}
        </Card.Content>
      </Form>
    );
  };

  const renderContent = renderStudentForm;
  // TODO Label to use au display name
  return (
    <ModalAdvanced
      open={isOpen}
      title={isEditMode ? `Edit ${userDisplayName}` : 'Add user'}
      onClose={onClose}
      actions={[{
        label: isEditMode ? 'Save changes' : 'Add user',
        onClick: handleSubmit,
        disabled: isSubmitting,
        loading: isSubmitting,
      }]}
    >
      {renderContent()}
    </ModalAdvanced>
  );
};

export default AcctUserForm;
