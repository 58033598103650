import { Fragment, useState, useMemo, useRef } from 'react';
import styled from '@emotion/styled';
import useViewport from '../../hooks/useViewport';
import useMe from '../../hooks/useMe';
import useAccount from '../../hooks/useAccount';
import useGlobalContext from '../../hooks/useGlobalContext';
import useStripe from '../../hooks/useStripe';
import {
  sort,
  padStart,
  getNow,
  formatDateTime,
  isDateTimeBefore,
  getPaymentMethodLabelAndImage,
  getPaymentMethod3dsStatus,
} from '../../utils';
import StripePaymentMethodForm from '../StripePaymentMethodForm';
import { Heading, Button, Radio, Select, Form, Image, Menu, Error, Spinner, List, Divider, Dialog } from '../../common';
import PaymentIcon from '@mui/icons-material/Payment';
import HttpsIcon from '@mui/icons-material/Https';
import DeleteIcon from '@mui/icons-material/Delete';

const { Control: FormControl, InputLabel } = Form;
const { Item: MenuItem } = Menu;

const SectionWrapper = styled.div`
  padding: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const FieldWrapper = styled.div`
  margin: 4px 0 !important;
  width: 100%;
`;

const CreditCardLogo = styled(Image)`
  width: 3.25rem !important;
  vertical-align: middle;
  margin-right: 0.5rem;
`;

const CardExpiry = styled.span`
  color: var(--darkGray);
  font-size: 0.75rem;
  margin: 0;
  margin-left: 0.5rem;
  padding: 0;
`;

const CardExpired = styled(CardExpiry)`
  color: var(--red);
`;

const ListRowWrapper = styled.span`
  display: flex;
  flex: 1 1 auto;
  align-items: space-between;
  justify-content: space-between;
`;

const ActionsWrapper = styled.span`
  display: flex;
  flex-grow: 0;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const CardWrapper = styled.span`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

// TODO Can be refactored further to reduce duplicate code for options
const PaymentMethods = ({
  selectedPaymentMethodId,
  onSelectPaymentMethod,
  onUpdatePaymentMethod,
  onDeletePaymentMethod,
  showHeader,
  showAddPaymentMethod,
  autoSelectPaymentMethod,
  showOptionsAs,
  errors,
  formKey,
  fullWidthAddButton,
  actionButtonVariant,
  showActionButtonIcon,
  loading: isLoading,
  dividerPosition,
  disableForm,
}) => {
  const { isMobile } = useViewport();
  const [{ isEmbedded }] = useGlobalContext();
  const { stripeLoading, stripeError, stripePromise, StripeElements } = useStripe();
  const [showAddPaymentForm, setShowPaymentForm] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(selectedPaymentMethodId);
  const [confirmation, setConfirmation] = useState(null);
  const [initMyAccount, setInitMyAccounts] = useState(false);

  const { me, loading: meLoading } = useMe();
  const { account, accountId, refetch: refetchAccount, loading: accountLoading } = useAccount();
  const loading = isLoading || meLoading || accountLoading;

  const now = useRef(getNow());

  const handleTogglePaymentMethodForm = ({ hasChange = false } = {}) => {
    if (hasChange) refetchAccount();
    setShowPaymentForm(!showAddPaymentForm);
  };

  const handleSelectPaymentMethod = (pmId) => {
    if (!initMyAccount) setInitMyAccounts(true);
    setSelectedPaymentMethod(pmId);
    if (onSelectPaymentMethod) onSelectPaymentMethod(pmId);
  };

  const handleUpdatePaymentMethod = async (pmId, params) => {
    if (!initMyAccount) setInitMyAccounts(true);
    await onUpdatePaymentMethod(pmId, params);
    refetchAccount();
  };

  const handleDeletePaymentMethod = async (pmId) => {
    await onDeletePaymentMethod(pmId);
    refetchAccount();
  };

  const handleDeletePaymentMethodConfirm = (pmId, pmLabel) => {
    setConfirmation({
      title: 'Confirm payment method deletion',
      message: `Are you sure you want to delete ${pmLabel}?`,
      onConfirm: () => handleDeletePaymentMethod(pmId),
      onCancel: () => setConfirmation(null),
      confirmText: `Delete ${pmLabel}`,
      cancelText: 'Cancel',
    });
  };

  const { paymentRef, paymentMethods, defaultPaymentMethodId } = useMemo(() => {
    const pRef = account?.paymentReference || null;
    const posPaymentMethod = account?.settings?.posPaymentMethod || null;
    const pMethods = [];
    let defaultPaymentMethod = null;
    if (pRef?.paymentMethods) {
      for (const pmId in pRef?.paymentMethods) {
        const is3ds = getPaymentMethod3dsStatus(pmId, pRef);
        const pMethod = pRef.paymentMethods[pmId];
        if (pMethod && !pMethod.isArchived && !is3ds) {
          if (pRef.defaultPaymentMethodId === pmId) {
            defaultPaymentMethod = pMethod;
          }
          pMethods.push(pMethod);
        }
      }
    }
    if (autoSelectPaymentMethod && Array.isArray(pMethods) && pMethods?.length === 1 && pMethods[0].id && !isEmbedded) {
      handleSelectPaymentMethod(pMethods[0].id);
    }
    else if (autoSelectPaymentMethod && defaultPaymentMethod?.id && !isEmbedded) {
      handleSelectPaymentMethod(defaultPaymentMethod.id);
    }
    if (isEmbedded && posPaymentMethod) {
      handleSelectPaymentMethod(posPaymentMethod);
    }
    return {
      paymentRef: pRef,
      paymentMethods: sort(pMethods, 'created'),
      defaultPaymentMethodId: defaultPaymentMethod?.id,
    };
  }, [account, autoSelectPaymentMethod, isEmbedded]);

  const renderConfirmationAlert = () => {
    if (!confirmation) return null;
    return (
      <Dialog {...confirmation} />
    );
  };

  const renderHeading = () => {
    if (!showHeader) return null;
    return (
      <Heading variant="h6">
              Payment Method
        <HttpsIcon
          sx={{ marginLeft: '0.5rem', marginBottom: '0.4rem', verticalAlign: 'middle' }}
          fontSize="small"
        />
      </Heading>
    );
  };

  const renderAddPaymentMethod = () => {
    if (!showAddPaymentMethod || !stripePromise || !accountId) return null;
    return (
      <>
        {showAddPaymentForm && (
          <StripeElements stripe={stripePromise} options={{}}>
            <StripePaymentMethodForm
              me={me}
              accountId={accountId}
              paymentRef={paymentRef}
              stripe={stripePromise}
              stripeLoading={stripeLoading}
              stripeError={stripeError}
              onClose={handleTogglePaymentMethodForm}
            />
          </StripeElements>
        )}
        <Button
          onClick={handleTogglePaymentMethodForm}
          variant={actionButtonVariant}
          sx={showOptionsAs === 'select' ? { marginTop: '0.5rem' } : null }
          fullWidth={isMobile || fullWidthAddButton}
          disabled={loading || disableForm}
        >
          {showActionButtonIcon && <PaymentIcon sx={{ marginRight: '0.5rem' }} />}
              Add payment method
        </Button>
      </>
    );
  };

  const renderPaymentMethodRadio = ({ id, card }) => {
    const { brand, last4, exp_month: expMonth, exp_year: expYear, status } = card;
    const { brandLabel, imgSrc } = getPaymentMethodLabelAndImage(brand, isMobile);
    const expMonthPadded = padStart(expMonth, 2, '0');
    // 7 days before expiration to allow for batch payment processing
    const expDate = formatDateTime({
      dateTime: `${expYear}-${expMonthPadded}`,
      asString: false,
    }).endOf('month').add(-7, 'days');
    const isExpired = isDateTimeBefore({ dateTime: expDate, beforeDateTime: now.current });
    const CardExpiryEl = isExpired ? CardExpired : CardExpiry;
    const cardText = `${brandLabel} ••••${last4}`;
    const expText = `(exp ${expMonthPadded}/${expYear})`;
    const label = (
      <>
        <CreditCardLogo src={imgSrc} /> {cardText}
        <CardExpiryEl>{expText}{isExpired && <em> card expired</em>}</CardExpiryEl>
      </>
    );
    const elProps = {
      checked: (id === selectedPaymentMethod) && (status === 'active' || !status),
      disabled: (!!status && status !== 'active') || isExpired || disableForm,
    };
    return (
      <FieldWrapper key={id}>
        <Form.ControlLabel
          name={name}
          value={id}
          control={<Radio {...elProps} />}
          label={(typeof label === 'string')
            ? <Form.OptionLabel>{label}</Form.OptionLabel>
            : label
          }
          onChange={() => handleSelectPaymentMethod(id)}
          sx={{ display: 'flex', flex: '0 0 100%', width: '100%' }}
        />
      </FieldWrapper>
    );
  };

  const renderPaymentMethodList = ({ id, card }) => {
    const isDefault = (id === defaultPaymentMethodId);
    const { brand, last4, exp_month: expMonth, exp_year: expYear, status } = card;
    const { brandLabel, imgSrc } = getPaymentMethodLabelAndImage(brand, isMobile);
    const expMonthPadded = padStart(expMonth, 2, '0');
    // 7 days before expiration to allow for batch payment processing
    const expDate = formatDateTime({
      dateTime: `${expYear}-${expMonthPadded}`,
      asString: false,
    }).endOf('month').add(-7, 'days');
    const isExpired = isDateTimeBefore({ dateTime: expDate, beforeDateTime: now.current });
    const cardText = `${brandLabel} ••••${last4}`;
    const lastFour = `••••${last4}`;
    const expText = `${expMonthPadded}/${expYear}`;
    const label = (
      <>
        <CreditCardLogo src={imgSrc} />
        <CardWrapper>
          <span>
            {brandLabel}
          </span>
          <b>
            {lastFour}
          </b>
          <span>
            {expText}
          </span>
          {isExpired && <em> card expired</em>}
        </CardWrapper>
      </>
    );
    return (
      <Fragment key={id}>
        {(dividerPosition === 'top') && <Divider component="li" sx={{ marginTop: '1rem', marginBottom: '1rem' }} />}
        <List.Item>
          <ListRowWrapper>
            {label}
            <ActionsWrapper>
              <Button
                onClick={() => handleUpdatePaymentMethod(id, { defaultPaymentMethodId: id })}
                variant={isDefault ? 'contained' : 'outlined'}
                disabled={isExpired || isDefault || loading || disableForm}
                sx={{ height: '2rem', marginRight: '0.5rem' }}
                size="small"
              >
              Default
              </Button>
              <Button
                onClick={() => handleDeletePaymentMethodConfirm(id, cardText)}
                aria-label="delete"
                disabled={loading || disableForm}
                sx={{ backgroundColor: 'var(--red)', height: '2rem', width: '1rem' }}
                size="small"
              >
                <DeleteIcon sx={{ fontSize: '1.2rem' }} />
              </Button>
            </ActionsWrapper>
          </ListRowWrapper>
        </List.Item>
        {(dividerPosition === 'bottom') && <Divider component="li" sx={{ marginTop: '1rem', marginBottom: '1rem' }} />}
      </Fragment>
    );
  };

  const renderPaymentMethodSelect = ({ id, card, isExpired, expMonthPadded }) => {
    const { brand, last4, exp_year: expYear } = card;
    const { brandLabel, imgSrc } = getPaymentMethodLabelAndImage(brand, isMobile);
    const cardText = `${brandLabel} ••••${last4}`;
    const expText = `(exp ${expMonthPadded}/${expYear})`;
    const label = (
      <>
        <CreditCardLogo src={imgSrc} /> {cardText}
        <CardExpiry>{expText}{isExpired && <em> card expired</em>}</CardExpiry>
      </>
    );
    return (
      <Fragment key={id}>
        {label}
      </Fragment>
    );
  };

  const renderPaymentMethodOptionsAsSelect = () => {
    if (showOptionsAs !== 'select') return null;
    const key = formKey;
    return (
      <FieldWrapper key={key}>
        <FormControl
          label="School"
          placeholder="School"
          sx={{ width: '100%' }}
        >
          <InputLabel id="locationId">Payment method</InputLabel>
          <Select
            id={key}
            value={selectedPaymentMethod || ''}
            onChange={(e) => handleSelectPaymentMethod(e?.target?.value)}
            label="Set payment method"
            sx={{ width: '100%' }}
          >
            {paymentMethods.map(({ id, card }) => {
              // TODO Make util func to share with Checkout
              const { exp_month: expMonth, exp_year: expYear } = card;
              const expMonthPadded = padStart(expMonth, 2, '0');
              // 7 days before expiration to allow for batch payment processing
              const expDate = formatDateTime({
                dateTime: `${expYear}-${expMonthPadded}`,
                asString: false,
              }).endOf('month').add(-7, 'days');
              const isExpired = isDateTimeBefore({ dateTime: expDate, beforeDateTime: now.current });
              return (
                <MenuItem key={id} value={id} disabled={isExpired || disableForm}>
                  {renderPaymentMethodSelect({ id, card, isExpired, expMonthPadded })}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        {!!errors && !!errors[key] && <Form.Error>{errors[key]}</Form.Error>}
      </FieldWrapper>
    );
  };

  const renderPaymentMethodOptionsAsRadio = () => {
    if (showOptionsAs !== 'radio') return null;
    return (
      <form>
        <Radio.Group
          name={formKey}
          value={selectedPaymentMethod || ''}
        >
          {paymentMethods.map(renderPaymentMethodRadio)}
        </Radio.Group>
      </form>
    );
  };

  const renderPaymentMethodOptionsAsList = () => {
    if (showOptionsAs !== 'list') return null;
    return (
      <List>
        {paymentMethods.map(renderPaymentMethodList)}
      </List>
    );
  };

  const renderPaymentMethodWarning = () => {
    return (
      <SectionWrapper>
        <Error header="Error" content={'Payment method unavailable. Unable to proceed with checkout.'} />
      </SectionWrapper>
    );
  };

  const renderPaymentMethods = () => {
    if (isEmbedded) return null;
    if (isEmbedded && !selectedPaymentMethodId) return renderPaymentMethodWarning();
    return (
      <SectionWrapper>
        {renderHeading()}
        {paymentMethods?.length > 0
          ? (
            <>
              {renderPaymentMethodOptionsAsRadio()}
              {renderPaymentMethodOptionsAsSelect()}
              {renderPaymentMethodOptionsAsList()}
            </>
          )
          : null
        }
        {renderAddPaymentMethod()}
        {renderConfirmationAlert()}
      </SectionWrapper>
    );
  };

  const renderSpinner = () => (
    <Spinner sx={{ marginTop: '2rem', marginBottom: '2rem' }} />
  );

  return !initMyAccount && loading
    ? renderSpinner()
    : renderPaymentMethods();
};

PaymentMethods.defaultProps = {
  formKey: 'paymentMethodId',
  showOptionsAs: 'radio',
  showHeader: true,
  autoSelectPaymentMethod: true,
  showAddPaymentMethod: true,
  fullWidthAddButton: false,
  actionButtonVariant: 'outlined',
  showActionButtonIcon: true,
  dividerPosition: 'bottom',
  disableForm: false,
};

export default PaymentMethods;
