import qs from 'query-string';
import { useLocation } from 'react-router-dom';

const useQueryParams = () => {
  const loc = useLocation();
  const parsed = qs.parse(loc.search);
  for (const key in parsed) {
    if (parsed[key].toLowerCase() === 'true') {
      parsed[key] = true;
    }
    else if (parsed[key].toLowerCase() === 'false') {
      parsed[key] = false;
    }
    else if (parsed[key].toLowerCase() === 'null') {
      parsed[key] = null;
    }
    // TODO Add case to coerce value to number
  }
  return parsed;
};

export default useQueryParams;
