import useViewport from '../../hooks/useViewport';
import Drawer from '../Drawer';
import Card from '../Card';
import Box from '../Box';
import Base from '../_Base';

const { ResponsiveHeader, ResponsiveFooter, ResponsiveScrollableArea } = Base;

const DrawerAdvanced = ({
  open,
  onClose,
  showCloseButton,
  forceOpen,
  title,
  headerRight,
  header,
  footer,
  actions,
  content,
  children,
  container,
  anchor,
}) => {
  const { isMobile } = useViewport();

  const handleClose = () => {
    if (onClose && !forceOpen) {
      onClose();
    }
  };

  const renderHeader = () => {
    return (
      <ResponsiveHeader
        onClose={handleClose}
        title={title}
        header={header}
        headerRight={headerRight}
        showCloseButton={showCloseButton || !forceOpen}
      />
    );
  };

  const renderFooter = () => {
    return (
      <ResponsiveFooter
        actions={actions}
        footer={footer}
      />
    );
  };

  const renderContent = () => {
    return (
      <ResponsiveScrollableArea container={container} >
        {content || children}
      </ResponsiveScrollableArea>
    );
  };

  const renderDrawerContent = () => {
    return (
      <>
        {renderHeader()}
        {renderContent()}
        {renderFooter()}
      </>
    );
  };

  return (
    <Drawer
      open={open}
      onClose={handleClose}
      anchor={anchor}
      PaperProps={{ sx: isMobile
        ? { width: '100vw', height: '100vh' }
        : { minWidth: '24rem', maxWidth: '100vw', height: '100vh' }
      }}
    >
      <Box sx={{
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        maxHeight: '100vh',
      }}>
        <Card variant={null} sx={{
          width: '100%',
          minWidth: '20rem',
          borderRadius: 0,
          height: '100%',
        }}>
          {renderDrawerContent()}
        </Card>
      </Box>
    </Drawer>
  );
};

DrawerAdvanced.defaultProps = {
  open: true,
  forceOpen: false,
  showCloseButton: true,
  anchor: 'right',
};

export default DrawerAdvanced;
