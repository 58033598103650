import {
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  CardActions,
  CardActionArea
} from '@mui/material';

Card.Header = CardHeader;
Card.Content = CardContent;
Card.Media = CardMedia;
Card.Actions = CardActions;
Card.ActionArea = CardActionArea;

Card.defaultProps = {};

export default Card;
