import { useMemo, useState, useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
import styled from '@emotion/styled';
import { useHistory } from 'react-router';
import { constructQueryString } from '../../utils';
import useQueryParams from '../../hooks/useQueryParams';
import useGlobalContext from '../../hooks/useGlobalContext';
import useTemplateContext from '../../hooks/useTemplateContext';
import useViewport from '../../hooks/useViewport';
import useSubscriptions from '../../hooks/useSubscriptions';
import useProductReactions from '../../hooks/useProductReactions';
import { SUBSCRIPTIONS, SUBSCRIPTIONS_SETUP, DASHBOARD } from '../../routes';
import {
  PageContainer,
  Wizard,
  SubscriptionConfig,
  SubscriptionUsersList,
  UserProductReactionsCatalog,
} from '../../components';
import {
  Heading,
  Box,
  SecondaryActionText,
  Button,
  IconAnimated,
} from '../../common';
import { UserSubscriptionSchema } from '../../components/SubscriptionConfigForm';

const Wrapper = styled.div`
  width: 100%;
`;

const Container = styled.div`
  padding-top: 5rem;
`;

const MobileContainer = styled.div`
  padding-top: 2rem;
`;

const SubscriptionSetup = () => {
  const { isMobile } = useViewport();
  const history = useHistory();
  const { userId } = useQueryParams();
  const [, setTemplateState] = useTemplateContext();
  const [, setGlobalState] = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [customerHeader, setCustomHeader] = useState(null);

  const {
    onUpsertUserSubscription,
    onAutofillSubscriptionCart,
    userIds,
    users,
    tags,
    loading: subsLoading,
    isSaving,
  } = useSubscriptions();
  const {
    products,
    userProducts,
    onUpdate: onUpdateProductReaction,
    loading: prodReactionsLoading,
    isSaving: isProdReactionsSaving,
  } = useProductReactions();

  const getInitNode = () => {
    const n = 'subscriptionConfig';
    return n;
  };

  const initNode = useRef(getInitNode());

  const handleSetCustomHeaderContent = (content = null) => {
    setCustomHeader(content);
  };

  const handleSetStepChange = (currentNodeId) => {
    switch (currentNodeId) {
      case 'subscriptionConfig':
        break;
      default:
        break;
    }
  };

  const handleWizardInit = (data) => {
    handleSetStepChange(data.currentNodeId);
  };

  const handleWizardGoBack = (data) => {
    const currentNodeId = data.currentNodeId;
    let prevNodeId = null;
    switch (currentNodeId) {
      case 'subscriptionConfig':
        prevNodeId = 'subscriptionConfig';
        break;
      case 'userProductReactions':
        prevNodeId = 'subscriptionConfig';
        break;
      default:
        break;
    }
    if (prevNodeId) handleSetStepChange(prevNodeId);
    return prevNodeId;
  };

  const handleGetSteps = (activeStep) => {
    const s = [{ label: 'Settings' }, { label: 'Preferences' }];
    return s;
  };

  const handleExitSubscriptionSetup = () => {
    history.push(DASHBOARD);
  };

  const handleUpsertSubscription = async (data) => {
    const formVals = data?.subscriptionConfig;
    const { userId, paymentMethodId, ...vals } = formVals;
    setLoading(true);
    const { data: subRes, errors: subErr } = await onUpsertUserSubscription(
      userId,
      paymentMethodId,
      vals,
    );
    setLoading(false);
    return 'userProductReactions';
  };

  const handleUserProductReactions = async (data) => {
    const { userProductReactions } = data;
    if (userProductReactions?.userId) setLoading(true);
    await onAutofillSubscriptionCart(userProductReactions.userId);
    setLoading(false);
    return 'success';
  };

  const handleSubscribe = (userId) => {
    const qs = constructQueryString({ userId });
    const route = `${SUBSCRIPTIONS_SETUP}${qs}`;
    history.push(route);
  };

  useEffect(() => {
    if (!userId) {
      history.push(SUBSCRIPTIONS);
    }
  }, [userId]);

  useEffect(() => {
    setTemplateState({ showHero: false });
    if (isMobile) setGlobalState({ showLogo: !isMobile });
    return () => {
      setGlobalState({ showLogo: true });
    };
  }, [isMobile]);

  const renderSubUserList = () => {
    const hasUnsubUsers = users.some(
      (u) => u.hasSubscriptionLocation && !u.subscription,
    );
    if (!hasUnsubUsers && !subsLoading) {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Heading variant="h4">You're all set!</Heading>
          <IconAnimated />
          <Button
            sx={{ marginTop: '1.5rem' }}
            onClick={handleExitSubscriptionSetup}
            fullWidth={isMobile}
          >
            Go to Menu
          </Button>
        </Box>
      );
    }
    return (
      <>
        <Heading variant="h4">Setup another subscription</Heading>
        <SubscriptionUsersList
          users={users}
          hideSubscribedUsers={true}
          onSubscribe={handleSubscribe}
          loading={subsLoading}
        />
        {!subsLoading && (
          <SecondaryActionText
            text="I'm all set."
            actionLabel="Go to Menu"
            route={DASHBOARD}
          />
        )}
      </>
    );
  };

  const renderSubOnboarding = ({ onUpdate }) => {
    return (
      <SubscriptionConfig
        onChange={onUpdate}
        onSubscribe={onUpsertUserSubscription}
        userIds={userIds}
        users={users}
        tags={tags}
        showUserHeader={true}
        showUserTabs={false}
        hideSubmitButton={true}
        shouldCreateSubscription={true}
        loading={subsLoading}
        isSaving={isSaving}
      />
    );
  };

  const renderUserProductReactionsCatalog = ({ onUpdate }) => {
    return (
      <UserProductReactionsCatalog
        userIds={userIds}
        users={users}
        products={products}
        userProducts={userProducts}
        onSubmit={onUpdateProductReaction}
        onChange={onUpdate}
        showUserHeader={true}
        showUserTabs={false}
        loading={prodReactionsLoading}
        isSaving={isProdReactionsSaving}
      />
    );
  };

  const subscriptionSetupFlows = useMemo(() => {
    return {
      subscriptionConfig: {
        step: 0,
        // footer: renderMoreActions(),
        // title: 'Config',
        // titleAlign: 'center',
        form: {
          initialValues: {},
          fields: [
            {
              id: 'subscriptionConfig',
              component: renderSubOnboarding,
              persistChangeImmediately: false,
            },
          ],
          actions: [
            {
              key: 'next',
              cb: handleUpsertSubscription,
              label: 'Next',
              nextNodeId: 'userProductReactions',
              loading,
              disabled: (data) => {
                return !UserSubscriptionSchema.isValidSync(
                  data?.subscriptionConfig,
                );
              },
            },
          ],
        },
      },
      userProductReactions: {
        step: 1,
        // title: 'Reactions',
        // titleAlign: 'center',
        form: {
          initialValues: {},
          fields: [
            {
              id: 'userProductReactions',
              component: renderUserProductReactionsCatalog,
              persistChangeImmediately: false,
            },
          ],
          actions: [
            {
              key: 'next',
              cb: handleUserProductReactions,
              label: 'Next',
              nextNodeId: 'success',
              loading,
              disabled: (data) => {
                return false;
              },
            },
          ],
        },
      },
      success: {
        step: 3,
        // title: 'Success',
        // titleAlign: 'center',
        form: {
          initialValues: {},
          fields: [
            {
              id: 'subscriptionUsers',
              component: renderSubUserList,
              persistChangeImmediately: false,
            },
          ],
          actions: [
            {
              key: 'next',
              cb: handleExitSubscriptionSetup,
              label: 'Done',
              loading,
            },
          ],
        },
      },
    };
  }, [loading, users, subsLoading, products, userProducts]);

  const renderError = () => {
    if (!error) return null;
    return (
      <>
        <br />
        <Error
          id="registration-error"
          header="Registration Error"
          content={error}
        />
      </>
    );
  };

  const renderCustomHeader = () => {
    const headerEl = document.getElementById('main-header');
    if (!headerEl) {
      console.warn('No header element found');
      return null;
    }
    return createPortal(customerHeader, headerEl);
  };

  const renderSubscriptionFlow = () => {
    return (
      <>
        <Wizard
          key={userId}
          flows={subscriptionSetupFlows}
          rootNodeId={initNode.current}
          namespace="subscriptions"
          onInit={handleWizardInit}
          onGoBack={handleWizardGoBack}
          onSetHeader={handleSetCustomHeaderContent}
          onGetSteps={handleGetSteps}
          persistState={false}
          showStepper={true}
          showActionsInHeader={true}
          wrapper={Wrapper}
          updateUrl={true}
          sx={null}
        />
        {customerHeader && renderCustomHeader()}
        {!!error && renderError()}
      </>
    );
  };

  const containerEl = useMemo(
    () => (isMobile ? MobileContainer : Container),
    [],
  );

  return (
    <PageContainer
      pageTitle="Subscription Setup"
      showPageTitle={false}
      loading={subsLoading}
      container={subsLoading ? null : containerEl}
    >
      {renderSubscriptionFlow()}
    </PageContainer>
  );
};

export default SubscriptionSetup;
