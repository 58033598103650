import {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { matchSorter } from 'match-sorter';
import { debounce, uniqBy } from 'lodash';
import { useLazyQuery } from '@apollo/client';
import { publicClient } from '../../graphql/client';
import { GET_LISTED_SCHOOLS_BY_STATE } from '../../graphql/queries';
import { TextField, Spinner, Autocomplete } from '../../common';

const MIN_QUERY_LENGTH = 1;

export const NOT_LISTED = 'NOT_LISTED';
const notListed = {
  label: '—School Not Listed—',
  value: NOT_LISTED,
  locationId: null,
};

const TextFieldRef = forwardRef((props, ref) => {
  const internalRef = useRef();

  useImperativeHandle(ref, () => ({
    blur: () => {
      const input = internalRef.current.querySelector('input');
      if (input) {
        input.blur();
      }
    },
  }));

  return <TextField ref={internalRef} {...props} />;
});

const SchoolSelect = ({
  id,
  placeholder,
  onChange,
  autocompleteProps,
  inputProps,
  textFieldProps,
  usState,
  stateLocationsCallback,
  filterCb,
  selectedLocationId,
  queryLimit,
  ...rest
}) => {
  const [locations, setLocations] = useState({ [usState]: [notListed] });
  const [schoolOptions, setSchoolOptions] = useState([notListed]);
  const [schoolQuery, setSchoolQuery] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const [
    getSchools,
    { data: listedStateSchools, loading: listedSchoolsLoading },
  ] = useLazyQuery(GET_LISTED_SCHOOLS_BY_STATE, {
    variables: { limit: queryLimit },
    client: publicClient,
  });

  const inputRef = useRef();

  useEffect(() => {
    if (stateLocationsCallback) {
      stateLocationsCallback({ [usState]: schoolOptions });
    }
  }, [schoolOptions, stateLocationsCallback]);

  const getSchoolsDb = useCallback(
    debounce(getSchools, 500, { leading: true }),
    [getSchools],
  );

  useEffect(() => {
    if (usState && listedStateSchools) {
      const schools = listedStateSchools.listedSchools.map((school) => {
        const cleanName = school.name.replace(/\./g, '').trim();
        return {
          value: school.digest,
          label: cleanName,
          locationId: school.locationId,
          city: school.city,
          stateProvince: school.stateProvince,
        };
      });
      const updatedLocations = {
        ...locations,
        [usState]: [notListed, ...schools],
      };
      const o = uniqBy(updatedLocations?.[usState], 'value');
      const options = filterCb ? o.filter(filterCb) : o;
      if (options.length === 0) {
        options.push(notListed);
      }
      setSchoolOptions(options);
      setLocations(updatedLocations);
    }
  }, [usState, listedStateSchools, notListed, filterCb]);

  useEffect(() => {
    if (!usState) return;
    const hasQuery = schoolQuery?.length >= MIN_QUERY_LENGTH;
    if (selectedLocationId || (usState && hasQuery)) {
      const variables = {};
      if (selectedLocationId && !hasQuery) {
        variables.locationId = selectedLocationId;
        // variables.limit = 1;
        variables.state = usState; // TODO Query requires state
        variables.limit = 0; // TODO Set to 0 with state to get by id
      } else {
        variables.state = usState;
        variables.query = schoolQuery;
        // variables.locationId = selectedLocationId;
      }
      getSchoolsDb({ variables });
    }
  }, [schoolQuery, usState, selectedLocationId, getSchoolsDb]);

  useEffect(() => {
    const isOpen = schoolQuery?.length >= MIN_QUERY_LENGTH;
    setShowOptions(isOpen);
  }, [schoolQuery]);

  const { label, loading, sOptions } = useMemo(() => {
    let isLoading = listedSchoolsLoading;
    let o = schoolOptions;
    let l =
      usState && locations?.[usState] && !selectedLocationId
        ? 'Find your school'
        : 'School';

    const getOptions = () => {
      if (schoolQuery?.length >= MIN_QUERY_LENGTH) {
        if (listedSchoolsLoading) {
          l = <Spinner size={16} message="Finding your school …" inline />;
        }
      }
    };
    getOptions();
    return {
      label: l,
      loading: isLoading,
      sOptions: o,
    };
  }, [
    schoolQuery,
    schoolOptions,
    listedSchoolsLoading,
    selectedLocationId,
    usState,
    locations,
  ]);

  const handleFilterOptions = (o, e) => {
    const { inputValue } = e;
    const val = inputValue.replace(/[^\w\s\']|_/g, '').replace(/\s+/g, ' ');
    if (!inputValue) return o;
    let fOptions = o;
    const words = val.split(' ');
    const m = words.reduceRight(
      (options, word) =>
        matchSorter(options, word, {
          keys: ['label'],
          threshold: matchSorter.rankings.WORD_STARTS_WITH,
        }),
      fOptions,
    );
    if (m.length === 0) {
      return [notListed];
    }
    return m;
  };

  const handleShowOptions = (e, reason) => {
    if (!e) return;
    if (e.type === 'mousedown' && schoolQuery?.length >= MIN_QUERY_LENGTH) {
      setShowOptions(true);
    } else {
      setShowOptions(false);
      if (reason === 'selectOption') {
        if (inputRef.current) inputRef.current.blur();
      }
    }
  };

  const handleSearchChange = (e) => {
    setSchoolQuery(e.target.value);
  };

  const handleOnChange = onChange;

  const renderInput = (params) => (
    <TextFieldRef
      {...params}
      id={id}
      name={id}
      autoComplete="off"
      placeholder={placeholder}
      label={label}
      onChange={handleSearchChange}
      inputProps={{
        ...params.inputProps,
        ...inputProps,
        onKeyDown: (e) => {
          if (e.key === 'Enter') {
            e.stopPropagation();
          }
        },
      }}
      InputProps={{
        ...params.InputProps,
        endAdornment: null,
      }}
      {...textFieldProps}
      ref={inputRef}
    />
  );

  const renderLoading = () => {
    return <Spinner size={20} message="Loading …" inline />;
  };

  return (
    <Autocomplete
      id={id}
      onChange={handleOnChange}
      options={sOptions}
      getOptionLabel={(val) => {
        const item = schoolOptions?.find((item) => item.value === val);
        return item?.label || '';
      }}
      isOptionEqualToValue={(option, value) => {
        return value
          ? option.value === value
          : option.value === notListed.value;
      }}
      autoComplete={schoolQuery?.length >= MIN_QUERY_LENGTH}
      disabled={!usState}
      loading={loading}
      loadingText={renderLoading()}
      renderInput={renderInput}
      filterOptions={handleFilterOptions}
      {...rest}
      {...autocompleteProps}
      open={showOptions}
      onOpen={handleShowOptions}
      onClose={handleShowOptions}
      disableClearable={false}
      disableListWrap={false}
    />
  );
};

SchoolSelect.defaultProps = {
  autocompleteProps: null,
  inputProps: null,
  textFieldProps: null,
  queryLimit: 200,
};

export default SchoolSelect;
