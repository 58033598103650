import styled from '@emotion/styled';
import { useHistory, useLocation } from 'react-router-dom';
import useViewport from '../hooks/useViewport';
import useGlobalContext from '../hooks/useGlobalContext';
import { DASHBOARD, CHECKOUT } from '../routes';
import { styleConstructor } from '../styles/Utils';
import { Image, IconButton, Box } from '../common';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';

const logo = '/public/ordo-logo.svg';

const Wrapper = styled.div`
  display: flex;
  background-color: var(--brand);
  border-bottom: 1px solid var(--gray);
  align-items: center;
  ${({ isShrunk }) =>
    styleConstructor({
      padding: isShrunk ? '1rem 1.5rem' : '1.5rem 1.5rem',
      height: isShrunk ? '1rem' : '4.5rem',
      justifyContent: isShrunk ? 'space-between' : 'space-between',
    })}
  ${({ isAuthenticated }) =>
    styleConstructor(
      isAuthenticated === true
        ? null
        : {
            borderBottom: 'none',
            justifyContent: 'center',
          },
    )}
    ${({ isEmbedded }) =>
    styleConstructor(
      isEmbedded
        ? {
            flexShrink: 1,
            borderBottom: 'none',
            display: 'none',
          }
        : null,
    )}
`;

const InlinePortalWrapper = styled.span`
  display: flex;
  flex-shrink: 1;
  justify-content: flex-end;
  align-items: center;
  &:empty {
    display: none;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
  }
`;

const MobileWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Header = ({ navigation, isEmbedded }) => {
  const { isShrunk, isMobile } = useViewport();
  const history = useHistory();
  const { pathname } = useLocation();
  const [{ isAuthenticated, showLogo }] = useGlobalContext();

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleLogoClick = () => {
    if (!isAuthenticated) {
      window.location.href = process.env.MARKETING_SITE_URI;
      return;
    }
    if (pathname === CHECKOUT) return;
    const route = DASHBOARD;
    history.push(route);
  };

  const renderLogo = () => {
    if (!showLogo) return null;
    const sx =
      isAuthenticated === true
        ? {
            width: isMobile ? '5rem' : '5rem',
            cursor: 'pointer',
          }
        : {
            width: isMobile ? '5rem' : '5rem',
            margin: 'auto',
            cursor: 'pointer',
          };
    return <Image src={logo} sx={sx} onClick={handleLogoClick} />;
  };

  const renderScrollToTop = () => {
    return (
      <IconButton onClick={handleScrollToTop}>
        <ArrowCircleUpIcon />
      </IconButton>
    );
  };

  if (isAuthenticated === null) return null;

  const renderNavigation = () => {
    if (isEmbedded) return null;
    return navigation;
  };

  const renderInlinePortalEl = () => {
    return <InlinePortalWrapper id="header-inline-portal" />;
  };

  const renderFixedPortalEl = () => {
    return <InlinePortalWrapper id="header-fixed-portal" />;
  };

  const renderHeader = () => {
    // if (isMobile) {
    return (
      <MobileWrapper>
        {renderNavigation()}
        {renderLogo()}
      </MobileWrapper>
    );
    // }
    // return (
    //   <>
    //     {renderLogo()}
    //     {renderNavigation()}
    //   </>
    // );
  };

  return (
    <>
      <Box id="header-portal-left" />
      <Wrapper
        id="main-header"
        isShrunk={false}
        isAuthenticated={isAuthenticated}
        isEmbedded={isEmbedded}
      >
        {renderHeader()}
        {renderInlinePortalEl()}
        {renderFixedPortalEl()}
      </Wrapper>
      <Box id="header-portal" />
    </>
  );
};

Header.defaultProps = {
  navigation: null,
  isEmbedded: false,
};

export default Header;
