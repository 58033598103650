import { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useHistory, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import useViewport from '../../hooks/useViewport';
import useGlobalContext from '../../hooks/useGlobalContext';
import useQueryParams from '../../hooks/useQueryParams';
import useMe from '../../hooks/useMe';
import useAccount from '../../hooks/useAccount';
import useMenus from '../../hooks/useMenus';
import useCart from '../../hooks/useCart';
import useObserver from '../../hooks/useObserver';
import {
  PageContainer,
  MenuItemGrid,
  MenuItemPreview,
  MenuFilters,
  MenuBar,
  CartView,
} from '../../components';
import { Heading, Divider, Button, EmptyState, Box } from '../../common';
import { DASHBOARD, MENU_ID, constructRouteWithParams } from '../../routes';
import { getNow, sort, isDateTimeAfter } from '../../utils';
import { MENU_TYPES, TAG_TYPES } from '../../utils/Consts';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import TuneIcon from '@mui/icons-material/Tune';
import LocalDiningIcon from '@mui/icons-material/LocalDining';
import Sticky from 'react-stickynode';

const Wrapper = styled.span`
  display: flex;
  flex: 1;
  align-items: center;
  align-content: center;
  justify-content: space-between;
`;

const PaddingWrapper = styled(Wrapper)`
  padding: 4rem 2rem 3rem 2rem;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
`;

const MobileContainer = styled(Box)`
  padding: 1rem;
  padding-bottom: 8rem;
  margin-bottom: 2rem;
  overflow: visible;
`;

const MenuContainer = styled(Box)`
  padding: 0 3rem 4rem;
  margin-top: 5rem;
  margin-bottom: 2rem;
`;

const MobileFooterWrapper = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
`;

const Menu = ({ authUser }) => {
  const { isMobile } = useViewport();
  const history = useHistory();
  const [{ isEmbedded, deliveryDate, userId, rosterUpdates }, setGlobalState] =
    useGlobalContext();
  const { menuId } = useParams();
  const { redirect } = useQueryParams();
  const { me } = useMe();
  const {
    aUsers,
    loading: accountLoading,
    settings,
  } = useAccount({ redirect: true });
  const { getMenus, menu, menus, menuLoading, menusLoading } = useMenus({
    id: menuId,
    queryAvailableMenus: false,
  });

  const {
    cart,
    transformedCart,
    summary,
    quantity: cartQty,
    cartLoading,
    onClearCart,
    onAddCartItem,
    onRemoveCartItem,
    onUpdateCartItem,
  } = useCart();

  const scrollPosition = useRef(0);
  const isProgramaticScroll = useRef(false);

  const [filters, setFilters] = useState([]);
  const [activeMenuSectionId, setActiveMenuSectionId] = useState(null);
  const [previewItem, setPreviewItem] = useState(null);
  const [showCart, setShowCart] = useState(false);
  const [showFilters, setShowFilters] = useState(false);

  const handleSetActiveMenuSectionId = useCallback(
    (id) => {
      if (!isProgramaticScroll.current) setActiveMenuSectionId(id);
    },
    [setActiveMenuSectionId, isProgramaticScroll],
  );

  const handleScroll = (id) => {
    const mainContentEl = document.getElementById('main-content');
    const mbEl = document.getElementById('menu-bar');
    const el = document.getElementById(id);
    if (!el || !mbEl || !mainContentEl) {
      mainContentEl?.scrollTo({ top: 0, behavior: 'smooth' });
      return;
    }
    const mainContentRect = mainContentEl.getBoundingClientRect();
    const elRect = el.getBoundingClientRect();
    const mbRect = mbEl.getBoundingClientRect();
    let y =
      elRect.top -
      mainContentRect.top +
      mainContentEl.scrollTop -
      mbRect.height -
      12;
    mainContentEl.scrollTo({ top: y, behavior: 'smooth' });
  };

  const tags = useMemo(() => {
    // Only show tags that are used by products on the menu
    const tIds = [];
    const tObjs = {};
    menu?.products?.forEach(({ product: { tags: pTags }, isVisible }) => {
      if (!isVisible) return;
      if (!Array.isArray(pTags)) return;
      pTags?.forEach(({ tag }) => {
        const { id: tId, type: tagType } = tag;
        if (
          (!tagType || tagType?.toUpperCase() === TAG_TYPES.DIETARY) &&
          !tIds.includes(tId)
        ) {
          tIds.push(tId);
          tObjs[tId] = tag;
        }
      });
    });
    return sort(
      tIds.map((tId) => tObjs[tId]),
      ['name', 'id'],
    );
  }, [menu]);

  const menuType = useMemo(() => menu?.type, [menu]);

  const allowBulkOrdering = useMemo(
    () => !!settings?.allowBulkOrdering,
    [settings],
  );

  const activeLocationMenus = useMemo(() => {
    const aMenus = menus?.filter(({ isActive }) => isActive);
    return sort(aMenus, ['organization.name', 'location.name', 'name', 'id']);
  }, [menus]);

  const { sectionIds, sections, foundItemsCount, locationId } = useMemo(() => {
    let locId = null;
    let itemsCount = 0;
    const menuSectionIds = [];
    const menuSections = {};
    if (menu) {
      locId = menu.location?.id;
      menu.products.forEach(
        ({ section, product, datesAvailable, isVisible }, idx) => {
          if (!isVisible) return;

          if (
            isEmbedded &&
            deliveryDate &&
            !datesAvailable?.includes(deliveryDate)
          ) {
            return;
          }

          if (!section) {
            console.warn('No section for item:', { section, product, menuId });
            return;
          }
          let isFilterMatch = Array.isArray(filters) && filters.length === 0;
          const { tags: productTags } = product;
          if (filters?.length > 0) {
            const tagIds =
              productTags
                ?.filter((t) => !!t)
                ?.map(({ tag: { id: tagId } }) => tagId) || [];
            isFilterMatch = filters.every((filter) => tagIds.includes(filter));
          }

          if (!menuSectionIds.includes(section.id)) {
            if (isFilterMatch) {
              menuSectionIds.push(section.id);
              menuSections[section.id] = { ...section, productIdx: [idx] };
              itemsCount++;
            } else {
              menuSections[section.id] = { ...section, productIdx: [] };
            }
          } else {
            if (isFilterMatch) {
              menuSections[section.id]?.productIdx.push(idx);
              itemsCount++;
            }
          }
        },
      );
    }
    if (!activeMenuSectionId && menuSectionIds.length > 0) {
      handleSetActiveMenuSectionId(menuSectionIds[0]);
    } else if (menuSectionIds.length === 0) {
      handleSetActiveMenuSectionId(null);
    } else {
      handleSetActiveMenuSectionId(menuSectionIds[0]);
    }

    return {
      sections: menuSections,
      sectionIds: menuSectionIds,
      foundItemsCount: itemsCount,
      locationId: locId,
    };
  }, [menu, filters]);

  const containerPadding = useMemo(() => {
    if (!isMobile) return 0;
    let m = 0;
    if (sectionIds?.length > 1) {
      m += 3.875;
    }
    if (
      menus?.filter(({ products }) =>
        products.some(({ datesAvailable }) => datesAvailable.length > 0),
      ).length > 1
    ) {
      m += 4.875;
    }
    return m;
  }, [isMobile, sectionIds, menus]);

  const containerEl = useMemo(() => {
    return isMobile
      ? (p) => (
          <MobileContainer
            {...p}
            sx={{ ...p?.sx, paddingTop: `${containerPadding}rem` }}
          />
        )
      : MenuContainer;
  }, [isMobile, containerPadding]);

  const { setSectionRef } = useObserver({
    onSetActiveId: handleSetActiveMenuSectionId,
    scrollableElement: document.getElementById('main-content'),
    marginTop: isMobile ? 240 : 260,
  });

  const { menuUsers } = useMemo(() => {
    if (!locationId || aUsers.length === 0) return { menuUsers: [] };
    let mUsers = aUsers
      .filter((u) => {
        if (menuType === MENU_TYPES.HOME_DELIVERY) {
          return !u.isArchived && u.isMe;
        }
        return (
          !u.isArchived &&
          u.locationId === locationId &&
          (u.isStudent || u.isStaff)
        );
      })
      .map((u) => u.user);
    if (isEmbedded && userId) {
      mUsers = mUsers.filter((u) => u.id === userId);
      if (mUsers.length === 0) {
        console.warn('No menu user found for ?userId', {
          userId,
          aUsers,
          mUsers,
        });
      }
    }
    const now = getNow();
    getMenus({
      variables: {
        locationIds: [locationId],
        now: now.format('YYYY-MM-DD'),
      },
    });
    return { menuUsers: mUsers };
  }, [aUsers, locationId, isEmbedded, userId, menuType]);

  useEffect(() => {
    if (menu?.endDateTime) {
      const { isArchived, isActive, products } = menu;
      let isMenuPastTerm = false;
      let lastDeliveryDate = null;
      const updateUserRostersIds = {};
      ``;
      const lastMenuDeliveryDate = products.reduce(
        (acc, { datesAvailable }) => {
          if (!Array.isArray(datesAvailable) || datesAvailable.length === 0)
            return acc;
          const sortedDates = sort(datesAvailable);
          const ld = sortedDates[sortedDates.length - 1];
          if (!acc || ld > acc) return ld;
          return acc;
        },
        null,
      );
      if (!lastDeliveryDate) lastDeliveryDate = lastMenuDeliveryDate;
      lastDeliveryDate = isDateTimeAfter({
        dateTime: lastMenuDeliveryDate,
        afterDateTime: lastDeliveryDate,
      })
        ? lastMenuDeliveryDate
        : lastDeliveryDate;

      const mUsers = aUsers.filter((au) =>
        au?.locationIds?.includes(locationId),
      );
      mUsers.forEach((au) => {
        const { userId, locationTerms } = au;
        let termId = null;
        let latestTermEndDate = null;
        locationTerms[locationId].forEach(({ id, endDateTime }) => {
          if (
            !latestTermEndDate ||
            isDateTimeAfter({
              dateTime: endDateTime,
              afterDateTime: latestTermEndDate,
              precision: 'day',
              inclusive: true,
            })
          ) {
            latestTermEndDate = endDateTime;
            termId = id;
          }
        });
        if (!latestTermEndDate) return;
        const isPastTerm = isDateTimeAfter({
          dateTime: lastMenuDeliveryDate,
          afterDateTime: latestTermEndDate,
          precision: 'day',
          inclusive: true,
        });
        if (isPastTerm) {
          updateUserRostersIds[userId] = {
            locationId,
            termId,
            termEndDate: latestTermEndDate,
            lastMenuDeliveryDate: lastDeliveryDate,
          };
          if (!isMenuPastTerm) isMenuPastTerm = true;
        }
      });

      if (!rosterUpdates && isMenuPastTerm) {
        setGlobalState({ rosterUpdates: updateUserRostersIds });
      } else if (rosterUpdates && !isMenuPastTerm) {
        setGlobalState({ rosterUpdates: null });
      }

      if ((!isActive || isArchived) && redirect !== false) {
        history.push(DASHBOARD);
      }
    }
  }, [menu, aUsers, locationId, rosterUpdates]);

  const handleGoToMenu = (mId) => {
    if (mId === menuId) return; // No-op
    const route = constructRouteWithParams(MENU_ID, { menuId: mId });
    history.push(route);
  };

  const handleAddItemToCart = async (cartItem, { recId: menuProductId }) => {
    const updatedCartData = await onAddCartItem({
      item: cartItem,
      menuId,
      menuProductId,
    });
    // In DEMO env, show cart contents on first item add
    if (
      process?.env?.ENV_INSTANCE === 'ordo-schools-demo' &&
      cart?.items?.length === 0 &&
      updatedCartData?.items?.length === 1
    ) {
      setShowCart(true);
    }
  };

  const handlePreview = ({ itemId, sectionId }) => {
    let selectedItem = null;
    if (itemId) {
      sections[sectionId].productIdx.forEach((idx) => {
        if (itemId === menu.products[idx].product.id) {
          selectedItem = menu.products[idx];
          if (isEmbedded && deliveryDate) {
            selectedItem = {
              ...selectedItem,
              datesAvailable: [deliveryDate],
            };
          }
        }
      });
      scrollPosition.current = window.scrollY;
      window.scrollTo({ top: 0 });
    }
    setPreviewItem(selectedItem);
    if (!selectedItem) {
      window.scrollTo({ top: scrollPosition.current });
    }
  };

  const handleSelectMenuSection = (sectionId) => {
    isProgramaticScroll.current = true;
    setActiveMenuSectionId(sectionId);
    handleScroll(sectionId);
    setTimeout(() => {
      isProgramaticScroll.current = false;
    }, 500);
  };

  const handleSetFilters = useCallback(
    (vals) => {
      setFilters(vals);
    },
    [filters, setFilters],
  );

  const handleToggleCartView = () => {
    setShowCart(!showCart);
  };

  const handleToggleFiltersView = useCallback(() => {
    setShowFilters((bool) => !bool);
  }, [showFilters, setShowFilters]);

  const renderMobileMenuContent = () => {
    if (!isMobile) return null;
    const headerInlinePortalEl = document.getElementById(
      'header-inline-portal',
    );
    const filterButton = (
      <Button
        onClick={handleToggleFiltersView}
        variant="outlined"
        sx={{ width: '2.8rem', height: '2.8rem', padding: 0 }}
      >
        <TuneIcon />
        {filters?.length > 0 && ` • ${filters.length}`}
      </Button>
    );
    if (!headerInlinePortalEl) {
      console.error(
        'Cannot set portal content filters for header-inline-portal',
      );
      return <Sticky>{filterButton}</Sticky>;
    }
    return createPortal(filterButton, headerInlinePortalEl);
  };

  const renderEmptyStateWithFilters = () => {
    return (
      <PaddingWrapper>
        <Heading as="h1">
          {"Uh oh! We currently don't have any items that match your search."}
        </Heading>
        <p>Try removing some filters to see the full menu.</p>
        <Button onClick={() => handleSetFilters([])} fullWidth={isMobile}>
          Clear all filters
        </Button>
      </PaddingWrapper>
    );
  };

  const renderEmptyState = (msg = 'No menu items for this section') => {
    return <EmptyState message={msg} icon={LocalDiningIcon} />;
  };

  const renderFiltersView = () => {
    return (
      <MenuFilters
        filters={filters}
        tags={tags}
        menuId={menuId}
        onToggleFiltersView={handleToggleFiltersView}
        onSetFilters={handleSetFilters}
        isOpen={showFilters}
      />
    );
  };

  const renderCartView = () => {
    return (
      <CartView
        cart={transformedCart}
        summary={summary}
        me={me}
        onToggleCartView={handleToggleCartView}
        onClearCart={onClearCart}
        onRemoveCartItem={onRemoveCartItem}
        onUpdateCartItem={onUpdateCartItem}
        loading={cartLoading}
        isOpen={showCart}
      />
    );
  };

  const renderMenuBar = () => {
    if (!activeMenuSectionId) return null;
    return (
      <MenuBar
        isMobile={isMobile}
        sectionIds={sectionIds}
        sections={sections}
        activeMenuSectionId={activeMenuSectionId}
        cartQty={cartQty}
        menuId={menuId}
        menus={activeLocationMenus}
        filters={filters}
        tags={tags}
        onSelectMenu={handleGoToMenu}
        onSelectMenuSection={handleSelectMenuSection}
        onSetFilters={handleSetFilters}
        onToggleCartView={handleToggleCartView}
        onToggleFiltersView={handleToggleFiltersView}
        showActionButtons={!isMobile}
        cartLoading={cartLoading}
      />
    );
  };

  // const renderButton = ({
  //   key,
  //   id,
  //   label,
  //   icon,
  //   variant,
  //   onClick,
  //   loading,
  // }) => {
  //   const onClickHandler = onClick;
  //   return (
  //     <Button
  //       key={key || id}
  //       id={id}
  //       onClick={onClickHandler}
  //       variant={variant}
  //       startIcon={icon}
  //       loading={loading}
  //       size="small"
  //       // sx={{ marginLeft: '1rem' }}
  //     >
  //       {label}
  //     </Button>
  //   );
  // };

  // const renderMobileCartButton = () => {
  //   const headerFixedPortalEl = document.getElementById('header-fixed-portal');
  //   const content = (
  //     <>
  //       {renderButton({
  //         id: 'cart',
  //         label: null,
  //         icon: <ShoppingCartIcon />,
  //         variant: 'contained',
  //         onClick: handleToggleCartView,
  //         loading: cartLoading,
  //       })}
  //     </>
  //   );
  //   if (!headerFixedPortalEl) {
  //     console.error(
  //       'Cannot set portal content filters for header-fixed-portal',
  //     );
  //     return <Sticky>{content}</Sticky>;
  //   }
  //   return (
  //     <>
  //       {createPortal(content, headerFixedPortalEl)}
  //       {renderCartView()}
  //     </>
  //   );
  // };

  const renderMobileCartButton = () => {
    return (
      <MobileFooterWrapper>
        <Button
          onClick={handleToggleCartView}
          sx={{
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            borderTopLeftRadius: '12px',
            borderTopRightRadius: '12px',
            padding: '1rem 2rem 2rem',
          }}
          loading={cartLoading}
          fullWidth
        >
          <Wrapper>
            Cart
            <span>
              <ShoppingCartIcon
                fontSize="small"
                sx={{ verticalAlign: 'middle' }}
              />{' '}
              • {cartQty}
            </span>
          </Wrapper>
        </Button>
      </MobileFooterWrapper>
    );
  };

  const renderMenuHeading = (id, name) => {
    const sx = isMobile
      ? { margin: '1.5rem 1.5rem 1.5rem' }
      : { margin: '2.5rem 0 1rem' };
    return (
      <Heading key={id} variant="h5" sx={sx} id={`section-heading-${id}`}>
        {name}
      </Heading>
    );
  };

  const renderMenuItems = (items, sectionId) => {
    if (items?.length === 0) return renderEmptyState();
    const params = isMobile
      ? {
          asCarousel: false,
          justifyContent: 'space-around',
          showPrice: false,
          showTags: true,
          itemSx: { width: '100%', padding: '0 1rem' },
        }
      : {
          showPrice: !allowBulkOrdering,
          asCarousel: false,
        };
    return (
      <MenuItemGrid
        items={items}
        sectionId={sectionId}
        onPreview={handlePreview}
        {...params}
      />
    );
  };

  const renderMenuSection = (sectionId, idx) => {
    const isLastSection = idx === sectionIds?.length - 1;
    const { name, productIdx } = sections[sectionId];
    const items = productIdx.map((idx) => menu.products[idx]);
    if (items.length === 0) return null;
    return (
      <div
        key={sectionId}
        id={sectionId}
        ref={(el) => setSectionRef(sectionId, el)}
      >
        {renderMenuHeading(sectionId, name)}
        {renderMenuItems(items, sectionId)}
        {isMobile && !isLastSection && (
          <Divider sx={isMobile ? { marginTop: '1.5rem' } : null} />
        )}
      </div>
    );
  };

  const renderPreviewItem = () => {
    if (!previewItem) return null;
    return (
      <MenuItemPreview
        item={previewItem}
        onClose={() => handlePreview({})}
        onAddItem={handleAddItemToCart}
        authUserUid={authUser?.uid}
        accountUsers={menuUsers}
        addons={[]}
        timeZone={menu?.timeZone}
        showPrice={!allowBulkOrdering}
      />
    );
  };

  const renderPageContent = () => {
    return (
      <>
        {renderMenuBar()}
        {filters?.length === 0 || (filters?.length > 0 && foundItemsCount > 0)
          ? foundItemsCount === 0
            ? renderEmptyState('No items available on this menu')
            : sectionIds.map(renderMenuSection)
          : renderEmptyStateWithFilters()}
        {renderPreviewItem()}
        {renderCartView()}
        {renderFiltersView()}
        {isMobile && renderMobileMenuContent()}
        {isMobile && renderMobileCartButton()}
      </>
    );
  };

  return (
    <PageContainer
      pageTitle="Menu"
      loading={[menuLoading, accountLoading, menusLoading]}
      loadingMessage="Loading menu …"
      showPageTitle={false}
      container={containerEl}
    >
      {renderPageContent()}
    </PageContainer>
  );
};

export default Menu;
