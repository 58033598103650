import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_MENUS, GET_MENU_BY_ID } from '../graphql/queries';
import { client as httpClient } from '../http';
import { getNow } from '../utils';
import useAccount from './useAccount';
import useQueryParams from './useQueryParams';

const useMenus = ({ id = null, queryAvailableMenus = true } = {}) => {
  const { menuApi = 'http' } = useQueryParams();
  const { locationIds } = useAccount();
  const [menu = null, setMenu] = useState(null);
  const [loading, setLoading] = useState(false);

  const [
    getMenuById,
    { data: menuData = { menus_by_pk: null }, loading: menuLoading },
  ] = useLazyQuery(GET_MENU_BY_ID);

  const [getMenus, { data: menusData = { menus: [] }, loading: menusLoading }] =
    useLazyQuery(GET_MENUS);

  useEffect(() => {
    if (!queryAvailableMenus) return;
    if (locationIds?.length > 0) {
      const now = getNow();
      getMenus({
        variables: {
          locationIds,
          now: now.format('YYYY-MM-DD'),
        },
      });
    }
  }, [locationIds]);

  useEffect(() => {
    if (id)
      if (menuApi !== 'gql') {
        (async () => {
          setLoading(true);
          await httpClient
            .get({
              url: `/menus/${id}`,
            })
            .then(({ data }) => {
              setMenu(data);
            })
            .catch((err) => {
              console.error({ error: err, menuId: id });
            });
          setLoading(false);
        })();
      } else {
        getMenuById({
          variables: { id },
        });
      }
  }, [id]);

  return {
    getMenus,
    getMenuById,
    menus: menusData?.menus,
    menu: menu || menuData?.menus_by_pk,
    menusLoading,
    menuLoading: menuLoading || loading,
    loading: menusLoading || menuLoading || loading,
  };
};

export default useMenus;
