import { useMemo } from 'react';
import { Avatar } from '../../common';
import HeadphonesRoundedIcon from '@mui/icons-material/HeadphonesRounded';
import LocalFloristRoundedIcon from '@mui/icons-material/LocalFloristRounded';
import SportsFootballRoundedIcon from '@mui/icons-material/SportsFootballRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import ParkRoundedIcon from '@mui/icons-material/ParkRounded';
import PetsRoundedIcon from '@mui/icons-material/PetsRounded';
import SportsEsportsRoundedIcon from '@mui/icons-material/SportsEsportsRounded';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import CircleIcon from '@mui/icons-material/Circle';

const ICONS = [
  HeadphonesRoundedIcon,
  LocalFloristRoundedIcon,
  SportsFootballRoundedIcon,
  StarRoundedIcon,
  ParkRoundedIcon,
  PetsRoundedIcon,
  SportsEsportsRoundedIcon,
  DarkModeRoundedIcon,
];

const GRADIENTS = [
  'linear-gradient(135.38deg, #F25555 0%, #FF9393 100%)',
  'linear-gradient(135.38deg, #F86998 0%, #F2A9C1 100%)',
  'linear-gradient(135.38deg, #F88B69 0%, #F2B6A9 100%)',
  'linear-gradient(135.38deg, #F8BF69 0%, #F2D5A9 100%)',
  'linear-gradient(135.38deg, #69F8C4 0%, #36BB8B 0%, #A9F2CF 100%)',
  'linear-gradient(135.38deg, #36B8F0 0%, #A1D7E2 100%)',
  'linear-gradient(135.38deg, #7B61FF 0%, #BBADFF 100%)',
  'linear-gradient(135.38deg, #626262 0%, #AFA9A9 100%)',
];

const AccountUserAvatar = ({
  random,
  icon,
  iconEl,
  iconStyle,
  iconColor,
  size,
  sx,
  variant,
  color,
  ...props
}) => {
  const IconEl = useMemo(() => {
    if (iconEl) return iconEl;
    if (random) {
      const r = Math.floor(Math.random() * ICONS.length);
      return ICONS[r];
    }
    switch (icon) {
    case 'headphones':
      return HeadphonesRoundedIcon;
    case 'flower':
      return LocalFloristRoundedIcon;
    case 'football':
      return SportsFootballRoundedIcon;
    case 'star':
      return StarRoundedIcon;
    case 'tree':
      return ParkRoundedIcon;
    case 'paw':
      return PetsRoundedIcon;
    case 'gamepad':
      return SportsEsportsRoundedIcon;
    case 'moon':
      return DarkModeRoundedIcon;
    case 'add':
      return AddIcon;
    default:
      return StarRoundedIcon;
    }
  }, [random, icon]);

  const avatarSx = useMemo(() => {
    let background = null;
    if (random) {
      const r = Math.floor(Math.random() * GRADIENTS.length);
      background = GRADIENTS[r];
    }
    else {
      switch (color) {
      case 'red':
        background = GRADIENTS[0];
        break;
      case 'pink':
        background = GRADIENTS[1];
        break;
      case 'orange':
        background = GRADIENTS[2];
        break;
      case 'yellow':
        background = GRADIENTS[3];
        break;
      case 'green':
        background = GRADIENTS[4];
        break;
      case 'blue':
        background = GRADIENTS[5];
        break;
      case 'purple':
        background = GRADIENTS[6];
        break;
      case 'gray':
        background = GRADIENTS[7];
        break;
      case 'lightGray':
        background = 'var(--lightGray)';
        break;
      case 'white':
        background = 'var(--white)';
        break;
      default:
        background = GRADIENTS[0];
        break;
      }
    }

    return ({
      '&:hover': 'unset',
      margin: '1rem',
      padding: '4rem',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '8px',
      width: size,
      height: size,
      background,
      [':hover']: {
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.35)',
        cursor: 'pointer',
      },
      ...sx,
    });
  }, [random]);

  const renderEditIcon = () => {
    if (icon === 'add' || !props.onClick) return null;
    return (
      <>
        <CircleIcon
          sx={{
            fontSize: 24,
            color: iconColor,
            display: 'block',
            position: 'absolute',
            top: 6,
            right: 6,
            opacity: 0.2,
          }}
        />
        <EditIcon
          sx={{
            fontSize: 12,
            color: iconColor,
            display: 'block',
            position: 'absolute',
            top: 12,
            right: 12,
          }}
        />
      </>
    );
  };

  return (
    <Avatar
      variant={variant}
      sx={avatarSx}
      {...props}
    >
      <IconEl
        sx={{
          fontSize: size,
          color: iconColor,
          ...iconStyle
        }}
      />
      {renderEditIcon()}
    </Avatar>
  );
};

AccountUserAvatar.defaultProps = {
  random: true,
  size: '5rem',
  variant: 'rounded',
  iconEl: null,
  icon: 'star',
  iconColor: 'var(--white)',
  iconStyle: { opacity: 0.8 },
  color: 'red',
  sx: null,
};

export default AccountUserAvatar;
