import { Fragment, useState, useEffect, useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router';
import { debounce } from 'lodash';
import useViewport from '../../hooks/useViewport';
import useSubscriptions from '../../hooks/useSubscriptions';
import { PRODUCT_CATALOG } from '../../routes';
import { EmptyState, Heading, Button, Spinner } from '../../common';
import { PRODUCT_REACTIONS } from '../../utils/Consts';
import { constructQueryString } from '../../utils';
import UserTabs from '../UserTabs';
import CatalogProductGrid from '../CatalogProductGrid';
import LocalDiningIcon from '@mui/icons-material/LocalDining';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const Wrapper = styled.div`
  margin-top: 2.5rem;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: 2rem;
`;

const SectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

const Text = styled.p`
  margin: 0;
`;

const UserProductReactionsSelections = ({
  userIds,
  users,
  products,
  userProducts,
  onSubmit,
  onChange,
  loading,
  isSaving,
  showUserHeader = false,
  showUserTabs = true,
}) => {
  const { isMobile } = useViewport();
  const history = useHistory();
  const [selectedUser, setSelectedUser] = useState(null);
  const [formValues, setFormValues] = useState({});
  const { refetch } = useSubscriptions();

  useEffect(() => {
    refetch();
  }, []);

  const onRefetchDb = useCallback(debounce(refetch, 1000), [refetch]);

  const handleGoToProductCatalog = () => {
    const query = constructQueryString({ userId: selectedUser.userId });
    const route = `${PRODUCT_CATALOG}${query}`;
    history.push(route);
  };

  const handleSaveReaction = useCallback(
    async (productId, userId, reaction = null) => {
      const v = formValues[userId] || {};
      v[productId] = v[productId] !== reaction ? reaction : null;
      setFormValues((prev) => ({
        ...prev,
        [userId]: v,
      }));
      if (onChange) onChange({ ...formValues, userId });
      await onSubmit({
        productId,
        userId,
        reaction: v[productId],
        cb: onRefetchDb,
      });
    },
    [formValues, onChange, onSubmit, onRefetchDb],
  );

  const header = useMemo(() => {
    if (showUserHeader && selectedUser) {
      return `Select ${selectedUser?.displayName}'s preferences`;
    }
    return 'Taste Profiles';
  }, [selectedUser, showUserHeader]);

  useEffect(() => {
    if (!selectedUser) return;
    if (!formValues[selectedUser.userId]) {
      const u = users.find((u) => u.userId === selectedUser.userId);
      const v = u.reactions || {};
      setFormValues((prev) => ({
        ...prev,
        [selectedUser.userId]: v,
      }));
      if (onChange) onChange({ ...formValues, userId: selectedUser.userId });
    }
  }, [selectedUser, users]);

  const { likedProducts, dislikedProducts } = useMemo(() => {
    const likedProducts = [];
    const dislikedProducts = [];
    if (!selectedUser) return { likedProducts, dislikedProducts };
    const { userId } = selectedUser;
    if (userProducts[userId]?.length > 0) {
      userProducts[userId].forEach((idx) => {
        const p = products[idx];
        const r = formValues[userId]?.[p.product.catalogProductId];
        switch (r) {
          case PRODUCT_REACTIONS.LIKE:
            likedProducts.push(idx);
            break;
          case PRODUCT_REACTIONS.DISLIKE:
            dislikedProducts.push(idx);
            break;
          default:
            break;
        }
      });
    }
    return { likedProducts, dislikedProducts };
  }, [selectedUser, userProducts, products, formValues, users]);

  const renderCatalogProducts = (itemIdx) => {
    const items = itemIdx.map((idx) => products[idx]);
    const params = isMobile
      ? {
          asCarousel: false,
          justifyContent: 'space-around',
          itemSx: { width: '100%', padding: '0 1rem' },
        }
      : {
          sx: { gridTemplateColumns: 'repeat(5, 1fr)' },
        };
    return (
      <CatalogProductGrid
        items={items}
        sectionId={null}
        userReactions={formValues[selectedUser.userId]}
        onReaction={(productId, reaction = null) =>
          handleSaveReaction(productId, selectedUser.userId, reaction)
        }
        loading={isSaving[selectedUser.userId] || {}}
        {...params}
      />
    );
  };

  const renderCatalogEmptyState = () => {
    return (
      <EmptyState
        title="Product Reactions"
        message="There are no products to view at this time."
        icon={LocalDiningIcon}
      />
    );
  };

  const renderUserEmptyState = () => {
    return (
      <EmptyState
        title="Users Reactions"
        message="There are no users to react."
        icon={LocalDiningIcon}
      />
    );
  };

  const renderCatalogButton = () => {
    return (
      <Button
        variant="outlined"
        onClick={handleGoToProductCatalog}
        sx={isMobile ? { margin: '1rem 0' } : null}
        fullWidth={isMobile}
      >
        Review all
        <ChevronRightIcon />
      </Button>
    );
  };

  const renderReactions = (products, title, text) => {
    const WrapperEl = isMobile ? Fragment : SectionWrapper;
    return (
      <div>
        <WrapperEl>
          <span>
            <Heading variant="h6" sx={{ marginBottom: 0, paddingBottom: 0 }}>
              {title}
            </Heading>
            <Text>{text}</Text>
          </span>
          {!isMobile && renderCatalogButton()}
        </WrapperEl>
        {products?.length > 0
          ? renderCatalogProducts(products)
          : renderCatalogEmptyState()}
        {isMobile && renderCatalogButton()}
      </div>
    );
  };

  const renderMenuProducts = () => {
    if (!selectedUser) return null;
    if (loading) return <Spinner />;
    const { userId } = selectedUser;
    if (!userProducts[userId]) return null;
    return (
      <>
        {renderReactions(
          likedProducts,
          'Liked',
          "We'll prioritize sending these meals when they are available!",
        )}
        {renderReactions(
          dislikedProducts,
          'Avoids',
          "We won't send these meals unless there is nothing else available on your selected service day.",
        )}
      </>
    );
  };

  const renderTabs = () => {
    return (
      <UserTabs
        userIds={userIds}
        users={users}
        onSelectUser={setSelectedUser}
        showUserTabs={showUserTabs}
      >
        {users.length > 0 ? (
          <Wrapper>{renderMenuProducts()}</Wrapper>
        ) : (
          renderUserEmptyState()
        )}
      </UserTabs>
    );
  };

  return (
    <>
      {showUserHeader && <Heading variant="h5">{header}</Heading>}
      <p>
        We'll always make sure to offer you the types of meals you like and
        avoid the ones you don't. Keep in mind that we're committed to offering
        you only the freshest options, so meals are subject to availability.
      </p>
      {users.length > 0 ? renderTabs() : null}
    </>
  );
};

export default UserProductReactionsSelections;
