import { useState, useMemo } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { throttle } from 'lodash';
import { client as httpClient } from '../http';

const getStripePromise = async () => {
  const res = await httpClient.get({
    url: '/payments/stripe/publickey',
  });
  if (res?.publicKey) {
    return loadStripe(res.publicKey);
  }
  return null;
};

const getStripePromiseThrottled = throttle(getStripePromise, 5000);

const useStripe = () => {
  const [stripeLoading, setStipeLoading] = useState(true);
  const [stripeError, setStripeError] = useState(null);
  const stripePromise = useMemo(
    () =>
      getStripePromiseThrottled()
        .then((res) => {
          setStipeLoading(false);
          if (!res)
            setStripeError(
              'There was a problem loading Stripe, our payment processor. Please refresh and try again. If this issue persists, please contact support.',
            );
          return Promise.resolve(res);
        })
        .catch((err) => {
          setStipeLoading(false);
          setStripeError(
            'There was a problem loading Stripe, our payment processor. Please refresh and try again. If this issue persists, please contact support.',
          );
          return Promise.reject(err);
        }),
    [],
  );

  return {
    stripeLoading,
    stripeError,
    stripePromise,
    StripeElements: Elements,
  };
};

export default useStripe;
