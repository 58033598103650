import { useMemo } from 'react';
import styled from '@emotion/styled';
import {
  Card,
  Image,
  IconButton,
  Currency,
  Form,
  Tag,
  Box,
  EmptyState,
} from '../../common';
import { sort, calculateProductBasePrice } from '../../utils';
import { TAG_TYPES } from '../../utils/Consts';
import WarningIcon from '@mui/icons-material/Warning';
import ImageIcon from '@mui/icons-material/Image';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ItemName = styled.span`
  font-weight: 600;
`;

const MenuItem = ({
  recId,
  product,
  quantity,
  section,
  datesAvailable,
  onPreview,
  sx,
  imageSx: imgSx,
  showPrice,
  showTags,
  minWidth,
  isSelected,
}) => {
  const { id, name: pName, currencyCode, tags = [], images = [] } = product;

  const price = useMemo(() => {
    // Base price
    const { price: p } = calculateProductBasePrice(product);
    return p;
  }, [product]);

  const isAvailable = useMemo(
    () => quantity > 0 && datesAvailable?.length > 0,
    [quantity, datesAvailable],
  );

  const featureImageUri = useMemo(
    () => (images.length > 0 ? images[0].uri : product?.featureImageUri),
    [product],
  );

  const mealTypeTags = useMemo(() => {
    if (!Array.isArray(tags) || !tags?.length || !showTags) return null;
    return (
      tags
        .filter(
          ({ tag: { type: tagType } }) =>
            tagType?.toUpperCase() === TAG_TYPES.MEAL_TYPE,
        )
        .map(({ tag: { name } }) => name)
        .join(', ') || null
    );
  }, [tags, showTags]);

  const name = useMemo(() => {
    return mealTypeTags ? `${pName} (${mealTypeTags})` : pName;
  }, [pName, mealTypeTags]);

  const sortedTags = useMemo(() => {
    if (!Array.isArray(tags) || !tags?.length || !showTags) return [];
    return sort(
      tags.filter(
        ({ tag: { type: tagType } }) =>
          !tagType || tagType?.toUpperCase() === TAG_TYPES.DIETARY,
      ),
      ['tag.name'],
    );
  }, [tags]);

  const handleClick = () => {
    if (!isAvailable) return;
    onPreview({ itemId: id, sectionId: section.id });
  };

  const renderImage = () => {
    const imageSx = { padding: 0 };
    const displayImg = featureImageUri ? (
      <Image
        src={featureImageUri}
        alt={name}
        loading="lazy"
        sx={{
          width: '100%',
          aspectRatio: '1 / 1',
          borderRadius: '12px',
          marginBottom: '0.5rem',
          zIndex: 0,
          opacity: isAvailable ? 1 : 0.3,
          cursor: isAvailable ? undefined : 'not-allowed',
          ...imgSx,
        }}
      />
    ) : (
      <EmptyState
        icon={ImageIcon}
        // message={name}
        sx={{
          backgroundColor: 'var(--lightGray)',
          width: '100%',
          minWidth: minWidth ?? '3rem',
          aspectRatio: '1 / 1',
          borderRadius: '12px',
          marginBottom: '0.5rem',
          opacity: isAvailable ? 1 : 0.3,
          cursor: isAvailable ? undefined : 'not-allowed',
          ...imgSx,
        }}
      />
    );
    return (
      <IconButton
        onClick={handleClick}
        sx={imageSx}
        disableRipple={!isAvailable}
      >
        {displayImg}
      </IconButton>
    );
  };

  const renderTag = ({ tag: { id, name, icon, color } }) => {
    return (
      <Tag
        key={id}
        label={name}
        sx={{ marginRight: '0.5rem', marginTop: '0.5rem' }}
        icon={icon}
        color={color}
      />
    );
  };

  const renderSoldOutNotice = () => {
    return (
      <Form.Error>
        <WarningIcon sx={{ fontSize: '0.8rem', verticalAlign: '-1px' }} />{' '}
        Unavailable
      </Form.Error>
    );
  };

  const renderSelectedImage = () => {
    return (
      <Box
        position="relative"
        onClick={handleClick}
        sx={{
          '&:hover': {
            opacity: 0.9,
          },
        }}
      >
        {renderImage()}
        {isSelected && (
          <IconButton
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              color: 'var(--green)',
            }}
            disableFocusRipple
            disableRipple
          >
            <CheckCircleIcon />
          </IconButton>
        )}
      </Box>
    );
  };

  return (
    <Card key={recId} id={recId} variant={null} sx={sx}>
      {isSelected ? renderSelectedImage() : renderImage()}
      <Wrapper>
        <ItemName>{name}</ItemName>
        {showPrice && (
          <Currency
            amount={price}
            currencyCode={currencyCode}
            sx={{ marginLeft: '0.75rem' }}
          />
        )}
      </Wrapper>
      {isAvailable ? sortedTags.map(renderTag) : renderSoldOutNotice()}
    </Card>
  );
};

MenuItem.defaultProps = {
  sx: { maxWidth: '18rem' },
  imageSx: {},
  showPrice: true,
  showTags: true,
  minWidth: '288px',
  isSelected: false,
};

export default MenuItem;
