import styled from '@emotion/styled';
import Progress from '../Progress';

const SpinWrapper = styled.div`
  display: flex;
  flex: 1 auto;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SpinWrapperInline = styled.div`
  display: flex;
  flex: 1 auto;
  height: 100%;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
`;

const SpinWrapperInlineCenter = styled(SpinWrapperInline)`
  justify-content: center;
`;

const SpinWrapperInlineRight = styled(SpinWrapperInline)`
  justify-content: flex-end;
`;

const Spinner = ({ message, inline, ...props }) => {
  let SpinWrapperEl = inline ? SpinWrapperInline : SpinWrapper;
  if (inline === 'center') SpinWrapperEl = SpinWrapperInlineCenter;
  else if (inline === 'right') SpinWrapperEl = SpinWrapperInlineRight;
  return (
    <SpinWrapperEl>
      <Progress {...props} type='circle' />
      {message}
    </SpinWrapperEl>
  );
};

Spinner.defaultProps = {
  size: 30,
  inline: false,
};

export default Spinner;
