import styled from '@emotion/styled';
import { Grid } from '../../common';
import MenuItem from '../MenuItem';

const HorizontalLayout = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  overflow-x: scroll;
  padding-bottom: 1rem;
`;

const HorizontalItemWrapper = styled.div`
  margin-left: 1.5rem;
  :last-child {
    margin-right: 1.5rem;
  }
`;

const MenuItemGrid = ({
  items,
  sectionId,
  onPreview,
  asCarousel,
  justifyContent,
  showPrice,
  showTags,
  itemSx,
}) => {
  const renderItem = (item, params = { showPrice, showTags, sx: itemSx }) => (
    <MenuItem {...item} sectionId={sectionId} onPreview={onPreview} {...params} />
  );

  const renderItemInGrid = (item) => {
    return (
      <Grid key={item.recId} item>
        {renderItem(item)}
      </Grid>
    );
  };

  const renderGrid = () => {
    return (
      <Grid
        container
        justifyContent={justifyContent}
        alignItems="flex-start"
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        rowSpacing={{ xs: 3, sm: 2, md: 3 }}
      >
        {items.map(renderItemInGrid)}
      </Grid>
    );
  };

  const renderCarousel = () => {
    const params = {
      showPrice: false, showTags: false, sx: { width:  '70vw', },
    };
    return (
      <HorizontalLayout>
        {items.map((item) => (
          <HorizontalItemWrapper key={item.product.id}>
            {renderItem(item, params)}
          </HorizontalItemWrapper>
        ))}
      </HorizontalLayout>
    );
  };

  return asCarousel ? renderCarousel() : renderGrid();
};

MenuItemGrid.defaultProps = {
  asCarousel: false,
  justifyContent: 'flex-start',
  showPrice: true,
  showTags: true,
  sx: null,
};

export default MenuItemGrid;
