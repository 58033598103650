import { Fragment, useState, useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import useViewport from '../../hooks/useViewport';
import {
  SUBSCRIPTION_OPTIONS,
  SUBSCRIPTION_OPTIONS_LABELS,
  SUBSCRIPTION_STATUSES,
  // SUBSCRIPTION_STATUS_LABELS,
} from '../../utils/Consts';
import { formatDateTime } from '../../utils';
import {
  Form,
  Checkbox,
  Radio,
  Button,
  ToggleButton,
  Heading,
  Box,
  Transitions,
  Dialog,
} from '../../common';
import PauseSubscriptionForm from '../PauseSubscriptionForm';

// TODO Refactor errors to a reusable component and/or hook
const ERROR_BACKGROUND_COLOR = '#ffffed';

const Wrapper = styled.div`
  margin-top: 2.5rem;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

const FieldWrapper = styled.div`
  margin: 4px 0 !important;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const RowItem = styled.div`
  width: 50%;
`;

const MobileRowItem = styled.div`
  width: 100%;
`;

const Info = styled.p`
  white-space: pre-wrap;
`;

const CustomLabelSubText = styled.div`
  font-size: 0.8rem;
  font-weight: 300;
`;

const CustomLabel = styled.div`
  font-size: 1rem;
  font-weight: 500;
`;

const MealTimeGroup = styled.div`
  margin-left: 1rem;
  font-weight: 600;
`;

const CustomWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: stretch;
  margin-bottom: 0.5rem;
  gap: 1rem;
`;

const ErrorWrapper = styled.div`
  background-color: ${ERROR_BACKGROUND_COLOR};
  border-radius: 4px;
  padding: 0.2rem;
`;

export const UserSubscriptionSchema = Yup.object().shape({
  dietaryPreferences: Yup.array().min(1, 'Select at least 1 option.'),
  weekDaysToSubscribe: Yup.array().min(2, 'Select at least 2 days.'),
  mealTimes: Yup.array().min(1, 'Select at least 1 meal time.'),
  paymentMethodId: Yup.string().required('Select a payment method.').nullable(),
});

const SubscriptionConfigForm = ({
  user,
  tags,
  onChange,
  onSubmit,
  values: initialValues,
  hideSubmitButton = false,
  showStatus = false,
  loading,
}) => {
  const { isMobile } = useViewport();
  const [confirmation, setConfirmation] = useState(null);
  const [showPauseForm, setShowPauseForm] = useState(false);
  const [selectedTermId, setSelectedTermId] = useState(null);

  const togglePauseForm = () => {
    setShowPauseForm((bool) => !bool);
  };

  const { values, errors, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        dietaryPreferences: initialValues?.dietaryPreferences ?? [
          SUBSCRIPTION_OPTIONS.DIETARY.OMNIVORE,
        ],
        weekDaysToSubscribe: initialValues?.weekDaysToSubscribe ?? [],
        mealTimes: initialValues?.mealTimes ?? [],
        premiumMeals: initialValues?.premiumMeals ?? null,
        paymentMethodId: initialValues?.paymentMethodId ?? null,
        status: initialValues?.status || SUBSCRIPTION_STATUSES.ENABLED,
        pauseUntil: initialValues?.pauseUntil ?? null,
      },
      validationSchema: UserSubscriptionSchema,
      onSubmit,
    });

  const handleCancelSubscription = async () => {
    setFieldValue('status', SUBSCRIPTION_STATUSES.CANCELLED);
    await handleSubmit();
  };

  const handleCancelSubscriptionConfirm = async () => {
    setConfirmation({
      title: 'Confirm Stop Subscription',
      message: `Are you sure you want to stop ${user?.displayName}'s subscription? You can resume it at any time.`,
      onConfirm: () => handleCancelSubscription(),
      onCancel: () => setConfirmation(null),
      confirmText: isMobile ? 'Stop' : 'Stop Subscription',
      cancelText: 'Cancel',
    });
  };

  const handleResumeSubscription = async () => {
    setFieldValue('status', SUBSCRIPTION_STATUSES.ENABLED);
    await handleSubmit();
  };

  const { subTermIds, subTermOptions } = useMemo(() => {
    const tIds = [];
    const o = {};
    user?.locationIds?.forEach((locId) => {
      user.locationTerms[locId]?.forEach((term) => {
        const { id: termId, hasSubscriptionLocation } = term;
        if (!o[termId] && hasSubscriptionLocation) {
          tIds.push(termId);
          o[termId] = {
            termId,
            location: user.locations[locId],
            subscription: {
              mealServiceTimes:
                user.locations[locId].settings?.mealServiceTimes || [],
            },
          };
        }
      });
    });
    if (tIds.length > 0) setSelectedTermId(tIds[0]); // TODO Support multiple subscriptions (1 per term)
    return {
      subTermIds: tIds,
      subTermOptions: o,
    };
  }, [user]);

  const handleCustomControlChange = (val, id) => {
    const { DIETARY } = SUBSCRIPTION_OPTIONS;
    let currentValues = [...values[id]];
    if (val === DIETARY.OMNIVORE) {
      currentValues = [DIETARY.OMNIVORE];
    } else {
      const omnivoreIndex = currentValues.indexOf(DIETARY.OMNIVORE);
      if (omnivoreIndex !== -1) {
        currentValues.splice(omnivoreIndex, 1);
      }
      const idx = currentValues.indexOf(val);
      if (idx === -1) {
        currentValues.push(val);
      } else {
        currentValues.splice(idx, 1);
      }
    }
    setFieldValue(id, currentValues);
  };

  const { hasSub, isActive, isPaused, isCancelled } = useMemo(() => {
    return {
      hasSub: !!user.subscription,
      isActive: user.subscription?.status === SUBSCRIPTION_STATUSES.ENABLED,
      isPaused: user.subscription?.status === SUBSCRIPTION_STATUSES.PAUSED,
      isCancelled:
        user.subscription?.status === SUBSCRIPTION_STATUSES.CANCELLED,
    };
  }, [user]);

  useEffect(() => {
    onChange(values);
  }, [values]);

  const getField = (f, fieldName, disabled = false) => {
    return {
      name: fieldName,
      label: f.label,
      value: f.value,
      disabled,
      isSubOption: !!f.isSubOption,
    };
  };

  const renderPauseSubscriptionForm = () => {
    if (!showPauseForm) return null;
    return (
      <PauseSubscriptionForm
        userId={user?.userId}
        userDisplayName={user?.displayName}
        onClose={togglePauseForm}
      />
    );
  };

  const renderError = (id) => {
    return (
      <Transitions.Collapse in={errors[id]}>
        {!!errors[id] && (
          <ErrorWrapper id={`error-${id}`}>
            <Form.Error>{errors[id]}</Form.Error>
          </ErrorWrapper>
        )}
      </Transitions.Collapse>
    );
  };

  const renderCustomControl = ({ id, options, disabled, columns = 1 }) => {
    const RowItemEl = isMobile ? MobileRowItem : CustomWrapper;
    const rows = [];
    const o = [];
    const sx = isMobile
      ? {
          display: 'flex',
          flexGrow: 1,
          marginBottom: '0.5rem',
        }
      : {
          display: 'flex',
          flexGrow: 1,
          marginRight: '0.5rem',
          width: '14.25rem',
          maxWidth: '14.25rem',
        };
    options.forEach((opt, idx) => {
      if (idx % columns === 0) {
        let rowItems = [];
        for (let i = 0; i < columns; i++) {
          if (!options[idx + i]) continue;
          const isSelected = values[id].includes(options[idx + i].value);
          rowItems.push(
            <RowItemEl key={`${idx + i}`}>
              {idx + i < options.length && (
                <ToggleButton
                  key={options[idx + i].value}
                  variant="outlined"
                  sx={sx}
                  onClick={() =>
                    handleCustomControlChange(options[idx + i].value, id)
                  }
                  fullWidth={isMobile}
                  disabled={disabled}
                  isSelected={isSelected}
                >
                  <CustomLabel>{options[idx + i].label}</CustomLabel>
                  <CustomLabelSubText>
                    {options[idx + i].description}
                  </CustomLabelSubText>
                </ToggleButton>
              )}
            </RowItemEl>,
          );
        }
        rows.push(
          <Fragment key={`${id}_${options[idx].value}`}>
            <Row>{rowItems.map((rItem) => rItem)}</Row>
          </Fragment>,
        );
      }
    });
    return rows;
  };

  const sections = useMemo(() => {
    const f = [
      {
        id: 'dietaryPreferences',
        name: 'dietaryPreferences',
        label: 'Dietary needs',
        description: `What types of meals does ${user?.displayName} prefer?

We support guaranteed daily offerings for vegetarian and nut-free meals at this time. If you need more specific allergens, we recommend ordering manually weekly.

If ${user.displayName} is a picky eater, you can "favorite" or "dislike" in the "Taste profiles" menu to make sure their preferred meals are ordered weekly.`,
        disabled: loading,
        component: renderCustomControl,
        columns: isMobile ? 1 : 3,
        options: [
          {
            id: SUBSCRIPTION_OPTIONS.DIETARY.OMNIVORE,
            name: SUBSCRIPTION_OPTIONS_LABELS.DIETARY[
              SUBSCRIPTION_OPTIONS.DIETARY.OMNIVORE
            ],
          },
        ]
          .concat(tags)
          .map((t) => ({
            value: t.id,
            label: t.name,
          })),
      },
      {
        id: 'weekDaysToSubscribe',
        name: 'weekDaysToSubscribe',
        label: 'Days of the week',
        description: `Select at least two days a week to subscribe.

We will only send meals of days of week when your school has active lunch service. If you have a school-wide vacations or pre-scheduled days off, we will automatically not order for that day.`,
        disabled: loading,
        type: 'checkbox',
        columns: isMobile ? 1 : 2,
        options: [
          SUBSCRIPTION_OPTIONS.DAY.MONDAY,
          SUBSCRIPTION_OPTIONS.DAY.TUESDAY,
          SUBSCRIPTION_OPTIONS.DAY.WEDNESDAY,
          SUBSCRIPTION_OPTIONS.DAY.THURSDAY,
          SUBSCRIPTION_OPTIONS.DAY.FRIDAY,
        ].map((key) => ({
          value: key,
          label: SUBSCRIPTION_OPTIONS_LABELS.DAY[key],
        })),
      },
      {
        id: 'mealTimes',
        name: 'mealTimes',
        label: 'Meal preferences',
        description: `Select at least one mealtime to subscribe. ${user.displayName} will receive a full meal at the selected times. You'll be able to select your "favorites" and "dislikes" so that your cart is auto-loaded to ${user.displayName}'s preferences.

All standard Ordo meals include a main and 1–2 sides. Prices range between $5.99–$7.99, and salads are $7.99–$9.99 depending on your region.

If you'd like to add on any additional a la carte menu items like extras or drinks, you're welcome to add them manually to your cart as needed.`,
        disabled: loading,
        type: 'checkbox',
        options: [],
        // showSectionCb: (opts) =>
        //   opts.filter(({ isSubOption }) => !isSubOption).length > 1,
      },
    ];

    const mt = f.find((field) => field.id === 'mealTimes');
    if (mt) {
      user.subscription?.subscriptionOptions.menuSlots.forEach((ms) => {
        const { productSlots, mealService, isActive } = ms;
        if (!isActive) return;
        mt.options.push({
          value: mealService,
          label: SUBSCRIPTION_OPTIONS_LABELS.MEAL_TIME[mealService],
          displayAsLabel: true,
        });
        productSlots.forEach((ps) => {
          mt.options.push({
            value: `${mealService}:${ps.productType}`,
            label: ps.slotName,
            isSubOption: true,
          });
        });
      });
    }
    return f;
  }, [
    values.dietaryPreferences,
    isMobile,
    user,
    tags,
    selectedTermId,
    subTermOptions,
    showStatus,
    loading,
  ]);

  const renderField = (
    { name, label, value, disabled, isSubOption },
    FormEl,
  ) => {
    return (
      <FieldWrapper key={value}>
        <Form.ControlLabel
          name={name}
          value={value}
          disabled={disabled}
          control={<FormEl checked={values[name]?.includes(value) || false} />}
          label={
            typeof label === 'string' ? (
              <Form.OptionLabel>{label}</Form.OptionLabel>
            ) : (
              label
            )
          }
          onChange={handleChange}
          sx={{
            paddingLeft: isSubOption ? '1.5rem' : null,
          }}
        />
      </FieldWrapper>
    );
  };

  const renderFieldsInGrid = ({
    id,
    name,
    options,
    label,
    description,
    type,
    component,
    disabled,
    showSectionCb,
    columns = 1,
  }) => {
    if (showSectionCb && !showSectionCb(options)) return null;
    const key = id;
    const rows = [];
    const RowItemEl = isMobile ? MobileRowItem : RowItem;
    if (type) {
      options.forEach((o, idx) => {
        if (idx % columns === 0) {
          let rowItems = [];
          for (let i = 0; i < columns; i++) {
            let FormEl = type === 'radio' ? Radio : Checkbox;
            if (o.displayAsLabel) FormEl = MealTimeGroup;
            rowItems.push(
              <RowItemEl key={`${idx + i}`}>
                {idx + i < options.length &&
                  renderField(
                    getField(options[idx + i], name, disabled),
                    FormEl,
                  )}
              </RowItemEl>,
            );
          }
          rows.push(
            <Fragment key={`${id}_${o.value}`}>
              <Row>{rowItems.map((rItem) => rItem)}</Row>
            </Fragment>,
          );
        }
      });
    } else if (component) {
      rows.push(
        <RowItemEl key={id}>
          {component({ id, name, label, options, disabled, columns })}
        </RowItemEl>,
      );
    }
    return (
      <Wrapper key={name}>
        <Heading variant="h6">{label}</Heading>
        <Info>{description}</Info>
        {rows.map((r) => r)}
        {renderError(key)}
      </Wrapper>
    );
  };

  const renderConfirmationAlert = () => {
    if (!confirmation) return null;
    return <Dialog {...confirmation} />;
  };

  const renderActions = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          gap: '0.5rem',
          marginTop: '1rem',
        }}
      >
        {isActive && (
          <>
            <Button type="submit" loading={loading} fullWidth={isMobile}>
              Save
            </Button>
            <Button
              onClick={togglePauseForm}
              variant="text"
              fullWidth={isMobile}
            >
              Pause Subscription
            </Button>
          </>
        )}
        {(isPaused || isCancelled) && (
          <Button
            onClick={handleResumeSubscription}
            sx={{ backgroundColor: 'var(--blue)' }}
            fullWidth={isMobile}
          >
            Resume Subscription
          </Button>
        )}
        {!isCancelled && (
          <Button
            onClick={handleCancelSubscriptionConfirm}
            variant="text"
            fullWidth={isMobile}
          >
            Stop Subscription
          </Button>
        )}
      </Box>
    );
  };

  const renderFooter = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
          marginTop: '1rem',
          padding: '1rem',
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.5)',
          backdropFilter: 'blur(15px)',
        }}
      >
        {renderActions()}
      </Box>
    );
  };

  const renderStatus = () => {
    if (isActive) return null;
    let message = null;
    const sx = {
      padding: '1rem',
      fontWeight: 700,
      textAlign: 'center',
      borderBottomLeftRadius: '6px',
      borderBottomRightRadius: '6px',
    };
    if (isPaused) {
      const pauseUntil = formatDateTime({
        dateTime: user.subscription?.pauseUntil,
        format: 'MMM D, YYYY',
      });
      message = user.subscription?.pauseUntil
        ? `Subscription is paused until ${pauseUntil}`
        : 'Subscription is paused';
      sx.backgroundColor = 'var(--yellow)';
    } else if (isCancelled) {
      message = 'Subscription is stopped';
      sx.backgroundColor = 'var(--red)';
      sx.color = 'var(--white)';
    }
    return <Box sx={sx}>{message}</Box>;
  };

  return (
    <Form onSubmit={handleSubmit}>
      {renderStatus()}
      {sections.map(renderFieldsInGrid)}
      {!hideSubmitButton && renderFooter()}
      {renderConfirmationAlert()}
      {renderPauseSubscriptionForm()}
    </Form>
  );
};

export default SubscriptionConfigForm;
