import { Fragment } from 'react';
import styled from '@emotion/styled';
import useViewport from '../../hooks/useViewport';
import Button from '../Button';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-content: flex-end;
  padding: 1rem;
  border-top: 1px solid var(--altGray);
`;

const MobileWrapper = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: var(--white);
  padding: 1rem;
  border-top: 1px solid var(--altGray);
`;

const ResponsiveFooter = ({ footer, actions }) => {
  const { isMobile } = useViewport();

  const renderFooter = () => {
    if (!footer) return null;
    return footer;
  };

  const renderActions = () => {
    if (!Array.isArray(actions) || actions.length === 0) return null;
    const ActionWrapperEl = isMobile ? MobileWrapper : Wrapper;
    return (
      <ActionWrapperEl>
        {(actions.map(({ key, label, onClick, component: C, ...rest }, idx) => {
          const { id } = rest;
          if (C) return <C key={key || id || label} />;
          return (
            <Button
              key={key || id || label}
              onClick={onClick}
              sx={(idx < actions.length -1) ? { marginBottom: '1rem' } : undefined}
              fullWidth
              {...rest}
            >
              {label}
            </Button>
          );
        }
        ))}
      </ActionWrapperEl>
    );
  };

  return (
    <>
      {renderFooter()}
      {renderActions()}
    </>
  );
};

export default ResponsiveFooter;
