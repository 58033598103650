import { useMemo } from 'react';
import styled from '@emotion/styled';
import { EmptyState, Spinner } from '../../common';
import SubscriptionUserCard from '../SubscriptionUserCard';
import FlashOnIcon from '@mui/icons-material/FlashOn';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 1.5rem;
  max-width: 768px;
`;

const SubscriptionUsersList = ({
  users,
  loading,
  onSubscribe,
  onChangePlan,
  onAutofillSubscriptionCart,
  hideSubscribedUsers = false,
  hideUnsubscribedUsers = false,
}) => {
  const sUsers = useMemo(() => {
    if (!hideSubscribedUsers && !hideUnsubscribedUsers) return users;
    return users.filter((u) => {
      if (hideSubscribedUsers && u.subscription) return false;
      if (hideUnsubscribedUsers && !u.subscription) return false;
      return true;
    });
  }, [users, hideSubscribedUsers, hideUnsubscribedUsers]);

  const renderSubscriptionUser = (u) => {
    return (
      <SubscriptionUserCard
        key={u.userId}
        user={u}
        onSubscribe={onSubscribe}
        onChangePlan={onChangePlan}
        onAutofillSubscriptionCart={onAutofillSubscriptionCart}
      />
    );
  };

  const renderEmptyState = () => {
    return (
      <EmptyState message="No subscriptions" icon={FlashOnIcon} showBorder />
    );
  };

  const renderSubscriptionUsersList = () => {
    // return <Wrapper>{renderEmptyState()}</Wrapper>;
    return (
      <Wrapper>
        {sUsers.length > 0
          ? sUsers.map((u) => renderSubscriptionUser(u))
          : renderEmptyState()}
      </Wrapper>
    );
  };

  const renderLoading = () => {
    return <Spinner />;
  };

  return loading ? renderLoading() : renderSubscriptionUsersList();
};

export default SubscriptionUsersList;
