import { Fragment, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { useFormik } from 'formik';
import useViewport from '../../hooks/useViewport';
import {
  ModalAdvanced,
  Button,
  Form,
  Checkbox,
  Card,
  Heading,
  Divider,
  DateRangePicker,
} from '../../common';
import { getNow } from '../../utils';
import ClearIcon from '@mui/icons-material/Close';

const FieldWrapper = styled.div`
  margin: 4px 0 !important;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const RowItem = styled.div`
  width: 50%;
`;

const OrdersFilters = ({
  filters,
  forms,
  onToggleFiltersView,
  onSetFilters,
  isOpen,
}) => {
  const { isMobile } = useViewport();
  const [showAll, setShowAll] = useState({});

  const handleToggleShowAll = (id) => {
    setShowAll({ ...showAll, [id]: !showAll[id] });
  };

  const handleSetFilters = (vals) => {
    onSetFilters(vals);
    onToggleFiltersView();
  };

  const initialValues = useMemo(() => {
    const init = {};
    forms.forEach(({ fieldName }) => {
      init[fieldName] = filters[fieldName] || [];
    });
    return init;
  }, [forms]);

  const formik = useFormik({
    initialValues,
    onSubmit: handleSetFilters,
  });

  const { values, handleChange, handleSubmit, setFieldValue } = formik;

  const handleResetFilters = () => {
    const resetFilters = {};
    forms.forEach(({ fieldName }) => {
      setFieldValue(fieldName, []);
      resetFilters[fieldName] = [];
    });
    handleSetFilters(resetFilters);
  };

  //   const renderError = (id) => {
  //     if (!errors[id]) return null;
  //     return (
  //       <div id={id}>
  //         <Form.Error>{errors[id]}</Form.Error>
  //       </div>
  //     );
  //   };

  const getField = (f, fieldName) => {
    return (
      {
        id: f.id,
        name: fieldName,
        label: f.label,
        type: f.type,
      }
    );
  };

  const shortcutItems = useMemo(() => {
    return [
      {
        id: 'today',
        label: 'Today',
        getValue: () => {
          const today = getNow();
          return [today, today];
        },
      },
      {
        id: 'tomorrow',
        label: 'Tomorrow',
        getValue: () => {
          const tomorrow = getNow().add(1, 'day');
          return [tomorrow, tomorrow];
        },
      },
      {
        id: 'thisWeek',
        label: 'This Week',
        getValue: () => {
          const today = getNow();
          return [today.startOf('week'), today.endOf('week')];
        },
      },
      {
        id: 'nextWeek',
        label: 'Next Week',
        getValue: () => {
          const nextWeek = getNow().add(1, 'week');
          return [nextWeek.startOf('week'), nextWeek.endOf('week')];
        },
      },
      {
        id: 'clear',
        label: isMobile
          ? 'Clear'
          : ClearIcon,
        getValue: () => {
          return [];
        },
      },
    ];
  }, [isMobile]);

  const renderShortcuts = (id) => {
    return (
      <Button.Group
        orientation={isMobile ? 'vertical' : 'horizontal'}
        size="small"
        sx={{ marginTop: '1rem' }}
        fullWidth={isMobile}
      >
        {shortcutItems.map((s) => {
          const label = (typeof s.label === 'string')
            ? s.label
            : <s.label sx={{ fontSize: '1rem' }} />;
          return(
            <Button
              key={s.id}
              variant={(s.id !== 'clear') && (values.dateRange?.toString() === s.getValue().toString())
                ? 'contained'
                : 'outlined'
              }
              size="small"
              sx={{ padding: '0.75rem', borderRadius: '12px' }}
              onClick={() => setFieldValue(id, s.getValue())}
            >
              {label}
            </Button>
          );
        })}
      </Button.Group>
    );
  };

  const renderDateRangeField = ({ id, name }) => {
    const p = {
      value: (values[id]?.length === 2) ? values[id] : [null, null]
    };
    return (
      <FieldWrapper key={id}>
        <Form.ControlLabel
          name={name}
          control={(
            <DateRangePicker
              slotProps={{ textField: { fullWidth: true } }}
            />
          )}
          label={''}
          onChange={(v) => {
            if (Array.isArray(v) && v.length === 2) {
              setFieldValue(id, v);
            }
          }}
          sx={{ display: 'flex', flex: '0 0 100%', width: '100%', marginLeft: 0, }}
          {...p}
        />
        {renderShortcuts(id)}
      </FieldWrapper>
    );
  };

  const renderField = ({ id, name, label }) => {
    return (
      <FieldWrapper key={id}>
        <Form.ControlLabel
          name={name}
          value={id}
          control={<Checkbox checked={values[name]?.includes(id) || false} />}
          label={(typeof label === 'string')
            ? <Form.OptionLabel>{label}</Form.OptionLabel>
            : label
          }
          onChange={handleChange}
          sx={{ display: 'flex', flex: '0 0 100%', width: '100%' }}
        />
      </FieldWrapper>
    );
  };

  const renderFields = ({ options, fieldName, type }) => {
    if (type === 'dateRangePicker') {
      return (
        <Fragment key={fieldName}>
          {renderDateRangeField({ id: fieldName, name: fieldName, label: 'Date Range'})}
        </Fragment>
      );
    }
    return options.map((o) => renderField(getField(o, fieldName)));
  };

  const renderFieldsInGrid = ({ options, fieldName, type, columns = 2 }) => {
    if (type === 'dateRangePicker') {
      return (
        <Fragment key={fieldName}>
          {renderDateRangeField({ id: fieldName, name: fieldName, label: 'Date Range'})}
        </Fragment>
      );
    }
    const rows = [];
    options.forEach((o, idx) => {
      if (idx % columns === 0) {
        let rowItems = [];
        for (let i = 0; i < columns; i++) {
          rowItems.push(
            <RowItem key={`${idx + i}`}>
              {(idx + i < options.length) && renderField(getField(options[idx + i], fieldName))}
            </RowItem>
          );
        }
        rows.push(
          <Fragment key={o.id}>
            <Row>
              {rowItems.map((rItem) => rItem)}
            </Row>
          </Fragment>
        );
      }
    });
    return rows.map((r) => r);
  };

  const renderToggleShowAll = ({ fieldName, getLabel }, showAllOptions) => {
    const label = getLabel(showAllOptions);
    return (
      <a onClick={() => handleToggleShowAll(fieldName)}>
        {label}
      </a>
    );
  };

  const renderFormSection = (section, idx) => {
    const { sectionTitle, fieldName, options, displayLimit } = section;
    let showAllOptions = true;
    let showMoreAction = false;
    const limitedOptions = [];
    if (displayLimit && (displayLimit < options.length)) {
      showAllOptions = !!showAll[fieldName];
      showMoreAction = true;
      limitedOptions.push(...options.slice(0, displayLimit));
    }
    const s = showAllOptions ? section : { ...section, options: limitedOptions };
    return (
      <Fragment key={fieldName}>
        <Heading as='h1'>{sectionTitle}:</Heading>
        {isMobile ? renderFields(s) : renderFieldsInGrid(s)}
        {showMoreAction && renderToggleShowAll(section, showAllOptions)}
        {(idx + 1 < forms.length) && <Divider sx={{ marginTop: '1rem', marginBottom: '1rem' }} />}
      </Fragment>
    );
  };

  const renderFilterForm = () => {
    return (
      <Form formik={formik} onSubmit={handleSubmit}>
        <Card.Content>
          {forms.map(renderFormSection)}
        </Card.Content>
      </Form>
    );
  };

  const renderClearAll = () => {
    return (
      <Button
        onClick={handleResetFilters}
        variant="text"
        disabled={false}
      >
        Reset all
      </Button>
    );
  };

  const renderContent = () => renderFilterForm();

  return (
    <ModalAdvanced
      open={isOpen}
      title="Filters"
      onClose={onToggleFiltersView}
      headerRight={renderClearAll()}
      actions={[{ label: 'Apply', onClick: handleSubmit }]}
    >
      {renderContent()}
    </ModalAdvanced>
  );
};

export default OrdersFilters;
