import queryString from 'query-string';
import dayjs from 'dayjs';
import dayJsLocalizedFormat from 'dayjs/plugin/localizedFormat';
import dayJsTimezone from 'dayjs/plugin/timezone';
import dayJsIsBetween from 'dayjs/plugin/isBetween';
import dayJsIsBefore from 'dayjs/plugin/isSameOrBefore';
import dayJsIsAfter from 'dayjs/plugin/isSameOrAfter';
import dayJsUtc from 'dayjs/plugin/utc';
import _kebabCase from 'lodash/kebabCase';
import _orderBy from 'lodash/orderBy';
import _forEach from 'lodash/forEach';
import _sum from 'lodash/sum';
import _sumBy from 'lodash/sumBy';
import _padStart from 'lodash/padStart';
// import _xor from 'lodash/xor';
import { ORDER_STATUSES, ORDER_PRODUCTS_STATUSES, MENU_TYPE_SORT_ORDER } from './Consts';

dayjs.extend(dayJsUtc);
dayjs.extend(dayJsLocalizedFormat);
dayjs.extend(dayJsTimezone);
dayjs.extend(dayJsIsBetween);
dayjs.extend(dayJsIsBefore);
dayjs.extend(dayJsIsAfter);

export const forEach = (obj, cb) => _forEach(obj, cb);

const getOS = () => {
  const { userAgent } = window.navigator;
  const { platform } = window.navigator;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  let os = null;
  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'macOS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux';
  }
  return os;
};

const userOS = getOS();
const isAppleOS = userOS === 'macOS' || userOS === 'iOS';

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const sum = _sum;
export const sumBy = _sumBy;
export const kebabCase = _kebabCase;
export const padStart = _padStart;
// export const xor = _xor;

export const sort = (data, sortFields, sortBy) => _orderBy(data, sortFields, sortBy);

export const makeArray = (obj, cb) => {
  const arr = [];
  forEach(obj, (val, key) => arr.push({ id: key, ...val }));
  return cb ? cb(arr) : arr;
};

export const makeObject = (arr, key = 'id') => {
  const obj = {};
  forEach(arr, (item) => (item[key] = item));
  return obj;
};

export const cleanInputs = (obj, removeEmptyVals = false, removeNulls = false) => {
  forEach(obj, (val, key) => {
    if (typeof val === 'string') obj[key] = val.trim();
    if (removeEmptyVals) {
      if (val === '' || val === undefined) delete obj[key];
    }
    if (removeNulls) {
      if (val === null) delete obj[key];
    }
    if (!removeEmptyVals && !removeNulls) {
      if (val === '' || val === undefined) obj[key] = null;
    }
  });
  return obj;
};

export const convertAmountToCents = (value) => {
  return Math.ceil(value * 100);
};

export const convertAmountToDollars = (value) => {
  return Number(value) * 0.01;
};

export const formatNumber = ({
  value,
  currencyCode,
  locale = 'en-US',
  style = 'currency',
  showDecimals = true,
  useGrouping = true,
}) => {
  const localCurrency = new Intl.NumberFormat(locale, {
    style,
    useGrouping,
    currency: currencyCode,
    minimumFractionDigits: showDecimals ? 2 : 0,
    maxmumFractionDigits: showDecimals ? 2 : 0,
  });
  const formattedVal = localCurrency.format(convertAmountToDollars(value));
  return formattedVal;
};

export const convertPagination = ({ limit, offset }) => {
  const pageSize = limit;
  const page = Math.floor(offset / limit);
  return { page, pageSize };
};

export const convertLimitOffset = ({ page, pageSize }) => {
  const limit = pageSize;
  const offset = page * pageSize;
  return { limit, offset };
};

export const USER_TZ = dayjs?.tz?.guess();

export const getNow = () => dayjs();

export const formatDateTime = ({ dateTime, timeZone, locale, format = 'llll', asString = true }) => {
  const dt = (typeof dateTime === 'string') ? dateTime.trim() : dateTime;
  const dtObj = timeZone ? dayjs(dt).tz(timeZone) : dayjs(dt);
  if (asString) return dtObj.format(format);
  return dtObj;
};

export const isDateTimeBetween = ({ dateTime = dayjs(), startDateTime, endDateTime, timeZone }) => {
  const start = dayjs(startDateTime).tz(timeZone);
  const end = dayjs(endDateTime).tz(timeZone);
  const dt = dayjs(dateTime);
  return dt.isBetween(start, end);
};

export const isDateTimeAfter = ({ dateTime = dayjs(), afterDateTime, timeZone, precision, inclusive = false }) => {
  const after = dayjs(afterDateTime).tz(timeZone);
  const dt = dayjs(dateTime);
  return inclusive
    ? dt.isSameOrAfter(after, precision)
    : dt.isAfter(after, precision);
};

export const isDateTimeBefore = ({ dateTime = dayjs(), beforeDateTime, timeZone, precision, inclusive = false }) => {
  const before = dayjs(beforeDateTime).tz(timeZone);
  const dt = dayjs(dateTime);
  return inclusive
    ? dt.isSameOrBefore(before, precision)
    : dt.isBefore(before, precision);
};

export const constructQueryString = (queryParams = {}, options = {}) => {
  const qs = queryString.stringify(queryParams, { arrayFormat: 'comma', ...options });
  return qs ? `?${qs}` : '';
};

export const getPaymentMethod3dsStatus = (pmId, paymentRef) => {
  let is3ds = false;
  if (paymentRef?.intents) {
    for (const k in paymentRef?.intents) {
      const pi = paymentRef.intents[k];
      if (pi?.payment_method === pmId) {
        switch (pi?.status) {
        case 'requires_action':
        case 'requires_source_action':
          is3ds = true;
          break;
        default:
          break;
        }
        break;
      }
    }
  }
  return is3ds;
};

export const getPaymentMethodLabelAndImage = (brand, isMobile) => {
  let imgSrc = '/public/cc/card-default-outline.svg';
  let brandLabel = brand;
  switch (brand.toLowerCase()) {
  case 'visa':
    imgSrc = '/public/cc/visa-outline.svg';
    brandLabel = 'Visa';
    break;
  case 'mastercard':
    imgSrc = '/public/cc/mastercard-outline.svg';
    brandLabel = 'Mastercard';
    break;
  case 'amex':
    imgSrc = '/public/cc/amex-outline.svg';
    brandLabel = isMobile ? 'AmEx' : 'American Express';
    break;
  case 'discover':
    imgSrc = '/public/cc/discover-outline.svg';
    brandLabel = 'Discover';
    break;
  case 'diners':
    imgSrc = '/public/cc/diners.svg';
    brandLabel = 'Diners';
    break;
  case 'jcb':
    imgSrc = '/public/cc/jcb.svg';
    brandLabel = 'JCB';
    break;
  case 'unionpay':
    imgSrc = '/public/cc/unionpay.svg';
    brandLabel = 'UnionPay';
    break;
  case 'maestro':
    imgSrc = '/public/cc/maestro.svg';
    brandLabel = 'Maestro';
    break;
  case 'paypal':
    imgSrc = '/public/cc/paypal.svg';
    brandLabel = 'PayPal';
    break;
  case 'elo':
    imgSrc = '/public/cc/elo.svg';
    brandLabel = 'Elo';
    break;
  default:
    break;
  }
  return { imgSrc, brandLabel };
};

export const calculateProductBasePrice = (product) => {
  let p = product?.price ?? 0; // Bundle product +/- price
  let minPrice = p;
  let maxPrice = p;
  const explodedPrices = [];

  if (product?.catalogProduct?.productSelectionGroups) {
    const sg = product?.catalogProduct?.productSelectionGroups;
    sg?.forEach(({
      linkedProducts,
      required: minQuantity,
      quantity: maxQuantity,
    }) => {
      let defaultPrice = 0;
      const possiblePrices = [];
      linkedProducts?.forEach(({
        product: { priceCents: p2, productCondiments },
        default: isDefault,
      }) => {
        possiblePrices.push(p2);
        if (isDefault) defaultPrice += p2;
        if (Array.isArray(productCondiments)) {
          const cPossiblePrices = {};
          productCondiments.forEach((c) => {
            const { productSelectionGroup: cSg } = c;
            const { required: cMinQty, quantity: cMaxQty } = cSg || {};
            if (c.default) defaultPrice += c?.condiment?.priceCents ?? 0;

            if (!cPossiblePrices[cSg.id]) cPossiblePrices[cSg.id] = {
              cMinQty,
              cMaxQty,
              cPrices: [],
            };
            cPossiblePrices[cSg.id].cPrices.push(c?.condiment?.priceCents ?? 0);

            // TODO Temp
            explodedPrices.push(c?.condiment?.priceCents ?? 0);
          });

          for (const cSgId in cPossiblePrices) {
            const { cMinQty, cMaxQty, cPrices } = cPossiblePrices[cSgId];
            cPrices.sort();
            for (let i = 0; i < cMinQty; i++) {
              minPrice += cPrices[i] || 0;
            }
            for (let i = cPrices.length - 1; i > cMaxQty; i--) {
              maxPrice += cPrices[i] || 0;
            }
          }
        }

        // TODO Temp
        explodedPrices.push(p2);

      });
      possiblePrices.sort();
      for (let i = 0; i < minQuantity; i++) {
        minPrice += possiblePrices[i] || 0;
      }
      for (let i = possiblePrices.length - 1; i > maxQuantity; i--) {
        maxPrice += possiblePrices[i] || 0;
      }
      p += defaultPrice;
    });
  }

  // TODO Temp
  const exPrice = explodedPrices.reduce((acc, v) => acc + v, 0) + product?.price ?? 0;
  // TODO Temp

  if (exPrice !== product?.calculatedPrice) {
    console.debug(exPrice, product?.calculatedPrice, product?.price);
  }

  // TODO Using exploded price for now until backend supports user selections
  return {
    price: product?.calculatedPrice, // TODO Change back to p
    minPrice,
    maxPrice,
  };
};

export const calculateOrderSummary = (order) => {
  const summary = {
    orderId: null,
    currencyCode: 'USD',
    quantity: 0,
    quantityWithBundleItems: 0,
    refunded: 0,
    voided: 0,
    subtotal: 0,
    taxAmount: 0,
    total: 0,
    coupon: null,
  };
  if (order) {
    summary.orderId = order.id ?? null;
    summary.subtotal = order.subtotalCents ?? 0;
    summary.taxAmount = order.taxesCents ?? 0;
    summary.sponsored = order.sponsoredCents ?? 0;
    summary.refunded = order.refundedCents ?? 0;
    summary.voided = order.voidedCents ?? 0;
    summary.total = order.finalCents ?? 0;
    summary.currencyCode = order.finalCurrency ?? 'USD';
    summary.quantity = order.quantity ?? 0;
    summary.quantityWithBundleItems = order.quantity ?? 0;
  }
  return summary;
};

// Helper consolidateCartItems()
const getOptionKey = (options) => {
  let optionKey = [];
  const makeOptKey = (o) => {
    for (const sgId in o) {
      const opts = o[sgId];
      opts?.forEach((opt) => {
        const { id: oId, quantity: oQty , options: oOptions } = opt;
        optionKey.push(`_${sgId}:${oId}(${oQty})`);
        if (oOptions) makeOptKey(oOptions);
      });
    }
  };
  if (options) makeOptKey(options);
  // Object order not guaranteed, so sort first
  return optionKey.sort().join('');
};

export const consolidateCartItems = (items) => {
  const uniqueUserItems = {};
  items.forEach((item) => {
    const {
      id,
      date,
      quantity,
      menuProductId: mpId,
      menuProduct,
      userId: uid,
      user,
      options,
    } = item;
    const userId = uid || user?.id;
    const menuProductId = mpId || menuProduct?.id;
    const optionKey = getOptionKey(options);
    const key = `${menuProductId}_${date}_${userId}${optionKey}`;
    if (!uniqueUserItems[key]) {
      uniqueUserItems[key] = { ...item };
    } else {
      if (id && uniqueUserItems[key].id) {
        uniqueUserItems[id] = { ...item };
      } else {
        if (id) uniqueUserItems[key].id = id;
        uniqueUserItems[key].quantity += quantity;
      }
    }
  });
  const consolidatedItems = [];
  for (const key in uniqueUserItems) {
    const item = uniqueUserItems[key];
    consolidatedItems.push(item);
  }
  return consolidatedItems;
};

export const getInvalidCartItemErrors = (id, invalidCartItems) => {
  const errors = [];
  if (Array.isArray(invalidCartItems)) {
    invalidCartItems.forEach(({
      cartItemId: invalidCartItemId,
      message,
    }) => {
      if (invalidCartItemId) {
        if (invalidCartItemId === id) {
          errors.push(message);
        }
      }
    });
  }
  return (errors.length === 0) ? null : errors;
};

export const transformCartItemsDsToOrderProductsDs = (cartItems, invalidCartItems = []) => {
  if (!Array.isArray(cartItems)) return [];
  const products = cartItems.map((item) => {
    const {
      id,
      quantity,
      product: {
        id: productId,
        name,
        description,
      },
      price: {
        cents: price,
        currency: {
          isoCode: currencyCode,
        }
      },
      cartItems: options,
    } = item;
    const errors = getInvalidCartItemErrors(id, invalidCartItems);
    let bPrice = null;
    const bundleItems = transformCartItemsDsToOrderProductsDs(options, []);
    if (bundleItems?.products?.length > 0) {
      bundleItems.products = sort(
        bundleItems.products,
        ['price', 'name', 'id'],
        ['desc', 'asc', 'asc']
      );
      bPrice = bundleItems.products.reduce((acc, v) => acc + v.bundlePrice, 0);
    }
    return ({
      id,
      recId: id, // TODO
      quantity,
      price,
      currencyCode,
      deliveryDate: item?.date,
      productId,
      name,
      description,
      user: { id: item?.user?.id },
      bundleItems,
      bundlePrice: price + bPrice,
      errors,
    });
  });
  const quantity = products.reduce((acc, v) => acc + v.quantity, 0);
  return { products, quantity };
};

export const transformCartCouponsDsToOrderCouponsDs = (cartCoupons, couponValidation, cartItems) => {
  const coupons = [];
  if (!couponValidation) return coupons;

  cartCoupons.forEach(({ id }) => {
    const { coupon, couponApplication, isValid } = couponValidation;
    if (isValid && id === coupon?.id) {
      const additionalParams = {
        orderDiscount: couponApplication.discountAmount,
      };
      if (couponApplication?.isRestricted) {
        additionalParams.orderProducts = couponApplication.applicableItems.map((idx) => {
          const item = cartItems[idx];
          const { menuId, userId, date, quantity, menuItem } = item;
          const { id: productId, price, currencyCode, taxRate } = menuItem;
          const opIdx = additionalParams.orderProducts?.length || 0;
          const p = {
            menuId,
            productId,
            userId,
            quantity,
            price,
            currencyCode,
            taxRate,
            deliveryDate: date,
            idxMap: [opIdx, idx],
          };
          return p;
        });
      }
      coupons.push({
        coupon,
        couponApplication: {
          ...couponApplication,
          ...additionalParams,
        },
      });
    }
  });
  return coupons;
};

export const transformCart = (cart, couponValidation) => {
  const tc = transformCartItemsDsToOrderProductsDs(cart || []);
  const coupons = transformCartCouponsDsToOrderCouponsDs(cart?.coupons || [], couponValidation, cart || []);
  return {
    transformedCartItems: tc.products,
    transformedCartCoupons: coupons,
    quantity: tc.quantity,
  };
};

export const sortMenus = (menus) => {
  if (!Array.isArray(menus) || menus.length === 0) return [];
  const sortedMenus = sort(menus.map((m) => {
    const { type } = m;
    return { ...m, sortOrder: (MENU_TYPE_SORT_ORDER[type] || 0) };
  }), ['sortOrder', 'name', 'id']);
  return sortedMenus;
};

// Use in Autocomplete filters
export const sortUsaStates = (options, { inputValue }) => {
  const input = inputValue?.toLowerCase();
  if (!input) return options;
  const filteredOptions = options.filter(
    (state) =>
      state.label.toLowerCase().includes(input)
            || state.value.toLowerCase().includes(input)
  );
  const sortedUsaStateOptions = filteredOptions.sort((a, b) => {
    const aNameLower = a.label.toLowerCase();
    const bNameLower = b.label.toLowerCase();
    const aAbbreviationLower = a.value.toLowerCase();
    const bAbbreviationLower = b.value.toLowerCase();
    const multiplier = `${input}`.length === 2 ? 2 : 1;
    const aScore =
            aNameLower.startsWith(input) * 2 +
            aAbbreviationLower.startsWith(input) * 1 * multiplier;
    const bScore =
            bNameLower.startsWith(input) * 2 +
            bAbbreviationLower.startsWith(input) * 1 * multiplier;
    return bScore - aScore;
  });
  return sortedUsaStateOptions;
};

export const sortGradesComparator = (a, b) => {
  if (a.description && b.description) {
    const gradeValues = {
      'INF': 1,
      'TOD': 2,
      'PPK': 3,
      'YS': 4,
      'PK': 5,
      'K': 6,
      '1': 7,
      '2': 8,
      '3': 9,
      '4': 10,
      '5': 11,
      '6': 12,
      '7': 13,
      '8': 14,
      '9': 15,
      '10': 16,
      '11': 17,
      '12': 18,
      '13': 19,
      '14': 20,
      '15': 21,
      '16': 22,
      '17': 23,
      '18': 24,
      '19': 25,
      '20': 26,
    };
    const gradeA = a.description.toUpperCase();
    const gradeB = b.description.toUpperCase();
    const valueA = gradeValues[gradeA];
    const valueB = gradeValues[gradeB];
    return (valueA && valueB)
      ? (valueA - valueB)
      : 0;
  }
  if (a.name && b.name) {
    const gradeA = a.name.toUpperCase();
    const gradeB = b.name.toUpperCase();
    return gradeA.localeCompare(gradeB);
  }
  return 0;
};

// ====================

const getIsEncrypted = (value) => {
  try {
    const parsedValue = JSON.parse(value);
    const isEncrypted = (
      typeof parsedValue === 'object' &&
      'encryptedData' in parsedValue &&
      'iv' in parsedValue &&
      'salt' in parsedValue
    );
    return isEncrypted;
  } catch (err) {
    // No-op
    return false;
  }
};

export const deriveKey = async (pw, salt) => {
  const enc = new TextEncoder();
  const baseKey = await window.crypto.subtle.importKey(
    'raw',
    enc.encode(pw),
    { name: 'PBKDF2' },
    false,
    ['deriveKey']
  );
  return window.crypto.subtle.deriveKey(
    {
      name: 'PBKDF2',
      salt: enc.encode(salt),
      iterations: 100000,
      hash: 'SHA-256',
    },
    baseKey,
    { name: 'AES-GCM', length: 256 },
    true,
    ['encrypt', 'decrypt']
  );
};

// This hardcoded key is used to obfuscate eg user's password client-side, but is not secure
const getHardcodedKey = async () => {
  const pw = 'hbLb6Ams86wTeNFZP3VGn3cpPEpZumeGdEA2ynCWJem7sdxs';
  const salt = 'eZzqktRgNL4HxUkFHZfWBjKM';
  const key = await deriveKey(pw, salt);
  return key;
};

export const encryptString = async (string, suppliedKey) => {
  if (getIsEncrypted(string)) return string;
  const key = suppliedKey || await getHardcodedKey();
  const enc = new TextEncoder();
  const iv = window.crypto.getRandomValues(new Uint8Array(12));
  const data = enc.encode(string);
  const cipherText = await window.crypto.subtle.encrypt(
    { name: 'AES-GCM', iv },
    key,
    data
  );
  const combined = new Uint8Array(iv.length + cipherText.byteLength);
  combined.set(iv, 0);
  combined.set(new Uint8Array(cipherText), iv.length);
  const val = btoa(String.fromCharCode.apply(null, combined));
  return val;
};

export const decryptString = async (encodedString, suppliedKey) => {
  const key = suppliedKey || await getHardcodedKey();
  const dec = new TextDecoder();
  const combined = Uint8Array.from(atob(encodedString), (c) => c.charCodeAt(0));
  const iv = combined.slice(0, 12);
  const data = combined.slice(12);
  const decrypted = await window.crypto.subtle.decrypt(
    { name: 'AES-GCM', iv },
    key,
    data
  );
  const val = dec.decode(decrypted);
  return val;
};
