import { useMemo, useState, useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { registerAuthUser } from '../../firebase/auth';
import { SIGN_IN, JOIN_WAITLIST } from '../../routes';
import { constructQueryString, cleanInputs, sortUsaStates } from '../../utils';
import { client as httpClient } from '../../http';
import useTemplateContext from '../../hooks/useTemplateContext';
import useStore from '../../hooks/useStore';
import useQueryParams from '../../hooks/useQueryParams';
import useGlobalContext from '../../hooks/useGlobalContext';
import { USER_ROLES, USER_ROLES_LABELS } from '../../utils/Consts';
import USA_STATE_LIST from '../../utils/usaStates.json';
import {
  PageContainer,
  Wizard,
  RegisterAccountUsers,
  TosPrivacyStatement,
  Logo,
} from '../../components';
import { Card, Heading, Error, Link, SecondaryActionText } from '../../common';

const { ADMIN, GUARDIAN, STAFF, STUDENT } = USER_ROLES;
const ROLE_OPTIONS = [GUARDIAN, STAFF, STUDENT];
const ROLE_BOOLS = {
  [GUARDIAN]: 'isGuardian',
  [STAFF]: 'isStaff',
  [STUDENT]: 'isStudent',
};

const ROLES = ROLE_OPTIONS.map((value) => ({
  label: USER_ROLES_LABELS[value],
  value,
}));

const ACCESS_KEY = process?.env?.CLOUD_ACCESS_KEY;

const Wrapper = styled.div`
  width: 500px;
  max-width: 100%;
`;

const Container = styled.div`
  padding: 0;
  margin: 0;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  min-height: 90vh;
`;

const SubtleText = styled.span`
  color: var(--darkGray);
  font-size: 0.8rem;
`;

const RegistrationSchema = Yup.object().shape({
  firstName: Yup.string().required('Please enter your first name'),
  lastName: Yup.string().required('Please enter your last name'),
  preferredName: Yup.lazy((value) => {
    switch (typeof value) {
      case 'undefined':
        return Yup.string().default('****');
      case 'string':
        return Yup.string();
      default:
        return Yup.ValidationError('preferredName be an string or undefined');
    }
  }),
  phoneNumber: Yup.lazy((value) => {
    switch (typeof value) {
      case 'undefined':
        return Yup.string().default('****');
      case 'string':
        return Yup.string();
      default:
        return Yup.ValidationError('phoneNumber be an string or undefined');
    }
  }),
  locationId: Yup.lazy((value) => {
    switch (typeof value) {
      case 'undefined':
        return Yup.string().default('****');
      case 'string':
        return Yup.string();
      default:
        return Yup.ValidationError('locationId be an string or undefined');
    }
  }),
  roles: Yup.array().min(2).required(),
  emailAddress: Yup.string()
    .required('Please enter a valid email address')
    .email('Please enter a valid email address'),
  password: Yup.string()
    .min(6, 'Password must be at least 6 characters')
    .required('Enter a password'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords do not match')
    .required('Enter password again to confirm'),
});

const USA_STATES = USA_STATE_LIST.map(
  ({ name: label, abbreviation: value }) => ({ label, value }),
);

const Register = () => {
  const [, setTemplateState] = useTemplateContext();
  const history = useHistory();
  const q = useQueryParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [customerHeader, setCustomHeader] = useState(null);

  // TODO Refactor wizard to hook?
  const storeInstance = useStore('registration');
  const initUsState = (() => {
    if (q?.state) {
      if (USA_STATES.some(({ value }) => value === q.state)) {
        return q.state;
      }
    }
    const s = storeInstance.get('usaState');
    if (s) {
      if (USA_STATES.some(({ value }) => value === s)) {
        return s;
      }
    }
    return null;
  })();
  const initLocationId =
    q?.locationId || storeInstance.get('locationId') || null;
  const [usState, setUSState] = useState(initUsState);
  const [selectedLocationId, setSelectedLocationId] = useState(initLocationId);
  const [locations, setLocations] = useState(usState ? { [usState]: [] } : {});
  const [, setGlobalState] = useGlobalContext();

  const getInitNode = () => {
    const n = storeInstance.get('currentNodeId') || 'schoolSelection';
    return n;
  };

  const initNode = useRef(getInitNode());

  const handleSetCustomHeaderContent = (content = null) => {
    setCustomHeader(content);
  };

  const handleScroll = (id, ancestorId) => {
    const wrapperEl = document.getElementById(ancestorId);
    const topPos = wrapperEl?.offsetTop || 0;
    const el = document.getElementById(id);
    if (!el || !wrapperEl) return;
    const y = el.getBoundingClientRect().top - window.pageYOffset - topPos;
    if (y === 0) return;
    wrapperEl.scroll({ top: y, behavior: 'smooth' });
  };

  useEffect(() => {
    setGlobalState({ showLogo: false });
    return () => {
      setGlobalState({ showLogo: true });
    };
  }, []);

  useEffect(() => {
    if (!error) return;
    handleScroll('registration-error', 'main-content');
  }, [error]);

  const handleSetStepChange = (currentNodeId) => {
    switch (currentNodeId) {
      case 'schoolSelection':
        setTemplateState({ showHero: true });
        break;
      case 'signup':
        setTemplateState({ showHero: true });
        break;
      case 'accountUsers':
        setTemplateState({ showHero: false });
        break;
      default:
        break;
    }
  };

  const handleWizardInit = (data) => {
    handleSetStepChange(data.currentNodeId);
  };

  const handleWizardGoBack = (data) => {
    const currentNodeId = data.currentNodeId;
    let prevNodeId = null;
    switch (currentNodeId) {
      case 'signup':
        prevNodeId = 'schoolSelection';
        break;
      case 'accountUsers':
        prevNodeId = 'signup';
        break;
      default:
        break;
    }
    if (prevNodeId) handleSetStepChange(prevNodeId);
    return prevNodeId;
  };

  const handleGetSteps = (activeStep) => {
    const s =
      activeStep === 0
        ? [
            { label: 'Check availability' },
            { label: 'Sign up' },
            { label: 'Place an order' },
          ]
        : [
            { label: 'Select school' },
            { label: 'Sign up' },
            { label: 'Setup your profile' },
          ];
    return s;
  };

  // const handleGoToOrdo = (data, { onClearState }) => {
  //   if (onClearState) onClearState();
  //   window.location.href = process.env.MARKETING_SITE_URI;
  // };

  const handleGoToWaitlist = (data) => {
    const { school: schoolId, role } = data;
    let url = JOIN_WAITLIST;
    if (schoolId && schoolId !== 'NOT_LISTED') {
      const s = locations?.[usState]?.find(({ value }) => value === schoolId);
      if (s?.label) {
        const qs = constructQueryString({
          school: s.label.trim(),
          schoolId,
          role,
        });
        url = `${JOIN_WAITLIST}${qs}`;
      }
    }
    history.push(url);
  };

  const handleRegisterUser = async (userData) => {
    const getErrMessage = (err) => {
      let m = 'There was an error registering. Please try again.';
      // https://firebase.google.com/docs/auth/admin/errors
      if (err?.code === 'auth/email-already-exists') {
        const qs = constructQueryString({
          emailAddress: userData.emailAddress,
        });
        const url = `${SIGN_IN}${qs}`;
        m = (
          <>
            {err?.message || m}
            <br />
            <Link href={url}>Please login here.</Link>
          </>
        );
      } else if (err?.message) {
        m = err.message;
      }
      return m;
    };

    const res = await registerAuthUser(userData).catch((err) => {
      console.error(err);
      setError(getErrMessage(err));
    });
    if (res && res.error) {
      console.error(res.error);
      setError(getErrMessage(res.error));
    }
  };

  const handleSubmitData = async (data) => {
    setLoading(true);
    const {
      firstName,
      lastName,
      preferredName,
      emailAddress,
      phoneNumber,
      password,
      confirmPassword,
      isGuardian,
      isStaff,
      isStudent,
      rosterIds,
      locationId: locId,
      accountUsers: aUsers,
    } = data;

    let accountUsers = [];
    if (aUsers) {
      try {
        const au = JSON.parse(aUsers); // TODO
        if (Array.isArray(au))
          accountUsers = au.map(({ user, ...aUser }) => {
            const u = {
              user: cleanInputs(
                {
                  firstName: user?.firstName,
                  lastName: user?.lastName,
                  preferredName: user?.preferredName,
                  pronouns: user?.pronouns || null,
                  emailAddress: user?.emailAddress || null,
                  phoneNumber: user?.phoneNumber || null,
                },
                true,
              ),
              rosterIds: user?.rosterIds || [],
              ...aUser,
            };
            return u;
          });
      } catch (err) {
        console.error(err);
      }
    }

    const school = locations?.[usState]?.find(
      ({ value } = {}) => value === data?.school,
    );
    const locationId = locId || school?.locationId;

    const userData = {
      firstName: firstName,
      lastName: lastName,
      emailAddress: emailAddress,
      password,
      roles: [ADMIN],
      rosterIds,
      accountUsers,
    };

    if (preferredName) userData.preferredName = preferredName;
    if (phoneNumber) userData.phoneNumber = phoneNumber;
    if (isGuardian) userData.roles.push(GUARDIAN);
    if (isStaff) userData.roles.push(STAFF);
    if (isStudent) userData.roles.push(STUDENT);
    if ((isStaff || isStudent) && locationId) userData.locationId = locationId;
    if ((isStaff || isStudent) && rosterIds) userData.rosterIds = rosterIds;

    if (accountUsers?.length > 0 && !userData.roles.includes(GUARDIAN)) {
      userData.roles.push(GUARDIAN);
    }

    const isValid = await RegistrationSchema.isValid({
      ...userData,
      confirmPassword,
    });
    if (isValid) {
      const cleanUserData = cleanInputs(userData, true);
      await handleRegisterUser(cleanUserData);
    } else {
      const validation = RegistrationSchema.validate({
        ...userData,
        confirmPassword,
      });
      console.error('Invalid registration user', userData, validation);
      setError('There was an error validating your registration.');
    }
    setLoading(false);
    return 'accountUsers';
  };

  const handleSchoolSubmit = (data, { onUpdateState }) => {
    let locId = null;
    const schoolObj = locations?.[usState]?.find(
      ({ value }) => value === data.school,
    );
    const hasLocationId = locations?.[usState]?.some(
      ({ locationId, value }) => {
        if (locationId) {
          const isMatch = value === data.school;
          if (isMatch) locId = locationId;
          return isMatch;
        }
        return false;
      },
    );
    if (!hasLocationId) {
      handleGoToWaitlist(data);
      return;
    }
    if (schoolObj) {
      const updateProperties = {
        schoolObj: JSON.stringify(schoolObj), // TODO abstract and have hook stringify values
        locationId: locId,
      };
      onUpdateState(updateProperties);
    }
    handleSetStepChange('signup');
    return 'signup';
  };

  const handleSchoolSelection = (school = null) => {
    if (!school) {
      setSelectedLocationId(null);
      return;
    }
    const foundLocation = locations[usState]?.find(
      ({ value }) => value === school,
    );
    if (foundLocation) {
      setSelectedLocationId(foundLocation?.locationId);
    } else {
      console.error(
        'Could not find location for school',
        school,
        locations[usState],
      );
    }
  };

  const handleUSStateSelection = (s = null) => {
    if (!s || s !== usState) {
      setSelectedLocationId(null);
    }
    setUSState(s);
  };

  const handleRegistrationPrecheck = async ({ emailAddress }) => {
    setError(null);
    const res = await httpClient.post({
      url: '/users/register/precheck',
      headers: { _accessKey: ACCESS_KEY },
      body: { emailAddress },
    });
    if (res?.exists) {
      setError(
        'This email address is already registered. Please login or reset your password.',
      );
      return 'signup';
    }
    handleSetStepChange('accountUsers');
    return 'accountUsers';
  };

  const handleAccountHasStudentOrStaff = (data) => {
    let hasAuStudentOrStaff = !!(data?.isStudent || data?.isStaff);
    if (!hasAuStudentOrStaff && data?.accountUsers) {
      try {
        const aUsers = JSON.parse(data?.accountUsers);
        if (Array.isArray(aUsers)) {
          hasAuStudentOrStaff = aUsers.some((au) => {
            return (
              Array.isArray(au?.roles) &&
              (au.roles.includes(STUDENT) || au.roles.includes(STAFF))
            );
          });
        }
      } catch (err) {
        // no-op
      }
    }
    return !hasAuStudentOrStaff;
  };

  const renderMoreActions = () => {
    return (
      <SecondaryActionText
        text="Already have an account?"
        actionLabel="Log in."
        route={SIGN_IN}
      />
    );
  };

  const renderTosActions = () => {
    return <TosPrivacyStatement />;
  };

  const renderContactInfoStatement = () => {
    return (
      <SubtleText>
        Your contact info will be used for emergency communication and school
        lunch reminders only. We will never send you spam or distribute this
        information.
      </SubtleText>
    );
  };

  const renderLogo = () => {
    return <Logo sx={{ width: '5.5rem' }} />;
  };

  const registrationFlows = useMemo(() => {
    return {
      schoolSelection: {
        step: 0,
        title: renderLogo(),
        description: (
          <Heading variant="h1" sx={{ fontSize: '2.25rem' }}>
            Find your school
          </Heading>
        ),
        footer: renderMoreActions(),
        form: {
          preventSubmitOnEnter: true,
          initialValues: { usaState: usState || '', school: '', role: '' },
          setValues: (vals, setFieldValue) => {
            if (
              selectedLocationId &&
              !vals.school &&
              usState &&
              locations[usState]
            ) {
              const foundSchool = locations[usState]?.find(
                ({ locationId: locId }) => locId === selectedLocationId,
              );
              if (foundSchool?.value) {
                setFieldValue('school', foundSchool.value);
              }
            }
            ROLE_OPTIONS.forEach((r) => {
              setFieldValue(ROLE_BOOLS[r], r === vals.role || null);
            });
          },
          validationSchema: Yup.object().shape({
            usaState: Yup.string().required(
              'Please select a US state or territory',
            ),
            school: Yup.string().when(['usaState'], {
              is: (val) => !!val,
              then: Yup.lazy(() =>
                Yup.string().required(
                  'Please select a school, or choose —School Not Listed— option',
                ),
              ),
            }),
            role: Yup.string().required('Please select a role'),
          }),
          fields: [
            {
              id: 'usaState',
              label: 'State',
              type: 'autocomplete',
              placeholder: 'Select state',
              cb: handleUSStateSelection,
              options: USA_STATES,
              filterOptions: sortUsaStates,
            },
            {
              id: 'school',
              usState,
              selectedLocationId,
              stateLocationsCallback: setLocations,
              type: 'schoolSelect',
              placeholder: "Type your school's name",
              cb: handleSchoolSelection,
              // groupBy: (option => option.city),
              isDropdownOpenCb: (val) => {
                return val?.length > 0;
              },
            },
            {
              type: 'divider',
            },
            {
              id: 'role',
              label: 'Role',
              type: 'dropdown',
              placeholder: 'Who are you?',
              options: ROLES,
            },
          ],
          actions: [
            {
              key: 'next',
              cb: handleSchoolSubmit,
              label: 'Next',
              disabled: (data) => {
                return (
                  !(data.usaState && data.school && data.role) ||
                  !ROLE_OPTIONS.includes(data.role)
                );
              },
            },
          ],
        },
      },
      signup: {
        step: 1,
        title: 'Sign up',
        footer: (
          <>
            {renderTosActions()}
            {renderMoreActions()}
          </>
        ),
        form: {
          initialValues: {},
          validationSchema: Yup.object().shape({
            role: Yup.string().required('Please select a role'),
            firstName: Yup.string().required('Please enter your first name'),
            lastName: Yup.string().required('Please enter your last name'),
            emailAddress: Yup.string()
              .required('Please enter a valid email address')
              .email('Please enter a valid email address'),
            phoneNumber: Yup.string()
              .min(11, 'Please enter a phone number')
              .required('Please enter a phone number'),
            password: Yup.string()
              .min(6, 'Password must be at least 6 characters')
              .required('Enter a password'),
            confirmPassword: Yup.string()
              .oneOf([Yup.ref('password'), null], 'Passwords do not match')
              .required('Enter password again to confirm'),
          }),
          fields: [
            {
              id: 'role',
              label: 'Role',
              type: 'dropdown',
              options: ROLES,
            },
            {
              id: 'firstName',
              label: 'First Name',
              type: 'text',
              autoFocus: true,
            },
            {
              id: 'lastName',
              label: 'Last Name',
              type: 'text',
            },
            {
              id: 'emailAddress',
              label: 'Email Address',
              type: 'email',
              autoComplete: 'username',
            },
            {
              id: 'phoneNumber',
              label: 'Cell Phone Number',
              type: 'tel',
              autoComplete: 'tel',
            },
            {
              id: 'password',
              label: 'Password',
              type: 'password',
              autoComplete: 'new-password',
            },
            {
              id: 'confirmPassword',
              label: 'Confirm Password',
              type: 'password',
              autoComplete: 'confirm-password',
            },
            {
              id: 'contactInfoStatement',
              component: renderContactInfoStatement,
            },
          ],
          actions: [
            {
              key: 'next',
              cb: handleRegistrationPrecheck,
              label: 'Next',
            },
          ],
        },
      },
      accountUsers: {
        step: 2,
        title: 'Add family members',
        titleAlign: 'center',
        form: {
          initialValues: {},
          fields: [
            {
              id: 'accountUsers',
              component: RegisterAccountUsers,
              persistChangeImmediately: true,
            },
          ],
          actions: [
            {
              key: 'next',
              cb: handleSubmitData,
              label: 'Sign up',
              disabled: handleAccountHasStudentOrStaff,
              loading,
            },
          ],
        },
      },
    };
  }, [loading, usState, selectedLocationId, locations]);

  const renderError = () => {
    if (!error) return null;
    return (
      <>
        <br />
        <Error
          id="registration-error"
          header="Registration Error"
          content={error}
        />
      </>
    );
  };

  const renderCustomHeader = () => {
    const headerEl = document.getElementById('main-header');
    if (!headerEl) {
      console.warn('No header element found');
      return null;
    }
    return createPortal(customerHeader, headerEl);
  };

  const renderRegistrationFlow = () => {
    return (
      <Card>
        <Card.Content>
          <Wrapper>
            <Wizard
              flows={registrationFlows}
              rootNodeId={initNode.current}
              namespace="registration"
              onInit={handleWizardInit}
              onGoBack={handleWizardGoBack}
              onSetHeader={handleSetCustomHeaderContent}
              onGetSteps={handleGetSteps}
              persistState={true}
              showStepper={true}
            />
            {customerHeader && renderCustomHeader()}
            {!!error && renderError()}
          </Wrapper>
        </Card.Content>
      </Card>
    );
  };

  const renderPageContent = renderRegistrationFlow;

  return (
    <PageContainer
      container={Container}
      loading={false}
      pageTitle="Register"
      showPageTitle={false}
    >
      {renderPageContent()}
    </PageContainer>
  );
};

export default Register;
