import { useEffect, useMemo, useState, useCallback } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { backendContext } from '../graphql/client';
import { GET_SUBSCRIPTION_TAGS } from '../graphql/queries';
import {
  CREATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
  PAUSE_SUBSCRIPTION,
  AUTOFILL_SUBSCRIPTION_CARTS,
} from '../graphql/mutations';
import useAccount from './useAccount';
import useCart from './useCart';
import { formatDateTime } from '../utils';
import { SUBSCRIPTION_OPTIONS, SUBSCRIPTION_STATUSES } from '../utils/Consts';

const WEEKDAYS = [
  SUBSCRIPTION_OPTIONS.DAY.MONDAY,
  SUBSCRIPTION_OPTIONS.DAY.TUESDAY,
  SUBSCRIPTION_OPTIONS.DAY.WEDNESDAY,
  SUBSCRIPTION_OPTIONS.DAY.THURSDAY,
  SUBSCRIPTION_OPTIONS.DAY.FRIDAY,
  SUBSCRIPTION_OPTIONS.DAY.SATURDAY,
  SUBSCRIPTION_OPTIONS.DAY.SUNDAY,
];

const useSubscriptions = () => {
  const {
    subscriptionUsers,
    hasSubscriptions,
    loading: loadingAccount,
    refetch,
  } = useAccount();
  const { refetchCart } = useCart();
  const [
    getSubscriptionTags,
    { data: subscriptionTagsData, loading: loadingTags },
  ] = useLazyQuery(GET_SUBSCRIPTION_TAGS);
  const [createSubscription] = useMutation(CREATE_SUBSCRIPTION, backendContext);
  const [updateSubscription] = useMutation(UPDATE_SUBSCRIPTION, backendContext);
  const [autofillSubscriptionCart, { loading: autofillLoading }] = useMutation(
    AUTOFILL_SUBSCRIPTION_CARTS,
    backendContext,
  );
  const [pauseSubscription, { loading: pauseSubLoading }] = useMutation(
    PAUSE_SUBSCRIPTION,
    backendContext,
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      await getSubscriptionTags();
    })();
  }, []);

  const handleUserSubscriptionSubmit = useCallback(
    async (userId, paymentMethodId, vals = {}) => {
      setLoading(true);
      const au = subscriptionUsers.find((u) => u.userId === userId);
      if (!au) {
        console.error('Account user not found', userId, vals);
        return;
      }

      let auSub = au?.subscription;
      let subId = auSub?.id;
      if (!subId) {
        const { data, errors } = await createSubscription({
          variables: {
            input: { accountUserId: au.id },
          },
        }).catch((e) => {
          console.error('Failed to create subscription', {
            userId,
            accountUserId: au.id,
            error: e,
          });
        });
        auSub = data?.createSubscription?.subscription;
        subId = auSub?.id;
        if (!subId) {
          console.error('Failed to create subscription', {
            userId,
            vals,
            data,
            errors,
          });
          setLoading(false);
          return;
        }
      }

      const {
        weekDaysToSubscribe = [],
        mealTimes = [],
        dietaryPreferences = [],
        status: s = null,
        pauseUntil = null,
      } = vals;
      const status = s;
      if (status === SUBSCRIPTION_STATUSES.PAUSED && pauseUntil) {
        const { data, errors } = await pauseSubscription({
          variables: {
            subscriptionId: subId,
            pauseUntil: formatDateTime({
              dateTime: pauseUntil,
              format: 'YYYY-MM-DD',
            }),
          },
        });
        setLoading(false);
        return { data, errors };
      }

      const mealTimesGroups = [];
      mealTimes.forEach((mt) => {
        const [mealService] = mt.split(':');
        if (!mealTimesGroups.includes(mealService)) {
          mealTimesGroups.push(mealService);
        }
      });

      const userSub = {
        id: subId,
        paymentMethodId,
        status: status || SUBSCRIPTION_STATUSES.ENABLED,
        subscriptionTagIds: dietaryPreferences.filter(
          (t) => t !== SUBSCRIPTION_OPTIONS.DIETARY.OMNIVORE,
        ),
        dailyOptions: WEEKDAYS.map((day) => ({
          [day.toLowerCase()]: {
            enabled: weekDaysToSubscribe.includes(day),
          },
        })).reduce((acc, val) => ({ ...acc, ...val }), {}),
        subscriptionOptions: {
          menuSlots: auSub.subscriptionOptions.menuSlots.map((slot) => ({
            enabled:
              mealTimesGroups.length > 0
                ? mealTimesGroups.includes(slot.mealService)
                : slot.isEnabled,
            mealService: slot.mealService,
            productSlots: slot.productSlots.map((ps) => ({
              enabled:
                mealTimes.length > 0
                  ? mealTimes.includes(`${slot.mealService}:${ps.productType}`)
                  : ps.isEnabled,
              quantity: ps.quantity,
              productType: ps.productType,
            })),
          })),
        },
      };

      const { data, errors } = await updateSubscription({
        variables: {
          input: { subscription: userSub },
        },
      }).catch((e) => {
        console.error('Failed to update subscription', {
          subscription: userSub,
          userId,
          error: e,
        });
        setLoading(false);
      });
      if (errors) {
        console.error('Failed to update subscription', {
          userId,
          vals,
          data,
          errors,
        });
        setLoading(false);
        return { data, errors };
      }
      await refetch();
      setLoading(false);
      return { data, errors };
    },
    [subscriptionUsers],
  );

  const handleAutoFillSubscriptionCart = useCallback(
    async (userId) => {
      const au = subscriptionUsers.find((u) => u.userId === userId);
      const subscriptionId = au?.subscription?.id;
      if (!subscriptionId) {
        console.error('Subscription not found', userId);
        return;
      }
      const { data, errors } = await autofillSubscriptionCart({
        variables: {
          subscriptionId,
        },
      }).catch((e) => {
        console.error('Failed to autofill subscription cart', {
          subscriptionId,
          userId,
          error: e,
        });
        setLoading(false);
        return { data: null, errors: e };
      });
      if (errors) {
        console.error('Failed to autofill subscription cart', {
          subscriptionId,
          data,
          errors,
        });
        setLoading(false);
        return { data, errors };
      }
      await refetchCart();
      setLoading(false);
      return { data, errors };
    },
    [subscriptionUsers],
  );

  const userIds = useMemo(() => {
    return subscriptionUsers.map((user) => user.userId);
  }, [subscriptionUsers]);

  return {
    userIds,
    users: subscriptionUsers,
    hasSubscriptions,
    tags: subscriptionTagsData?.tags || [],
    onUpsertUserSubscription: handleUserSubscriptionSubmit,
    onAutofillSubscriptionCart: handleAutoFillSubscriptionCart,
    refetch,
    loading: loadingTags || loadingAccount,
    isSaving: loading || autofillLoading || pauseSubLoading,
  };
};

export default useSubscriptions;
