import { Fragment, useEffect } from 'react';
import styled from '@emotion/styled';
import { useFormik } from 'formik';
import useViewport from '../../hooks/useViewport';
import {
  ModalAdvanced,
  Button,
  Form,
  Checkbox,
  Card,
  Heading,
  EmptyState,
} from '../../common';
import TuneIcon from '@mui/icons-material/Tune';

const FieldWrapper = styled.div`
  margin: 4px 0 !important;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const RowItem = styled.div`
  width: 50%;
`;

const CatalogProductFilters = ({
  filters,
  tags,
  userId,
  onToggleFiltersView,
  onSetFilters,
  isOpen,
}) => {
  const { isMobile } = useViewport();
  const hasFiltersOptions = tags?.length > 0;

  const handleSetFilters = ({ selectedFilters }) => {
    onSetFilters(selectedFilters);
    onToggleFiltersView();
  };

  const formik = useFormik({
    initialValues: {
      selectedUser: userId,
      selectedFilters: filters,
    },
    onSubmit: handleSetFilters,
  });

  const { values, handleChange, handleSubmit, setFieldValue } = formik;

  const handleResetFilters = () => {
    setFieldValue('selectedFilters', []);
    handleSetFilters({ selectedFilters: [] });
    onToggleFiltersView();
  };

  useEffect(() => {
    setFieldValue('selectedFilters', filters);
  }, [filters]);

  //   const renderError = (id) => {
  //     if (!errors[id]) return null;
  //     return (
  //       <div id={id}>
  //         <Form.Error>{errors[id]}</Form.Error>
  //       </div>
  //     );
  //   };

  const getTagField = (t) => {
    if (!t) return null;
    return {
      id: t.id,
      name: 'selectedFilters',
      label: t.name,
      type: 'checkbox',
    };
  };

  const renderField = ({ id, name, label }) => {
    return (
      <FieldWrapper key={id}>
        <Form.ControlLabel
          name={name}
          value={id}
          control={<Checkbox checked={values.selectedFilters.includes(id)} />}
          label={
            typeof label === 'string' ? (
              <Form.OptionLabel>{label}</Form.OptionLabel>
            ) : (
              label
            )
          }
          onChange={handleChange}
          sx={{ display: 'flex', flex: '0 0 100%', width: '100%' }}
        />
      </FieldWrapper>
    );
  };

  const renderTagFields = () => {
    if (!Array.isArray(tags)) return null;
    return tags.map((t) => renderField(getTagField(t)));
  };

  const renderTagFieldsInGrid = () => {
    const tagRows = [];
    if (!Array.isArray(tags)) return null;
    tags.forEach((t, idx) => {
      if (!t) return null;
      if (idx % 2 === 0) {
        tagRows.push(
          <Fragment key={t.id}>
            <Row>
              <RowItem>{renderField(getTagField(t))}</RowItem>
              <RowItem>
                {idx + 1 < tags.length &&
                  renderField(getTagField(tags[idx + 1]))}
              </RowItem>
            </Row>
          </Fragment>,
        );
      }
    });
    return tagRows.map((r) => r);
  };

  const renderFilterForm = () => {
    return (
      <Form formik={formik} onSubmit={handleSubmit}>
        <Card.Content>
          <Heading as="h1">Dietary:</Heading>
          {isMobile ? renderTagFields() : renderTagFieldsInGrid()}
        </Card.Content>
      </Form>
    );
  };

  const renderClearAll = () => {
    return (
      <Button
        onClick={handleResetFilters}
        variant="text"
        disabled={values.selectedFilters.length === 0}
      >
        Reset all
      </Button>
    );
  };

  const renderEmptyState = () => {
    return (
      <EmptyState
        icon={TuneIcon}
        message="There aren't any filters available for this user."
      />
    );
  };

  const renderContent = () =>
    hasFiltersOptions ? renderFilterForm() : renderEmptyState();

  return (
    <ModalAdvanced
      open={isOpen}
      title="Filters"
      onClose={onToggleFiltersView}
      headerRight={renderClearAll()}
      actions={[
        {
          label: hasFiltersOptions ? 'Apply' : 'Close',
          onClick: handleSubmit,
        },
      ]}
    >
      {renderContent()}
    </ModalAdvanced>
  );
};

export default CatalogProductFilters;
