import styled from '@emotion/styled';
import useViewport from '../../hooks/useViewport';
import Card from '../Card';
import Heading from '../Heading';
import IconButton from '../IconButton';
import CloseIcon from '@mui/icons-material/Close';

const Wrapper = styled.span`
  display: flex;
  flex: 1;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--altGray);
`;

const MobileWrapper = styled.div`
  display: flex;
  flex: 1;
  padding: 0 1rem;
  align-items: center;
  justify-content: space-between;
  height: 4rem;
  border-bottom: 1px solid var(--altGray);
`;

const HeaderEndCell = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  width: 6rem;
  padding: 0 0.5rem;
`;

const HeaderEndCellRight = styled(HeaderEndCell)`
  justify-content: flex-end;
`;

const ResponsiveHeader = ({ onClose, title, header, headerRight, showCloseButton }) => {
  const { isMobile } = useViewport();

  const renderCloseButton = () => {
    if (!showCloseButton) return null;
    return (
      <IconButton onClick={onClose}>
        <CloseIcon />
      </IconButton>
    );
  };

  const renderTitle = () => (
    <Heading variant="h6" sx={{ alignSelf: 'center' }}>
      {title}
    </Heading>
  );

  const renderHeader = () => {
    const HeaderWrapperEl = isMobile ? MobileWrapper : Wrapper;
    return (
      <HeaderWrapperEl>
        <HeaderEndCell>
          {renderCloseButton()}
        </HeaderEndCell>
        <Card.Header
          title={renderTitle()}
        />
        <HeaderEndCellRight>
          {headerRight}
        </HeaderEndCellRight>
      </HeaderWrapperEl>
    );
  };

  const renderCustomHeader = () => {
    return (
      <>
        {renderCloseButton()}
        {header}
      </>
    );
  };

  return header ? renderCustomHeader() : renderHeader();
};

ResponsiveHeader.defaultProps = {
  showCloseButton: true,
};

export default ResponsiveHeader;
