import { gql } from '@apollo/client';
import {
  CORE_USER_FIELDS,
  SUBSCRIPTION_FIELDS,
  CART_ITEM_FIELDS,
} from '../fragments';

export const CREATE_JOB = gql`
  mutation createJob($job: jsonb!) {
    insert__queue_one(object: { job: $job }) {
      id
      status
    }
  }
`;

export const CREATE_USER = gql`
  ${CORE_USER_FIELDS}
  mutation createUser($user: users_insert_input!) {
    insert_users_one(object: $user) {
      ...CoreUserFields
    }
  }
`;

export const UPDATE_USER = gql`
  ${CORE_USER_FIELDS}
  mutation updateUser($id: uuid!, $user: users_set_input!) {
    update_users_by_pk(pk_columns: { id: $id }, _set: $user) {
      ...CoreUserFields
    }
  }
`;

export const UPDATE_CART = gql`
  ${CART_ITEM_FIELDS}
  mutation updateCart($contents: JSON!) {
    updateCart(input: { contents: $contents }) {
      carts {
        id
        updatedAt
        subtotalCents
        subtotalCurrency
        finalCents
        finalCurrency
        taxesCents
        taxesCurrency
        cartItems {
          date
          user {
            id
          }
          menuProduct {
            id
          }
          ...CartItemFields
          cartItems {
            ...CartItemFields
            cartItems {
              ...CartItemFields
            }
          }
        }
      }
      errors {
        message
        code
        path
      }
    }
  }
`;

export const ADD_CART_ITEM = gql`
  ${CART_ITEM_FIELDS}
  mutation addCartItem($item: AddCartItemInput!) {
    addCartItem(input: $item) {
      cart {
        id
        updatedAt
        subtotalCents
        subtotalCurrency
        finalCents
        finalCurrency
        taxesCents
        taxesCurrency
        cartItems {
          date
          user {
            id
          }
          menuProduct {
            id
          }
          ...CartItemFields
          cartItems {
            ...CartItemFields
            cartItems {
              ...CartItemFields
            }
          }
        }
      }
      errors {
        message
        code
        path
      }
    }
  }
`;

export const ADD_CART_ITEMS = gql`
  ${CART_ITEM_FIELDS}
  mutation addCartItems($items: [CartItemInput!]!) {
    addCartItems(input: { items: $items }) {
      carts {
        id
        updatedAt
        subtotalCents
        subtotalCurrency
        finalCents
        finalCurrency
        taxesCents
        taxesCurrency
        cartItems {
          date
          user {
            id
          }
          menuProduct {
            id
          }
          ...CartItemFields
          cartItems {
            ...CartItemFields
            cartItems {
              ...CartItemFields
            }
          }
        }
      }
      errors {
        message
        code
        path
      }
    }
  }
`;

export const UPDATE_CART_ITEM = gql`
  ${CART_ITEM_FIELDS}
  mutation updateCartItem($cartId: ID!, $itemId: ID!, $quantity: Int!) {
    updateCartItem(
      input: { cartId: $cartId, itemId: $itemId, quantity: $quantity }
    ) {
      cart {
        id
        updatedAt
        subtotalCents
        subtotalCurrency
        finalCents
        finalCurrency
        taxesCents
        taxesCurrency
        cartItems {
          date
          user {
            id
          }
          menuProduct {
            id
          }
          ...CartItemFields
          cartItems {
            ...CartItemFields
            cartItems {
              ...CartItemFields
            }
          }
        }
      }
      errors {
        message
        code
        path
      }
    }
  }
`;

export const REMOVE_CART_ITEM = gql`
  ${CART_ITEM_FIELDS}
  mutation removeCartItem($cartId: ID!, $itemId: ID!) {
    removeCartItem(input: { cartId: $cartId, itemId: $itemId }) {
      cart {
        id
        updatedAt
        subtotalCents
        subtotalCurrency
        finalCents
        finalCurrency
        taxesCents
        taxesCurrency
        cartItems {
          date
          user {
            id
          }
          menuProduct {
            id
          }
          ...CartItemFields
          cartItems {
            ...CartItemFields
            cartItems {
              ...CartItemFields
            }
          }
        }
      }
    }
  }
`;

export const CLEAR_CARTS = gql`
  ${CART_ITEM_FIELDS}
  mutation clearCarts {
    clearCarts {
      carts {
        id
        updatedAt
        subtotalCents
        subtotalCurrency
        finalCents
        finalCurrency
        taxesCents
        taxesCurrency
        cartItems {
          date
          user {
            id
          }
          menuProduct {
            id
          }
          ...CartItemFields
          cartItems {
            ...CartItemFields
            cartItems {
              ...CartItemFields
            }
          }
        }
      }
      errors {
        message
        code
        path
      }
    }
  }
`;

export const CHECKOUT = gql`
  mutation checkout($accountId: ID, $paymentMethodId: String!) {
    checkout(
      input: { accountId: $accountId, paymentMethodId: $paymentMethodId }
    ) {
      isValid
      unavailableItems {
        idx
        menuId
        variantId
        productId
        message
      }
      orders {
        id
        createdAt
        updatedAt
        userAdjustable
        userCancellable
        status
        paymentStatus
        deliveryTimestamp
        deliveryDate
        finalCents
        finalCurrency
        quantity
        orderProducts {
          recId: id
          orderProductId
          user {
            id
            firstName
            preferredName
          }
        }
      }
      errors {
        message
        code
        path
      }
    }
  }
`;

export const CREATE_ACCOUNTS_USERS = gql`
  mutation createAccountsUsers(
    $accountId: uuid!
    $userId: uuid!
    $roles: jsonb!
    $locationId: uuid
  ) {
    insert_accounts_users_one(
      object: {
        accountId: $accountId
        userId: $userId
        roles: $roles
        locationId: $locationId
      }
    ) {
      id
      accountId
      userId
      roles
      locationId
    }
  }
`;

export const UPDATE_ACCOUNTS_USERS = gql`
  mutation updateAccountsUsers(
    $recId: uuid!
    $roles: jsonb!
    $locationId: uuid
  ) {
    update_accounts_users_by_pk(
      pk_columns: { id: $recId }
      _set: { roles: $roles, locationId: $locationId }
    ) {
      id
      accountId
      userId
      roles
      locationId
    }
  }
`;

export const UPDATE_ACCOUNT_SETTINGS = gql`
  mutation updateAccountSettings($id: ID!, $settings: JSON!) {
    updateAccountSettings(input: { id: $id, settings: $settings }) {
      settings
    }
  }
`;

export const UPDATE_ACCOUNT_USER_SETTINGS = gql`
  mutation updateAccountUserSettings($recId: ID!, $settings: JSON!) {
    updateAccountUserSettings(input: { id: $recId, settings: $settings }) {
      settings
    }
  }
`;

export const REPROCESS_ORDER_PAYMENTS = gql`
  mutation resolveFailedPayments(
    $accountId: ID!
    $paymentMethodId: String!
    $batchIds: [ID!]!
  ) {
    resolveFailedPayments(
      input: {
        accountId: $accountId
        paymentMethodId: $paymentMethodId
        batchIds: $batchIds
      }
    ) {
      orderBatches {
        id
        failedAt
        orders {
          id
          status
          paymentStatus
        }
      }
      errors {
        message
        code
        path
      }
    }
  }
`;

export const CANCEL_ORDER = gql`
  mutation cancelOrder($id: ID!) {
    cancelOrder(input: { id: $id }) {
      order {
        id
        status
        userAdjustable
        userCancellable
      }
      errors {
        code
        message
      }
    }
  }
`;

export const CANCEL_ORDER_ITEM = gql`
  mutation cancelOrderItem($id: ID!) {
    cancelOrderItem(input: { id: $id }) {
      order {
        id
        status
        userAdjustable
        userCancellable
      }
      item {
        id
        status
        quantity
        userAdjustable
        userCancellable
        subtotalCents
        voidedCents
        refundedCents
      }
      errors {
        code
        message
      }
    }
  }
`;

export const ADJUST_ORDER_ITEM_QUANTITY = gql`
  mutation adjustOrderItemQuantity($id: ID!, $quantity: Int!) {
    adjustOrderItemQuantity(input: { id: $id, quantity: $quantity }) {
      order {
        id
        status
        userAdjustable
        userCancellable
      }
      item {
        id
        quantity
        userAdjustable
        userCancellable
        subtotalCents
      }
      errors {
        code
        message
      }
    }
  }
`;

export const UPDATE_PRODUCT_REACTION = gql`
  mutation updateProductReaction(
    $productId: ID!
    $userId: ID!
    $reaction: ProductReactionEnum!
  ) {
    addProductReaction(
      input: { productId: $productId, userId: $userId, reaction: $reaction }
    ) {
      reaction {
        id
        userId
        productId
        reaction
      }
      errors {
        code
        message
        path
      }
    }
  }
`;

export const REMOVE_PRODUCT_REACTION = gql`
  mutation removeProductReaction($productId: ID!, $userId: ID!) {
    removeProductReaction(input: { productId: $productId, userId: $userId }) {
      reaction {
        id
        userId
        productId
        reaction
      }
      errors {
        code
        message
        path
      }
    }
  }
`;

export const UPDATE_SUBSCRIPTION = gql`
  ${SUBSCRIPTION_FIELDS}
  mutation updateSubscription($input: UpdateSubscriptionInput!) {
    updateSubscription(input: $input) {
      subscription {
        ...SubscriptionFields
      }
      errors {
        code
        message
        path
      }
    }
  }
`;

export const CREATE_SUBSCRIPTION = gql`
  ${SUBSCRIPTION_FIELDS}
  mutation createSubscription($input: CreateSubscriptionInput!) {
    createSubscription(input: $input) {
      subscription {
        ...SubscriptionFields
      }
      errors {
        code
        message
        path
      }
    }
  }
`;

export const PAUSE_SUBSCRIPTION = gql`
  mutation pauseSubscription($subscriptionId: ID!, $pauseUntil: ISO8601Date!) {
    pauseSubscription(
      input: { subscriptionId: $subscriptionId, pauseUntil: $pauseUntil }
    ) {
      subscription {
        id
        status
        pauseUntil
      }
      errors {
        code
        message
        path
      }
    }
  }
`;

export const AUTOFILL_SUBSCRIPTION_CARTS = gql`
  mutation autofillSubscriptionCarts($subscriptionId: ID!) {
    autofillSubscription(input: { subscriptionId: $subscriptionId }) {
      subscription {
        id
        status
      }
      errors {
        code
        message
        path
      }
    }
  }
`;
