import { Link as MuiLink } from '@mui/material';
import styled from '@emotion/styled';
import { Link as RRLink } from 'react-router-dom';

const LinkStyled = styled(MuiLink)`
    color: var(--link-color) !important;
    :hover {
        color: var(--link-hover-color) !important;
    }
`;

// TODO Support query + path params
const Link = (props) => {
  const { children, underline, sx, ...rest } = props;
  const { href, route } = rest;

  const rrLinkParams = (href || route)
    ? { to:
        {
          ...rest,
          pathname: href || route,
        }
    }
    : rest;

  return (
    <RRLink {...rest} {...rrLinkParams}>
      <LinkStyled as='span' underline={underline} sx={sx} >{children}</LinkStyled>
    </RRLink>
  );
};

Link.defaultProps = {
  underline: 'none',
};

export default Link;
