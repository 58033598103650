import { Chip as MuiTag } from '@mui/material';
import { getContrastRatio } from '@mui/material/styles';
import Icon from '../Icon';

const getContrastTextColor = (color) => {
  if (!color) return color;
  return getContrastRatio(color, '#fff') > 1.5 ? '#fff' : '#000000de';
};

const Tag = ({ icon, color, sx, ...props }) => {
  const c = getContrastTextColor(color);
  return (
    <MuiTag
      label={props.label || props.name}
      icon={icon ? <Icon color={c}>{icon}</Icon> : undefined}
      sx={[
        {
          mr: 1,
          mt: 1,
          backgroundColor: color,
          color: color ? c : null
        },
        sx,
      ]}
    />
  );
};

Tag.defaultColor = {};

export default Tag;
