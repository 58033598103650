import styled from '@emotion/styled';
import { styleConstructor } from '../../styles/Utils';
import { formatNumber } from '../../utils';

const CurrencyStyled = styled.data`
  ${({ sx }) => styleConstructor(sx)}
`;

const Currency = ({ amount, currencyCode, showCents, showSign, withSpace, sx }) => {
  const isNegative = amount < 0;
  const value = (showSign && isNegative) ? Math.abs(amount) : amount;
  const formattedValue = withSpace
    ? formatNumber({
      value, currencyCode, showDecimals: showCents
    }).replace(/^(\D+)/, '$1 ')
    : formatNumber({
      value, currencyCode, showDecimals: showCents
    });
  return (
    <CurrencyStyled sx={sx}>{showSign
      ? `${isNegative ? '-' : '+'} ${formattedValue}`
      : formattedValue}</CurrencyStyled>
  );
};

Currency.defaultProps = {
  showCents: true,
  currencyCode: 'USD',
  withSpace: false,
  showSign: false,
};

export default Currency;
