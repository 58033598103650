import { useEffect, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_ACCOUNT_TODOS } from '../graphql/queries';
// import { TODO_TYPES } from '../utils/Consts';

// TODO Consider using context to store account data to avoid processing multiple times
const useTodos = ({ redirect = false } = {}) => {
  const [getAccountTodos, { data, loading, refetch }] = useLazyQuery(GET_ACCOUNT_TODOS, {
    fetchPolicy: 'cache-first', // Won't query server if data is in cache
  });

  useEffect(() => {
    getAccountTodos();
  }, [getAccountTodos]);

  // TODO Filter to incomplete todos, also return count
  const todos = useMemo(() => data?.accountTodos?.rows || [], [data]);

  // useEffect(() => {
  //   if (todos?.length > 0) {
  //     todos.forEach((t) => {
  //       if (t.definition.type === TODO_TYPES.ACCOUNT_DELINQUENT) {
  //         switch (t.definition.blocking) {
  //         case true:
  //           console.log('Account is suspended');
  //           break;
  //         case false:
  //           console.log('Account is delinquent');
  //           break;
  //         default:
  //           break;
  //         }
  //       } else if (t.definition.type === TODO_TYPES.PLACE_USER_IN_ROSTER) {
  //         console.log('Place user in roster');
  //       }
  //     });
  //   }
  // }, [todos]);

  // TODO Handle redirects here instead of useAccount
  //   const shouldRedirect = useMemo(() => {
  //     if (!me) return false;
  //     if (!redirect) return false;
  //     if (redirect && isSuspended) return true;
  //     if (hasStudentStaffUsers && hasTermRosters) return false;
  //     return true;
  //   }, [me, redirect, isSuspended, hasStudentStaffUsers, hasTermRosters, needsRostersUsers, hasStudentStaffUsers, todos]);

  //   useEffect(() => {
  //     if (shouldRedirect && isSuspended) {
  //       history.push(DELINQUENT);
  //     }
  //     else if (shouldRedirect && account?.id) {
  //       const route = !hasStudentStaffUsers ? ACCOUNT : ACCOUNT_UPDATE;
  //       history.push(route);
  //     }
  //   }, [isSuspended, shouldRedirect, hasStudentStaffUsers, account?.id]);

  return {
    refetch,
    todos,
    loading,
  };
};

export default useTodos;
