import { useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import useViewport from '../../hooks/useViewport';
import useQueryParams from '../../hooks/useQueryParams';
import useGlobalContext from '../../hooks/useGlobalContext';
import useAccount from '../../hooks/useAccount';
import useMenus from '../../hooks/useMenus';
import { Button, Card, Tag, Divider, Heading, EmptyState } from '../../common';
import { PageContainer } from '../../components';
import { MENU_ID, ACCOUNT, constructRouteWithParams } from '../../routes';
import { formatDateTime, sort, isDateTimeAfter } from '../../utils';
import { MENU_TYPE_LABELS, MENU_TYPE_SORT_ORDER } from '../../utils/Consts';
import BallotIcon from '@mui/icons-material/Ballot';

const Container = styled.div`
  padding: 0;
  margin: 0;
  margin-top: 3rem;
  display: flex;
  /* flex: 1 auto; */
  align-items: center;
  justify-content: center;
  /* background-color: var(--white); */
`;

const Wrapper = styled.div`
  width: 80vw;
  max-width: 1200px;
`;

const LocationWrapper = styled.div`
  margin-bottom: 3rem;
`;

const Dashboard = () => {
  const { isMobile } = useViewport();
  const [{ isEmbedded = false, rosterUpdates }, setGlobalState] =
    useGlobalContext();
  const history = useHistory();
  const { redirect } = useQueryParams();
  const {
    loading: accountLoading,
    locationIds,
    aUsers,
  } = useAccount({ redirect: true });
  const { menus, loading: menusLoading } = useMenus();

  const handleGoToMenu = (menuId) => {
    const route = constructRouteWithParams(MENU_ID, { menuId });
    history.push(route);
  };

  const handleGoToAccountProfile = () => {
    history.push(ACCOUNT);
  };

  const getStartEndDates = (products) => {
    let startDate = null;
    let endDate = null;
    products.forEach(({ datesAvailable }) => {
      if (!Array.isArray(datesAvailable) || datesAvailable.length === 0) return;
      const sortedDates = sort(datesAvailable);
      const sd = sortedDates[0];
      const ed = sortedDates[sortedDates.length - 1];
      if (!startDate || sd < startDate) startDate = sd;
      if (!endDate || ed > endDate) endDate = ed;
    });
    return { startDate, endDate };
  };

  const { activeMenusByLocation, menuLocations, redirectToMenuId } =
    useMemo(() => {
      const m = {};
      let mLocs = [];
      let menuId = null;
      let isMenuPastTerm = false;
      let lastDeliveryDate = null;
      const updateUserRostersIds = {};

      menus.forEach((menu) => {
        const { location, organization, isActive, products } = menu;
        if (!isActive) return;
        const { name: orgName } = organization;
        const { id: locationId, name } = location;
        const locName = orgName === name ? name : `${orgName}: ${name}`;
        const { startDate, endDate } = getStartEndDates(products);

        if (!m[locationId]) m[locationId] = [];
        m[locationId].push({
          ...menu,
          sortOrder: MENU_TYPE_SORT_ORDER[menu.type],
          dateRange: { startDate, endDate },
        });

        const fLoc = mLocs.find((l) => l.id === locationId);
        if (fLoc) {
          if (startDate < fLoc.sortOrder) fLoc.sortOrder = startDate;
        } else {
          mLocs.push({ id: locationId, name: locName, sortOrder: startDate });
        }

        const lastMenuDeliveryDate = products.reduce(
          (acc, { datesAvailable }) => {
            if (!Array.isArray(datesAvailable) || datesAvailable.length === 0)
              return acc;
            const sortedDates = sort(datesAvailable);
            const ld = sortedDates[sortedDates.length - 1];
            if (!acc || ld > acc) return ld;
            return acc;
          },
          null,
        );
        if (!lastDeliveryDate) lastDeliveryDate = lastMenuDeliveryDate;
        lastDeliveryDate = isDateTimeAfter({
          dateTime: lastMenuDeliveryDate,
          afterDateTime: lastDeliveryDate,
        })
          ? lastMenuDeliveryDate
          : lastDeliveryDate;

        const mUsers = aUsers.filter((au) =>
          au?.locationIds?.includes(locationId),
        );
        mUsers.forEach((au) => {
          const { userId, locationTerms } = au;
          const hasLocationTerm = !!locationTerms[locationId];
          let termId = null;
          let latestTermEndDate = null;
          locationTerms[locationId]?.forEach(({ id, endDateTime }) => {
            if (
              !latestTermEndDate ||
              isDateTimeAfter({
                dateTime: endDateTime,
                afterDateTime: latestTermEndDate,
                precision: 'day',
                inclusive: true,
              })
            ) {
              latestTermEndDate = endDateTime;
              termId = id;
            }
          });
          if (!latestTermEndDate && hasLocationTerm) return;
          const isPastTerm = !hasLocationTerm
            ? true
            : isDateTimeAfter({
                dateTime: lastMenuDeliveryDate,
                afterDateTime: latestTermEndDate,
                precision: 'day',
                inclusive: true,
              });
          if (isPastTerm) {
            updateUserRostersIds[userId] = {
              locationId,
              termId,
              termEndDate: latestTermEndDate,
              lastMenuDeliveryDate: lastDeliveryDate,
            };
            if (!isMenuPastTerm) isMenuPastTerm = true;
          }
        });
      });

      if (mLocs.length === 1) {
        const [{ id: locationId }] = mLocs;
        if (m[locationId].length === 1) {
          menuId = m[locationId][0].id;
        }
      } else {
        mLocs = sort(mLocs, ['sortOrder', 'name', 'id']);
        mLocs.forEach(({ id: locationId }) => {
          m[locationId] = sort(m[locationId], [
            'dateRange.startDate',
            'sortOrder',
            'name',
            'id',
          ]);
        });
      }

      if (!rosterUpdates && isMenuPastTerm) {
        setGlobalState({ rosterUpdates: updateUserRostersIds });
      } else if (rosterUpdates && !isMenuPastTerm) {
        setGlobalState({ rosterUpdates: null });
      }

      return {
        menuLocations: mLocs,
        activeMenusByLocation: m,
        redirectToMenuId: menuId,
      };
    }, [menus, aUsers, rosterUpdates]);

  useEffect(() => {
    if (redirectToMenuId && redirect !== false) {
      handleGoToMenu(redirectToMenuId);
    }
  }, [redirectToMenuId, redirect]);

  const renderMenuButton = ({ id, name, type, dateRange }) => {
    const menuLabel = name ? name : MENU_TYPE_LABELS[type];
    const { startDate, endDate } = dateRange;
    const sd = formatDateTime({ dateTime: startDate, format: 'MMM D' });
    const ed = formatDateTime({ dateTime: endDate, format: 'MMM D' });
    const tagLabel =
      startDate && endDate ? (sd !== ed ? `${sd} – ${ed}` : sd) : null;
    return (
      <Button
        key={id}
        onClick={() => handleGoToMenu(id)}
        variant="outlined"
        sx={{
          minWidth: '16rem',
          padding: '1.5rem',
          alignItems: 'flex-start',
          flexDirection: 'column',
          marginBottom: '1rem',
          marginRight: '1rem',
          borderRadius: '12px',
        }}
        fullWidth={isMobile}
      >
        {menuLabel}
        {!!tagLabel && <Tag label={tagLabel} sx={{ marginTop: '1rem' }} />}
      </Button>
    );
  };

  const renderLocation = ({ id, name }) => {
    return (
      <LocationWrapper key={id}>
        <Heading variant="h6" sx={{ fontSize: '1.2rem' }}>
          {name}
        </Heading>
        <Divider sx={{ marginTop: '0.5rem', marginBottom: '1.5rem' }} />
        {activeMenusByLocation[id].map(renderMenuButton)}
      </LocationWrapper>
    );
  };

  const renderEmptyState = () => {
    const hasLocations = locationIds?.length > 0;
    const message = hasLocations
      ? 'There are no active menus for your account at this time. Please check back later.'
      : 'There are no locations found for your account at this time. Please view your profile to make sure your family is setup properly.';
    return (
      <>
        <EmptyState icon={BallotIcon} message={message} />
        {!hasLocations && !isEmbedded && (
          <Button onClick={handleGoToAccountProfile} fullWidth>
            Go to account
          </Button>
        )}
      </>
    );
  };

  const renderPageContent = () => (
    <Card>
      <Card.Header title="Available menus" />
      <Card.Content>
        <Wrapper>
          {menuLocations?.length > 0
            ? menuLocations.map(renderLocation)
            : renderEmptyState()}
        </Wrapper>
      </Card.Content>
    </Card>
  );

  const loading = accountLoading || menusLoading;

  return (
    <PageContainer
      pageTitle="Dashboard"
      loading={loading}
      showPageTitle={false}
      container={Container}
    >
      {renderPageContent()}
    </PageContainer>
  );
};

export default Dashboard;
