import { useRef, useState, useMemo, useCallback, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_ORDERS, GET_ORDERS_BY_ID } from '../graphql/queries';
import {
  CANCEL_ORDER,
  CANCEL_ORDER_ITEM,
  ADJUST_ORDER_ITEM_QUANTITY,
} from '../graphql/mutations';
import { getNow, calculateOrderSummary, convertPagination } from '../utils';
import {
  ORDER_STATUS_LABELS,
  PAYMENT_STATUS_LABELS,
  ORDER_STATUSES,
  PAYMENT_STATUSES,
} from '../utils/Consts';

const LIMIT = 20;
const PENDING = 'pending';
const COMPLETED = 'completed';
const FILTERED = 'filtered';

const useOrders = ({
  orderId,
  limit = LIMIT,
  shouldQueryOrders = true,
} = {}) => {
  const init = useRef(false);
  const { current: today } = useRef(getNow().format('YYYY-MM-DD'));

  const [
    getOrderById,
    { data: orderData, loading: orderLoading, refetch: refetchOrderById },
  ] = useLazyQuery(GET_ORDERS_BY_ID);

  const [fetchMoreLoading, setFetchMoreLoading] = useState({
    pending: false,
    completed: false,
  });

  const [filters, setFilters] = useState({});
  const [showFilters, setShowFilters] = useState(false);
  const [hasMore, setHasMore] = useState({
    pending: true,
    completed: true,
    filtered: true,
  });
  const [aggregations, setAggregations] = useState({
    pending: null,
    completed: null,
    filtered: null,
  });
  const [
    getPendingOrders,
    {
      data: ordersDataPending,
      loading: loadingPending,
      fetchMore: fetchMorePending,
    },
  ] = useLazyQuery(GET_ORDERS, {
    variables: {
      sort: 'asc',
      filters: {
        filters: [
          { field: 'startDate', value: [today], operator: 'greater_or_equal' },
          {
            field: 'paymentStatus',
            value: [
              PAYMENT_STATUSES.UNPAID,
              PAYMENT_STATUSES.REFUNDED,
              PAYMENT_STATUSES.PAID,
              PAYMENT_STATUSES.VOID,
            ],
          },
        ],
      },
      ...convertPagination({ offset: 0, limit }),
    },
    fetchPolicy: 'cache-and-network',
  });
  const [
    getCompletedOrders,
    {
      data: ordersDataCompleted,
      loading: loadingCompleted,
      fetchMore: fetchMoreCompleted,
    },
  ] = useLazyQuery(GET_ORDERS, {
    variables: {
      sort: 'desc',
      filters: {
        filters: [
          { field: 'endDate', value: [today], operator: 'less' },
          {
            field: 'paymentStatus',
            value: [
              PAYMENT_STATUSES.UNPAID,
              PAYMENT_STATUSES.REFUNDED,
              PAYMENT_STATUSES.PAID,
              PAYMENT_STATUSES.VOID,
            ],
          },
        ],
      },
      ...convertPagination({ offset: 0, limit }),
    },
    fetchPolicy: 'cache-and-network',
  });
  const [
    getFilteredOrders,
    {
      data: ordersDataFiltered,
      loading: loadingFiltered,
      fetchMore: fetchMoreFiltered,
    },
  ] = useLazyQuery(GET_ORDERS, {
    variables: {
      sort: 'desc',
      ...convertPagination({ offset: 0, limit }),
    },
    fetchPolicy: 'cache-and-network',
  });

  const [cancelOrder, { loading: cancelOrderLoading }] =
    useMutation(CANCEL_ORDER);
  const [cancelOrderItem, { loading: cancelOrderItemLoading }] =
    useMutation(CANCEL_ORDER_ITEM);
  const [adjustOrderItemQuantity, { loading: adjustOrderItemQuantityLoading }] =
    useMutation(ADJUST_ORDER_ITEM_QUANTITY);

  const filterCount = useMemo(() => {
    let c = 0;
    for (let f in filters) {
      if (filters[f].length > 0) {
        c += filters[f].length;
      }
    }
    if (c > 0) {
      const f = [];
      for (let key in filters) {
        if (Array.isArray(filters[key]) && filters[key].length > 0) {
          switch (key) {
            case 'dateRange':
              if (filters[key].length !== 2) break;
              const [start, end] = filters[key];
              f.push({
                field: 'startDate',
                value: [start.format('YYYY-MM-DD')],
                operator: 'greater_or_equal',
              });
              f.push({
                field: 'endDate',
                value: [end.format('YYYY-MM-DD')],
                operator: 'less_or_equal',
              });
              break;
            case 'statuses':
              const statusFilters = filters[key].filter(
                (s) => !!ORDER_STATUSES[s],
              );
              const paymentStatusFilters = filters[key].filter(
                (s) => !!PAYMENT_STATUSES[s],
              );
              if (statusFilters.length > 0) {
                if (statusFilters.includes(ORDER_STATUSES.COMPLETED)) {
                  // TODO Alias this status for now — was originally meant to be on orderProducts level only
                  statusFilters.push(ORDER_STATUSES.FULFILLED);
                }
                f.push({ field: 'status', value: statusFilters });
              }
              if (paymentStatusFilters.length > 0) {
                f.push({ field: 'paymentStatus', value: paymentStatusFilters });
              }
              break;
            default:
              f.push({ field: key, value: filters[key] });
              break;
          }
        }
      }
      getFilteredOrders({ variables: { filters: { filters: f } } });
    }
    return c;
  }, [filters]);

  const handleToggleFiltersView = useCallback(() => {
    setShowFilters((s) => !s);
  }, []);

  const handleSetFilters = useCallback((f) => {
    setFilters(f);
  }, []);

  useEffect(() => {
    if (!orderId) {
      if (!shouldQueryOrders) return;
      getPendingOrders();
      getCompletedOrders();
    } else {
      getOrderById({ variables: { id: [orderId] } });
    }
  }, [orderId]);

  const augmentOrder = (order) => {
    const { orderProducts: products, quantity, status, paymentStatus } = order;
    const {
      total,
      subtotal,
      taxAmount,
      refunded,
      voided,
      sponsored,
      currencyCode,
      coupon,
    } = calculateOrderSummary(order);
    const itemLabel = quantity > 1 ? `${quantity} items` : '1 item';
    const statusLabel = ORDER_STATUS_LABELS[status?.toUpperCase()];
    const paymentStatusLabel =
      PAYMENT_STATUS_LABELS[paymentStatus?.toUpperCase()];

    const userIds = [];
    const users = {};
    products.forEach(({ user }) => {
      const { id: userId, firstName, preferredName } = user;
      if (!userIds.includes(userId)) {
        userIds.push(userId);
        users[userId] = preferredName || firstName;
      }
    });
    userIds.sort((a, b) => {
      if (users[a] < users[b]) return -1;
      if (users[a] > users[b]) return 1;
      return 0;
    });

    return {
      ...order,
      products,
      status: status?.toUpperCase(),
      paymentStatus: paymentStatus?.toUpperCase(),
      statusLabel,
      paymentStatusLabel,
      itemLabel,
      total,
      subtotal,
      taxAmount,
      quantity,
      refunded,
      voided,
      sponsored,
      currencyCode,
      userIds,
      users,
      coupon,
    };
  };

  const handleFetchMore = useCallback((tab, offset, filters = {}) => {
    setFetchMoreLoading((s) => ({ ...s, [tab]: true }));
    let fetchMore = null;
    const variables = convertPagination({ offset, limit });
    switch (tab) {
      case PENDING:
        fetchMore = fetchMorePending;
        break;
      case COMPLETED:
        fetchMore = fetchMoreCompleted;
        break;
      default:
        fetchMore = fetchMoreFiltered;
        break;
    }
    fetchMore({
      variables,
      updateQuery: (prev, { fetchMoreResult }) => {
        const orders = fetchMoreResult?.myOrders?.orders || [];
        const isExhausted = !fetchMoreResult?.myOrders?.pageInfo?.hasNextPage;
        const agg = {
          scopeCount: fetchMoreResult?.myOrders?.count,
        };
        fetchMoreResult?.myOrders?.aggregations?.forEach((a) => {
          agg[a.field] = a.buckets;
        });
        setHasMore({ ...hasMore, [tab]: !isExhausted });
        setAggregations((a) => ({ ...a, [tab]: agg }));
        setFetchMoreLoading((s) => ({ ...s, [tab]: false }));
        return {
          myOrders: {
            ...prev.myOrders,
            ...fetchMoreResult.myOrders,
            orders: [...prev.myOrders.orders, ...orders].reduce(
              (acc, current) => {
                const x = acc.find((item) => item.id === current.id);
                if (!x) {
                  return acc.concat([current]);
                } else {
                  return acc;
                }
              },
              [],
            ),
          },
        };
      },
    });
  }, []);

  const orders = useMemo(() => {
    const o = {
      [PENDING]: ordersDataPending?.myOrders?.orders || [],
      [COMPLETED]: ordersDataCompleted?.myOrders?.orders || [],
      [FILTERED]: ordersDataFiltered?.myOrders?.orders || [],
    };
    if (!init.current && ordersDataPending && ordersDataCompleted) {
      init.current = true;
      const more = {
        [PENDING]: !!ordersDataPending?.myOrders?.pageInfo?.hasNextPage,
        [COMPLETED]: !!ordersDataCompleted?.myOrders?.pageInfo?.hasNextPage,
      };
      const agg = {
        [PENDING]: { scopeCount: ordersDataPending?.myOrders?.count },
        [COMPLETED]: { scopeCount: ordersDataCompleted?.myOrders?.count },
      };
      ordersDataPending?.myOrders?.aggregations?.forEach((a) => {
        agg[PENDING][a.field] = a.buckets;
      });
      ordersDataCompleted?.myOrders?.aggregations?.forEach((a) => {
        agg[COMPLETED][a.field] = a.buckets;
      });
      setHasMore((m) => ({
        ...m,
        [PENDING]: more[PENDING],
        [COMPLETED]: more[COMPLETED],
      }));
      setAggregations((a) => ({
        ...a,
        [PENDING]: agg[PENDING],
        [COMPLETED]: agg[COMPLETED],
      }));
    }
    if (ordersDataFiltered) {
      const more = {
        [FILTERED]: !!ordersDataFiltered?.myOrders?.pageInfo?.hasNextPage,
      };
      const agg = {
        [FILTERED]: { scopeCount: ordersDataFiltered?.myOrders?.count },
      };
      ordersDataFiltered?.myOrders?.aggregations?.forEach((a) => {
        agg[FILTERED][a.field] = a.buckets;
      });
      setHasMore((m) => ({ ...m, [FILTERED]: more[FILTERED] }));
      setAggregations((a) => ({ ...a, [FILTERED]: agg[FILTERED] }));
    }
    return {
      [PENDING]: o.pending.map(augmentOrder),
      [COMPLETED]: o.completed.map(augmentOrder),
      [FILTERED]: o.filtered.map(augmentOrder),
    };
  }, [ordersDataPending, ordersDataCompleted, ordersDataFiltered]);

  const order = useMemo(() => {
    if (orderData?.myOrders?.length !== 1) return null;
    const o = augmentOrder(orderData.myOrders[0]);
    return o;
  }, [orderData]);

  const handleCancelOrder = useCallback(async (id) => {
    await cancelOrder({ variables: { id } });
  }, []);

  const handleCancelOrderItem = useCallback(async (id) => {
    await cancelOrderItem({ variables: { id } });
  }, []);

  const handleAdjustOrderItem = useCallback(async (id, quantity) => {
    await adjustOrderItemQuantity({ variables: { id, quantity } });
  }, []);

  return {
    order,
    orders,
    aggregations,
    loading:
      loadingPending ||
      loadingCompleted ||
      loadingFiltered ||
      orderLoading ||
      cancelOrderLoading ||
      cancelOrderItemLoading ||
      adjustOrderItemQuantityLoading,
    loadingPending,
    loadingCompleted,
    fetchMoreLoading,
    hasMore,
    onFetchMore: handleFetchMore,
    PENDING,
    COMPLETED,
    FILTERED,
    filters,
    filterCount,
    showFilters,
    onToggleFiltersView: handleToggleFiltersView,
    onSetFilters: handleSetFilters,
    onCancelOrder: handleCancelOrder,
    onCancelOrderItem: handleCancelOrderItem,
    onAdjustOrderItem: handleAdjustOrderItem,
    cancelOrderLoading,
    cancelOrderItemLoading,
    adjustOrderItemQuantityLoading,
    refetchOrder: refetchOrderById,
  };
};

export default useOrders;
