import { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import useViewport from '../../hooks/useViewport';
import { Button } from '../../common';
import { USER_ROLES } from '../../utils/Consts';
import SchoolIcon from '@mui/icons-material/School';
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';
import PersonIcon from '@mui/icons-material/Person';
import StarIcon from '@mui/icons-material/Star';

const { GUARDIAN, STAFF, STUDENT } = USER_ROLES;

const Wrapper = styled.div``;

const RolesWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
`;

const ButtonLabel = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    font-weight: 600;
`;

const MobileRolesWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
`;

const MobileButtonLabel = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 1.1rem;
    font-weight: 600;
`;

const UserOnboard = ({ onChange, data }) => {
  const { isMobile } = useViewport();
  const initRoles = data?.roles?.split(',') || [];
  const [roles, setRoles] = useState(initRoles);
  const ROLES = [STUDENT, GUARDIAN, STAFF, 'OTHER']; // TODO
  const ROLE_LABELS = ['Student', 'Parent', 'Staff', 'Other']; // TODO Make label consts
  const ROLE_ICONS = [SchoolIcon, EscalatorWarningIcon, PersonIcon, StarIcon];

  const handleSetRole = (role) => {
    if (roles.includes(role)) {
      setRoles(roles.filter((r) => r !== role));
    } else {
      setRoles([...roles, role]);
    }
  };

  useEffect(() => {
    if (onChange) onChange(null, roles.sort().join());
  }, [roles]);

  const RolesWrapperEl = isMobile ? MobileRolesWrapper : RolesWrapper;
  const ButtonLabelEl = isMobile ? MobileButtonLabel : ButtonLabel;

  const renderRoleChoices = (role, idx) => {
    const hasRole = roles.includes(role);
    const IconEl = ROLE_ICONS[idx];
    const buttonSx = isMobile
      ? {
        height: '10vh',
        maxHeight: '7rem',
        [':hover']: {
          backgroundColor: hasRole ? 'var(--lightGray)' : 'var(--white)',
          borderColor: hasRole ? 'var(--charcoal)' : 'var(--gray)',
        },
        ['@supports (-webkit-touch-callout: none) and (not (translate: none))']: {
          ['&:not(:last-child)']: { // iOS 14 fix
            marginBottom: '1.5rem',
          }
        }
      }
      : { height: '7rem' };

    buttonSx.borderWidth = '2px';
    buttonSx[':hover'] = {
      borderWidth: '2px'
    };

    const iconSx = isMobile
      ? { fontSize: '2rem', marginRight: '1rem' }
      : { fontSize: '4rem' };

    if (hasRole) {
      buttonSx.backgroundColor = 'var(--lightGray)';
      buttonSx.borderColor = 'var(--charcoal)';
      // buttonSx.borderWidth = '2px';
      buttonSx[':hover'] = {
        // borderWidth: '2px'
      };
    }

    return (
      <Button
        key={role}
        onClick={() => handleSetRole(role)}
        variant="outlined"
        sx={buttonSx}
        fullWidth
      >
        <ButtonLabelEl>
          <IconEl sx={iconSx}/>
          {ROLE_LABELS[idx]}
        </ButtonLabelEl>
      </Button>
    );
  };

  return (
    <Wrapper>
      {/* <span>You may select multiple if you are both a school staff member and parent.</span> */}
      <RolesWrapperEl>
        {ROLES.map(renderRoleChoices)}
      </RolesWrapperEl>
    </Wrapper>
  );
};

export default UserOnboard;
