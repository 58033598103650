import { useState, useRef } from 'react';
import styled from '@emotion/styled';
import useAccount from '../../hooks/useAccount';
import useUpsertAccountUser from '../../hooks/useUpsertAccountUser';
import { Tag, Spinner } from '../../common';
import { USER_ROLES, USER_ROLES_LABELS } from '../../utils/Consts';
import AccountUserAvatar from '../RegisterAvatar';
import AccountUserForm from '../AccountUserForm';

const { GUARDIAN, STAFF, STUDENT } = USER_ROLES;

const AvatarGrid = styled.div`
  width: 100%;
  display: grid;
  align-items: start;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
`;

const AccountUserWrapper = styled.div`
  max-width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 0;
  margin: auto;
`;

const AccountUserNameRoleWrapper = styled.div`
  text-align: center;
`;

const AccountUserName = styled.span`
`;

const AccountUserRole = styled.span`
  color: var(--darkGray);
`;

const AccountUsersGrid = () => {
  const { accountId, aUsers, loading } = useAccount();
  const { onUpsertAccountUser, onArchiveAccountUser, loading: upsertingAu } = useUpsertAccountUser();
  const [showAccountUserForm, setShowAccountUserForm] = useState(false);
  const getDisplayName = (user) => (user?.preferredName || user?.firstName);

  const userData = useRef(null); // Edit user
  const auFormParams = useRef(null);

  const setAuFormParams = () => {
    const hFields = [];
    let s = null;
    if (userData.current) {
      if (userData.current.isStudent || userData.current.isStaff) {
        userData.current.user?.rosters?.some(({ roster: r }) => {
          if (r.term?.location?.address?.stateProvince) {
            s = r.term?.location?.address?.stateProvince;
            if (!hFields.includes('usaState')) hFields.push('usaState');
            return true;
          }
          return false;
        });
      }
    }
    if (!s) {
      aUsers.some((au) => {
        if (au.isStudent || au.isStaff) {
          au.user?.rosters?.some(({ roster: r }) => {
            if (r.term?.location?.address?.stateProvince) {
              s = r.term?.location?.address?.stateProvince;
              if (!hFields.includes('usaState')) hFields.push('usaState');
              return true;
            }
            return false;
          });
        }
      });
    }
    auFormParams.current = {
      usaState: s,
      hiddenFields: hFields,
    };
  };

  const handleSelectAccountUser = (aUser = null) => {
    userData.current = aUser;
    setAuFormParams();
    setShowAccountUserForm(true);
  };

  const toggleShowAccountUserForm = () => {
    setShowAccountUserForm((bool) => {
      if (bool) {
        userData.current = null;
        auFormParams.current = null;
      }
      return !bool;
    });
  };

  const handleArchiveUser = async (bool) => {
    const au = {
      recId: userData?.current?.id,
      userId: userData?.current?.user?.id,
      isArchived: bool,
    };
    await onArchiveAccountUser(au, toggleShowAccountUserForm);
  };

  const handleUpdateAccountUser = async (vals) => {
    await onUpsertAccountUser(vals, toggleShowAccountUserForm);
  };

  const renderTag = ({ isAdmin = null, isArchived = null }) => {
    if (!isAdmin && !isArchived) return null;
    const label = (isAdmin) ? 'Account admin' : 'Archived';
    const color = (isAdmin) ? 'var(--orange)' : 'var(--darkGray)';
    return (
      <Tag
        label={label}
        size="small"
        sx={{ background: color, color: 'var(--white)', marginTop: '0.5rem' }}
      />
    );
  };

  const renderNameRole = (name, role) => {
    const roleLabel = USER_ROLES_LABELS[role];
    return (
      <AccountUserNameRoleWrapper>
        <AccountUserName>{`${name}${!!roleLabel && ', '}`}</AccountUserName>
        {!!roleLabel && <AccountUserRole>{roleLabel}</AccountUserRole>}
      </AccountUserNameRoleWrapper>
    );
  };

  const renderAccountUser = (au, idx) => {
    const { isAccountAdmin: isAdmin, isArchived, user, isStaff, isStudent, isGuardian } = au;
    const displayName = getDisplayName(user);
    let role = null;
    if (isStaff) role = STAFF;
    else if (isStudent) role = STUDENT;
    else if (isGuardian) role = GUARDIAN;
    return (
      <AccountUserWrapper key={`${user.id || 'au'}-${idx || 'u'}`}>
        <AccountUserAvatar
          onClick={() => handleSelectAccountUser(au)}
        />
        {renderNameRole(displayName, role)}
        {renderTag({ isAdmin, isArchived })}
      </AccountUserWrapper>
    );
  };

  const renderAddAccountUser = () => {
    return (
      <AccountUserWrapper>
        <AccountUserAvatar
          onClick={() => handleSelectAccountUser()}
          icon="add"
          iconColor="var(--black)"
          color="lightGray"
          random={false}
          sx={{
            '&:hover': { background: 'var(--white)' }
          }}
        />
      </AccountUserWrapper>
    );
  };

  const renderAccountUserForm = () => {
    if (!showAccountUserForm) return null;
    return (
      <AccountUserForm
        key={userData.current?.id}
        isOpen={showAccountUserForm}
        userData={userData.current}
        onClose={toggleShowAccountUserForm}
        onSubmit={handleUpdateAccountUser}
        onArchiveUser={handleArchiveUser}
        disabledFields={['role']}
        showArchiveAction={!userData.current?.isAccountAdmin}
        actionLoading={upsertingAu}
        {...auFormParams.current}
      />
    );
  };

  const renderAccountUsers = () => {
    if (!accountId || loading) return <Spinner />;
    return (
      <AvatarGrid>
        {aUsers.map(renderAccountUser)}
        {renderAddAccountUser()}
      </AvatarGrid>
    );
  };

  return (
    <>
      {renderAccountUsers()}
      {renderAccountUserForm()}
    </>
  );
};

export default AccountUsersGrid;
