import { Fragment, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import useViewport from '../../hooks/useViewport';
import useQueryParams from '../../hooks/useQueryParams';
import {
  SUBSCRIPTION_OPTIONS,
  SUBSCRIPTION_STATUSES,
  SUBSCRIPTION_STATUS_LABELS,
} from '../../utils/Consts';
import { COLORS } from '../../styles/Consts';
import { Heading, Card, Button, Tag, DateTime } from '../../common';
import PauseSubscriptionForm from '../PauseSubscriptionForm';
import LoopIcon from '@mui/icons-material/Loop';
import FlashOnIcon from '@mui/icons-material/FlashOn';

const UserWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
`;

const FauxLink = styled.button`
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  color: var(--link-color);
  cursor: pointer;
  font-weight: 500;
  [:hover] {
    color: var(--link-hover-color);
  }
`;

const WEEKDAYS = [
  SUBSCRIPTION_OPTIONS.DAY.MONDAY,
  SUBSCRIPTION_OPTIONS.DAY.TUESDAY,
  SUBSCRIPTION_OPTIONS.DAY.WEDNESDAY,
  SUBSCRIPTION_OPTIONS.DAY.THURSDAY,
  SUBSCRIPTION_OPTIONS.DAY.FRIDAY,
  SUBSCRIPTION_OPTIONS.DAY.SATURDAY,
  SUBSCRIPTION_OPTIONS.DAY.SUNDAY,
];

const WEEKDAY_LABELS = {
  [SUBSCRIPTION_OPTIONS.DAY.MONDAY]: 'Mon',
  [SUBSCRIPTION_OPTIONS.DAY.TUESDAY]: 'Tue',
  [SUBSCRIPTION_OPTIONS.DAY.WEDNESDAY]: 'Wed',
  [SUBSCRIPTION_OPTIONS.DAY.THURSDAY]: 'Thu',
  [SUBSCRIPTION_OPTIONS.DAY.FRIDAY]: 'Fri',
  [SUBSCRIPTION_OPTIONS.DAY.SATURDAY]: 'Sat',
  [SUBSCRIPTION_OPTIONS.DAY.SUNDAY]: 'Sun',
};

const SubscriptionUserCard = ({
  user: u,
  onChangePlan,
  onSubscribe,
  onAutofillSubscriptionCart,
}) => {
  const { isMobile } = useViewport();
  const { showAutofillCart = false } = useQueryParams();
  const [loading, setLoading] = useState(false);
  const [showPauseForm, setShowPauseForm] = useState(false);

  const togglePauseForm = () => {
    setShowPauseForm((bool) => !bool);
  };

  const { hasSub, isActive, isPaused, isCancelled, showMoreInfo } =
    useMemo(() => {
      return {
        hasSub: !!u.subscription,
        isActive: u.subscription?.status === SUBSCRIPTION_STATUSES.ENABLED,
        isPaused: u.subscription?.status === SUBSCRIPTION_STATUSES.PAUSED,
        isCancelled: u.subscription?.status === SUBSCRIPTION_STATUSES.CANCELLED,
        showMoreInfo:
          !!u.subscription &&
          !(u.subscription?.status === SUBSCRIPTION_STATUSES.CANCELLED),
      };
    }, [u]);

  const mealCount = useMemo(() => {
    if (!hasSub) return 0;
    let mealServiceCount = 0;
    let dailyCount = 0;
    const days = [];
    u.subscription?.subscriptionOptions?.menuSlots?.forEach((ms) => {
      ms?.productSlots?.forEach((ps) => {
        if (ps.isEnabled) {
          mealServiceCount += ps.quantity;
        }
      });
    });
    WEEKDAYS.forEach((d) => {
      if (u.subscription?.dailyOptions?.[d?.toLowerCase()]?.isEnabled) {
        dailyCount += 1;
        days.push(d);
      }
    });

    const formatDayRanges = (days) => {
      if (days.length === 0) return '';
      let ranges = [];
      let start = days[0];
      for (let i = 1; i <= days.length; i++) {
        const currentDay = days[i];
        const previousDay = days[i - 1];
        const nextExpectedDay =
          WEEKDAY_LABELS[WEEKDAYS[WEEKDAYS.indexOf(previousDay) + 1]];

        if (
          i === days.length ||
          WEEKDAY_LABELS[currentDay] !== nextExpectedDay
        ) {
          let end = previousDay;
          ranges.push(
            start === end
              ? WEEKDAY_LABELS[start]
              : `${WEEKDAY_LABELS[start]}–${WEEKDAY_LABELS[end]}`,
          );
          start = currentDay;
        }
      }
      return ranges.join(', ');
    };

    // mealServiceCount * dailyCount;
    // return `${mealServiceCount} × ${
    //   mealServiceCount === 1 ? 'meal' : 'meals'
    // } per day (${formatDayRanges(days)})`;
    if (mealServiceCount === 0) return 'Inactive';
    return `Active ${formatDayRanges(days)}`;
  }, [u, hasSub, showMoreInfo]);

  const renderPauseSubscriptionForm = () => {
    if (!showPauseForm) return null;
    return (
      <PauseSubscriptionForm
        userId={u.userId}
        userDisplayName={u.displayName}
        onClose={togglePauseForm}
      />
    );
  };

  const renderStatus = () => {
    const status = u.subscription?.status;
    let color = null;
    switch (status) {
      case SUBSCRIPTION_STATUSES.ENABLED:
        color = COLORS.green;
        break;
      case SUBSCRIPTION_STATUSES.CANCELLED:
        color = COLORS.red;
        break;
      case SUBSCRIPTION_STATUSES.PAUSED:
        color = COLORS.yellow;
        break;
      default:
        break;
    }
    return (
      <Tag
        label={SUBSCRIPTION_STATUS_LABELS[status?.toUpperCase()]}
        color={color}
      />
    );
  };

  const renderUserInfo = () => {
    return (
      <div>
        <Heading sx={{ fontSize: '1.2rem' }} variant="h6">
          {u.displayName}
        </Heading>
        {isActive && <div>{mealCount}</div>}
        {showMoreInfo && !isActive && renderStatus()}
      </div>
    );
  };

  const renderAction = (label, icon, params) => {
    return (
      <Button {...params}>
        {icon}
        {label}
      </Button>
    );
  };

  const renderAutofillCartAction = () => {
    if (!showAutofillCart || !hasSub) return null;
    return renderAction('Autofill Cart', null, {
      variant: 'outlined',
      onClick: async () => {
        setLoading(true);
        await onAutofillSubscriptionCart(u.userId).finally(() => {
          setLoading(false);
        });
      },
      loading,
      sx: {
        marginTop: '1rem',
      },
      fullWidth: true,
    });
  };

  const renderUser = () => {
    const actionParams =
      hasSub && !isCancelled
        ? {
            variant: 'outlined',
            onClick: () => onChangePlan(u.userId),
            sx: { alignSelf: 'center' },
            fullWidth: isMobile,
          }
        : {
            variant: 'contained',
            onClick: () => onSubscribe(u.userId),
            sx: {
              alignSelf: 'center',
              backgroundColor: 'var(--blue)',
            },
            fullWidth: isMobile,
          };
    if (isMobile) actionParams.sx.marginTop = '1.5rem';
    const WrapperEl = isMobile ? Fragment : UserWrapper;
    return (
      <WrapperEl>
        {renderUserInfo()}
        {renderAction(
          hasSub && !isCancelled ? 'Change Plan' : 'Subscribe',
          hasSub && !isCancelled ? <LoopIcon /> : <FlashOnIcon />,
          actionParams,
        )}
      </WrapperEl>
    );
  };

  const renderPauseSubscription = () => {
    return (
      <>
        <p>
          Traveling? Need a break? It's easy to{' '}
          <FauxLink onClick={togglePauseForm}>skip your order</FauxLink>.
        </p>
        {renderPauseSubscriptionForm()}
      </>
    );
  };

  const renderResumeSubscription = () => {
    return (
      <>
        <p>
          Your subscription is currently paused{' '}
          <>
            {!!u.subscription?.pauseUntil && (
              <>
                until{' '}
                <>
                  <DateTime
                    dateTime={u.subscription?.pauseUntil}
                    format="MMM D, YYYY"
                    sx={{ fontWeight: 600 }}
                  />
                </>
              </>
            )}
          </>
          . You can resume your plan at any time.
        </p>
        {renderPauseSubscriptionForm()}
      </>
    );
  };

  const renderReactivateSubscription = () => {
    return (
      <>
        <p>
          <b>Your subscription is currently stopped.</b> You can reactivate your
          plan at any time.
        </p>
        {renderPauseSubscriptionForm()}
      </>
    );
  };

  const renderMoreInfo = () => {
    return (
      <>
        {isActive && renderPauseSubscription()}
        {isPaused && renderResumeSubscription()}
        {isCancelled && renderReactivateSubscription()}
      </>
    );
  };

  const renderSubscriptionUser = () => {
    return (
      <>
        <Card
          sx={{
            width: '100%',
            border: '1px solid var(--darkGray)',
            borderRadius: '12px',
            borderBottomLeftRadius: showMoreInfo ? 0 : '12px',
            borderBottomRightRadius: showMoreInfo ? 0 : '12px',
            padding: '1rem',
            margin: showMoreInfo ? 0 : '0 0 1.5rem 0',
          }}
        >
          <Card.Content>{renderUser()}</Card.Content>
          {renderAutofillCartAction()}
        </Card>
        {showMoreInfo && (
          <Card
            sx={{
              width: '100%',
              border: '1px solid var(--darkGray)',
              borderTop: 'none',
              borderRadius: '12px',
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              padding: '0 1rem',
              margin: '0 0 1.5rem 0',
            }}
          >
            <Card.Content>{renderMoreInfo()}</Card.Content>
          </Card>
        )}
      </>
    );
  };

  return renderSubscriptionUser();
};

export default SubscriptionUserCard;
