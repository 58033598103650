import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const DatePicker = ({ dateAdapter = AdapterDayjs, ...props }) => {
  return (
    <LocalizationProvider dateAdapter={dateAdapter}>
      <MuiDatePicker {...props} />
    </LocalizationProvider>
  );
};

export default DatePicker;
