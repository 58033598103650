import {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import styled from '@emotion/styled';
import UserTabs from '../UserTabs';
import PaymentMethods from '../PaymentMethods';
import SubscriptionConfigForm from '../SubscriptionConfigForm';
import useViewport from '../../hooks/useViewport';
import usePaymentMethods from '../../hooks/usePaymentMethods';
import {
  SUBSCRIPTION_OPTIONS,
  SUBSCRIPTION_STATUSES,
} from '../../utils/Consts';
import { formatDateTime } from '../../utils';
import { Heading, Button, EmptyState, Spinner, Error } from '../../common';
import FlashOnIcon from '@mui/icons-material/FlashOn';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 865px;
`;

const WEEKDAYS = [
  SUBSCRIPTION_OPTIONS.DAY.MONDAY,
  SUBSCRIPTION_OPTIONS.DAY.TUESDAY,
  SUBSCRIPTION_OPTIONS.DAY.WEDNESDAY,
  SUBSCRIPTION_OPTIONS.DAY.THURSDAY,
  SUBSCRIPTION_OPTIONS.DAY.FRIDAY,
  SUBSCRIPTION_OPTIONS.DAY.SATURDAY,
  SUBSCRIPTION_OPTIONS.DAY.SUNDAY,
];

const INITIAL_SUBSCRIPTION_VALUES = {
  dietaryPreferences: [SUBSCRIPTION_OPTIONS.DIETARY.OMNIVORE],
  weekDaysToSubscribe: [
    SUBSCRIPTION_OPTIONS.DAY.MONDAY,
    SUBSCRIPTION_OPTIONS.DAY.TUESDAY,
    SUBSCRIPTION_OPTIONS.DAY.WEDNESDAY,
    SUBSCRIPTION_OPTIONS.DAY.THURSDAY,
    SUBSCRIPTION_OPTIONS.DAY.FRIDAY,
  ],
  mealTimes: [SUBSCRIPTION_OPTIONS.MEAL_TIME.LUNCH],
  status: SUBSCRIPTION_STATUSES.ENABLED,
  premiumMeals: false,
};

const SubscriptionConfig = ({
  userIds,
  users,
  tags,
  onSubmit,
  onChange, // wizard
  onSubscribe,
  showUserHeader,
  showUserTabs = true,
  hideSubmitButton = false,
  shouldCreateSubscription = false,
  showStatus = false,
  loading: isLoading,
  isSaving,
}) => {
  const { isMobile } = useViewport();
  const { defaultPaymentMethodId } = usePaymentMethods();
  const [selectedUser, setSelectedUser] = useState(null);
  const [paymentMethodId, setPaymentMethodId] = useState();
  const [formValues, setFormValues] = useState({});
  const [error, setError] = useState(null);

  const upsertSubRef = useRef({});

  const WrapperEl = useMemo(() => (isMobile ? Fragment : Wrapper), [isMobile]);
  const hasSub = useMemo(() => !!selectedUser?.subscription, [selectedUser]);

  const handleSetPaymentMethodId = useCallback((pmId) => {
    setPaymentMethodId(pmId);
  }, []);

  useEffect(() => {
    if (selectedUser?.subscription?.paymentMethodId) {
      setPaymentMethodId(selectedUser.subscription.paymentMethodId);
      return;
    }
    if (defaultPaymentMethodId && !paymentMethodId) {
      setPaymentMethodId(defaultPaymentMethodId);
      return;
    }
  }, [paymentMethodId, defaultPaymentMethodId, selectedUser]);

  const handleSaveSubscription = useCallback(
    (vals) => {
      if (!selectedUser.userId) return;
      if (onSubmit) onSubmit(selectedUser.userId, paymentMethodId, vals);
    },
    [selectedUser, paymentMethodId, onSubmit],
  );

  const handleUserFormChange = useCallback(
    (values, userId) => {
      setFormValues((prev) => ({ ...prev, [userId]: values }));
      if (onChange) onChange({ ...values, paymentMethodId, userId });
    },
    [onChange],
  );

  const handleSubscribe = useCallback(
    async (userId, shouldResume = false) => {
      const formVals = formValues[userId] || INITIAL_SUBSCRIPTION_VALUES;
      if (shouldResume) {
        formVals.status = SUBSCRIPTION_STATUSES.ENABLED;
      }
      await onSubscribe(userId, paymentMethodId, formVals);
    },
    [onSubscribe, paymentMethodId, formValues],
  );

  const header = useMemo(() => {
    if (showUserHeader && selectedUser) {
      return `Setup ${selectedUser?.displayName}'s subscription`;
    }
    return 'Subscription settings';
  }, [selectedUser, showUserHeader]);

  useEffect(() => {
    if (!selectedUser) return;
    if (!formValues[selectedUser.userId] && selectedUser.subscription) {
      const {
        paymentMethodId: pmId,
        status,
        dailyOptions,
        subscriptionOptions,
        subscriptionTags,
      } = selectedUser.subscription;
      const v = {
        status: null,
        pauseUntil: null,
        dietaryPreferences: [],
        weekDaysToSubscribe: [],
        mealTimes: [],
        paymentMethodId: pmId,
      };
      subscriptionTags.forEach((sTag) => {
        v.dietaryPreferences.push(sTag.tag.id);
      });
      if (v.dietaryPreferences.length === 0) {
        v.dietaryPreferences.push(SUBSCRIPTION_OPTIONS.DIETARY.OMNIVORE);
      }
      WEEKDAYS.forEach((day) => {
        if (dailyOptions[day.toLowerCase()]?.isEnabled) {
          v.weekDaysToSubscribe.push(day);
        }
      });
      subscriptionOptions.menuSlots.forEach((slot) => {
        if (slot.isEnabled) {
          slot.productSlots.forEach((ps) => {
            if (ps.isEnabled) {
              v.mealTimes.push(`${slot.mealService}:${ps.productType}`);
            }
          });
        }
      });
      v.status = status;
      if (selectedUser?.subscription?.pauseUntil) {
        v.pauseUntil = formatDateTime({
          dateTime: selectedUser.subscription.pauseUntil,
          asString: false,
        });
      }
      if (pmId && paymentMethodId !== pmId) {
        v.paymentMethodId = pmId;
        setPaymentMethodId(pmId);
      }
      if (onChange) onChange({ ...v, userId: selectedUser.userId });
      setFormValues((prev) => ({ ...prev, [selectedUser.userId]: v }));
    }
  }, [selectedUser, paymentMethodId, onChange]);

  useEffect(() => {
    // Create new subscription
    if (
      shouldCreateSubscription &&
      selectedUser &&
      paymentMethodId &&
      !hasSub &&
      !upsertSubRef.current[selectedUser.userId]
    ) {
      (async () => {
        upsertSubRef.current[selectedUser.userId] = true;
        await handleSubscribe(selectedUser.userId);
        upsertSubRef.current[selectedUser.userId] = false;
      })();
    }
    // Resume cancelled subscription
    if (
      shouldCreateSubscription &&
      selectedUser &&
      paymentMethodId &&
      hasSub &&
      selectedUser?.subscription?.status === SUBSCRIPTION_STATUSES.CANCELLED &&
      !upsertSubRef.current[selectedUser.userId]
    ) {
      (async () => {
        upsertSubRef.current[selectedUser.userId] = true;
        await handleSubscribe(selectedUser.userId, true); // resume stopped subscription
        upsertSubRef.current[selectedUser.userId] = false;
      })();
    }
  }, [
    selectedUser,
    paymentMethodId,
    shouldCreateSubscription,
    hasSub,
    handleSubscribe,
  ]);

  const renderError = () => {
    return <Error id="error" header="Error" content={error} />;
  };

  const renderPaymentMethods = () => {
    return (
      <WrapperEl>
        <p>Select a payment method to get started.</p>
        <PaymentMethods
          onSelectPaymentMethod={handleSetPaymentMethodId}
          selectedPaymentMethodId={paymentMethodId || ''}
          showOptionsAs="select"
          showHeader={true}
          autoSelectPaymentMethod={true}
        />
      </WrapperEl>
    );
  };

  const renderSubscriptionForm = () => {
    if (!selectedUser) return null;
    return (
      <SubscriptionConfigForm
        key={selectedUser.userId}
        user={selectedUser}
        tags={tags}
        hideSubmitButton={hideSubmitButton}
        showStatus={showStatus}
        values={formValues[selectedUser.userId]}
        onChange={(values) =>
          handleUserFormChange(values, selectedUser?.userId)
        }
        onSubmit={handleSaveSubscription}
        loading={isSaving}
      />
    );
  };

  const renderSubscribeAction = () => {
    if (shouldCreateSubscription) return null;
    return (
      <Button
        onClick={() => handleSubscribe(selectedUser.userId)}
        sx={{
          backgroundColor: 'var(--blue)',
          margin: '1rem auto',
          alignSelf: 'center',
          fullWidth: isMobile,
          loading: isSaving,
        }}
      >
        <FlashOnIcon sx={{ marginRight: '0.5rem' }} />
        Subscribe
      </Button>
    );
  };

  const renderEmptyState = () => {
    return (
      <EmptyState
        message="There are no subscriptions to setup at this time."
        icon={FlashOnIcon}
      />
    );
  };

  const renderTabs = () => {
    return (
      <UserTabs
        userIds={userIds}
        users={users}
        onSelectUser={setSelectedUser}
        showUserTabs={showUserTabs}
      >
        <WrapperEl>
          {hasSub ? renderSubscriptionForm() : renderSubscribeAction()}
        </WrapperEl>
      </UserTabs>
    );
  };

  const renderSubscriptionUsers = () => {
    return (
      <>
        <Heading variant="h6">How it works</Heading>
        <p>
          Set and forget your orders! We require at least 2 items ordered weekly
          for each student opting into subscriptions. We'll automatically order
          items for you for each day according to your settings. Items will be
          automatically added to your cart for your review each Sunday, and will
          be auto-checked out on Thursday nights if you don't edit your cart or
          pause your subscription. Please note, we will only send meals of days
          of week when your school has active lunch service. If you have a
          school-wide vacations or pre-scheduled days off, we will automatically
          not order for that day.
        </p>
        {users.length > 0 ? renderTabs() : renderEmptyState()}
      </>
    );
  };

  const renderContent = () => {
    if (isLoading) return <Spinner />;
    if (error) return renderError();
    if (selectedUser && !paymentMethodId) return renderPaymentMethods();
    if (shouldCreateSubscription && paymentMethodId && selectedUser && !hasSub)
      return (
        <Spinner
          message={`Creating subscription for ${selectedUser?.displayName} …`}
        />
      );
    return renderSubscriptionUsers();
  };

  return (
    <>
      <Heading variant="h4" sx={{ marginBottom: '1rem' }}>
        {header}
      </Heading>
      {renderContent()}
    </>
  );
};

export default SubscriptionConfig;
