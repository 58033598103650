import { useState, useMemo } from 'react';
import { createPortal } from 'react-dom';
import useViewport from '../../hooks/useViewport';
import useTemplateContext from '../../hooks/useTemplateContext';
import useMe from '../../hooks/useMe';
import useCart from '../../hooks/useCart';
import useSubscriptions from '../../hooks/useSubscriptions';
import { Button } from '../../common';
import CartView from '../CartView';
import SubscribeCTA from '../SubscribeCTA';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Sticky from 'react-stickynode';

const FixedHeaderContent = ({ template }) => {
  const { isMobile } = useViewport();
  const [showCart, setShowCart] = useState(false);

  const {
    transformedCart,
    summary,
    quantity: cartQty,
    cartLoading,
    onClearCart,
    onRemoveCartItem,
    onUpdateCartItem,
  } = useCart();
  const { me } = useMe();
  const { hasSubscriptions } = useSubscriptions();

  const handleToggleCartView = () => {
    setShowCart(!showCart);
  };

  const cartLabel = useMemo(
    () => (cartQty > 0 ? ` • ${cartQty}` : ''),
    [cartQty],
  );

  const renderCartView = () => {
    return (
      <CartView
        cart={transformedCart}
        summary={summary}
        me={me}
        onToggleCartView={handleToggleCartView}
        onClearCart={onClearCart}
        onRemoveCartItem={onRemoveCartItem}
        onUpdateCartItem={onUpdateCartItem}
        loading={cartLoading}
        isOpen={showCart}
      />
    );
  };

  const renderButton = ({
    key,
    id,
    label,
    icon,
    variant,
    onClick,
    loading,
  }) => {
    const onClickHandler = onClick;
    return (
      <Button
        key={key || id}
        id={id}
        onClick={onClickHandler}
        variant={variant}
        loading={loading}
        sx={{ marginLeft: '1rem' }}
      >
        {icon}
        {label}
      </Button>
    );
  };

  const renderSubscribeCTA = () => {
    if (hasSubscriptions !== false) return null;
    return <SubscribeCTA />;
  };

  const renderContent = () => {
    if (isMobile || template !== 'default') return null;
    const headerFixedPortalEl = document.getElementById('header-fixed-portal');
    const content = (
      <>
        {renderSubscribeCTA()}
        {renderButton({
          id: 'cart',
          label: cartLabel,
          icon: <ShoppingCartIcon />,
          variant: 'contained',
          onClick: handleToggleCartView,
          loading: cartLoading,
        })}
      </>
    );
    if (!headerFixedPortalEl) {
      // console.warn('Cannot set portal content filters for header-fixed-portal');
      return <Sticky>{content}</Sticky>;
    }
    return (
      <>
        {createPortal(content, headerFixedPortalEl)}
        {renderCartView()}
      </>
    );
  };

  return renderContent();
};

export default FixedHeaderContent;
