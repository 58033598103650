import { Fragment } from 'react';
import styled from '@emotion/styled';
import useViewport from '../../hooks/useViewport';

const DefaultContainer = styled.div`
  padding: 1rem;
  margin-bottom: 3rem;
  max-width: 100vw;
`;

const ScrollWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  height: var(--doc-height);
  max-height: calc(var(--doc-height) - 8.75rem);
`;

const MobileScrollWrapper = styled(ScrollWrapper)`
  overflow: scroll;
  overflow-y: scroll;
  height: 100%;
  padding-bottom: 6rem;
  -webkit-overflow-scrolling: touch;
`;

const ResponsiveScrollableArea = ({ content, children, containerId, wrapperId, container, wrapper }) => {
  const { isMobile } = useViewport();

  const renderContent = () => {
    const defaultWrapper = isMobile ? MobileScrollWrapper : ScrollWrapper;
    const WrapperEl = wrapper || defaultWrapper;
    const Container = container || Fragment;
    return (
      <WrapperEl id={wrapperId}>
        <Container id={containerId}>
          {children || content}
        </Container>
      </WrapperEl>
    );
  };

  return renderContent();
};

ResponsiveScrollableArea.defaultProps = {
  container: DefaultContainer,
};

export default ResponsiveScrollableArea;
