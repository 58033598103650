import { useState, useMemo } from 'react';
import { client as httpClient } from '../http';
import {
  constructQueryString,
  sort,
  getPaymentMethod3dsStatus,
} from '../utils';
import useAccount from './useAccount';

const usePaymentMethods = () => {
  const { account, accountId } = useAccount();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const autoSelectPaymentMethod = true;

  // TODO Refator this to centralize the payment methods logic
  const { paymentRef, paymentMethods, defaultPaymentMethodId } = useMemo(() => {
    const pRef = account?.paymentReference || null;
    const pMethods = [];
    let defaultPaymentMethod = null;
    if (pRef?.paymentMethods) {
      for (const pmId in pRef?.paymentMethods) {
        const is3ds = getPaymentMethod3dsStatus(pmId, pRef);
        const pMethod = pRef.paymentMethods[pmId];
        if (pMethod && !pMethod.isArchived && !is3ds) {
          if (pRef.defaultPaymentMethodId === pmId) {
            defaultPaymentMethod = pMethod;
          }
          pMethods.push(pMethod);
        }
      }
    }
    return {
      paymentRef: pRef,
      paymentMethods: sort(pMethods, 'created'),
      defaultPaymentMethodId:
        defaultPaymentMethod?.id || pMethods.length === 1
          ? pMethods[0]?.id
          : null,
    };
  }, [account, autoSelectPaymentMethod]);

  const handleSetError = (message, obj) => {
    if (!message) {
      setError(null);
      return;
    }
    const msg = obj?.error?.message || obj?.raw?.message || obj?.message;
    const err = msg ? `${message} Error: ${msg}` : message;
    setError(err);
  };

  const handleUpdatePaymentMethod = async (paymentMethodId, params) => {
    if (!accountId) {
      setError('Error updating payment method. Please reload and try again.');
      console.error('Missing accountId in handleUpdatePaymentMethod()', {
        accountId,
        paymentMethodId,
        params,
      });
      return null;
    }
    if (error) handleSetError(null);
    setLoading(true);
    await httpClient
      .patch({
        url: `/payments/methods/${paymentMethodId}`,
        body: { params, accountId },
        checkStatus: true,
      })
      .catch((err) => {
        console.error('handleUpdateePaymentMethod', err);
        handleSetError(
          'There was a problem updating your payment method. Please try again. If the problem persists, please contact support.',
          err,
        );
      });
    setLoading(false);
  };

  const handleDeletePaymentMethod = async (paymentMethodId) => {
    if (!accountId) {
      setError('Error deleting payment method. Please reload and try again.');
      console.error('Missing accountId in handleDeletePaymentMethod()', {
        accountId,
        paymentMethodId,
      });
      return null;
    }
    if (error) handleSetError(null);
    setLoading(true);
    const qs = constructQueryString({ accountId });
    await httpClient
      .delete({
        url: `/payments/methods/${paymentMethodId}${qs}`,
        checkStatus: true,
      })
      .catch((err) => {
        console.error('handleDeletePaymentMethod', err);
        handleSetError(
          'There was a problem deleting your payment method. Please try again. If the problem persists, please contact support.',
          err,
        );
      });
    setLoading(false);
  };

  return {
    paymentRef,
    paymentMethods,
    defaultPaymentMethodId,
    loading,
    error,
    onUpdatePaymentMethod: handleUpdatePaymentMethod,
    onDeletePaymentMethod: handleDeletePaymentMethod,
  };
};

export default usePaymentMethods;
