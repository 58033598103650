import { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';
import { Heading, Icon, Spinner } from '../../common';

const HeadingStyled = styled(Heading)`
  margin: 0 !important;
  padding: 1.5rem !important;
`;

const DefaultContainer = styled.div`
  padding: 1.5rem;
`;

const DefaultWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-flow: column;
  width: 100%;
`;

const IconStyled = styled(Icon)`
  margin-right: 1rem !important;
`;

const PageContainer = ({
  children,
  pageTitle,
  showPageTitle,
  icon,
  loading,
  minimumLoadingTime,
  loadingMessage,
  container,
  wrapper,
  footer,
  header,
  id,
  wrapperId,
}) => {
  const [isReadyToRender, setIsReadyToRender] = useState(false);

  useEffect(() => {
    if (Array.isArray(loading)) {
      const allLoaded = loading.every((state) => !state);
      if (allLoaded) {
        setIsReadyToRender(true);
        // const timer = setTimeout(() => {
        //   console.log('timer:', minimumLoadingTime);
        //   setIsReadyToRender(true);
        // }, minimumLoadingTime);
        // return () => clearTimeout(timer);
      } else {
        setIsReadyToRender(false);
      }
    } else {
      setIsReadyToRender(!loading);
    }
  }, [loading]);

  const renderHeader = () => (
    <HeadingStyled variant="h5">
      {icon && <IconStyled name={icon} />}
      {pageTitle}
    </HeadingStyled>
  );

  const renderFooter = () => null;

  const renderTitle = () => (
    <Helmet>
      <title>{`${pageTitle} | ${process.env.APP_NAME}`}</title>
    </Helmet>
  );

  const Element = container || DefaultContainer;
  const Wrapper = wrapper || DefaultWrapper;
  const Header = header || renderHeader;
  const Footer = footer || renderFooter;

  const renderElement = () => {
    if (!isReadyToRender) {
      return <Spinner message={loadingMessage} />;
    }
    return <Element id={id}>{children}</Element>;
  };

  return (
    <>
      {pageTitle && renderTitle()}
      <Wrapper id={wrapperId}>
        {showPageTitle && (pageTitle || header) && <Header />}
        {renderElement()}
        <Footer />
      </Wrapper>
    </>
  );
};

PageContainer.defaultProps = {
  showPageTitle: true,
  minimumLoadingTime: 500,
};

export default PageContainer;
