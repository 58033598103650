import { useMemo, useState } from 'react';
import styled from '@emotion/styled';
import useQueryParams from '../../hooks/useQueryParams';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { signInAuthUser } from '../../firebase/auth';
import { Button, Link, Card, TextField, Form, Error } from '../../common';
import { PageContainer } from '../../components';
import { REGISTER, PASSWORD_FORGET } from '../../routes';

const ActionsWrapper = styled.div`
  margin-top: 2.5rem;
  font-size: 0.9rem;
  font-weight: 500;
`;

const ForgotPasswordWrapper = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  text-align: right;
  font-size: 0.9rem;
`;

const Wrapper = styled.div`
  width: 500px;
  max-width: 100%;
`;

const FieldWrapper = styled.div`
  margin-bottom: 10px !important;
`;

const Container = styled.div`
  padding: 0;
  margin: 0;
  display: flex;
  /* flex: 1 auto; */
  align-items: center;
  justify-content: center;
  /* background-color: var(--white); */
`;

const SignInSchema = Yup.object().shape({
  emailAddress: Yup.string()
    .required('Please enter a valid email address')
    .email('Please enter a valid email address'),
  password: Yup.string()
    .min(6, 'Password must be at least 6 characters')
    .required('Enter a password'),
});

const SignIn = () => {
  const q = useQueryParams();
  const [error, setError] = useState(null);

  const history = useHistory();

  const handleSignInUser = async (vals) => {
    const { emailAddress, password } = vals;
    await signInAuthUser(emailAddress, password)
      .catch((err) => setError(err));
  };

  const handleGoToRegister = () => {
    history.push(REGISTER);
  };

  const formik = useFormik({
    initialValues: {
      emailAddress: q?.emailAddress || '',
      password: '',
    },
    validationSchema: SignInSchema,
    onSubmit: handleSignInUser,
  });

  const fields = useMemo(() => [
    {
      id: 'emailAddress',
      label: 'Email address',
      type: 'email',
      autoFocus: true,
      autoComplete: 'username',
    },
    {
      id: 'password',
      label: 'Password',
      type: 'password',
      autoComplete: 'current-password',
    },
  ], []);

  const { values, errors, touched, dirty, handleChange, handleSubmit, isSubmitting } = formik;

  const renderField = ({ id, type, label, placeholder = null, autoFocus = false, autoComplete }) => {
    return (
      <FieldWrapper key={id}>
        <TextField
          id={id}
          name={id}
          label={label}
          type={type}
          onChange={handleChange}
          value={values[id]}
          placeholder={placeholder}
          autoFocus={autoFocus}
          autoComplete={autoComplete}
          error={touched[id] && !!(errors[id])}
          helperText={touched[id] && errors[id]}
          fullWidth
        />
      </FieldWrapper>
    );
  };

  const renderForgotPasswordLink = () => {
    return (
      <ForgotPasswordWrapper>
        <Link route={PASSWORD_FORGET}>Forgot your password?</Link>
      </ForgotPasswordWrapper>
    );
  };

  const renderSignInForm = () => (
    <Form formik={formik} onSubmit={handleSubmit}>
      {fields.map(renderField)}
      {renderForgotPasswordLink()}
      <Button
        type="submit"
        disabled={isSubmitting || !dirty}
        loading={isSubmitting}
        fullWidth
      >
        Log in
      </Button>
    </Form>
  );

  const renderMoreActions = () => {
    return (
      <ActionsWrapper>
        {'Don\'t have a login? '}
        <Button
          sx={{ marginTop: '0.5rem' }}
          onClick={handleGoToRegister}
          variant="outlined"
          fullWidth
        >
          Create your account
        </Button>
      </ActionsWrapper>
    );
  };

  const renderError = () => {
    if (!error) return null;
    const errMessage = 'Unable to sign in. Please verify your email address and password and try again.';
    return (<>
      <br />
      <Error header="Authentication Error" content={errMessage} />
    </>);
  };

  const renderPageContent = () => {
    return (
      <Card>
        <Card.Header sx={{ paddingBottom: 0 }} title="Login" />
        <Card.Content>
          <Wrapper>
            {renderSignInForm()}
            {error && renderError()}
            {renderMoreActions()}
          </Wrapper>
        </Card.Content>
      </Card>
    );
  };

  return (
    <PageContainer
      container={Container}
      loading={false}
      pageTitle='Login'
      showPageTitle={false}
    >
      {renderPageContent()}
    </PageContainer>
  );
};

export default SignIn;
