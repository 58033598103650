import {
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
} from '@mui/material';
import { FormikProvider, Formik, Form as FormikForm } from 'formik';
import styled from '@emotion/styled';

const FormLabel = styled.label`
  color: var(--gray);
`;

const FormError = styled.span`
  color: var(--red);
  vertical-align: baseline;
`;

const FormOptionLabel = styled.span`
  color: var(--black);
  font-weight: 500;
  line-height: 1rem;
  vertical-align: middle;
`;

const Form = ({ formik, ...props }) => {
  return (
    <FormikProvider value={formik}>
      <Formik>
        <FormikForm {...props} />
      </Formik>
    </FormikProvider>
  );
};

Form.Label = FormLabel;
Form.OptionLabel = FormOptionLabel;
Form.Error = FormError;
Form.ControlLabel = FormControlLabel;
Form.Control = FormControl;
Form.Group = FormGroup;
Form.InputLabel = InputLabel;

export default Form;
