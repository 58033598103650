// import ModalDrawer from './ModalDrawer';
import ResponsiveHeader from './ResponsiveHeader';
import ResponsiveFooter from './ResponsiveFooter';
import ResponsiveScrollableArea from './ResponsiveScrollableArea';

const _Base = () => null;

_Base.ResponsiveHeader = ResponsiveHeader;
_Base.ResponsiveFooter = ResponsiveFooter;
_Base.ResponsiveScrollableArea = ResponsiveScrollableArea;

export default _Base;
