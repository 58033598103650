import { Fragment, useState, useRef } from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import useViewport from '../../hooks/useViewport';
import { DASHBOARD } from '../../routes';
import { Card, Button, Spinner } from '../../common';
import { PageContainer, AccountUserList, AccountUserForm } from '../../components';
import useAccount from '../../hooks/useAccount';
import useGlobalContext from '../../hooks/useGlobalContext';
import useUpsertAccountUser from '../../hooks/useUpsertAccountUser';

const Wrapper = styled.div`
  width: 500px;
`;

const Container = styled.div`
  padding: 0;
  margin: 0;
  margin-top: 3rem;
  display: flex;
  /* flex: 1 auto; */
  align-items: center;
  justify-content: center;
  /* background-color: var(--white); */
`;

const AccountUpdate = () => {
  const { isMobile } = useViewport();
  const history = useHistory();
  const [{ rosterUpdates }, setGlobalState] = useGlobalContext();
  const { accountId, loading, aUsers, hasTermRosters } = useAccount();
  const { onUpsertAccountUser, onArchiveAccountUser, loading: upsertingAu } = useUpsertAccountUser();
  const [showAccountUserForm, setShowAccountUserForm] = useState(false);

  const userData = useRef(null); // Edit user
  const auFormParams = useRef(null);

  const handleGoToMenu = () => {
    history.push(DASHBOARD);
  };

  const setAuFormParams = () => {
    const hFields = [];
    let s = null;
    let lId = null;
    if (userData.current) {
      if (userData.current.isStudent || userData.current.isStaff) {
        userData.current.rosters?.some(({ roster: r }) => {
          if (r.term?.location?.address?.stateProvince) {
            if (!hFields.includes('usaState')) hFields.push('usaState');
            return true;
          }
          return false;
        });
      }
    }
    if (!s) {
      aUsers.some((au) => {
        if (au.isStudent || au.isStaff) {
          au.user?.rosters?.some(({ roster: r }) => {
            if (r.term?.location?.address?.stateProvince) {
              s = r.term?.location?.address?.stateProvince;
              lId = r.term?.location?.id;
              if (!hFields.includes('usaState')) hFields.push('usaState');
              return true;
            }
            return false;
          });
        }
      });
    }
    auFormParams.current = {
      usaState: s,
      locationId: lId,
      hiddenFields: hFields,
    };
  };

  const handleSelectAccountUser = (aUser) => {
    userData.current = aUser;
    setAuFormParams();
    setShowAccountUserForm(true);
  };

  const toggleShowAccountUserForm = () => {
    setShowAccountUserForm((bool) => {
      if (bool) {
        userData.current = null;
        auFormParams.current = null;
      }
      return !bool;
    });
  };

  const handleRosterUpdatesState = (userId) => {
    let ru = { ...rosterUpdates };
    if (ru && ru[userId]) {
      delete ru[userId];
      if (Object.keys(ru).length === 0) ru = null;
      setGlobalState({ rosterUpdates: ru });
    }
  };

  const handleArchiveUser = async (bool) => {
    const au = {
      recId: userData?.current?.id,
      userId: userData?.current?.user?.id,
      isArchived: bool,
    };
    const userId = userData?.current?.user?.id;
    await onArchiveAccountUser(au, toggleShowAccountUserForm)
      .then(() => handleRosterUpdatesState(userId));
  };

  const handleUpdateAccountUser = async (vals) => {
    const userId = userData?.current?.user?.id;
    await onUpsertAccountUser(vals, toggleShowAccountUserForm)
      .then(() => handleRosterUpdatesState(userId));
  };

  const renderAccountUserForm = () => {
    if (!showAccountUserForm) return null;
    return (
      <AccountUserForm
        isOpen={showAccountUserForm}
        userData={userData.current}
        onClose={toggleShowAccountUserForm}
        onSubmit={handleUpdateAccountUser}
        onArchiveUser={handleArchiveUser}
        disabledFields={['role']}
        showArchiveAction={!userData.current?.isAccountAdmin}
        actionLoading={upsertingAu}
        rosterUpdates={rosterUpdates}
        {...auFormParams.current}
      />
    );
  };

  const renderAccountUserList = (WrapperEl, sx) => {
    if (!accountId || loading) return <Spinner />;
    return (
      <Card.Content sx={sx}>
        <WrapperEl>
          <AccountUserList
            users={aUsers}
            onEdit={handleSelectAccountUser}
          />
          <Button
            onClick={handleGoToMenu}
            sx={{ marginTop: '1rem', display: 'block' }}
            disabled={!hasTermRosters}
            fullWidth
          >
            Go to menu
          </Button>
        </WrapperEl>
        {showAccountUserForm && renderAccountUserForm()}
      </Card.Content>
    );
  };

  const renderPageContent = () => {
    const WrapperEl = isMobile ? Fragment : Wrapper;
    const sx = {
      paddingLeft: 0,
      paddingRight: 0,
    };
    return (
      <Card>
        <Card.Header title="Update missing info" sx={{ ...sx, textAlign: 'center' }} titleTypographyProps={{ sx: { fontSize: isMobile ? '2rem' : '2.5rem' } }} />
        {renderAccountUserList(WrapperEl, sx)}
      </Card>
    );
  };

  return (
    <PageContainer
      container={isMobile ? null : Container}
      pageTitle='Account Update'
      showPageTitle={false}
    >
      {renderPageContent()}
    </PageContainer>
  );
};

export default AccountUpdate;
