import { useState, useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import { client as httpClient } from '../../http';
import { Card, Box, Error, SecondaryActionText } from '../../common';
import { PageContainer, Wizard, Logo } from '../../components';
import { USER_ROLES, USER_ROLES_LABELS } from '../../utils/Consts';
import { JOIN_WAITLIST, JOIN_WAITLIST_SUCCESS, SIGN_IN } from '../../routes';
import { constructQueryString, cleanInputs } from '../../utils';
import useQueryParams from '../../hooks/useQueryParams';
import useViewport from '../../hooks/useViewport';

const WAITLIST_SERVICE_ENDPOINT = '/waitlist';

const { GUARDIAN, STAFF, STUDENT } = USER_ROLES;
const ROLE_OPTIONS = [GUARDIAN, STAFF, STUDENT];

const ROLES = ROLE_OPTIONS
  .map((value) => ({ label: USER_ROLES_LABELS[value], value }));

const Wrapper = styled.div`
  width: 500px;
  max-width: 100%;
`;

const Container = styled.div`
  padding: 0;
  margin: 0;
  display: flex;
  flex: 1 auto;
  align-items: center;
  justify-content: center;
  min-height: 92vh;
`;

const WaitlistSchema = Yup.object().shape({
  school: Yup.string().required('Please enter a school'),
  role: Yup.string().required('Please select a role'),
  firstName: Yup.string().required('Please enter your first name'),
  lastName: Yup.string().required('Please enter your last name'),
  emailAddress: Yup.string().required('Please enter your email address')
    .email('Please enter a valid email address')
});

const Waitlist = () => {
  const { isMobile } = useViewport();
  const history = useHistory();
  const { school = '', role, node } = useQueryParams();
  const [loading, setLoading] = useState(false);
  const [linkCopied, setLinkedCopied] = useState(false);
  const [error, setError] = useState(null);
  const [customerHeader, setCustomHeader] = useState(null);

  const handleGoToRoute = (r) => {
    history.push(r);
  };

  const isStaff = useMemo(() => role === USER_ROLES.STAFF, [role]);

  const getInitNode = () => {
    let n = 'waitlist';
    if (history?.location?.pathname === JOIN_WAITLIST_SUCCESS) {
      switch (node) {
      case 'success':
        n = node;
        break;
      case 'successViaRegistration':
        n = 'success'; // Deprecate ?node=successViaRegistration
        break;
      case 'successDemo':
        n = node;
        break;
      default:
        handleGoToRoute(JOIN_WAITLIST);
        break;
      }
    }
    return n;
  };

  const initNode = useRef(getInitNode());

  const handleSetCustomHeaderContent = (content = null) => {
    setCustomHeader(content);
  };

  const handleSetStepChange = (currentNodeId) => {
    switch (currentNodeId) {
    case 'waitlist':
      break;
    case 'successViaRegistration':
    case 'successDemo':
    case 'success':
      handleSetCustomHeaderContent((
        <Box
          sx={{
            width: '100%',
            padding: '0 4rem',
            alignContent: isMobile ? undefined : 'flex-start',
          }}
        >
          <Logo />
        </Box>
      ));
      break;
    default:
      break;
    }
  };

  const handleWizardInit = (data) => {
    handleSetStepChange(data.currentNodeId);
  };

  const handleWizardGoBack = (data) => {
    const currentNodeId = data.currentNodeId;
    let prevNodeId = null;
    // No go back scenario in this flow right now
    if (prevNodeId) handleSetStepChange(prevNodeId);
    return prevNodeId;
  };

  const steps = useMemo(() => {
    return [
      { label: 'Check availability' },
      { label: 'Sign up' },
      { label: 'Place an order' },
    ];
  }, []);

  const handleGoToOrdo = (data, { onClearState }) => {
    if (onClearState) onClearState();
    window.location.href = process.env.MARKETING_SITE_URI;
  };

  const handleCopyLink = (data) => {
    // const link = `https://${process.env.DOMAIN}${JOIN_WAITLIST}`;
    const link = process.env.MARKETING_SITE_URI;
    // const name = `${data.firstName || ''} ${data.lastName || ''}`.trim();
    // const message = name
    //   ? `${name} invites you to join the waitlist for the best school lunch — period.\n.\n\n${link}`
    //   : `You're invited to join the waitlist for the best school lunch — period.\n.\n\n${link}`;

    if (navigator.share) {
      navigator.share({
        title: 'Ordo Schools 🍎',
        url: link,
      })
        .catch(console.error);
    } else {
      navigator.clipboard.writeText(link);
      setLinkedCopied(true);
    }
  };

  const handleSubmit = async (data) => {
    setError(null);
    const {
      firstName,
      lastName,
      emailAddress,
      school: s,
      role,
    } = data;
    const payload = {
      firstName,
      lastName,
      emailAddress,
      school: s || school,
      roles: role,
    };
    let nextNode = 'waitlist';

    const isValid = await WaitlistSchema.isValid({ ...payload, role });
    if (isValid) {
      setLoading(true);
      const cleanPayload = cleanInputs(payload);
      const res = await httpClient.post({
        url: WAITLIST_SERVICE_ENDPOINT,
        body: cleanPayload,
      });
      if (res?.result?.ok) {
        nextNode = isStaff ? 'successDemo' : 'success';
        const qs = `${constructQueryString({ node: nextNode })}`;
        handleGoToRoute(`${JOIN_WAITLIST_SUCCESS}${qs}`);
        return nextNode;
      }
    } else {
      const validation = await WaitlistSchema.validate({ ...payload, role });
      console.error('Invalid waitlist submission', payload, validation);
      setError('There was an error validating your request.');
    }
    setLoading(false);
    return nextNode;
  };

  const renderMoreActions = () => {
    return (
      <SecondaryActionText
        text='Already have an account?'
        actionLabel='Log in.'
        route={SIGN_IN}
      />
    );
  };

  const waitlistFlows = useMemo(() => {
    return (
      {
        waitlist: {
          step: 0,
          title: isStaff
            ? 'Book a demo'
            : 'Request your school',
          description: null,
          footer: renderMoreActions(),
          form: {
            preventSubmitOnEnter: true,
            initialValues: { school, role },
            validationSchema: WaitlistSchema,
            fields: [
              {
                id: 'school',
                label: 'School',
                disabled: !!school,
                autoFocus: !school,
              },
              {
                id: 'role',
                label: 'Role',
                type: 'dropdown',
                options: ROLES,
                dropdownParams: { disabled: !!role },
              },
              {
                id: 'firstName',
                label: 'First name',
                autoFocus: (!!role && !!school),
              },
              {
                id: 'lastName',
                label: 'Last name',
              },
              {
                id: 'emailAddress',
                label: 'Email address',
              },
            ],
            actions: [
              {
                key: 'next',
                cb: handleSubmit,
                label: isStaff
                  ? 'Next'
                  : 'Sign up',
                loading,
                disabled: (data) => (
                  !data?.school
                  || !data?.role
                  || !data?.firstName
                  || !data?.lastName
                  || !data?.emailAddress
                ),
              },
            ],
          },
        },
        successDemo: {
          step: null,
          title: 'We\'ll be in touch soon!',
          description: 'Thank you for your interest in Ordo! We\'ll reach out ASAP to schedule a demo.',
          footer: renderMoreActions(),
          form: {
            fields: [],
            actions: [
              {
                key: 'back',
                cb: handleGoToOrdo,
                label: 'Back to website',
              },
            ],
          }
        },
        success: {
          step: null,
          title: 'Thanks for signing up.',
          footer: renderMoreActions(),
          form: {
            fields: [],
            actions: [
              {
                key: 'copyLink',
                cb: handleCopyLink,
                label: 'Share waitlist',
              },
              {
                key: 'back',
                cb: handleGoToOrdo,
                label: 'Back to website',
                variant: 'outlined',
              },
            ],
          }
        },
        successViaRegistration: {
          step: null,
          title: 'Thanks for signing up.',
          content: '',
          form: {
            fields: [],
            actions: [
              {
                key: 'back',
                cb: handleGoToOrdo,
                label: 'Back to website',
              },
            ],
          }
        },
      }
    );
  }, [loading, school]);

  const renderLinkCopiedMessage = () => {
    return (
      <SecondaryActionText
        text='Copied link to clipboard!'
      />
    );
  };

  const renderError = () => {
    if (!error) return null;
    return (
      <>
        <br />
        <Error header="Waitlist Error" content={error} />
      </>
    );
  };

  const renderCustomHeader = () => {
    const headerEl = document.getElementById('main-header');
    if (!headerEl) {
      console.warn('No header element found');
      return null;
    }
    return createPortal(
      customerHeader,
      headerEl,
    );
  };

  const renderPageContent = () => {
    return (
      <Card>
        <Card.Content>
          <Wrapper>
            <Wizard
              flows={waitlistFlows}
              rootNodeId={initNode.current}
              namespace="waitlist"
              onInit={handleWizardInit}
              onGoBack={handleWizardGoBack}
              onSetHeader={handleSetCustomHeaderContent}
              steps={steps}
              showStepper={!isMobile}
            />
            {customerHeader && renderCustomHeader()}
            {linkCopied && renderLinkCopiedMessage()}
            {error && renderError()}
          </Wrapper>
        </Card.Content>
      </Card>
    );
  };

  return (
    <PageContainer
      container={Container}
      loading={false}
      pageTitle='Join Waitlist'
      showPageTitle={false}
    >
      {renderPageContent()}
    </PageContainer>
  );
};

export default Waitlist;
