import { LinearProgress } from '@mui/material';
import { CircularProgress } from '@mui/material';

const Progress = ({ type, ...rest }) => {
  if (type === 'circle') {
    return <CircularProgress {...rest} />;
  }
  return <LinearProgress {...rest} />;
};

Progress.defaultProps = {
  type: 'line',
};

export default Progress;
