import { Fragment, useMemo, useState, useCallback } from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { debounce } from 'lodash';
import useViewport from '../../hooks/useViewport';
import useQueryParams from '../../hooks/useQueryParams';
import useAccount from '../../hooks/useAccount';
import {
  DrawerAdvanced,
  Button,
  Form,
  Heading,
  Divider,
  Currency,
  TextField,
  Menu,
  Select,
  Dialog,
  EmptyState,
  Box,
  IconButton,
} from '../../common';
import { sort, formatDateTime, getInvalidCartItemErrors } from '../../utils';
import { CHECKOUT } from '../../routes';
import TodayIcon from '@mui/icons-material/Today';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const { Control: FormControl } = Form;
const { Item: MenuItem } = Menu;

const Wrapper = styled.span`
  display: flex;
  flex: 1;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
`;

const WrapperSpaced = styled(Wrapper)`
  justify-content: space-between;
`;

const SectionWrapper = styled.div`
  padding: 0.5rem 0;
`;

const SectionWrapperInDrawer = styled.div`
  padding: 1rem 0;
`;

const UserWrapper = styled.div`
  margin: 1rem 0;
`;

const ItemRow = styled.div`
  font-size: 0.8rem;
`;

const BundleItemRow = styled(Box)``;

const Name = styled.span`
  font-size: 0.8rem;
  font-weight: 500;
`;

const CartItemName = styled.span`
  max-width: 17rem;
  margin-left: 0.5rem;
  justify-content: flex-start;
  width: 100%;
  text-wrap: balance;
`;

const ClearButton = styled(Button)`
  display: block !important;
  color: var(--gray) !important;
`;

const TodayIconStyled = styled(TodayIcon)`
  margin-right: 0.25rem;
  font-size: 1.08rem !important;
  vertical-align: middle !important;
`;

const DeleteIcon = styled(DeleteForeverIcon)``;

// TODO Refactor this with OrderItems component
const CartView = ({
  cart,
  summary,
  unavailableItems,
  me,
  onToggleCartView,
  onClearCart,
  onRemoveCartItem,
  onUpdateCartItem,
  loading,
  isOpen,
  showInDrawer,
  disableForm,
}) => {
  const { isMobile } = useViewport();
  const history = useHistory();
  const { showBundlePrices = false } = useQueryParams();
  const { settings, aUsers } = useAccount();
  const [confirmation, setConfirmation] = useState(null);

  const handleGoToCheckout = () => {
    onToggleCartView();
    history.push(CHECKOUT);
  };

  const allowBulkOrdering = useMemo(
    () => !!settings?.allowBulkOrdering,
    [settings],
  );
  const quantityArr = useMemo(() => [0, 1, 2, 3, 4, 5, 6, 7, 8, 9], []);

  const getUser = (userId) => {
    const u = aUsers.find((au) => {
      return au?.user?.id === userId;
    });
    return u;
  };

  const { currencyCode, groupedItems, sectionKeys, sectionKeyUsers } =
    useMemo(() => {
      let cc = 'USD';
      const keys = [];
      const skUsers = {};
      const gItems = {};

      if (cart?.length && aUsers?.length) {
        sort(cart || [], ['name', 'id']).forEach((item, idx) => {
          const {
            currencyCode,
            deliveryDate: date,
            user: { id: userId },
          } = item;
          if (cc !== currencyCode) {
            cc = currencyCode;
          }
          const errors = getInvalidCartItemErrors(item.id, unavailableItems);
          const key = date;
          if (!keys.includes(key)) keys.push(key);
          if (!skUsers[key]) skUsers[key] = [userId];
          if (!skUsers[key].includes(userId)) skUsers[key].push(userId);
          if (!gItems[key])
            gItems[key] = { [userId]: [{ ...item, idx, errors }] };
          else if (!gItems[key][userId])
            gItems[key][userId] = [{ ...item, idx, errors }];
          else gItems[key][userId].push({ ...item, idx, errors });
        });
      }

      keys.sort();
      keys.forEach((k) => {
        skUsers[k].sort((a, b) => {
          const aUser = getUser(a)?.user;
          const bUser = getUser(b)?.user;
          const aName = aUser?.preferredName || aUser?.firstName;
          const bName = bUser?.preferredName || bUser?.firstName;
          if (aName < bName) return -1;
          if (aName > bName) return 1;
          return 0;
        });
      });
      return {
        groupedItems: gItems,
        sectionKeys: keys,
        sectionKeyUsers: skUsers,
      };
    }, [cart, unavailableItems, aUsers]);

  const handleClearCart = () => {
    setConfirmation({
      title: 'Confirm Clear Cart',
      message:
        'Are you sure you want to clear your cart? This action will remove all items and cannot be undone.',
      onConfirm: () => onClearCart(),
      onCancel: () => setConfirmation(null),
      confirmText: 'Clear Cart',
      cancelText: 'Cancel',
    });
  };

  const handleConfirmRemoveCartItem = (params) => {
    const { name, date, userId, quantity, recId } = params;
    const displayDate = formatDateTime({
      dateTime: date,
      format: 'ddd, MMM D',
    });
    const u = getUser(userId)?.user;
    setConfirmation({
      title: 'Confirm Remove Item',
      message: `Are you sure you want to remove ${quantity} × ${name} for ${
        u?.preferredName || u?.firstName
      } on ${displayDate}?`,
      onConfirm: () => onRemoveCartItem({ id: recId }),
      onCancel: () => setConfirmation(null),
      confirmText: 'Remove Item',
      cancelText: 'Cancel',
    });
  };

  const handleQuantityChange = (quantity, params, prevQuantity) => {
    const { name, date, userId, recId } = params;
    if (quantity === 0 && !allowBulkOrdering) {
      handleConfirmRemoveCartItem({ ...params, quantity: prevQuantity });
    } else {
      const q = quantity < 0 ? 0 : quantity;
      onUpdateCartItem({ id: recId, quantity: q });

      // Handle an edge case for bulk ordering where a user enters a negative quantity
      if (quantity < 0) {
        const displayDate = formatDateTime({
          dateTime: date,
          format: 'ddd, MMM D',
        });
        const u = getUser(userId)?.user;

        setConfirmation({
          title: 'Invalid Quantity',
          message: `Invalid quantity entered for ${name} for ${
            u?.preferredName || u?.firstName
          } on ${displayDate}. Quantity will be set to 0 unless corrected.`,
          onConfirm: () => setConfirmation(null),
          confirmText: 'OK',
        });
      }
    }
  };

  const handleQuantityChangeDb = useCallback(
    debounce(handleQuantityChange, 1000),
    [handleQuantityChange, onUpdateCartItem],
  );

  const renderConfirmationAlert = () => {
    if (!confirmation) return null;
    return <Dialog {...confirmation} />;
  };

  const renderBulkQuantity = ({ quantity, ...params }) => {
    const { userId, date, recId } = params;
    const key = `${date}-${userId}-${recId}`;
    const values = { [key]: quantity };
    return (
      <Fragment key={key}>
        <IconButton
          id={`${key}-delete-btn`}
          disabled={loading || disableForm}
          onClick={() => handleConfirmRemoveCartItem({ ...params, quantity })}
        >
          <DeleteIcon />
        </IconButton>
        <FormControl
          key={key}
          variant="standard"
          sx={{
            margin: '0.5rem 0',
            display: 'inline-block',
            '& .MuiInput-root:before': {
              borderBottom: 'none',
            },
          }}
        >
          <TextField
            id={key}
            name={key}
            // label={label}
            type="number"
            inputProps={{ min: 0 }}
            onChange={(e) =>
              handleQuantityChangeDb(Number(e?.target?.value), params, quantity)
            }
            defaultValue={values[key]}
            // placeholder={placeholder}
            // error={touched[id] && !!(errors[id])}
            // helperText={touched[id] && errors[id]}
            sx={{
              width: '5rem',
              maxWidth: '5rem',
              marginRight: isMobile ? '6rem' : '4.5rem',
              marginLeft: '1rem',
            }}
            disabled={loading || disableForm}
          />
        </FormControl>
      </Fragment>
    );
  };

  const renderQuantity = ({ quantity, ...params }) => {
    if (
      allowBulkOrdering ||
      !quantityArr.includes(quantity) ||
      quantity === 0
    ) {
      return renderBulkQuantity({ quantity, ...params });
    }
    const { id, userId, date, idx } = params;
    const key = `${date}-${userId}-${id}-${idx}`;
    return (
      <FormControl
        key={key}
        variant="standard"
        sx={{
          margin: '0.5rem 0',
          display: 'inline-block',
          '& .MuiInput-root:before': {
            borderBottom: 'none',
          },
        }}
      >
        <Select
          id={key}
          value={quantity}
          onChange={(e) =>
            handleQuantityChange(e?.target?.value, params, quantity)
          }
          disabled={disableForm}
          label=""
          sx={{
            backgroundColor: 'var(--lightGray)',
            color: 'var(--black)',
            padding: '0 0.5rem',
            margin: '0 1rem 0 0',
            maxWidth: '2.5rem',
            border: 'none',
            borderRadius: '5px',
            fontSize: '0.8rem',
          }}
        >
          {quantityArr.map((q) => {
            return (
              <MenuItem key={q} value={q}>
                {q === 0 ? 'Remove' : q}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  const renderItemError = (errors) => {
    if (!Array.isArray(errors)) return null;
    return (
      <Form.Error>
        <ul>
          {errors.map((err, idx) => {
            return <li key={`${err}_${idx}`}>{err}</li>;
          })}
        </ul>
      </Form.Error>
    );
  };

  const renderBundlePrice = (price, currencyCode) => {
    return (
      <Currency
        amount={price}
        currencyCode={currencyCode}
        sx={{ marginLeft: '1rem', backgroundColor: 'var(--yellow)' }}
      />
    );
  };

  const renderBundleItem = (
    { id, name, quantity, price, bundleItems },
    parentId,
    idx,
    errors,
    isSubItem = false,
  ) => {
    const itemLabel = `${quantity} × ${name}`;
    const sx = isSubItem
      ? {
          margin: '0.5rem 0 0 1.25rem',
          padding: 0,
        }
      : {
          borderLeft: '2px solid var(--gray)',
          marginLeft: '3.5rem',
          padding: '0.25rem 0 0.25rem 1rem',
        };
    return (
      <Fragment key={`${id}-${parentId}-${idx}`}>
        <BundleItemRow sx={sx}>
          {errors ? <Form.Error>{itemLabel}</Form.Error> : itemLabel}
          {showBundlePrices && renderBundlePrice(price, currencyCode)}
          {bundleItems?.products?.length > 0 &&
            bundleItems.products.map((b, bIdx) =>
              renderBundleItem(b, id, bIdx, errors, true),
            )}
        </BundleItemRow>
      </Fragment>
    );
  };

  const renderItem = (
    {
      recId,
      id,
      name,
      quantity,
      price,
      currencyCode,
      idx,
      errors,
      bundleItems = null,
      bundlePrice,
    },
    sectionKey,
    userId,
  ) => {
    const p = bundlePrice || price;
    return (
      <ItemRow key={`${recId}_${userId}`}>
        <WrapperSpaced>
          <Wrapper>
            {renderQuantity({
              quantity,
              id,
              name,
              date: sectionKey,
              userId,
              idx,
              recId,
            })}
            {errors ? (
              <Form.Error>{name}</Form.Error>
            ) : (
              <CartItemName>{name}</CartItemName>
            )}
            {showBundlePrices && renderBundlePrice(price, currencyCode)}
          </Wrapper>
          {!allowBulkOrdering && (
            <Currency
              amount={p * quantity}
              currencyCode={currencyCode}
              sx={{ marginLeft: '1rem' }}
            />
          )}
        </WrapperSpaced>
        {bundleItems?.products?.length > 0 &&
          bundleItems.products.map((b, bIdx) =>
            renderBundleItem(b, id, bIdx, errors),
          )}
        {renderItemError(errors)}
      </ItemRow>
    );
  };

  const renderUser = (userId) => {
    const u = getUser(userId)?.user;
    const label = u
      ? `${u?.preferredName || u?.firstName}${userId === me?.id ? ' (me)' : ''}`
      : '';
    return <Name>{label}</Name>;
  };

  const renderSectionHeader = (text) => {
    return (
      <Heading
        variant="h6"
        sx={{ borderBottom: '1px solid var(--black)', display: 'inline-block' }}
      >
        <TodayIconStyled />
        {text}
      </Heading>
    );
  };

  const renderSection = (sectionKey, idx) => {
    const displayDate = formatDateTime({
      dateTime: sectionKey,
      format: 'ddd, MMM D',
    });
    const SectionWrapperEl = showInDrawer
      ? SectionWrapperInDrawer
      : SectionWrapper;
    const isLastSection =
      Array.isArray(sectionKeys) && idx === sectionKeys.length - 1;
    return (
      <Fragment key={sectionKey}>
        <SectionWrapperEl>
          {renderSectionHeader(displayDate)}
          {sectionKeyUsers[sectionKey].map((userId) => {
            const userItems = groupedItems[sectionKey][userId];
            return (
              <UserWrapper key={userId}>
                {renderUser(userId)}
                {userItems.map((item) => renderItem(item, sectionKey, userId))}
              </UserWrapper>
            );
          })}
        </SectionWrapperEl>
        {!isLastSection && <Divider />}
      </Fragment>
    );
  };

  const renderCartItems = () => {
    return Array.isArray(sectionKeys) && sectionKeys.length > 0 ? (
      <>
        {sectionKeys.map(renderSection)}
        {renderConfirmationAlert()}
      </>
    ) : (
      <EmptyState
        icon={ShoppingCartIcon}
        message="You don't have any items in your cart."
      />
    );
  };

  const renderCheckoutLabel = () => {
    return (
      <WrapperSpaced>
        Checkout
        {!allowBulkOrdering && (
          <Currency amount={summary?.total} currencyCode={currencyCode} />
        )}
      </WrapperSpaced>
    );
  };

  const renderClearAll = () => {
    return (
      <ClearButton
        onClick={handleClearCart}
        variant="text"
        disabled={cart?.items?.length === 0 || loading || disableForm}
      >
        Clear
      </ClearButton>
    );
  };

  if (!showInDrawer) return renderCartItems();

  return (
    <DrawerAdvanced
      open={isOpen}
      title="Cart"
      anchor={isMobile ? 'bottom' : 'right'}
      headerRight={renderClearAll()}
      onClose={onToggleCartView}
      actions={[
        {
          label: renderCheckoutLabel(),
          onClick: handleGoToCheckout,
          disabled: cart?.items?.length === 0 || disableForm,
          loading,
        },
      ]}
    >
      {renderCartItems()}
    </DrawerAdvanced>
  );
};

CartView.defaultProps = {
  showInDrawer: true,
  showQuantityForm: true,
  disableForm: false,
};

export default CartView;
